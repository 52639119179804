import React from 'react';
import {
  Button, Card, Col, Modal, Row,
} from 'react-bootstrap';
import RenderHTML from '../Shared/RenderHTML';

function ModaleAnteprimaArticoli({ show, handleClose, articoli }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      role="dialog"
    >
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title id="example-modal-sizes-title-lg">
          Anteprima del corpo della campagna informativa
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row xs={1} md={2} className="g-4">
          {articoli.map((el) => (
            <Col>
              <Card>
                {el.imageSrc ? <Card.Img variant="top" src={el.imageSrc} alt={el.nomeImmagine} /> : null}
                <Card.Body>
                  <Card.Title>{el.titolo}</Card.Title>
                  <RenderHTML html={el.testo} />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModaleAnteprimaArticoli;
