import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import FormColori from '../../../../components/Versioni/FormColori';
import FormContatti from '../../../../components/Versioni/FormContatti';
import FormImmagini from '../../../../components/Versioni/FormImmagini';
import FormLingue from '../../../../components/Versioni/FormLingue';
import FormNewsletter from '../../../../components/Versioni/FormNewsletter';
import FormNomeAlias from '../../../../components/Versioni/FormNomeAlias';
import galleriaService from '../../../../services/galleriaService';
import versioniService from '../../../../services/versioniService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';
import { setItem } from '../../../../utils/storage';

const ModificaVersioneSitoWeb = () => {
  const { idVersione } = useParams();
  const [context, setContext] = useContext(Context);
  const [versione, setVersione] = useState({
    desc_nome: '',
    desc_alias: '',
    flag_trasparenza: false,
    flag_newsletter: false,
    fk_codi_colore: null,
    lingue: [],
    recapiti: [
      {
        nome: 'Indirizzo struttura', desc_recapito: '', fk_codi_tipo_recapito: 1,
      },
      {
        nome: 'P.IVA/Codice fiscale', desc_recapito: '', fk_codi_tipo_recapito: 2,
      },
      {
        nome: 'Telefono aziendale', desc_recapito: '', fk_codi_tipo_recapito: 3,
      },
      {
        nome: 'Telefono', desc_recapito: '', fk_codi_tipo_recapito: 4,
      },
      {
        nome: 'Email', desc_recapito: '', fk_codi_tipo_recapito: 5,
      },
      {
        nome: 'PEC', desc_recapito: '', fk_codi_tipo_recapito: 6,
      },
      {
        nome: 'Facebook', desc_recapito: '', fk_codi_tipo_recapito: 7,
      },
      {
        nome: 'Twitter', desc_recapito: '', fk_codi_tipo_recapito: 8,
      },
      {
        nome: 'Instagram', desc_recapito: '', fk_codi_tipo_recapito: 9,
      },
      {
        nome: 'Youtube', desc_recapito: '', fk_codi_tipo_recapito: 10,
      },
      {
        nome: 'Portale amministrazione trasparente', desc_recapito: '', fk_codi_tipo_recapito: 11,
      },
    ],
    fk_logo_mongo_db: '',
    desc_filename_logo: '',
  });
  const [immagine, setImmagine] = useState({
    nomeImmagine: '',
    fileCaricato: null,
    alt: '',
  });
  const [listaColori, setListaColori] = useState([]);
  const [listaLingue, setListaLingue] = useState([]);
  const [listaImmagini, setListaImmagini] = useState([]);
  const [listaLingueRimosse, setListaLingueRimosse] = useState([]);

  async function getEntita() {
    try {
      const coloriDisponibili = await versioniService.getColoriDisponibili();
      const lingueDisponibili = await versioniService.getLingueDisponibili();
      const versioneById = await versioniService.getVersione(idVersione);
      const imgDisponibili = await galleriaService.getGalleria();

      setListaColori(coloriDisponibili);
      setListaLingue(lingueDisponibili);
      setVersione({
        ...versioneById,
        recapiti: versione.recapiti
          .map((el) => ({
            ...el,
            sequ_k_recapito: versioneById.recapiti
              .find((elem) => elem.fk_codi_tipo_recapito === el.fk_codi_tipo_recapito)
              ?.sequ_k_recapito,
            desc_recapito: versioneById.recapiti
              .find((elem) => elem.fk_codi_tipo_recapito === el.fk_codi_tipo_recapito)
              ?.desc_recapito,
          })),
      });
      setListaImmagini(imgDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  async function modificaVersione() {
    try {
      setItem('lingue', JSON.stringify(versione.lingue));
      const response = await versioniService.modificaVersione(
        idVersione,
        versione,
        listaLingueRimosse,
      );
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function uploadImmagine() {
    try {
      const response = await galleriaService.uploadImmagine(immagine);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      const imgDisponibili = await galleriaService.getGalleria();
      setListaImmagini(imgDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChange(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setVersione({
      ...versione,
      [e.target.id]: value,
    });
  }

  function onChangeColore(e) {
    const { value } = e.target;
    setVersione({
      ...versione,
      fk_codi_colore: Number(value),
    });
  }

  function onChangeLingue(e) {
    const { value, checked } = e.target;
    setVersione({
      ...versione,
      lingue: checked
        ? [...versione.lingue, Number(value)]
        : versione.lingue.filter((el) => (el.fk_codi_tipo_lingua
          ? el.fk_codi_tipo_lingua !== Number(value)
          : el !== Number(value))),
    });
    if (!checked) {
      setListaLingueRimosse([
        ...listaLingueRimosse, Number(value),
      ]);
    }
  }

  function onChangeRecapiti(e) {
    const { id, value } = e.target;
    setVersione({
      ...versione,
      recapiti:
      versione.recapiti
        .map((el) => (el.fk_codi_tipo_recapito === Number(id)
          ? ({ ...el, desc_recapito: value })
          : el)),
    });
  }

  function onChangeImmagine(immagineScelta) {
    setVersione({
      ...versione,
      fk_logo_mongo_db: immagineScelta._id,
      desc_filename_logo: immagineScelta.nomeFile,
    });
  }

  const onUpload = (e) => {
    const { id, value, files } = e.target;
    setImmagine({
      ...immagine,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  const onChangeAltImmagine = (e) => {
    const { id, value } = e.target;
    setImmagine({
      ...immagine,
      [id]: value,
    });
  };

  useEffect(() => {
    getEntita();
  }, []);

  return (
    <>
      <TitlePage
        title="Modifica versione sito web"
        subtitle="È possibile modificare una versione del sito web."
        showIndietro
        functionIndietro={() => history.goBack()}
        showAvanti
        functionAvanti={() => { modificaVersione(); history.push(`/area-riservata/siti-web/${idVersione}/albero`); }}
      />
      <p className="h5">Sezione 1:</p>
      <div className="h6">
        In questa prima sezione, è obbligatorio inserire:
        <ol>
          <li>il nome della versione, ovvero un nome mnemonico che si vuole dare alla versione;</li>
          <li>l&apos;alias del sito, ovvero l&apos;indirizzo di pubblicazione del sito;</li>
          <li>Il colore che si vuole dare al sito (rosso è di default)</li>
          <li>I recapiti del museo contrassegnati con * (indirizzo, telefono, ecc...)</li>
          <li>Il logo del museo</li>
        </ol>
      </div>
      <p className="h6"> I campi contrassegnati con * sono obbligatori </p>
      <FormNomeAlias
        versione={versione}
        onChange={onChange}
      />
      <FormNewsletter
        versione={versione}
        onChange={onChange}
      />
      <FormColori
        versione={versione}
        onChange={onChangeColore}
        listaColori={listaColori}
      />
      <FormLingue
        versione={versione}
        onChange={onChangeLingue}
        listaLingue={listaLingue}
      />
      <FormContatti
        onChange={onChangeRecapiti}
        listaRecapiti={versione.recapiti}
      />
      <FormImmagini
        onChange={onChangeImmagine}
        listaImmagini={listaImmagini}
        versione={versione}
        immagine={immagine}
        onUpload={onUpload}
        onChangeAltImmagine={onChangeAltImmagine}
        uploadImmagine={uploadImmagine}
      />
    </>
  );
};

export default ModificaVersioneSitoWeb;
