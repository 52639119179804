import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styles from './index.module.css';
import avatar from '../../../images/default-avatar.jpg';

function LoginInfo({ user }) {
  return (
    <div>
      <Row xs={1} md={2} lg={2}>
        <Col className="col-lg-auto col-md-auto text-center">
          <img className={`${styles.img}`} alt="Foto profilo" src={avatar} />
          <br />
          {/* <span className="text-center ">
            <FontAwesomeIcon aria-hidden="true" icon="edit" />
            {' '}
            Cambia foto
          </span> */}
        </Col>
        {user.sequ_k_utente_trusted ? (
          <Col>
            <h2>Sei autenticato in modalità trusted</h2>
            <Form className="my-3">
              <Form.Group>
                <Form.Label htmlFor="codice-fiscale">Codice fiscale</Form.Label>
                <Form.Control value={user.codi_id_fiscale} disabled id="codice-fiscale" />
              </Form.Group>
            </Form>
            {user.codi_area || user.codi_servizio
              ? (
                <Form className="my-3">
                  <Form.Group>
                    <Form.Label htmlFor="area">Area</Form.Label>
                    <Form.Control value={user.codi_area} disabled id="area" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="servizio">Servizio</Form.Label>
                    <Form.Control value={user.codi_servizio} disabled id="servizio" />
                  </Form.Group>
                </Form>
              )
              : null }
          </Col>
        )
          : (
            <Col>
              <h2>Sei autenticato in modalità untrusted</h2>
              <Form className="my-3">
                <Form.Group>
                  <Form.Label htmlFor="tipo-connettore">Tipo connettore</Form.Label>
                  <Form.Control value={user.desc_tipo_connettore} disabled id="tipo-connettore" />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="identificativo-connettore">Identificativo connettore</Form.Label>
                  <Form.Control value={user.codi_id_connettore_utente} disabled id="identificativo-connettore" />
                </Form.Group>
              </Form>
            </Col>
          )}
      </Row>
    </div>
  );
}

export default LoginInfo;
