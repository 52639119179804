import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';
import { formatoData } from '../../../../utils/formatData';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';

const fieldsProfonditaStorica = [
  {
    dataField: 'sequ_k_ruolo',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome_ruolo',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
  },
  {
    dataField: 'desc_nome_tag_ruolo',
    text: 'Tag',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'flag_default',
    text: 'Default',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
    formatter: (e) => (e ? 'Sì' : 'No'),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e, row) => (

      <div>
        <>

          <FontAwesomeIcon
            title={`Visualizza il ruolo ${row.desc_nome_ruolo}`}
            icon={['fas', 'search']}
            size="lg"
            id={`visualizza-${row.sequ_k_ruolo}`}
            style={{ color: themeColors.primary }}
            onClick={() => {
              history.push(`/area-riservata/amministrazione/gestione-ruolo/${row.sequ_k_ruolo}/dettaglio`);
            }}
          />

          {controlla('X6') && !row.data_fine_validita && (
          <FontAwesomeIcon
            title={`Modifica il ruolo ${row.desc_nome_ruolo}`}
            icon={['fas', 'edit']}
            size="lg"
            id={`modifica-${row.sequ_k_ruolo}`}
            style={{ color: themeColors.primary }}
            onClick={() => history.push(`/area-riservata/amministrazione/gestione-ruolo/${row.sequ_k_ruolo}/modifica`)}
          />
          )}
          {controlla('X6') && !row.flag_protetto && !row.data_fine_validita && (
          <FontAwesomeIcon
            title={`Elimina il ruolo ${row.desc_nome_ruolo}`}
            icon={['fas', 'trash-alt']}
            size="lg"
            id={`elimina-${row.sequ_k_ruolo}`}
            style={{ color: themeColors.primary }}
            onClick={() => history.push(`/area-riservata/amministrazione/gestione-ruolo/${row.sequ_k_ruolo}/dettaglio?elimina=true`)}
          />
          )}
        </>
      </div>

    ),
  },
];

const fieldsNoProfonditaStorica = [
  {
    dataField: 'sequ_k_ruolo',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome_ruolo',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
  },
  {
    dataField: 'desc_nome_tag_ruolo',
    text: 'Tag',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'flag_default',
    text: 'Default',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
    formatter: (e) => (e ? 'Sì' : 'No'),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '25%' }),
    formatter: (e, row) => (

      <div>
        <>

          <FontAwesomeIcon
            title={`Visualizza il ruolo ${row.desc_nome_ruolo}`}
            icon={['fas', 'search']}
            size="lg"
            id={`visualizza-${row.sequ_k_ruolo}`}
            style={{ color: themeColors.primary }}
            onClick={() => {
              history.push(`/area-riservata/amministrazione/gestione-ruolo/${row.sequ_k_ruolo}/dettaglio`);
            }}
          />

          {controlla('X6') && !row.data_fine_validita && (
          <FontAwesomeIcon
            title={`Modifica il ruolo ${row.desc_nome_ruolo}`}
            icon={['fas', 'edit']}
            size="lg"
            id={`modifica-${row.sequ_k_ruolo}`}
            style={{ color: themeColors.primary }}
            onClick={() => history.push(`/area-riservata/amministrazione/gestione-ruolo/${row.sequ_k_ruolo}/modifica`)}
          />
          )}
          {controlla('X8') && !row.flag_protetto && !row.data_fine_validita && (
          <FontAwesomeIcon
            title={`Elimina il ruolo ${row.desc_nome_ruolo}`}
            icon={['fas', 'trash-alt']}
            size="lg"
            id={`elimina-${row.sequ_k_ruolo}`}
            style={{ color: themeColors.primary }}
            onClick={() => history.push(`/area-riservata/amministrazione/gestione-ruolo/${row.sequ_k_ruolo}/dettaglio?elimina=true`)}
          />
          )}

        </>
      </div>

    ),
  },
];

export { fieldsNoProfonditaStorica, fieldsProfonditaStorica };
