import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Form, Row, Button, Col,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import { fieldsProfonditaStoricaIdentita, fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Identita/fieldsTableIdentita';
import { fieldsProfonditaStorica, fieldsNoProfonditaStorica } from '../../../../../components/Identita/fieldsTableRuoli';
import FormDettaglioIdentita from '../../../../../components/Identita/FormDettaglioIdentita';
import ModaleNuovoTagIdentita from '../../../../../components/Identita/ModaleNuovoTagIdentita';
import ModaleRicercaIdentitaModifica from '../../../../../components/Identita/ModaleRicercaIdentitaModifica';
import ModaleRicercaRuoliModifica from '../../../../../components/Identita/ModaleRicercaRuoliModifica';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import Table from '../../../../../components/Shared/Table';
import identitaService from '../../../../../services/identitaService';
import organizzazioniService from '../../../../../services/organizzazioniService';
import ruoliService from '../../../../../services/ruoliService';
import TagIdentitaService from '../../../../../services/tagIdentitaService';
import tagRuoliService from '../../../../../services/tagRuoliService';
import tipoIdentitaService from '../../../../../services/tipoIdentitaService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';

function ModificaIdentita() {
  const { idIdentita } = useParams();
  const [context, setContext] = useContext(Context);
  const [listaTagIdentita, setListaTagIdentita] = useState([]);
  const [listaOrganizzazioni, setListaOrganizzazioni] = useState([]);
  const [listaTipoIdentita, setListaTipoIdentita] = useState([]);
  const [listaRuoli, setListaRuoli] = useState([]);
  const [listaIdentita, setListaIdentita] = useState([]);
  const [ruoliScelti, setRuoliScelti] = useState([]);
  const [ruoliAggiunti, setRuoliAggiunti] = useState([]);
  const [ruoliRimossi, setRuoliRimossi] = useState([]);
  const [identitaScelte, setIdentitaScelte] = useState([]);
  const [identitaAggiunte, setIdentitaAggiunte] = useState([]);
  const [identitaRimosse, setIdentitaRimosse] = useState([]);
  const [showModalRuoli, setShowModalRuoli] = useState(false);
  const [showModalIdentita, setShowModalIdentita] = useState(false);
  const [showModaleNewTagIdentita, setShowModaleNewTagIdentita] = useState(false);
  const [listaTagRuolo, setListaTagRuolo] = useState([]);
  const [nuovaIdentita, setNuovaIdentita] = useState({
    desc_nome_identita: '',
    desc_identita: '',
    desc_note_identita: '',
    fk_codi_tipo_identita: 0,
    fk_sequ_tag_identita: 0,
    fk_sequ_organizzazione: 0,
    data_inizio_validita: new Date(),
  });
  const [filtriIdentitaDettaglio, setFiltriIdentitaDettaglio] = useState({
    profondita_storica_ruoli: false,
    profondita_storica_figlie: false,
  });
  const [paginationRuoli, setPaginationRuoli] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [paginationIdentita, setPaginationIdentita] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtriRuoli, setFiltriRuoli] = useState({
    flag_admin: false,
    flag_protetto: false,
    fk_sequ_tag_ruolo: null,
    profondita_storica: false,
  });
  const [filtriIdentita, setFiltriIdentita] = useState({
    flag_protetto: false,
    fk_sequ_tag_identita: null,
    desc_nome_identita: '',
    fk_codi_tipo_identita: null,
    profondita_storica: false,
  });
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function getListaIdentitaDominio() {
    const response = await identitaService.getIdentita(
      { fk_codi_tipo_identita: 5, profondita_storica: false }, 0, 10000,
    );
    return response.data;
  }

  async function onChangeForm(e) {
    const { id, value } = e.target;
    if (id === 'fk_codi_tipo_identita' && value === '5') {
      setIdentitaScelte(await getListaIdentitaDominio());
    } else if (id === 'fk_codi_tipo_identita' && value === '6') {
      setIdentitaScelte([]);
    }
    // if (value === 'true') {
    //   value = true;
    // } else if (value === 'false') {
    //   value = false;
    // // eslint-disable-next-line no-restricted-globals
    // } else if (!isNaN(value)) {
    //   value = parseInt(value, 10);
    // }
    // VEDI: Ho risolt oil problema dei Nan così, se vuoi usare un metodo alternativo va benissimo
    if (id === 'fk_codi_tipo_identita' || id === 'fk_sequ_tag_identita') {
      setNuovaIdentita((prevIdentita) => ({
        ...prevIdentita,
        [id]: parseInt(value, 10),
      }));
    } else {
      setNuovaIdentita((prevIdentita) => ({
        ...prevIdentita,
        [id]: value,
      }));
    }
  }

  async function getListaTagIdentita() {
    const response = await TagIdentitaService.getTagIdentita({ flag_protetto: false });
    setListaTagIdentita(response.data);
  }

  async function getListaOrganizzazioni() {
    const response = await organizzazioniService.getOrganizzazioni();
    setListaOrganizzazioni(response);
  }

  async function getListaTipoIdentita() {
    const response = await tipoIdentitaService.getTipoIdentita({ flag_protetto: false });
    setListaTipoIdentita(response);
  }

  async function getListaRuoli(offset, limit, page) {
    const response = await ruoliService.getRuoli(filtriRuoli, offset, limit);
    setListaRuoli(response.data);
    setPaginationRuoli({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getListaIdentita(offset, limit, page) {
    const response = await identitaService.getIdentita(
      { flag_config: false, ...filtriIdentita }, offset, limit,
    );
    setListaIdentita(response.data);
    setPaginationIdentita({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getTagsRuolo() {
    const { data } = await tagRuoliService.getTagRuoli();
    setListaTagRuolo(data);
  }

  function onChangeFiltriRuolo(e) {
    let { value } = e.target;
    const { id } = e.target;
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(value)) {
      value = parseInt(value, 10);
    }
    setFiltriRuoli({
      ...filtriRuoli,
      [id]: value,
    });
  }

  function onChangeFiltriIdentita(e) {
    let { value } = e.target;
    const { id } = e.target;
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(value)) {
      value = parseInt(value, 10);
    }
    setFiltriIdentita({
      ...filtriIdentita,
      [id]: value,
    });
  }

  function handleModaleRuoliStatus() {
    setShowModalRuoli(!showModalRuoli);
  }

  function handleModaleIdentitaStatus() {
    setShowModalIdentita(!showModalIdentita);
  }

  const handleTableChangeRuoli = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getListaRuoli(currentIndex, sizePerPage, page);
  };

  const handleTableChangeIdentita = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getListaIdentita(currentIndex, sizePerPage, page);
  };

  async function modificaIdentita() {
    const ruoliBody = [];
    const nuoviAggiunti = [...new Set(ruoliAggiunti)];
    const nuoviRimossi = [...new Set(ruoliRimossi)];
    nuoviRimossi.forEach((el) => {
      ruoliBody.push({ ...el, azione: 'elimina' });
    });
    nuoviAggiunti.forEach((el) => {
      ruoliBody.push({ ...el, azione: 'aggiungi' });
    });

    const identitaCollegate = [];
    const identitaEscluse = [];

    let nuoveIdentitaCollegateEsterne = [];
    let nuoveIdentitaRimosseEsterne = [];
    if (nuovaIdentita.fk_codi_tipo_identita === 6) {
      nuoveIdentitaCollegateEsterne = new Set(identitaAggiunte.filter(
        (el) => el.fk_codi_tipo_identita === 6 || el.fk_codi_tipo_identita === 5,
      ));

      nuoveIdentitaRimosseEsterne = new Set(identitaRimosse.filter(
        (el) => el.fk_codi_tipo_identita === 6 || el.fk_codi_tipo_identita === 5,
      ));
    } else {
      nuoveIdentitaCollegateEsterne = new Set(identitaAggiunte.filter(
        (el) => el.fk_codi_tipo_identita === 6,
      ));

      nuoveIdentitaRimosseEsterne = new Set(identitaRimosse.filter(
        (el) => el.fk_codi_tipo_identita === 6,
      ));
    }

    nuoveIdentitaCollegateEsterne.forEach((el) => {
      identitaCollegate.push({ ...el, azione: 'aggiungi' });
    });

    nuoveIdentitaRimosseEsterne.forEach((el) => {
      identitaCollegate.push({ ...el, azione: 'elimina' });
    });

    const nuoveIdentitaCollegateDominio = new Set(identitaAggiunte.filter(
      (el) => el.fk_codi_tipo_identita === 5,
    ));

    const nuoveIdentitaRimosseDominio = new Set(identitaRimosse.filter(
      (el) => el.fk_codi_tipo_identita === 5,
    ));

    nuoveIdentitaCollegateDominio.forEach((el) => {
      identitaEscluse.push({ ...el, azione: 'elimina' });
    });

    nuoveIdentitaRimosseDominio.forEach((el) => {
      identitaEscluse.push({ ...el, azione: 'aggiungi' });
    });

    identitaService.modificaIdentita({
      ...nuovaIdentita,
      ruoli: ruoliBody,
      collegate: identitaCollegate,
      escluse: identitaEscluse,
    })
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function getIdentita() {
    const response = await identitaService.getIdentitaById(idIdentita, filtriIdentitaDettaglio);
    setNuovaIdentita(response);
    setRuoliScelti(response.ruoli);
    setIdentitaScelte(response.identita);
  }

  function handleModaleNewTagIdentita() {
    setShowModaleNewTagIdentita(!showModaleNewTagIdentita);
    getListaTagIdentita();
  }

  function onChangeFiltriIdentitaDettaglio(e) {
    const { id } = e.target;
    const { value } = e.target;
    setFiltriIdentitaDettaglio({
      ...filtriIdentitaDettaglio,
      [id]: value,
    });
  }

  useEffect(() => {
    getIdentita();
  }, [filtriIdentitaDettaglio]);

  useEffect(() => {
    getListaTagIdentita();
    getListaOrganizzazioni();
    getListaTipoIdentita();
    getListaRuoli(0, 5, 1);
    getTagsRuolo();
    getListaIdentita(0, 5, 1);
    getIdentita();
  }, []);

  return (
    <>
      <TitlePage
        title="Modifica dell'identità"
        subtitle="È possibile modificare l'identità."
        showIndietro
        functionIndietro={() => {
          history.push('/area-riservata/amministrazione/gestione-identita');
        }}
      />
      <Row className="mt-3 w-25">
        <Form.Group as={Col} controlId="sequ_k_identita">
          <Form.Label>ID</Form.Label>
          <Form.Control
            placeholder="ID identità"
            value={nuovaIdentita.sequ_k_identita}
            disabled
          />
        </Form.Group>
      </Row>
      <FormDettaglioIdentita
        listaTagIdentita={listaTagIdentita}
        listaOrganizzazioni={listaOrganizzazioni}
        listaTipoIdentita={listaTipoIdentita}
        nuovaIdentita={nuovaIdentita}
        onChangeForm={onChangeForm}
        disabledModifica
        handleModaleNewTagIdentita={handleModaleNewTagIdentita}
      />
      <Row className="mb-3 border-top border-dark">
        <Table
          title="Elenco ruoli associati"
          data={ruoliScelti}
          fields={filtriIdentitaDettaglio.profondita_storica_ruoli === 'true' ? fieldsProfonditaStorica : fieldsNoProfonditaStorica}
          keyField="sequ_k_ruolo"
          button={<Button onClick={handleModaleRuoliStatus}>Modifica</Button>}
          profonditaStorica={(
            <Form.Group controlId="profondita_storica_ruoli">
              <Form.Label>Visualizza profondità storica</Form.Label>
              <Form.Select
                onChange={onChangeFiltriIdentitaDettaglio}
                value={filtriIdentitaDettaglio.profondita_storica_ruoli}
              >
                <option value="true">Sì</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Group>
        )}
        />
      </Row>
      {!nuovaIdentita.flag_base ? (
        <Row className="mb-3 border-top border-dark">
          <Table
            title="Lista identità referenziate da questa identità"
            data={identitaScelte}
            fields={filtriIdentitaDettaglio.profondita_storica_figlie === 'true' ? fieldsProfonditaStoricaIdentita : fieldsNoProfonditaStoricaIdentita}
            keyField="sequ_k_identita"
            button={<Button onClick={handleModaleIdentitaStatus}>Modifica</Button>}
            profonditaStorica={(
              <Form.Group controlId="profondita_storica_figlie">
                <Form.Label>Visualizza profondità storica</Form.Label>
                <Form.Select
                  onChange={onChangeFiltriIdentitaDettaglio}
                  value={filtriIdentitaDettaglio.profondita_storica_figlie}
                >
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
          )}
          />
        </Row>
      ) : null }
      <br />
      <div className="d-flex justify-content-end">
        <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
          <FontAwesomeIcon aria-hidden="true" icon="save" />
          {' '}
          Salva
        </Button>
      </div>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione modificherà l\'identità'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={modificaIdentita}
      />
      <ModaleRicercaRuoliModifica
        show={showModalRuoli}
        handleClose={handleModaleRuoliStatus}
        ruoli={listaRuoli}
        getListaRuoli={getListaRuoli}
        ruoliScelti={ruoliScelti}
        setRuoliScelti={setRuoliScelti}
        handleTableChangeRuoli={handleTableChangeRuoli}
        listaTagRuolo={listaTagRuolo}
        paginationRuoli={paginationRuoli}
        onChangeFiltriRuolo={onChangeFiltriRuolo}
        ruoliAggiunti={ruoliAggiunti}
        ruoliRimossi={ruoliRimossi}
        setRuoliAggiunti={setRuoliAggiunti}
        setRuoliRimossi={setRuoliRimossi}
      />
      <ModaleRicercaIdentitaModifica
        show={showModalIdentita}
        handleClose={handleModaleIdentitaStatus}
        identita={listaIdentita}
        getListaIdentita={getListaIdentita}
        identitaScelte={identitaScelte}
        setIdentitaScelte={setIdentitaScelte}
        handleTableChangeIdentita={handleTableChangeIdentita}
        listaTagIdentita={listaTagIdentita}
        listaTipoIdentita={listaTipoIdentita}
        paginationIdentita={paginationIdentita}
        setPaginationIdentita={setPaginationIdentita}
        onChangeFiltriIdentita={onChangeFiltriIdentita}
        identitaAggiunte={identitaAggiunte}
        identitaRimosse={identitaRimosse}
        setIdentitaAggiunte={setIdentitaAggiunte}
        setIdentitaRimosse={setIdentitaRimosse}
      />
      <ModaleNuovoTagIdentita
        show={showModaleNewTagIdentita}
        handleClose={handleModaleNewTagIdentita}
      />
    </>
  );
}

export default ModificaIdentita;
