import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Form, Row, Button, Accordion,
} from 'react-bootstrap';

function Filtri({
  filtri, onChangeFiltri, getTagRuolo,
}) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="desc_nome_tag_ruolo">
                <Form.Label>Nome</Form.Label>
                <Form.Control placeholder="Inserisci nome" value={filtri.desc_nome_tag_ruolo} onChange={onChangeFiltri} />
              </Form.Group>

              <Form.Group as={Col} lg controlId="desc_tag_ruolo">
                <Form.Label>Descrizione</Form.Label>
                <Form.Control placeholder="Inserisci descrizione" value={filtri.desc_tag_ruolo} onChange={onChangeFiltri} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="col-lg-6" controlId="profondita_storica">
                <Form.Label>Visualizza profondità storica</Form.Label>
                <Form.Select
                  onChange={onChangeFiltri}
                  value={filtri.profondita_storica}
                >
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Button variant="primary" onClick={() => getTagRuolo(0, 5, 1)}>
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Filtri;
