import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getTagIdentita(filtri) {
  const params = createQueryString(filtri);

  return api.get(`${accreditamentoUrl}/gestione-tag-identita?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoTagIdentita(data) {
  return api.post(`${accreditamentoUrl}/gestione-tag-identita`, data)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function cancellaTagIdentita(id) {
  return api.delete(`${accreditamentoUrl}/gestione-tag-identita/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const TagIdentitaService = {
  getTagIdentita,
  nuovoTagIdentita,
  cancellaTagIdentita,
};

export default TagIdentitaService;
