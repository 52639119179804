/* eslint-disable consistent-return */
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import questionariNonTracciatiService from '../../services/questionariNonTracciatiService';
import { Context } from '../../utils/Context';
import history from '../../utils/history';

const AccettazioneTemplateEsterno = () => {
  const { idTemplate } = useParams();
  const [context, setContext] = useContext(Context);
  const [template, setTemplate] = useState({
    titolo: '',
    descrizione: '',
  });

  async function getTemplate() {
    try {
      const response = await questionariNonTracciatiService.getTemplateById(idTemplate);
      setTemplate(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  async function createQuestionario() {
    try {
      const response = await questionariNonTracciatiService.createQuestionario(idTemplate);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      history.push(`/questionari/compila-questionario/${response.id}`);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  useEffect(() => {
    getTemplate();
  }, []);
  return (
    <>
      {
      template ? (
        <div className="m-2" style={{ textAlign: 'center' }}>
          <h1>
            Sei stato invitato a compilare il questionario &quot;
            {template.titolo}
            &quot;
          </h1>
          <p id="desc">Clicca il pulsante qui sotto per cominciare.</p>
          <Button onClick={createQuestionario}>Inizia</Button>
        </div>
      ) : (
        <div className="m-2" style={{ textAlign: 'center' }}>
          <h1>Link non valido</h1>
          <Link to="/">Torna alla home</Link>
        </div>
      )
    }
    </>
  );
};

export default AccettazioneTemplateEsterno;
