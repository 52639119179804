/* eslint-disable prefer-template */
import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AutorizzazioniForum = ({
  autorizzazioni, onChangeAutorizzazioni, listaIdentita, aggiungiRow, handleCloseModaleUtenti,
  setIndexAutorizzazioneScelta, eliminaRow, setIdentitaScelta,
}) => (
  <>
    <Button variant="primary" className="mb-2" onClick={aggiungiRow}>
      <FontAwesomeIcon aria-hidden="true" icon="plus" />
      {' '}
      AGGIUNGI
    </Button>
    <Form>
      {autorizzazioni?.map((el, i) => (
        <>
          <Row className="my-1" key={el.idIdentita}>
            <Form.Group as={Col} controlId={'idIdentita' + el.idIdentita}>
              <Form.Label>Lista identità</Form.Label>
              <Form.Select
                onChange={(e) => onChangeAutorizzazioni('idIdentita', e, i, 'int')}
                value={el.idIdentita}
                disabled={el.codiceFiscale}
              >
                <option> </option>
                {listaIdentita.map((tipo) => (
                  <option
                    key={tipo.sequ_k_identita}
                    value={tipo.sequ_k_identita}
                  >
                    {tipo.desc_nome_identita}

                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Col>
              <Form.Label>Utente autorizzato</Form.Label>
              <br />
              {el.codiceFiscale
                ? (
                  <>
                    {el.codiceFiscale}
                    &nbsp;
                    <Button
                      variant="danger"
                      className="mb-2"
                      onClick={() => { onChangeAutorizzazioni('codiceFiscale', { target: { value: null } }, i); }}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="trash" />
                      {' '}
                      ELIMINA
                    </Button>
                  </>
                )
                : (
                  <Button
                    variant="primary"
                    className="mb-2"
                    onClick={() => {
                      setIdentitaScelta(autorizzazioni[i]?.idIdentita);
                      setIndexAutorizzazioneScelta(i); handleCloseModaleUtenti();
                    }}
                  >
                    <FontAwesomeIcon aria-hidden="true" icon="user" />
                    {' '}
                    SCEGLI UTENTE
                  </Button>
                )}
            </Col>
            <Col>
              <Button
                variant="danger"
                className="mb-2"
                onClick={() => { eliminaRow(i); }}
              >
                <FontAwesomeIcon aria-hidden="true" icon="minus" />
                {' '}
                ELIMINA REGOLA
              </Button>
            </Col>
          </Row>
          <div className="separator border-1 border-bottom py-1" />
        </>
      ))}
    </Form>
  </>
);

export default AutorizzazioniForum;
