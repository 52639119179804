import React, { useContext, useState } from 'react';

import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import FormNomeAlias from '../../../components/Versioni/FormNomeAlias';
import versioniService from '../../../services/versioniService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';

const CreazioneVersioneAmmTrasparente = () => {
  const [context, setContext] = useContext(Context);
  const [versione, setVersione] = useState({
    desc_nome: '',
    desc_alias: '',
    flag_trasparenza: true,
    flag_newsletter: false,
    fk_codi_colore: 1,
  });

  async function creaVersione() {
    try {
      const response = await versioniService.creaVersione(versione);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      history.push(`/area-riservata/siti-web/${response.insertedId}/albero`);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChange(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setVersione({
      ...versione,
      [e.target.id]: value,
    });
  }

  return (
    <>
      <TitlePage
        title="Creazione versione Amministrazione trasparente"
        subtitle="È possibile creare una nuova versione del sito web per l'amministrazione trasparente."
        showIndietro
        functionIndietro={() => history.goBack()}
        showAvanti
        functionAvanti={creaVersione}
      />
      <p className="h5">Sezione 1:</p>
      <div className="h6">
        In questa prima sezione, è obbligatorio inserire:
        <ol>
          <li>il nome della versione, ovvero un nome mnemonico che si vuole dare alla versione;</li>
          <li>l&apos;alias del sito, ovvero l&apos;indirizzo di pubblicazione del sito;</li>
        </ol>
      </div>
      <p className="h6"> I campi contrassegnati con * sono obbligatori </p>
      <FormNomeAlias
        versione={versione}
        onChange={onChange}
      />
    </>
  );
};

export default CreazioneVersioneAmmTrasparente;
