import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  React, useContext, useEffect, useState,
} from 'react';
import {
  Button, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import { fieldsProfonditaStorica, fieldsNoProfonditaStorica } from '../../../../../components/Ruoli/fieldsTableFunzioniModifica';
import { fieldsProfonditaStoricaIdentita, fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Ruoli/fieldsTableIdentita';
import FormInformazioni from '../../../../../components/Ruoli/FormInformazioni';
import ModaleRicercaFunzioniModifica from '../../../../../components/Ruoli/ModaleRicercaFunzioniModifica';
import ModaleNuovoTagRuolo from '../../../../../components/Ruoli/ModaleNuovoTagRuolo';

import Table from '../../../../../components/Shared/Table';
import funzioniService from '../../../../../services/funzioniService';
import ruoliService from '../../../../../services/ruoliService';
import { Context } from '../../../../../utils/Context';
import tagFunzioniService from '../../../../../services/tagFunzioniService';
import tagRuoliService from '../../../../../services/tagRuoliService';
import history from '../../../../../utils/history';

function FormModificaRuolo() {
  const { idRuolo } = useParams();
  const [context, setContext] = useContext(Context);
  const [tagsRuolo, setTagsRuolo] = useState([]);
  const [showModalFunzioni, setShowModalFunzioni] = useState(false);
  const [funzioni, setFunzioni] = useState([]);
  const [tagsFunzione, setTagsFunzione] = useState([]);
  const [nuovoRuolo, setNuovoRuolo] = useState({
    desc_nome_ruolo: '',
    desc_ruolo: '',
    desc_note_ruolo: '',
    flag_default: false,
    fk_sequ_tag_ruolo: 0,
    identita: [],
  });
  const [funzioniScelte, setFunzioniScelte] = useState([]);
  const [funzioniAggiunte, setFunzioniAggiunte] = useState([]);
  const [funzioniRimosse, setFunzioniRimosse] = useState([]);
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [showModalNewTagRuolo, setshowModalNewTagRuolo] = useState(false);

  const [filtriFunzione, setFiltriFunzione] = useState({
    flag_admin: false,
  });
  const [filtriRuolo, setFiltriRuolo] = useState({
    profondita_storica_funzioni: false,
    profondita_storica_identita: false,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function handleModaleFunzioniStatus() {
    setShowModalFunzioni(!showModalFunzioni);
  }

  function onChangeForm(e) {
    const { id, value } = e.target;
    // if (value === 'true') {
    //   value = true;
    // } else if (value === 'false') {
    //   value = false;
    // // eslint-disable-next-line no-restricted-globals
    // } else if (!isNaN(value)) {
    //   value = parseInt(value, 10);
    // }
    // VEDI: Ho risolto il problema dei Nan così, se vuoi usare un metodo alternativo va benissimo
    switch (id) {
      case 'flag_default': {
        setNuovoRuolo((prevRuolo) => ({
          ...prevRuolo,
          [id]: value === 'true',
        }));
        break;
      }
      case 'fk_sequ_tag_ruolo': {
        setNuovoRuolo((prevRuolo) => ({
          ...prevRuolo,
          [id]: parseInt(value, 10),
        }));
        break;
      }
      default: {
        setNuovoRuolo((prevRuolo) => ({
          ...prevRuolo,
          [id]: value,
        }));
        break;
      }
    }
  }

  async function getTagsRuolo() {
    const { data } = await tagRuoliService.getTagRuoli({ flag_protetto: false });
    setTagsRuolo(data);
  }

  async function getTagFunzione() {
    setTagsFunzione(await tagFunzioniService.getTagFunzioni());
  }

  async function getFunzioni(offset, limit, page) {
    const response = await funzioniService.getFunzioni(filtriFunzione, offset, limit);
    setFunzioni(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getFunzioni(currentIndex, sizePerPage, page);
  };

  async function modificaRuolo() {
    setNuovoRuolo({ ...nuovoRuolo, funzioni: funzioniScelte });
    const funzioniBody = [];
    const nuoveAggiunte = [...new Set(funzioniAggiunte)];
    const nuoveRimosse = [...new Set(funzioniRimosse)];
    nuoveRimosse.forEach((el) => {
      funzioniBody.push({ ...el, azione: 'elimina' });
    });
    nuoveAggiunte.forEach((el) => {
      funzioniBody.push({ ...el, azione: 'aggiungi' });
    });
    ruoliService.modificaRuolo({
      ...nuovoRuolo,
      funzioni: funzioniBody,
    })
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-ruolo');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function getRuolo() {
    const response = await ruoliService.getRuoloById(idRuolo, filtriRuolo);
    setNuovoRuolo(response);
    setFunzioniScelte(response.funzioni);
  }

  function onChangeFiltriFunzione(e) {
    const { id } = e.target;
    let { value } = e.target;
    value = parseInt(value, 10);
    setFiltriFunzione({
      ...filtriFunzione,
      [id]: value,
    });
  }

  function onChangeFiltriRuolo(e) {
    const { id } = e.target;
    const { value } = e.target;
    setFiltriRuolo({
      ...filtriRuolo,
      [id]: value,
    });
  }

  function handleModaleNewTagRuolo() {
    setshowModalNewTagRuolo(!showModalNewTagRuolo);
    getTagsRuolo();
  }

  // TODO: sistemare la pagination
  useEffect(() => {
    getTagsRuolo();
    getFunzioni(0, 5, 1);
    getRuolo();
    getTagFunzione();
  }, []);

  useEffect(() => {
    getRuolo();
  }, [filtriRuolo]);

  return (
    <>
      <TitlePage
        title="Modifica ruolo"
        subtitle="È possibile modificare il ruolo."
        showIndietro
        functionIndietro={() => {
          history.push('/area-riservata/amministrazione/gestione-ruolo');
        }}
      />
      <FormInformazioni
        nuovoRuolo={nuovoRuolo}
        onChangeForm={onChangeForm}
        tagsRuolo={tagsRuolo}
        disabledNome
        handleModaleNewTagRuolo={handleModaleNewTagRuolo}
      />
      <Row className="mb-3 border-top border-dark">
        <Table
          title="Elenco funzioni associate*"
          data={[...funzioniScelte]}
          fields={filtriRuolo.profondita_storica_funzioni === 'true' ? fieldsProfonditaStorica : fieldsNoProfonditaStorica}
          keyField="sequ_k_funzione"
          button={<Button onClick={handleModaleFunzioniStatus}>Modifica</Button>}
          profonditaStorica={(
            <Form.Group controlId="profondita_storica_funzioni">
              <Form.Label>Visualizza profondità storica</Form.Label>
              <Form.Select
                onChange={onChangeFiltriRuolo}
                value={filtriRuolo.profondita_storica_funzioni}
              >
                <option value="true">Sì</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Group>
          )}
        />
      </Row>
      <Row className="mb-3 border-top border-dark">
        <Table
          title="Elenco identità associate"
          data={nuovoRuolo.identita}
          fields={filtriRuolo.profondita_storica_identita === 'true' ? fieldsProfonditaStoricaIdentita : fieldsNoProfonditaStoricaIdentita}
          keyField="sequ_k_identita"
          profonditaStorica={(
            <Form.Group controlId="profondita_storica_identita">
              <Form.Label>Visualizza profondità storica</Form.Label>
              <Form.Select
                onChange={onChangeFiltriRuolo}
                value={filtriRuolo.profondita_storica_identita}
              >
                <option value="true">Sì</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Group>
          )}
        />
      </Row>
      <br />
      <div className="d-flex justify-content-end">
        <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
          <FontAwesomeIcon aria-hidden="true" icon="save" />
          {' '}
          Salva
        </Button>
      </div>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione inserirà un nuovo ruolo'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={modificaRuolo}
      />
      <ModaleRicercaFunzioniModifica
        show={showModalFunzioni}
        handleClose={handleModaleFunzioniStatus}
        funzioni={funzioni}
        getFunzioni={getFunzioni}
        funzioniScelte={funzioniScelte}
        setFunzioniScelte={setFunzioniScelte}
        onChangeFiltriFunzione={onChangeFiltriFunzione}
        tagsFunzione={tagsFunzione}
        funzioniRimosse={funzioniRimosse}
        setFunzioniRimosse={setFunzioniRimosse}
        funzioniAggiunte={funzioniAggiunte}
        setFunzioniAggiunte={setFunzioniAggiunte}
        handleTableChange={handleTableChange}
        pagination={pagination}
        seyPagination={setPagination}
      />
      <ModaleNuovoTagRuolo
        show={showModalNewTagRuolo}
        handleClose={handleModaleNewTagRuolo}
      />
    </>
  );
}

export default FormModificaRuolo;
