import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatoDataEOra } from '../../../utils/formatData';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';

const fields = (handleStateModaleConferma, setIdCampagnaScelta) => [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'name',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'status',
    text: 'Stato',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => (e === 'draft' ? 'Bozza' : 'Conclusa'),
  },
  {
    dataField: 'created_at',
    text: 'Data creazione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => (formatoDataEOra(e)),
  },
  {
    dataField: 'updated_at',
    text: 'Data aggiornamento',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => (formatoDataEOra(e)),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '35%' }),
    // text: "Azioni"
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row',
      }}
      >
        <>

          {row.status === 'draft' && controlla('X77') ? (
            <FontAwesomeIcon
              icon={['fas', 'edit']}
              size="lg"
              id={`Modifica-campagna-${row.id}`}
              title={`Modifica la campagna ${row.name}`}
              style={{ color: '#0059b3' }}
              onClick={() => history.push(`/area-riservata/newsletter/campagna/${row.id}/modifica`)}
            />
          ) : null}

          {controlla('X78') && (
          <FontAwesomeIcon
            icon={['fas', 'trash-alt']}
            size="lg"
            title={`Elimina la campagna ${row.name}`}
            id={`Elimina-campagna-${row.id}`}
            style={{ color: '#0059b3' }}
            onClick={() => { handleStateModaleConferma(); setIdCampagnaScelta(row.id); }}
          />
          )}
        </>
      </div>

    ),
  },
];

export default fields;
