import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Card, Button, Col, Row,
} from 'react-bootstrap';
import themeColors from '../../../utils/contants/theme-colors';
import { client } from '../../../utils/matrix';
import parseNome from '../../../utils/parseNomeMatrix';
import { getItem, removeItem, setItem } from '../../../utils/storage';
import { controlla } from '../../../utils/verificaPermessi';
import ModaleConferma from '../../modali/ModaleConferma';
import NewPeopleModal from '../NewPeopleModal';
import styles from './index.module.scss';

const ManageRoom = ({
  onDeleteRoom, room, exitFromRoom, kickUser,
}) => {
  const [showNewPeopleModal, setShowNewPeopleModal] = useState(false);
  // Modale di conferma per l'uscita dalla stanza
  const [openCloseModaleConfermaUscitaStanza,
    setOpenCloseModaleConfermaUscitaStanza] = useState(false);
  function handleStateModaleConfermaUscitaStanza() {
    setOpenCloseModaleConfermaUscitaStanza(!openCloseModaleConfermaUscitaStanza);
  }
  // Modale di conferma per l'eliminazione di un utente dalla stanza
  const [openCloseModaleConfermaKickUser,
    setOpenCloseModaleConfermaKickUser] = useState(false);
  function handleStateModaleConfermaKickUser(userIdUser) {
    setOpenCloseModaleConfermaKickUser(!openCloseModaleConfermaKickUser);
    if (userIdUser)setItem('utenteDaEliminare', userIdUser);
    else removeItem('utenteDaEliminare');
  }
  // Modale di conferma per l'eliminazione della stanza
  const [openCloseModaleConfermaDeleteStanza,
    setOpenCloseModaleConfermaDeleteStanza] = useState(false);
  function handleStateModaleConfermaDeleteStanza() {
    setOpenCloseModaleConfermaDeleteStanza(!openCloseModaleConfermaDeleteStanza);
  }

  function onShowPointNewPeopleModal() {
    setShowNewPeopleModal(true);
  }

  function onHideNewPeopleModal() {
    setShowNewPeopleModal(false);
  }

  return (
    <>
      { showNewPeopleModal ? (
        <NewPeopleModal
          show={showNewPeopleModal}
          handleClose={onHideNewPeopleModal}
          room={room}
          people={room.currentState.getMembers().filter((el) => el.membership === 'join')}
        />
      ) : null}
      <Card className={`p-3 my-3 ${styles.manageRoomCard}`}>
        <p>
          Nella stanza
          {' '}
          {room.getJoinedMemberCount() === 1 ? 'è presente' : 'sono presenti'}
          {' '}
          <b>{room.getJoinedMemberCount()}</b>
          {' '}
          {room.getJoinedMemberCount() === 1 ? 'persona' : 'persone'}
        </p>
        {room.getMember(client.getUserId()).powerLevel === 100
          ? (
            <Row className="justify-content-end">
              <Col className="col-auto my-2">
                {controlla('X22') && (
                <Button
                  variant="primary"
                  onClick={onShowPointNewPeopleModal}
                >
                  <FontAwesomeIcon aria-hidden="true" icon="plus" />
                  {' '}
                  Aggiungi persone
                </Button>
                )}
              </Col>
              {/* <Col className="col-auto my-2">
                <Button
                  variant="primary"
                  onClick={onShowExternalNewPeopleModal}
                >
                  <FontAwesomeIcon aria-hidden="true" icon="plus" />
                  {' '}
                  Aggiungi persona esterna
                </Button>
              </Col> */}
              <Col className="col-auto my-2">
                {controlla('X23') && (
                <Button
                  variant="danger"
                  onClick={handleStateModaleConfermaDeleteStanza}
                >
                  <FontAwesomeIcon aria-hidden="true" icon={['far', 'trash-alt']} />
                  {' '}
                  Elimina stanza
                </Button>
                )}
              </Col>
            </Row>
          )
          : null }
        <Row className="mt-2">
          <h3>Lista persone:</h3>
          <PeopleList
            people={room.currentState.getMembers().filter((el) => el.membership === 'join')}
            handleStateModaleConfermaUscitaStanza={handleStateModaleConfermaUscitaStanza}
            handleStateModaleConfermaKickUser={handleStateModaleConfermaKickUser}
            room={room}
          />
        </Row>
      </Card>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione ti farà uscire dalla stanza.'}
        show={openCloseModaleConfermaUscitaStanza}
        handleClose={handleStateModaleConfermaUscitaStanza}
        handleOk={exitFromRoom}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione farà uscire l\'utente selezionato dalla stanza.'}
        show={openCloseModaleConfermaKickUser}
        handleClose={() => handleStateModaleConfermaKickUser()}
        handleOk={() => { kickUser(getItem('utenteDaEliminare')); handleStateModaleConfermaKickUser(); }}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione eliminerà la stanza.'}
        show={openCloseModaleConfermaDeleteStanza}
        handleClose={handleStateModaleConfermaDeleteStanza}
        handleOk={onDeleteRoom}
      />
    </>
  );
};

function PeopleList({
  people, handleStateModaleConfermaUscitaStanza, handleStateModaleConfermaKickUser, room,
}) {
  return (
    <Card className={`${styles.peopleListCard} border-0`}>
      <ul className={`${styles.peopleList}`}>
        {
      people.map((personElement) => (
        <li className="d-flex align-items-center mb-3 p-3" key={personElement.userId}>
          <span className={`text-uppercase text-center mx-2 ${styles.personBadge} p-2`}>{parseNome(personElement.name)}</span>
          <p className="mb-0 mx-2">{personElement.name}</p>
          {client.getUserId() === personElement.userId
              && personElement.membership === 'join'
              && controlla('X24')
            ? (
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => handleStateModaleConfermaUscitaStanza()}
                title={`Esci dalla stanza ${room.name}`}
                aria-label={`Esci dalla stanza ${room.name}`}
              >

                <FontAwesomeIcon
                  aria-hidden="true"
                  icon={['fas', 'times-circle']}
                  size="1x"
                  id="esci-da-stanza-icon"
                  style={{ color: themeColors.danger }}
                />

              </button>
            )
            : personElement.membership === 'join'
            && room.getMember(client.getUserId()).powerLevel === 100
            && controlla('X25')
              ? (
                <button
                  type="button"
                  className="bg-transparent border-0"
                  onClick={() => handleStateModaleConfermaKickUser(personElement.userId)}
                  title={`Elimina l'utente ${personElement.name}`}
                  aria-label={`Elimina l'utente ${personElement.name}`}
                >

                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={['fas', 'sign-out-alt']}
                    size="1x"
                    id={`elimina-utente-icon-${personElement.userId}`}
                    style={{ color: themeColors.danger }}
                  />

                </button>
              )
              : null }
          <div className="separator border-1 border-bottom" />
        </li>
      ))
      }
      </ul>
    </Card>
  );
}

export default ManageRoom;
