/* eslint-disable no-restricted-syntax */

export default function fromHtmlToArray(body) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(body, 'text/html');
  const divTags = parsedHtml.getElementsByTagName('div');
  const vocab = [];
  for (const div of divTags) {
    const titolo = div.getElementsByTagName('h2')[0].textContent.trim();
    const imageSrc = div.querySelector('img')?.src;
    const nomeImmagine = div.querySelector('img')?.id;
    const allText = div.textContent;
    const testo = allText.replace(titolo, '').trim();
    vocab.push({
      titolo, testo, imageSrc, nomeImmagine,
    });
  }
  return vocab;
}
