/* eslint-disable prefer-destructuring */
import React, { useContext, useEffect, useState } from 'react';
import authProviderService from '../services/authProviderService';
import api from '../utils/api';
import { Context } from '../utils/Context';
import history from '../utils/history';
import { globalVariables } from '../utils/refreshToken';
import { getItem, setItem } from '../utils/storage';
import useQuery from '../utils/useQuery';

const AgidLoginOspite = () => {
  const query = useQuery();
  const [value, setValue] = useContext(Context);
  const [error, setError] = useState('');
  const login = async () => {
    try {
      if (!query.get('code') && !query.get('error')) {
        history.push('/');
      } else if (!query.get('error') && query.get('code')) {
        authProviderService.agidLoginOspite(query.get('code'))
          .then(async (response) => {
            setItem('user', JSON.stringify(response.utente));
            api.defaults.headers.common['X-CSRF-Token'] = response.csrfToken;
            globalVariables.refreshToken = response.refresh_token;
            history.push(getItem('redirectUrl'));
          })
          .catch((err) => {
            setValue(() => ({
              ...value, open: true, testoErrore: 'Ops... qualcosa è andato storto', statusCode: err?.status,
            }));
            history.push('/');
          });
      } else {
        setError(query.get('error_description'));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
  useEffect(() => {
    login();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="inline-block align-middle">
          {error
            ? (
              <>
                <h1>ERRORE</h1>
                <h2 className="font-weight-normal lead" id="desc">{error.replaceAll('%20', ' ').replaceAll('%27', '\'')}</h2>
              </>
            )
            : <h1 className="font-weight-normal lead" id="desc">Caricamento...</h1> }
        </div>
        {' '}
      </div>
    </>
  );
};

export default AgidLoginOspite;
