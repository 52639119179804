/* eslint-disable jsx-a11y/label-has-associated-control */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import tiny from '../../utils/TinyMCEConfiguration';

const Sezioni = ({
  aggiungiSezione, sezioni, eliminaSezione,
  onChangeTitoloSezione, onChangeParagrafoSezione, aggiungiCampoInSezione,
  onChangeCampoSezione, listaCampiTabella,
}) => (
  <Card className="mb-3" body>
    <p className="h6">Sezioni</p>
    <Button className="mb-1" onClick={aggiungiSezione}>
      <FontAwesomeIcon icon="plus" aria-hidden="true" />
      Aggiungi sezione
    </Button>
    {sezioni.map((el, index) => (
      <div className="border rounded my-1 p-1" key={`sezione-${el.id}`}>
        <p className="h6 mb-3">
          Sezione
          {' '}
          {index + 1}
          <Button
            aria-label="Elimina sezione"
            variant="danger"
            className="mb-1 float-end"
            onClick={() => eliminaSezione(el.id)}
          >
            <FontAwesomeIcon icon="trash-alt" aria-hidden="true" />
          </Button>
        </p>
        <Form.Group controlId={`titolo-${index}`}>
          <Form.Label>Titolo</Form.Label>
          <Form.Control
            placeholder="Inserisci il titolo della sezione"
            onChange={(e) => onChangeTitoloSezione(e, el.id)}
            value={el.titolo}
          />
        </Form.Group>
        <label htmlFor="content-html">Paragrafo</label>
        <Editor
          id={`content-html-${el.id}`}
          init={{
            plugins: tiny.plugins,
            toolbar: tiny.toolbar,
          }}
          apiKey={tiny.apiKey}
          onEditorChange={(text) => onChangeParagrafoSezione(text, el.id)}
          value={el.paragrafo}
        />
        <Button className="mb-1" onClick={() => aggiungiCampoInSezione(el.id)}>
          <FontAwesomeIcon icon="plus" aria-hidden="true" />
          Aggiungi campo
        </Button>
        {el.campi.map((elem) => (
          <Form.Group controlId={`lista-campi-${elem.id}`} key={`campo-${elem.id}`}>
            <Form.Label>Campo</Form.Label>
            <Form.Select
              value={elem.campo}
              onChange={(e) => onChangeCampoSezione(e, el.id)}
              id={elem.id}
            >
              <option> </option>
              {listaCampiTabella.map((campo) => (
                <option
                  key={campo.nume_posizione}
                  value={campo.desc_colonna}
                >
                  {campo.desc_colonna}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
        ))}
      </div>
    ))}
  </Card>
);

export default Sezioni;
