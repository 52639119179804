import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const questionariUrl = process.env.REACT_APP_QUESTIONARI_URL;

async function getQuestionari(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${questionariUrl}/interni?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createQuestionario(idTemplate) {
  return api.post(`${questionariUrl}/interni/crea/${idTemplate}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getTemplateDisponibili(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${questionariUrl}/interni/template-disponibili?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getQuestionarioById(idQuestionario) {
  return api.get(`${questionariUrl}/interni/${idQuestionario}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editQuestionario(idQuestionario, body) {
  return api.put(`${questionariUrl}/interni/${idQuestionario}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editSezione(idQuestionario, titolo, body) {
  return api.put(`${questionariUrl}/interni/${idQuestionario}/sezione/${titolo}/compila-domande`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function closeQuestionario(idQuestionario) {
  return api.put(`${questionariUrl}/interni/${idQuestionario}/completa`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const questionariService = {
  getQuestionari,
  createQuestionario,
  getTemplateDisponibili,
  getQuestionarioById,
  editQuestionario,
  editSezione,
  closeQuestionario,
};

export default questionariService;
