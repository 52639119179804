import React, { useContext, useEffect, useState } from 'react';
import appService from '../../../services/appService';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import { Context } from '../../../utils/Context';
import { controlla } from '../../../utils/verificaPermessi';
import Table from '../../../components/Shared/Table';
import appFields from './fieldsApp';
import FiltriRicercaApp from '../../../components/AppEsterne/FiltriRicerca';

function GestioneAppEsterne() {
  const [context, setContext] = useContext(Context);
  const [app, setApp] = useState([]);
  const [idApp, setidApp] = useState();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    nome: '',
    descrizione: '',
  });

  function handleStateModaleConferma(id) {
    setOpenModaleConferma(!openModaleConferma);
    setidApp(id);
  }

  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  async function getApp(offset, limit, page) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await appService.getListaApp(filtri, offset, limit);
      setPagination({
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      });
      setApp(response?.listaApp || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function eliminaApp() {
    await appService.deleteApp(idApp)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getApp(0, 5);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  async function modificaApp(id) {
    history.push(`/area-riservata/gestione-app-esterne/modifica/${id}`);
  }

  function visualizzaApp(id) {
    history.push(`/area-riservata/gestione-app-esterne/visualizza/${id}`);
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getApp(currentIndex, sizePerPage, page);
  };

  useEffect(() => {
    getApp(0, 5);
  }, []);

  return (
    <div>
      <TitlePage
        title="Gestione Applicazioni esterne"
        subtitle="E' possibile ricercare, gestire e creare Applicazioni esterne"
        showAdd={controlla('X81') === true}
        showRefresh
        functionAdd={() => history.push('/area-riservata/gestione-app-esterne/creazione')}
        functionRefresh={() => {
          getApp(0, 5);
        }}
      />
      <FiltriRicercaApp
        filtri={filtri}
        getApp={getApp}
        onChange={onChangeFiltri}
      />
      <Table
        title="Applicazioni esterne:"
        data={app}
        keyField="id"
        fields={appFields(modificaApp, visualizzaApp, handleStateModaleConferma)}
        pagination={pagination}
        onTableChange={handleTableChange}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione eliminerà l'applicazione selezionata"
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => eliminaApp()}
      />
    </div>
  );
}

export default GestioneAppEsterne;
