import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import NewsletterModal from '../../../../components/Newsletter/NewsletterModal';
import Table from '../../../../components/Shared/Table';
import newsletterService from '../../../../services/newsletterService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';
import utentiFields from './fieldsUtenti';

function HandleUtenti() {
  const { id } = useParams();
  const [idDeleteUtente, setIdDeleteUtente] = useState();
  const [context, setContext] = useContext(Context);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [utentiInCoda, setUtentiInCoda] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [listInfo, setListInfo] = useState({
    listmonkUserId: [],
    codi_k_id_lista: '',
  });

  function handleStateModaleConferma(idUtente) {
    setOpenModaleConferma(!openModaleConferma);
    setIdDeleteUtente(idUtente);
  }

  async function getListaUtenti() {
    const response = await newsletterService.getListaUtentiByIdLista(id);
    setListInfo({
      listmonkUserId: response.data.results,
      codi_k_id_lista: id,
    });
    const resp = await newsletterService.verificaUtentiCoda(id);
    setUtentiInCoda(resp.utentiInCoda);
  }

  async function deleteUtente() {
    await newsletterService.deleteUtente(idDeleteUtente, id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getListaUtenti();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  function handleTableChange() {
    setPagination({
      page: 2,
      sizePerPage: 5,
      totalSize: 5,
    });
  }

  async function handleCloseModaleUtenti() {
    setShowModaleUtenti(!showModaleUtenti);
  }

  // async function getListaDistribuzioneById() {
  //   setListInfo(await newsletterService.getListaDistribuzioneById(id));
  // }

  useEffect(() => {
    // getListaDistribuzioneById();
    getListaUtenti();
  }, []);

  return (
    <div>
      <Container fluid className="mt-3">
        <TitlePage
          title="Gestione utenti newsletter"
          subtitle="Aggiungi o elimina utenti dalla newsletter"
          showIndietro
          functionIndietro={() => history.push('/area-riservata/newsletter')}
        />
        <Row>
          <Col />
          {controlla('X79') && !utentiInCoda && (
          <Col className="col-lg-auto mt-1 text-end">
            <Button
              variant="primary"
              onClick={() => {
                handleCloseModaleUtenti();
              }}
            >
              {' '}
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
              {' '}
              AGGIUNGI UTENTI
              {' '}
            </Button>
          </Col>
          )}
        </Row>
        {utentiInCoda
          ? (
            <Alert variant="warning">
              Attualmente, non è possibile aggiungere utenti perché il sistema è in fase di
              aggiornamento. Si prega di tornare più tardi.
            </Alert>
          ) : null }
        <Table
          title="Utenti iscritti:"
          data={listInfo.listmonkUserId}
          keyField="id"
          fields={utentiFields(handleStateModaleConferma, id)}
          pagination={pagination}
          onTableChange={handleTableChange}
        />
      </Container>
      {showModaleUtenti && (
      <NewsletterModal
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        listInfo={listInfo}
      />
      )}
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione eliminerà dalla lista l'utente selezionato"
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={deleteUtente}
      />
    </div>
  );
}

export default HandleUtenti;
