/* eslint-disable consistent-return */
const throttle = (callback, delay) => {
  let timeoutID;

  // If setTimeout is already scheduled, no need to do anything
  if (timeoutID !== undefined) {
    return;
  }

  // Schedule a setTimeout after delay seconds
  return (...args) => {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(callback, delay, ...args);
  };
};

export default throttle;
