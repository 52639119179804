/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Card, Col, Row, Button,
} from 'react-bootstrap';

function CardGroup({
  items, title, showDelete, functionDelete,
}) {
  return (

    <div className="mt-3">
      <h2>{title}</h2>
      <Row xs={1} md={2} lg={4} className="g-4 mt-2 mb-2">
        {items?.map((el) => (
          <Col key={el.key}>
            <Card key={el.key}>
              <Card.Header>
                {' '}
                <h5>{el.titolo}</h5>
                {' '}
              </Card.Header>
              <Card.Body>
                {/* <Card.Text>
                  <RenderHTML html={el.descrizione} />
                </Card.Text> */}
                <Row>

                  {showDelete ? (
                    <Col>
                      <Button className="text-center" variant="danger" onClick={() => functionDelete(el.key)}>
                        <FontAwesomeIcon aria-hidden="true" icon={['far', 'trash-alt']} />
                        {' '}
                        ELIMINA
                      </Button>

                    </Col>
                  )
                    : null}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

  );
}

export default CardGroup;
