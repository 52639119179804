import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import themeColors from '../../../../../utils/contants/theme-colors';
import { formatoData } from '../../../../../utils/formatData';

const fieldsContent = (modificaElemento, eliminaElemento, move, arrayContenuti,
  pagina, setPagina, selezionaElemento) => [
  {
    dataField: 'nome',
    text: 'Nome',
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'dataCreazione',
    text: 'Data',
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'tipo',
    text: 'Tipo',
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'azioni',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    formatExtraData: arrayContenuti,
    formatter: (e, row, rowIndex, extraData) => (

      <div>
        <>

          {rowIndex > 0 ? (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'arrow-up']}
              size="lg"
              title={`Modifica l'elemento ${row.nome}`}
              id={`modifica-${row.id}`}
              style={{ color: themeColors.primary }}
              onClick={() => { move(rowIndex, rowIndex - 1); }}
            />
          ) : null}

          {rowIndex < extraData.length - 1 ? (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'arrow-down']}
              size="lg"
              title={`Modifica l'elemento ${row.nome}`}
              id={`modifica-${row.id}`}
              style={{ color: themeColors.primary }}
              onClick={() => { move(rowIndex, rowIndex + 1); }}
            />
          ) : null}
          {row.tipo === 'paragrafo' ? (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'edit']}
              size="lg"
              title={`Modifica l'elemento ${row.nome}`}
              id={`modifica-${row.nome}`}
              style={{ color: themeColors.primary }}
              onClick={() => { modificaElemento(row, rowIndex); }}
            />
          ) : null}

          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash-alt']}
            size="lg"
            title={`Elimina l'elemento ${row.nome}`}
            id={`elimina-${row.nome}`}
            style={{ color: themeColors.primary }}
            onClick={() => { eliminaElemento(row); }}
          />
        </>
      </div>

    ),
  },
  selezionaElemento ? {
    dataField: 'selezione',
    isDummyField: true,
    text: 'Seleziona',
    headerStyle: () => ({ width: '15%' }),
    formatExtraData: arrayContenuti,
    formatter: (e, row, rowIndex) => (

      <div>
        <>
          <Form.Check
            type="checkbox"
            id="selected"
            onChange={() => {
              selezionaElemento(rowIndex);
            }}
            value={row.selected}
            checked={row.selected}
          />
        </>
      </div>

    ),
  } : {},
];

export default fieldsContent;
