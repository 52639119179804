import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './style.css';
import RenderHTML from '../Shared/RenderHTML';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

export default function ModaleAnteprima({ contenuto, handleClose, show }) {
  return (
    <Modal show={show} onHide={handleClose} centered size="xl" role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Anteprima contenuto dinamico</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <main id="main" className="opere">
          <article>
            <div className="container-fluid container-single-title">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <header>
                      <h1 id="titolo-pagina">{ contenuto.titolo || 'Titolo' }</h1>
                      <p id="sottotitolo-pagina">
                        {contenuto.sottotitolo || 'Sottotitolo'}
                      </p>
                    </header>
                    <hr align="left" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>

            <div className="container-lg">
              <div className="container-fluid row">
                <img
                  src={contenuto.fk_logo_mongo_db ? `${sitiWebUrl}/galleria/${contenuto.fk_logo_mongo_db}` : 'https://via.placeholder.com/1300x700'}
                  alt="Foto"
                  className="rounded mx-auto img-fluid"
                />
              </div>
            </div>

            <div
              id="scheda-opera"
              className="container-fluid background-container-scheda-opera"
            >
              <div className="container-lg container-scheda-opera">
                <div className="row">
                  <div className="col-lg-5 scheda-opera border">
                    {contenuto.sezioni.map((el) => (
                      // eslint-disable-next-line prefer-template
                      <div key={'sezione-' + el.index}>
                        <h2>{ el.titolo }</h2>
                        {el.tabella.map((riga) => (
                          <dl
                            key={`riga-${el.index}-${riga.index}`}
                          >
                            <dt>{ riga.proprieta }</dt>
                            <dd>
                              <RenderHTML html={riga.descrizione} />
                            </dd>
                          </dl>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="col-lg-7 descrizione-opera border">
                    <h2>Descrizione</h2>
                    <RenderHTML html={contenuto.descrizione} />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </main>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
