import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const faqUrl = process.env.REACT_APP_NEWS_FAQ_URL;

async function getListaFaq(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${faqUrl}/faq?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getFaqById(id) {
  return api.get(`${faqUrl}/faq/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createFaq(faq) {
  return api.post(`${faqUrl}/faq`, faq)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteFaq(faqId) {
  return api.delete(`${faqUrl}/faq/${faqId}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editFaq(id, body) {
  return api.patch(`${faqUrl}/faq/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const faqService = {
  getListaFaq,
  getFaqById,
  createFaq,
  deleteFaq,
  editFaq,
};

export default faqService;
