import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Row, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Title from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import FormRichiestaIdentita from '../../../../components/RichiesteIdentita/Form';
import richiestIdentitaService from '../../../../services/richiestaIdentitaService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

function DettaglioRichiestaIdentitaPerUtente() {
  const { idRichiesta } = useParams();
  const [context, setContext] = useContext(Context);
  const [richiestaIdentita, setRichiestaIdentita] = useState({});
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function deleteRichiesta() {
    richiestIdentitaService.deleteRichiesta(idRichiesta)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/richiesta-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function getRichiestaIdentita() {
    setRichiestaIdentita(await richiestIdentitaService.getRichiestaIdentitaById(idRichiesta));
  }

  useEffect(() => {
    getRichiestaIdentita();
  }, []);

  return (
    <>
      <Row id="form-richiesta-identita">
        <Row>
          <Title
            title="Dettaglio richiesta identità"
            subtitle="È possibile visualizzare i dettagli della richiesta e l'eventuale risposta"
            showIndietro
            functionIndietro={() => history.push('/area-riservata/richiesta-identita')}
          />
        </Row>
        <FormRichiestaIdentita
          richiestaIdentita={richiestaIdentita}
          disabled
        />
        {new URLSearchParams(window.location.search).get('elimina')
          ? (
            <>
              <div className="d-flex justify-content-end">
                <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
                  <FontAwesomeIcon aria-hidden="true" icon="trash" />
                  {' '}
                  Elimina
                </Button>
              </div>
              <ModaleConferma
                labelBottone="Conferma"
                coloreAzione="primary"
                title="Sei sicuro di procedere?"
                body={'L\'operazione eliminerà la richiesta selezionata'}
                show={openCloseModaleConferma}
                handleClose={handleStateModaleConferma}
                handleOk={deleteRichiesta}
              />
            </>
          )
          : null }
      </Row>
    </>
  );
}

export default DettaglioRichiestaIdentitaPerUtente;
