import React, { useEffect } from 'react';
import history from '../utils/history';
import { clear } from '../utils/storage';

const AgidLogout = () => {
  useEffect(() => {
    clear();
    history.push('/');
  }, []);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="inline-block align-middle">
          <h1 className="font-weight-normal lead" id="desc">Logout in corso...</h1>
        </div>
        {' '}
      </div>
    </>
  );
};

export default AgidLogout;
