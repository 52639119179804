import React, { useContext, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import RenderHTML from '../../../../../components/Shared/RenderHTML';
import entitaTrasparenzaService from '../../../../../services/entitaTrasparenzaService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';
// eslint-disable-next-line import/no-unresolved
const XLSX = require('xlsx');

const AggiornamentoDatiEntita = () => {
  const { idEntita } = useParams();
  const [context, setContext] = useContext(Context);
  const [html, setHtml] = useState();
  const [sovrascrittura, setSovrascrittura] = useState(false);
  const [file, setFile] = useState({
    nome: '',
    fileCaricato: null,
  });

  function onChange(e) {
    const { checked } = e.target;
    setSovrascrittura(checked);
  }

  function onChangeFile(event) {
    const { files } = event.target;
    const reader = new FileReader();

    setFile({
      nome: files[0].name,
      fileCaricato: files[0],
    });

    reader.onload = (e) => {
      const fileExcel = e.target.result;
      const workbook = XLSX.read(fileExcel, {
        type: 'binary',
        sheetRows: 6,
      });

      workbook.SheetNames.forEach((sheetName) => {
        // Here is your object

        setHtml(XLSX.utils.sheet_to_html(workbook.Sheets[sheetName], { id: 'search_form' }));
      });
    };

    reader.onerror = () => {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore nella lettura del file', statusCode: 500,
      }));
    };

    reader.readAsBinaryString(event.target.files[0]);
  }

  async function aggiornaExcel() {
    try {
      const res = await entitaTrasparenzaService.gestioneFileExcel(idEntita, file, sovrascrittura);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      history.goBack();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
      }));
    }
  }

  return (
    <>
      <TitlePage
        title="Aggiornamento dati entità"
        subtitle="È possibile aggiornare o sovrascrivere i dati dell'entità."
        showIndietro
        functionIndietro={() => history.goBack()}
        showSave
        functionSave={aggiornaExcel}
      />
      <Form className="mb-3">
        <Row>
          <Form.Group as={Col} controlId="file" className="mb-3 md-6">
            <Form.Label>Allega file</Form.Label>
            <Form.Control type="file" size="sm" onChange={onChangeFile} />
          </Form.Group>
        </Row>
      </Form>
      <p className="h6">Preview tabella</p>
      <RenderHTML html={html} />
      <Form.Check
        type="switch"
        id="sovrascrittura"
        label="Sovrascrivi dati esistenti"
        onChange={onChange}
        value={sovrascrittura}
      />
    </>
  );
};

export default AggiornamentoDatiEntita;
