import React from 'react';
import {
  Card, Col, Form, Row,
} from 'react-bootstrap';
import Regex from '../../utils/regex';

const baseUrl = process.env.REACT_APP_BASE_URL;

const regexAlias = new RegExp(Regex.relativeUrl);

const FormNomeAlias = ({ versione, onChange, disabled }) => (
  <Card className="mb-3">
    <Card.Body>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="desc_nome">
          <Form.Label>Nome*</Form.Label>
          <Form.Control
            placeholder="Inserisci il nome"
            value={versione.desc_nome}
            onChange={onChange}
            disabled={disabled}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="desc_alias">
          <Form.Label>
            Alias*
            <span className="sr-only">Campo obbligatorio</span>
          </Form.Label>
          <Form.Control
            placeholder="Inserisci l'alias"
            value={versione.desc_alias}
            onChange={onChange}
            disabled={disabled}
            isInvalid={!regexAlias.test(versione.desc_alias) && versione.desc_alias !== ''}
          />
          <Form.Text className="text-muted">
            L&apos;alias definisce l&apos;indirizzo di pubblicazione del sito:&nbsp;
            {`${baseUrl}/${versione.desc_alias}`}
          </Form.Text>
        </Form.Group>
      </Row>
    </Card.Body>
  </Card>
);

export default FormNomeAlias;
