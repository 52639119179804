import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import LoginInfo from '../../../components/Account/LoginInfo/index';
import ModaleNuovoUntrusted from '../../../components/Account/ModaleNuovoUntrusted';
import UserContacts from '../../../components/Account/UserContacts/index';
import UserInfo from '../../../components/Account/UserInfo';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import Table from '../../../components/Shared/Table';
import tipiConnettoriService from '../../../services/tipiConnettoriService';
import utenteService from '../../../services/utentiService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import Regex from '../../../utils/regex';
import { getItem } from '../../../utils/storage';
import fields from './fields';

function UserInformation() {
  const [context, setContext] = useContext(Context);
  const [user, setUser] = useState({});
  const [listaTipiConnettore, setListaTipiConnettore] = useState([]);
  const [openCloseModale, setOpenCloseModale] = useState(false);

  function handleStateModale() {
    setOpenCloseModale(!openCloseModale);
  }

  async function getListaTipiConnettore() {
    setListaTipiConnettore(await tipiConnettoriService.getTipiConnettori());
  }

  async function salvaUtente() {
    if (Regex.mail.test(user.desc_email)) {
      const body = {};
      body.trusted = (JSON.parse(getItem('user'))).trusted;
      body.desc_email = user.desc_email;
      body.untrusted = user.utenti_untrusted_associati;
      utenteService.modificaUtentePerUtente(body)
        .then(async (res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setUser(await utenteService.getUtenteByIdUtente());
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: 400,
          }));
        });
    } else {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Mail non in un formato valido', statusCode: 400,
      }));
    }
  }

  function onChange(e) {
    const { id } = e.target;
    const { value } = e.target;
    setUser({
      ...user,
      [id]: value,
    });
  }

  useEffect(async () => {
    setUser(await utenteService.getUtenteByIdUtente());
    getListaTipiConnettore();
  }, []);

  return (
    <div>
      <Title
        title="Informazioni utente"
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <LoginInfo user={user} />
      <hr />
      <UserContacts user={user} onChange={onChange} />
      <hr />
      <UserInfo user={user} />
      {user.sequ_k_utente_trusted ? (
        <>
          <span>
            <Form.Label>Associazione utenti untrusted*</Form.Label>
            {' '}
            <Button onClick={handleStateModale}>Aggiungi</Button>
          </span>
          <Table
            title="Elenco account untrusted associati"
            data={user.utenti_untrusted_associati}
            fields={fields(user, setUser)}
            keyField="sequ_k_utente_untrusted"
          />

          <ModaleNuovoUntrusted
            show={openCloseModale}
            handleClose={handleStateModale}
            setUser={setUser}
            user={user}
            listaTipiConnettore={listaTipiConnettore}
          />
        </>
      )
        : null }
      <div className="d-flex justify-content-end">
        <Button variant="primary" className="text-uppercase" onClick={salvaUtente}>
          <FontAwesomeIcon aria-hidden="true" icon="save" />
          {' '}
          Salva
        </Button>
      </div>
    </div>
  );
}

export default UserInformation;
