import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import newsletterService from '../../../../../services/newsletterService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';

function HandleEditLista() {
  const { id } = useParams();
  const [context, setContext] = useContext(Context);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [listInfo, setListInfo] = useState({
    name: '',
  });
  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }
  function handleChange(e) {
    const { value } = e.target;
    setListInfo({
      name: value,
    });
  }

  async function editListaDistribuzione() {
    await newsletterService.editListaDistribuzione(id, listInfo)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  async function getListaDistribuzioneById() {
    const response = await newsletterService.getListaDistribuzioneById(id);
    setListInfo({
      name: response.data.name,
    });
  }

  useEffect(() => {
    getListaDistribuzioneById();
  }, []);
  return (
    <>
      <TitlePage
        title="Modifica lista di distribuzione"
        subtitle="Stai modificando la lista"
        showIndietro
        showSave
        functionSave={() => { handleStateModaleConferma(); }}
        functionIndietro={() => history.push('/area-riservata/newsletter')}
      />
      <Row>
        <Form.Group as={Col} controlId="nome">
          <Form.Label>Nome</Form.Label>
          <Form.Control placeholder="Inserisci il nome della lista" value={listInfo.name} onChange={handleChange} />
        </Form.Group>
      </Row>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione modificherà la lista: ${listInfo.name}`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => editListaDistribuzione()}
      />
    </>
  );
}

export default HandleEditLista;
