/* eslint-disable no-unused-vars */
import {
  React, useContext, useEffect, useState,
} from 'react';
import Title from '../../../../components/GestisciUtenti/TitoloPagina/index';
import Table from '../../../../components/Shared/Table';
import { fieldsProfonditaStorica, fieldsNoProfonditaStorica } from './fieldsTable';
import history from '../../../../utils/history';
import Filtri from './filtri';
import TagIdentitaService from '../../../../services/tagIdentitaService';
import { Context } from '../../../../utils/Context';
import { controlla } from '../../../../utils/verificaPermessi';

function GestioneTagIdentita() {
  const [context, setContext] = useContext(Context);
  const [nome, setNome] = useState('');
  const [descrizione, setDescrizione] = useState('');
  const [profonditaStorica, setProfonditaStorica] = useState(false);
  const [tagIdentita, setTagIdentita] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  async function searchTagIdentita(offset, limit, page) {
    const response = await TagIdentitaService.getTagIdentita({
      desc_nome_tag_identita: nome,
      desc_tag_identita: descrizione,
      profondita_storica: profonditaStorica,
      offset,
      limit,
    });
    setTagIdentita(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  function onChangeNome(event) {
    setNome(event.target.value);
  }

  function onChangeDescrizione(event) {
    setDescrizione(event.target.value);
  }

  function onChangeProfStorica(event) {
    setProfonditaStorica(event.target.value);
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    searchTagIdentita(currentIndex, sizePerPage, page);
  };

  const actions = {
    delete: async (id) => {
      TagIdentitaService.cancellaTagIdentita(id)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          searchTagIdentita(0, 5, 1);
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    },
  };

  function refresh() {
    searchTagIdentita(0, 5, 1);
  }

  useEffect(() => {
    searchTagIdentita(0, 5, 1);
  }, []);

  return (
    <>
      <Title
        title="Gestione Tag identità"
        subtitle="È possibile ricercare, gestire e creare Tag identità"
        showRefresh
        showAdd={controlla('X14') === true}
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione')}
        functionRefresh={refresh}
        functionAdd={() => history.push('/area-riservata/amministrazione/gestione-tag-identita/aggiungi')}
      />

      <Filtri
        nome={nome}
        onChangeNome={onChangeNome}
        descrizione={descrizione}
        onChangeDescrizione={onChangeDescrizione}
        searchTagIdentita={searchTagIdentita}
        profonditaStorica={profonditaStorica}
        onChangeProfStorica={onChangeProfStorica}
      />
      <Table
        title="Elenco Tag identità"
        data={tagIdentita}
        fields={profonditaStorica === 'true' ? fieldsProfonditaStorica(actions) : fieldsNoProfonditaStorica(actions)}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="sequ_k_tag_identita"
      />
    </>
  );
}

export default GestioneTagIdentita;
