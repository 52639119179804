/* eslint-disable no-underscore-dangle */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import repositoryService from '../../../../services/repositoryService';
import { Context } from '../../../../utils/Context';
import formatBytes from '../../../../utils/formatBytes';
import { formatoDataEOra } from '../../../../utils/formatData';
import history from '../../../../utils/history';

const FileInfo = () => {
  const { idFile } = useParams();
  const [file, setFile] = useState({});
  const [configurazione, setConfigurazione] = useState({});
  const [showModaleConferma, setShowModaleConferma] = useState(false);
  const [context, setContext] = useContext(Context);

  const handleCloseModaleConferma = () => setShowModaleConferma(!showModaleConferma);

  async function modificaFile() {
    await repositoryService.modificaFile(file._id, file)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleCloseModaleConferma();
      });
  }

  async function getFile() {
    try {
      const fileInfo = await repositoryService.getFile(idFile);
      const configurazioneInfo = await repositoryService.getConfigurazione();
      setConfigurazione(configurazioneInfo);
      setFile({
        ...fileInfo,
        metadati: configurazioneInfo.metadati.reduce((o, m) => {
          // eslint-disable-next-line no-param-reassign
          o[m.chiave] = fileInfo.metadati[m.chiave] || '';
          return o;
        }, {}),
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  const onChange = (e) => {
    const { value, id } = e.target;
    setFile({
      ...file,
      [id]: value,
    });
  };

  const onChangeMetadata = (e) => {
    const { value, id } = e.target;
    setFile({
      ...file,
      metadati: { ...file.metadati, [id]: value },
    });
  };

  useEffect(() => {
    getFile();
  }, []);

  return (
    <>
      <TitlePage
        title="Repository - dettaglio file"
        subtitle="È possibile visualizzare e modificare le informazioni del file."
        showIndietro
        functionIndietro={() => {
          history.goBack();
        }}
      />
      <Form>

        <Form.Group controlId="path">
          <Form.Label>Percorso cartella</Form.Label>
          <Form.Control
            value={file.path}
            onChange={onChange}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="nome">
          <Form.Label>Nome documento</Form.Label>
          <Form.Control
            value={file.nome}
            onChange={onChange}
          />
        </Form.Group>
        <Form.Group controlId="nomeFile">
          <Form.Label>Nome file</Form.Label>
          <Form.Control
            value={file.nomeFile}
            onChange={onChange}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="tipoFile">
          <Form.Label>Tipo file</Form.Label>
          <Form.Control
            value={file.tipoFile}
            onChange={onChange}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="dimensioni">
          <Form.Label>Dimensioni file (in MB)</Form.Label>
          <Form.Control
            value={formatBytes(file.dimensioni)}
            onChange={onChange}
            disabled
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Pubblicato da</Form.Label>
          <Form.Control
            value={file.creatore?.identificativo}
            onChange={onChange}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="dataUltimaModifica">
          <Form.Label>Data ultima modifica</Form.Label>
          <Form.Control
            value={formatoDataEOra(file.dataUltimaModifica)}
            onChange={onChange}
            disabled
          />
        </Form.Group>
        {configurazione?.metadati?.map((el) => (
          <Form.Group className="mb-3" controlId={el.chiave} key={el.chiave}>
            <Form.Label>{el.etichetta}</Form.Label>
            <Form.Control value={file?.metadati?.[el.chiave]} placeholder="Inserisci il TAG" onChange={onChangeMetadata} />
          </Form.Group>
        ))}
      </Form>
      <Button variant="primary" className="m-3 float-end" onClick={handleCloseModaleConferma}>
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        {' '}
        SALVA
      </Button>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione modificherà le informazioni del file."
        show={showModaleConferma}
        handleClose={handleCloseModaleConferma}
        handleOk={modificaFile}
      />
    </>
  );
};

export default FileInfo;
