/* eslint-disable no-underscore-dangle */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import formatBytes from '../../../utils/formatBytes';
import { formatoDataEOra } from '../../../utils/formatData';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';
import controllaRepository from '../../../utils/verificaPermessiRepository';

const repositoryUrl = process.env.REACT_APP_REPOSITORY_URL;

const azioniCartelle = (cartella, setModalRinominaShow, setCartella, handleStateModaleConferma,
  setModalSpostaShow, setCartellaOrFile, pathRepo, cartellaAttuale) => [
  {
    label: 'Esplora',
    disabled: !controlla('X31'),
    onClick: () => {
      history.push(`/area-riservata/repository/${encodeURIComponent(cartella.path.substring(1))}`);
    },
  },
  {
    label: 'Rinomina',
    disabled: pathRepo === '/main' ? !controlla('W1') : cartellaAttuale.path === pathRepo && (!controllaRepository('M', cartellaAttuale) || !controlla('X32')),
    onClick: () => {
      setCartella(cartella); setModalRinominaShow(true);
    },
  },
  {
    label: 'Sposta',
    disabled: pathRepo === '/main' ? !controlla('W1') : cartellaAttuale.path === pathRepo && (!controllaRepository('M', cartellaAttuale) || !controlla('X33')),
    onClick: () => {
      setCartellaOrFile('cartella');
      setCartella(cartella);
      setModalSpostaShow(true);
    },
  },
  {
    label: 'Elimina',
    disabled: pathRepo === '/main' ? !controlla('W1') : cartellaAttuale.path === pathRepo && (!controllaRepository('C', cartellaAttuale) || !controlla('X34')),
    onClick: () => {
      setCartella(cartella); handleStateModaleConferma();
    },
  },
  {
    label: 'Autorizzazioni',
    disabled: !controlla('W1'),
    onClick: () => {
      history.push(`/area-riservata/repository/cartella/autorizzazioni/${cartella._id}`);
    },
  },
];

const azioniFile = (file, handleStateModaleEliminaFile, setFile, setModalSpostaShow,
  setCartellaOrFile, pathRepo, cartellaAttuale) => [
  { label: 'Metadati', disabled: !controlla('X36'), onClick: () => { history.push(`/area-riservata/repository/file/${file._id}`); } },
  {
    label: 'Scarica file',
    disabled: !controlla('X37'),
    onClick: () => {
      const a = document.createElement('A');
      a.href = `${repositoryUrl}/file/${file._id}`;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  {
    label: 'Aggiorna file',
    disabled: pathRepo === '/main' ? !controlla('W1') : cartellaAttuale.path === pathRepo && (!controllaRepository('M', file) || !controlla('X38')),
    onClick: () => {
      history.push(`/area-riservata/repository/upload/${encodeURIComponent(file.path)}?id=${file._id}`);
    },
  },
  {
    label: 'Rinomina',
    disabled: pathRepo === '/main' ? !controlla('W1') : cartellaAttuale.path === pathRepo && (!controllaRepository('M', file) || !controlla('X39')),
    onClick: () => {
      history.push(`/area-riservata/repository/file/${file._id}`);
    },
  },
  {
    label: 'Sposta',
    disabled: pathRepo === '/main' ? !controlla('W1') : cartellaAttuale.path === pathRepo && (!controllaRepository('M', file) || !controlla('X40')),
    onClick: () => {
      setCartellaOrFile('file');
      setFile(file);
      setModalSpostaShow(true);
    },
  },
  {
    label: 'Elimina',
    disabled: pathRepo === '/main' ? !controlla('W1') : cartellaAttuale.path === pathRepo && (!controllaRepository('C', file) || !controlla('X41')),
    onClick: () => {
      setFile(file); handleStateModaleEliminaFile();
    },
  },
  {
    label: 'Versioni',
    disabled: pathRepo === '/main' ? !controlla('W1') : !controlla('X42'),
    onClick: () => {
      history.push(`/area-riservata/repository/file/versioni/${file._id}`);
    },
  },
  {
    label: 'Autorizzazioni',
    disabled: !controlla('W1'),
    onClick: () => {
      history.push(`/area-riservata/repository/file/autorizzazioni/${file._id}`);
    },
  },
];

const fields = (
  setModalRinominaShow, setCartella, handleStateModaleConferma,
  handleStateModaleEliminaFile, setFile, setModalSpostaShow, setCartellaOrFile,
  pathRepo, cartellaAttuale,
) => [
  {
    dataField: 'nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
    formatter: (cell, row) => (
      <>
        {/* La verifica per vedere se si tratta di un file
        o meno è fatta attraverso il campo nomeFile */}
        {row.nomeFile
          ? (
            <>
              <FontAwesomeIcon aria-hidden="true" icon={['far', 'file']} />
              {' '}
              <Link to={`/area-riservata/repository/file/${row._id}`}>
                {cell}
              </Link>
            </>
          )
          : (
            <>
              <FontAwesomeIcon aria-hidden="true" icon="folder" />
              {' '}
              <Link to={`/area-riservata/repository/${encodeURIComponent(row.path.substring(1))}`}>
                {cell}
              </Link>
            </>
          )}
      </>
    ),
  },
  {
    dataField: 'dataUltima',
    text: 'Ultima modifica',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (cell, row) => (
      <>
        {formatoDataEOra(row.dataCreazione || row.dataUltimaModifica)}
      </>
    ),
  },
  {
    dataField: 'dimensioni',
    text: 'Dimensioni',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (cell) => (
      <>
        {formatBytes(cell)}
      </>
    ),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
      }}
      >
        <Dropdown>
          <Dropdown.Toggle id={`azioni${row._id}`} size="sm">
            Azioni
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* La verifica per vedere se si tratta di un file
        o meno è fatta attraverso il campo nomeFile */}
            {row.nomeFile
              ? azioniFile(row, handleStateModaleEliminaFile, setFile,
                setModalSpostaShow, setCartellaOrFile, pathRepo, cartellaAttuale).map((el) => (
                  <Dropdown.Item
                    key={el.label}
                    onClick={el.onClick}
                    disabled={el.disabled}
                  >
                    {el.label}
                  </Dropdown.Item>
              ))
              : azioniCartelle(row, setModalRinominaShow, setCartella, handleStateModaleConferma,
                setModalSpostaShow, setCartellaOrFile, pathRepo, cartellaAttuale)
                .map((el) => (
                  <Dropdown.Item
                    key={el.label}
                    onClick={el.onClick}
                    disabled={el.disabled}
                  >
                    {el.label}
                  </Dropdown.Item>
                ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

    ),
  },
];

export default fields;
