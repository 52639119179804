import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const newsletterUrl = process.env.REACT_APP_NEWS_FAQ_URL;

async function getListeDistribuzione(filtri) {
  const filters = { ...filtri };
  const params = createQueryString(filters);
  return api.get(`${newsletterUrl}/newsletter/liste?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const listeAndreaService = {
  getListeDistribuzione,
};

export default listeAndreaService;
