/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import React from 'react';
import {
  Button, Modal,
} from 'react-bootstrap';

function ModalNotes({
  showModalNote, setShowModalNote, versioneScelta,
}) {
  return (

    <Modal show={showModalNote} onHide={() => setShowModalNote(false)}>
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>
          Note -
          {' '}
          {versioneScelta.desc_nome}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Note creatore:
          {' '}
          {versioneScelta.desc_note_creatore || '-'}
        </p>
        <p>
          Motivo non approvazione:
          {' '}
          {versioneScelta.desc_motivo_non_approvazione || '-'}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowModalNote(false)}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>

  );
}

export default ModalNotes;
