/* eslint-disable react/destructuring-assignment */

import { useState } from 'react';
import {
  Modal, Button, Form, Col, Row,
} from 'react-bootstrap';

function NewRoomModal({ onAddRoom, ...props }) {
  const [room, setRoom] = useState({
    name: '',
    argument: '',
  });
  function handleOnChange(e) {
    const { name, value } = e.target;
    setRoom((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }

  function onClickCreateRoom() {
    onAddRoom({
      name: room.name,
      argument: room.argument,
    });
  }

  return (
    <Modal
      role="dialog"
      size="lg"
      aria-labelledby="modal-room-creation"
      centered
      {...props}
    >
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Creazione nuova stanza</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} lg>
            <Form.Label>Nome *</Form.Label>
            <Form.Control
              name="name"
              placeholder="Inserisci il nome della stanza"
              value={room.name}
              onChange={handleOnChange}
            />
          </Form.Group>
        </Row>
        <Row className="my-3">
          <Form.Group as={Col} lg>
            <Form.Label>Argomento (facoltativo)</Form.Label>
            <Form.Control
              name="argument"
              placeholder="Inserisci l'argomento"
              value={room.argument}
              onChange={handleOnChange}
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="grey"
          onClick={props.onHide}
        >
          <b>Annulla</b>
        </Button>
        <Button
          disabled={room.name === ''}
          onClick={onClickCreateRoom}
          variant="primary"
        >
          Crea stanza
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewRoomModal;
