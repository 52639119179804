/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import React from 'react';
import {
  Button, Modal,
} from 'react-bootstrap';
import FormFile from './FormFile';
import CardGroupDocument from './CardGroupDocument';

function ModalAttachment({
  showModal, setShowModal, arrayAllegati, setArrayAllegati,
  file, setFile, handleStateNuovoFile, updateAllegati,
}) {
  function documentDelete(document) {
    setArrayAllegati(arrayAllegati.filter((el) => el !== document));
  }

  return (

    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>
          Lista Allegati del Dettaglio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {arrayAllegati ? (
          <CardGroupDocument
            data={arrayAllegati}
            functionDelete={documentDelete}
          />
        ) : <p>Non ci sono documenti ancora</p>}
        <FormFile
          file={file}
          setFile={setFile}
          handleStateNuovoFile={handleStateNuovoFile}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={() => setShowModal(false)}>
          Chiudi
        </Button>
        <Button variant="primary" onClick={() => { setShowModal(false); updateAllegati(); }}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>

  );
}

export default ModalAttachment;
