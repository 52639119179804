/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import canaliService from '../../services/canaliService';
import { Context } from '../../utils/Context';
import FiltriRicercaUtentiChat from '../Chat/NewPeopleModal/FiltriRicercaUtentiChat';
import Table from '../Shared/Table';
import { fieldsUtenti, selectRows } from './fieldsUtenti';

const ModaleRicercaUtenti = ({
  show,
  handleClose,
  idIdentita,
  listaIdentita,
  onChangeAutorizzazioni,
  indexAutorizzazioneScelta,
}) => {
  const [context, setContext] = useContext(Context);
  const [listaUtenti, setListaUtenti] = useState([]);
  const [filtri, setFiltri] = useState({
    testo: '',
    identita: idIdentita,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }
  async function getListaUtenti(offset, limit, page) {
    const response = await canaliService.getUtentiByCanali(filtri, offset, limit);
    setListaUtenti(response.data);
    setPagination((prevPag) => ({
      ...prevPag,
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    }));
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getListaUtenti(currentIndex, sizePerPage, page);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="xl"
      role="dialog"
      aria-labelledby="modale-scelta-utente"
    >
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Seleziona l&apos;utente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FiltriRicercaUtentiChat
          listaIdentitaCanali={listaIdentita}
          getListaUtenti={getListaUtenti}
          onChangeFiltriUtenti={onChangeFiltri}
          notShowSelectIdentita
        />
        <Table
          title="Ricerca utenti"
          data={listaUtenti}
          fields={fieldsUtenti}
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="codi_matrix_user_id"
          selectRow={selectRows(onChangeAutorizzazioni, indexAutorizzazioneScelta)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Aggiungi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModaleRicercaUtenti;
