import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const newsUrl = process.env.REACT_APP_NEWS_FAQ_URL;

async function getListeDistribuzione() {
  return api.get(`${newsUrl}/newsletter/liste`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getCampagneInformative(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${newsUrl}/newsletter/campagne?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListaDistribuzioneById(id) {
  return api.get(`${newsUrl}/newsletter/liste/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createNewListaDistribuzione(body) {
  return api.post(`${newsUrl}/newsletter/liste/aggiungi`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editListaDistribuzione(id, body) {
  return api.put(`${newsUrl}/newsletter/liste/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteListaDistribuzione(id) {
  return api.delete(`${newsUrl}/newsletter/liste/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListaUtentiByIdLista(id, filtri) {
  const filters = {
    ...filtri,
    list_id: id,
  };
  const params = createQueryString(filters);
  return api.get(`${newsUrl}/newsletter/subscribers?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function aggiungiUtentiInCoda(listaUtenti, idLista) {
  const body = {
    listmonkUserId: listaUtenti,
    codi_k_id_lista: idLista,
  };
  return api.post(`${newsUrl}/newsletter/liste/aggiungi-utenti-coda`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteUtente(idUtente, idLista) {
  const body = {
    list_id: idLista,
  };
  const params = createQueryString(body);
  return api.delete(`${newsUrl}/newsletter/subscribers/${idUtente}?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteLista(id) {
  return api.delete(`${newsUrl}/newsletter/liste/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function verificaUtentiCoda(id) {
  return api.get(`${newsUrl}/newsletter/liste/verifica-utenti-coda/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const newsletterService = {
  getListeDistribuzione,
  getCampagneInformative,
  getListaDistribuzioneById,
  createNewListaDistribuzione,
  editListaDistribuzione,
  deleteListaDistribuzione,
  getListaUtentiByIdLista,
  aggiungiUtentiInCoda,
  deleteUtente,
  deleteLista,
  verificaUtentiCoda,
};

export default newsletterService;
