import React from 'react';
import {
  Accordion,
  Form,
} from 'react-bootstrap';

const FormColori = ({
  versione, onChange, listaColori, disabled,
}) => (
  <Accordion className="mb-3">
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        Colore del sito
      </Accordion.Header>
      <Accordion.Body>
        <strong>
          Si può scegliere il colore principale del sito web.
        </strong>
        {listaColori?.map((el) => (
          <Form.Check
            key={el.codi_k_colore}
            label={el.desc_colore}
            type="radio"
            name="lista-colori"
            // eslint-disable-next-line prefer-template
            id={'lista-colori' + el.codi_k_colore}
            value={el.codi_k_colore}
            onChange={onChange}
            checked={versione.fk_codi_colore === el.codi_k_colore}
            disabled={disabled}
          />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
);

export default FormColori;
