import React from 'react';
import { Form } from 'react-bootstrap';

export default function InEvidenza({ onChange, contenuto }) {
  return (
    <div className="rounded border border-grey p-2">
      <p>Scegli se inserire il contenuto in homepage e/o in evidenza</p>
      <Form.Check
        type="switch"
        id="inHomepage"
        label="Visualizza in homepage"
        onChange={onChange}
        value={contenuto.inHomepage}
        checked={contenuto.inHomepage}
      />
      <Form.Check
        type="switch"
        id="inEvidenza"
        label="Visualizza in evidenza"
        onChange={onChange}
        value={contenuto.inEvidenza}
        checked={contenuto.inEvidenza}
      />
    </div>
  );
}
