/* eslint-disable no-self-compare */
/* eslint-disable no-constant-condition */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card, Col, Row,
} from 'react-bootstrap';
import { formatoDataEOra } from '../../utils/formatData';
import { controlla } from '../../utils/verificaPermessi';
import ExportButton from '../ExportButton/ExportButton';

function CardGroup({
  templates, functionShow, functionEdit, functionDelete, functionPublish,
  functionClone, functionRetire, functionExport, functionSeeStats, handleStateModaleConferma,
  setFunzioneModaleConferma, setTestoModaleConferma, setIdTemplate, copyLink,
}) {
  return (
    <div className="mt-3">
      <Row lg={1} xs={1} className="g-4 mt-2 mb-2">
        {templates.length !== 0 ? templates.map((el) => (
          <Col key={el._id}>
            <Card>
              <Card.Header>
                <Row>
                  <Col lg={10}>
                    <h3>
                      {el?.titolo}
                    </h3>
                    <div>
                      Creata da
                      <strong>
                        {' '}
                        {el?.creatore?.nome}
                        {' '}
                        {el?.creatore?.cognome}
                      </strong>
                      {' '}
                      il
                      {' '}
                      <strong>{formatoDataEOra(el.dataCreazione)}</strong>
                      <br />
                      Ultima modifica:
                      <strong>
                        {' '}
                        {el?.autoreUltimaModifica?.nome}
                        {' '}
                        {el?.autoreUltimaModifica?.cognome}
                      </strong>
                      {' '}
                      il
                      {' '}
                      <strong>{formatoDataEOra(el.dataUltimaModifica)}</strong>
                    </div>
                  </Col>
                  <Col lg={2}>
                    <ButtonGroup className="float-end" aria-label={`Azioni disponibili per il questionario ${el.titolo}`}>
                      {/* Verifica se l'utente è il creatore
                          oppure se l'utente è responsabile */}
                      {el.stato === 'bozza' && controlla('X54')
                        ? (
                          <Button
                            className="me-2"
                            onClick={() => functionEdit(el._id)}
                            title={`Modifica il template ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="edit" />
                          </Button>
                        ) : null}
                      {controlla('X52')
                        ? (
                          <Button
                            className="me-2"
                            onClick={() => functionShow(el._id)}
                            title={`Visualizza il template ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="search" />
                          </Button>
                        ) : null}
                      {el.stato === 'pubblicato' && controlla('X57')
                        ? (
                          <Button
                            variant="danger"
                            className="me-2"
                            onClick={() => {
                              setIdTemplate(el._id);
                              setFunzioneModaleConferma(() => functionRetire);
                              setTestoModaleConferma('ritirerà');
                              handleStateModaleConferma();
                            }}
                            title={`Ritira il template ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="arrow-left" />
                          </Button>
                        ) : null}
                      {controlla('X53')
                        ? (
                          <Button
                            className="me-2"
                            onClick={() => functionClone(el._id)}
                            title={`Clona il template ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="clone" />
                          </Button>
                        ) : null}
                      {(el.stato === 'bozza' || el.stato === 'ritirato') && !el.dataFineValidita && controlla('X55')
                        ? (
                          <Button
                            className="text-center me-2"
                            variant="danger"
                            onClick={() => {
                              setIdTemplate(el._id);
                              setFunzioneModaleConferma(() => functionDelete);
                              setTestoModaleConferma('eliminerà');
                              handleStateModaleConferma();
                            }}
                            title={`Elimina il template ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon={['far', 'trash-alt']} />
                          </Button>
                        ) : null}
                      {el.stato === 'bozza' && controlla('X56')
                        ? (
                          <Button
                            className="text-center me-2"
                            onClick={() => {
                              setIdTemplate(el._id);
                              setFunzioneModaleConferma(() => functionPublish);
                              setTestoModaleConferma('pubblicherà');
                              handleStateModaleConferma();
                            }}
                            title={`Pubblica il template ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="arrow-up" />
                          </Button>
                        ) : null}
                      {(el.stato === 'pubblicato' || el.stato === 'ritirato') && controlla('X58')
                        ? (
                          <Button
                            className="text-center me-2"
                            onClick={() => { functionSeeStats(el._id); }}
                            title={`Visualizza statistiche di ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="chart-line" />
                          </Button>
                        ) : null}
                      {el.stato === 'pubblicato' && el.isEsterno
                        ? (
                          <Button
                            className="text-center me-2"
                            onClick={() => copyLink(el)}
                            title={`Copia il link di ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="link" />
                          </Button>
                        )
                        : null}
                    </ButtonGroup>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <strong>Domande: </strong>
                    {el?.totaleDomande}
                    <br />
                    {/* <strong>Sezioni: </strong>
                    {el?.sezioni ? Object.keys(el?.sezioni).length : 0}
                    <br /> */}
                    <strong>Stato: </strong>
                    {el.stato}
                    {' '}
                    {el.dataFineValidita ? '- eliminato' : null}
                    <br />
                  </Col>
                  {controlla('X52')
                    ? (
                      <Col>
                        <div className="float-end">
                          <ExportButton
                            id={el._id}
                            functionExport={functionExport}
                          />
                        </div>
                      </Col>
                    ) : null}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )) : <p><strong>Non sono presenti ancora template.</strong></p>}
      </Row>
    </div>

  );
}

export default CardGroup;
