/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row, Tabs, Tab, Button,
} from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';
import settings from '../../../../utils/TinyMCEConfiguration';
import CardGroup from '../../../../components/GestisciUtenti/CardGroup';
import listeAndreaService from '../../../../services/listeAndreaService';
import { Context } from '../../../../utils/Context';
import campaignService from '../../../../services/campaignService';
import fromArrayToHtml from '../../../../utils/fromArrayToHtml';
import ModaleAnteprimaArticoli from '../../../../components/Newsletter/ModaleAnteprimaArticoli';
import { dataOraAttuale } from '../../../../utils/dataAttuale';

const listmonkMediaUrl = process.env.REACT_APP_LISTMONK_MEDIA_URL;

function CreaCampagna() {
  const [context, setContext] = useContext(Context);
  const [campagna, setCampagna] = useState({
    body: '',
    lists: [],
    name: '',
    send_later: false,
    send_at: '',
    subject: '',
  });
  const [articles, setArticles] = useState(
    [],
  );
  const [liste, setListe] = useState([]);
  const [articolo, setArticolo] = useState(
    {
      key: 0,
      titolo: '',
      testo: '',
      nomeImmagine: '',
    },
  );
  const [showModaleAnteprima, setShowModaleAnteprima] = useState(false);

  function handleStateModaleAnteprima() {
    setShowModaleAnteprima(!showModaleAnteprima);
  }

  const onUpload = (e) => {
    const { id, value, files } = e.target;
    setArticolo({
      ...articolo,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  /** Aggiorna "editorText"
   * @param e - L'evento emesso
   */
  function handleEditorChange(e) {
    setArticolo({
      ...articolo,
      testo: e.target.getContent(),
    });
  }

  /** Aggiorna "title"
   * @param e - L'evento emesso
   */
  function handleTitleChange(e) {
    setArticolo({
      ...articolo,
      titolo: e.target.value,
    });
  }

  async function aggiungiMedia() {
    await campaignService.aggiungiMedia(articolo)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Articolo e immagine caricati correttamente',
        }));
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  /** Aggiunge un articolo alla lista */
  async function handleArticleSubmit() {
    try {
      const article = {
        key: Math.random(),
        titolo: articolo.titolo,
        testo: articolo.testo,
        nomeImmagine: articolo.fileCaricato?.name,
        imageSrc: `${listmonkMediaUrl}/${articolo.fileCaricato?.name}`,
      };
      setArticolo({
        key: 0,
        titolo: '',
        testo: '',
        nomeImmagine: '',
      });
      if (articolo.fileCaricato) await aggiungiMedia();
      setArticles([...articles, article]);
      setContext(() => ({
        ...context, openConferma: true, testo: 'Articolo aggiunto correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function deleteArticle(id) {
    const newArticles = articles.filter((article) => article.key !== id);
    setArticles(newArticles);
  }

  function onChange(e) {
    const {
      id, checked, type, value,
    } = e.target;
    const valore = type === 'checkbox' ? checked : value;
    setCampagna({
      ...campagna,
      [id]: valore,
    });
  }

  function onChangeListe(e) {
    const {
      value,
    } = e.target;
    setCampagna({
      ...campagna,
      lists: campagna.lists.includes(Number(value))
        ? campagna.lists.filter((el) => el !== Number(value)) : [...campagna.lists, Number(value)],
    });
  }

  async function getListe() {
    try {
      const response = await listeAndreaService.getListeDistribuzione({});
      setListe(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function nuovaCampagna() {
    await campaignService.nuovaCampagna(campagna)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Campagna aggiunta correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  useEffect(() => {
    getListe();
  }, []);

  useEffect(() => {
    setCampagna({
      ...campagna,
      body: fromArrayToHtml(articles),
    });
  }, [articles]);

  return (
    <>
      <TitlePage
        title="Creazione nuova campagna informativa"
        subtitle="Attraverso il form seguente è possibile creare una nuova campgna informativa."
        showIndietro
        showSave
        functionIndietro={() => history.push('/area-riservata/newsletter')}
        functionSave={nuovaCampagna}
      />
      <Tabs defaultActiveKey="dettagli" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="dettagli" title="Dettagli">
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="name">
                <Form.Label>Nome*</Form.Label>
                <Form.Control
                  placeholder="Inserisci il nome della campagna informativa"
                  onChange={onChange}
                  value={campagna?.name}
                />
              </Form.Group>

              <Form.Group as={Col} lg controlId="subject">
                <Form.Label>Oggetto*</Form.Label>
                <Form.Control
                  placeholder="Inserisci l'oggetto della campagna informativa"
                  onChange={onChange}
                  value={campagna?.subject}
                />

              </Form.Group>
            </Row>
            <Row>
              <Form.Group>
                <Form.Label>Liste di distribuzione</Form.Label>
                <Form.Select
                  multiple
                  onChange={onChangeListe}
                  value={campagna.lists}
                >
                  <option> </option>
                  {liste.map((lista) => (
                    <option
                      key={lista.id}
                      value={lista.id}
                    >
                      {lista.name}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mt-5">
              <Col>
                <Form.Check
                  type="switch"
                  id="send_later"
                  label="Schedula invio"
                  value={campagna?.send_later}
                  onChange={onChange}
                />
              </Col>
              <Col>
                <label htmlFor="send_at">Data invio*</label>
                <Form.Control
                  disabled={!campagna?.send_later}
                  type="datetime-local"
                  id="send_at"
                  value={campagna?.send_at}
                  onChange={onChange}
                  min={dataOraAttuale()}
                />
              </Col>
            </Row>
          </Form>
        </Tab>
        <Tab eventKey="articoli" title="Articoli">
          <Form className="mt-3">
            <Row>
              <Col>
                <h3>Aggiungi articoli alla newsletter</h3>
              </Col>
              <Col>
                <Button className="float-end mx-1" onClick={handleArticleSubmit}>
                  <FontAwesomeIcon aria-hidden="true" icon="plus" />
                  {' '}
                  AGGIUNGI ARTICOLO
                </Button>
                <Button className="float-end" onClick={handleStateModaleAnteprima}>
                  <FontAwesomeIcon aria-hidden="true" icon="search" />
                  {' '}
                  ANTEPRIMA
                </Button>
              </Col>
            </Row>

            <Row className="my-3">
              <Form.Group as={Col} lg controlId="titolo">
                <Form.Label>Titolo*</Form.Label>
                <Form.Control
                  placeholder="Inserisci il titolo dell'articolo"
                  onChange={handleTitleChange}
                  value={articolo.titolo}
                />
              </Form.Group>
            </Row>
            <label htmlFor="testo">Testo*</label>
            <Editor
              id="testo"
              initialValue="Inserisci il testo qui..."
              init={{
                plugins: settings.plugins,
                toolbar: settings.toolbar,
              }}
              apiKey={settings.apiKey}
              onChange={handleEditorChange}
              value={articolo.testo}
            />
            <Form.Group controlId="nomeImmagine" className="mt-3">
              <Form.Label>Immagine</Form.Label>
              <Form.Control
                value={articolo.nomeImmagine}
                type="file"
                size="sm"
                onChange={onUpload}
              />
              <Form.Text muted>
                È possibile inserire soltanto file di dimensioni non superiori a 5 MB.
                Sono supportati soltanto i seguenti formati: .jpg, .png, .gif
              </Form.Text>
            </Form.Group>
          </Form>
          <hr />
          <Row>
            <CardGroup items={articles} title="Articoli:" showDelete functionDelete={deleteArticle} />
          </Row>
        </Tab>
      </Tabs>
      <ModaleAnteprimaArticoli
        show={showModaleAnteprima}
        handleClose={handleStateModaleAnteprima}
        articoli={articles}
      />
    </>
  );
}

export default CreaCampagna;
