import api from '../utils/api';

const questionariUrl = process.env.REACT_APP_QUESTIONARI_URL;

async function getTemplateById(idTemplate) {
  return api.get(`${questionariUrl}/esterni-tracciati/template/${idTemplate}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createQuestionario(idTemplate) {
  return api.post(`${questionariUrl}/esterni-tracciati/crea/${idTemplate}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editSezione(idQuestionario, titolo, body) {
  return api.put(`${questionariUrl}/esterni-tracciati/${idQuestionario}/sezione/${titolo}/compila-domande`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getQuestionarioById(idQuestionario) {
  return api.get(`${questionariUrl}/esterni-tracciati/${idQuestionario}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function closeQuestionario(idQuestionario) {
  return api.put(`${questionariUrl}/esterni-tracciati/${idQuestionario}/completa`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function verificaEsistenzaQuestionario(idTemplate) {
  return api.get(`${questionariUrl}/esterni-tracciati/verifica-esistenza-questionario?idTemplate=${idTemplate}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const questionariTracciatiService = {
  getTemplateById,
  createQuestionario,
  editSezione,
  getQuestionarioById,
  closeQuestionario,
  verificaEsistenzaQuestionario,
};

export default questionariTracciatiService;
