/* eslint-disable max-len */
import React, {
  useContext,
  useEffect, useRef, useState,
} from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';
import useLayoutBreakpoint from '../../../../hooks/useLayoutBreakpoint';
import './_id.scss';
import scrollTo from '../../../../utils/scrollTo';
import themeColors from '../../../../utils/contants/theme-colors';
import newsService from '../../../../services/newsService';
import { Context } from '../../../../utils/Context';
import { formatoDataEOra } from '../../../../utils/formatData';
import RenderHTML from '../../../../components/Shared/RenderHTML';

const newsUrl = process.env.REACT_APP_NEWS_FAQ_URL;

const Component1 = ({ news }) => (
  <article id="descrizione" className="my-5" style={{ minHeight: '80vh' }}>
    <h1>Descrizione</h1>
    <RenderHTML html={news?.contenuto} />
  </article>
);

// const Component2 = ({ news }) => (
//   <article id="documenti" className="my-5" style={{ minHeight: '500px' }}>
//     <h1>Documenti</h1>
//     <div className="row justify-content-center">
//       <div className="card shadow p-4 mt-3 rounded border col-md-4 col-12 mx-3" style={{ zIndex: '-1' }}>
//         <FontAwesomeIcon
//           aria-hidden="true"
//           icon={['fas', 'paperclip']}
//           size="2x"
//           title="Allegato"
//           id="paperclip-icon-2"
//         />
//         <div className="card-body">
//           <p className="card-title">
//             <a href="#link">
//               Lo speciale di National Geographic sulle regate in Sardegna
//             </a>
//           </p>
//         </div>
//       </div>
//       <div className="card shadow p-4 mt-3 rounded border col-md-4 col-12 mx-3" style={{ zIndex: '-1' }}>
//         <FontAwesomeIcon
//           aria-hidden="true"
//           icon={['fas', 'paperclip']}
//           size="2x"
//           title="Allegato"
//           id="paperclip-icon"
//         />
//         <div className="card-body">
//           <p className="card-title">
//             <a href="#link">
//               {'Le rotte del mare: porti e approdi sulle coste dell\' isola'}
//             </a>
//           </p>
//         </div>
//       </div>
//     </div>
//   </article>
// );

const Component3 = ({ news }) => (
  <article id="ultimo-aggiornamento" className="my-5" style={{ minHeight: '50px' }}>
    <h1>Ultimo aggiornamento</h1>
    <p><strong>{formatoDataEOra(news?.dataUltimaModifica || news?.dataCreazione)}</strong></p>
  </article>
);

// const obsvOpts = {
//   root: null,
//   rootMargin: '0px',
//   threshold: [1.0],
// };

const menuItems = [{
  id: 'a',
  label: 'Descrizione',
  component: (news) => <Component1 news={news} />,
},
// {
//   id: 'b',
//   label: 'Documenti',
//   component: () => <Component2 />,
// },
{
  id: 'c',
  label: 'Ulteriori informazioni',
  component: (news) => <Component3 news={news} />,
},
];

const MenuNotizia = ({
  items, activeMenuItem, handleClickMenuItem, top,
}) => {
  const [display, setDisplay] = useState(true);

  return (
    <div className="menu-notizia" style={{ top }}>
      <button className="d-flex px-3 w-100 align-items-center justify-content-center" type="button" onClick={() => setDisplay(!display)}>
        <h6 className="text-uppercase page-index-title mb-0">indice della pagina</h6>
        <FontAwesomeIcon
          style={{ color: themeColors.primary, transform: `rotate(${display ? '180deg' : '0deg'})` }}
          aria-hidden="true"
          icon={['fas', 'angle-down']}
          size="2x"
          title="Espandi menu"
          id="expand-icon"
        />
      </button>
      <div className="menu-notizia-separator"><div style={{ backgroundColor: 'brown', width: '3rem', height: '5px' }} /></div>
      {display ? (
        <ul className="list-notizia py-3 px-0">
          {items.map((item) => <li key={`li-${item.id}`} className="mb-3"><a className={`px-3 py-2 ${activeMenuItem && activeMenuItem.id === item.id ? 'active' : ''}`} href={`#${item.id}`} onClick={handleClickMenuItem}>{item.label}</a></li>)}
        </ul>
      ) : null}
    </div>
  );
};

const DettaglioNews = () => {
  const { id } = useParams();
  const [context, setContext] = useContext(Context);
  const [news, setNews] = useState();
  const [activeMenuItem, setActiveMenuItem] = useState(document.querySelector(`[id="${menuItems[0].id}"]`));
  const isScrolling = useRef(false);
  const breakpoint = useLayoutBreakpoint();

  const itHeaderNavbarWrapper = document.querySelector('.it-header-navbar-wrapper');
  const itHeaderCenterWrapper = document.querySelector('.it-header-center-wrapper');

  const headerHeigth = breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md'
    ? itHeaderCenterWrapper && itHeaderCenterWrapper.getBoundingClientRect().height : itHeaderNavbarWrapper && itHeaderNavbarWrapper.getBoundingClientRect().height;

  // useLayoutEffect(() => {
  //   const observer = new IntersectionObserver((entries) => entries.forEach((entry) => {
  //     if (entry.intersectionRatio > 0.5 && !isScrolling.current) {
  //       setActiveMenuItem(entry.target);
  //     }
  //   }),
  //   obsvOpts);

  //   menuItems.forEach((item) => observer.observe(document.querySelector(`[id="${item.id}"]`)));

  //   return () => menuItems.forEach((item) => observer.unobserve(document.querySelector(`[id="${item.id}"]`)));
  // }, []);

  const handleClickMenuItem = (event) => {
    event.preventDefault();
    const href = event.target.getAttribute('href');
    const element = document.querySelector(href);
    isScrolling.current = true;
    scrollTo(element.offsetTop, () => {
      isScrolling.current = false;
      setActiveMenuItem(element);
    });
  };

  async function getNews() {
    try {
      const response = await newsService.getNewsById(id);
      setNews(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
      <TitlePage
        showIndietro
        functionIndietro={() => {
          history.goBack();
        }}
      />
      <div className="col-lg-8 px-lg-4 py-lg-2">
        <h1>
          {news?.titolo}
        </h1>
        {/* <article>
          <p>
            Donec in consequat nunc. Duis semper fermentum lacus, ac condimentum
            justo auctor a. Nam erat erat, porta vel pharetra in, ullamcorper vel
            turpis.
          </p>
        </article> */}
        <div className="row mb-4">
          <div className="col-6">
            <small>Data pubblicazione:</small>
            <p className="fw-bold">{formatoDataEOra(news?.dataCreazione)}</p>
          </div>
          {/* <div className="col-6">
            <small>Tempo di lettura:</small>
            <p className="fw-bold">3 min</p>
          </div> */}
        </div>
      </div>
      {news?.pathMinioImmagine
        ? <Image src={news && `${newsUrl}/news/immagine/${encodeURIComponent(news.pathMinioImmagine)}`} className="w-100" alt="Immagine notizia" />
        : null }
      <div className={`${breakpoint && (breakpoint === 'lg' || breakpoint === 'xl') ? 'd-flex' : ''} mt-5 menu-notizia-container`}>
        { breakpoint && (breakpoint === 'lg' || breakpoint === 'xl')
          ? (
            <div className="d-block">
              <MenuNotizia items={menuItems} activeMenuItem={activeMenuItem} handleClickMenuItem={handleClickMenuItem} top={headerHeigth} />
            </div>
          ) : <MenuNotizia items={menuItems} activeMenuItem={activeMenuItem} handleClickMenuItem={handleClickMenuItem} top={headerHeigth} />}
        <div className="menu-items p-5">
          {news && menuItems.map((item) => <section key={`section-${item.id}`} id={item.id}>{item.component(news)}</section>)}
        </div>
      </div>
    </>
  );
};

export default DettaglioNews;
