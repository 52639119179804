/* eslint-disable prefer-template */
import React from 'react';
import {
  Form, Row, Button, Col, Accordion,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { controlla } from '../../utils/verificaPermessi';

function FiltriRicercaRepository({
  filtri,
  onChange,
  getElementiByPath,
  configurazione,
}) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <Col className="mb-3">
                <Form.Group as={Col} lg controlId="nome">
                  <Form.Label>Nome file/cartella</Form.Label>
                  <Form.Control placeholder="Inserisci il nome" onChange={onChange} value={filtri.nome} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  id="sottocartelle"
                  label="Applica a sottocartelle"
                  onChange={(e) => onChange(e, 'checkbox')}
                  value={filtri.sottocartelle}
                />
              </Col>
              {controlla('W1') && (
              <Col>
                <Form.Check
                  type="switch"
                  id="profonditaStorica"
                  label="Visualizza profondità storica"
                  onChange={(e) => onChange(e, 'checkbox')}
                  value={filtri.profonditaStorica}
                />
              </Col>
              )}
            </Row>
            {configurazione?.metadati?.map((el, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row className="mb-3" key={index}>
                <Form.Group controlId={'metadati.' + el.chiave}>
                  <Form.Label>{el.etichetta}</Form.Label>
                  <Form.Control value={filtri.metadati[el.chiave]} placeholder="Inserisci il TAG" onChange={onChange} />
                </Form.Group>
              </Row>
            ))}
          </Form>
          <Button variant="primary" onClick={() => { getElementiByPath(0, 5, 1); }}>
            <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
            CERCA
          </Button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriRicercaRepository;
