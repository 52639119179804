/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import themeColors from '../../../utils/contants/theme-colors';
import CardGroupSezioni from '../../../components/Template/CardGroupSezioni';
import NewTemplateSection from '../../../components/Template/NewTemplateSection';
import { Context } from '../../../utils/Context';
import templateService from '../../../services/templateService';
import { formatoDataEOra } from '../../../utils/formatData';

function VisualizzaTemplate() {
  const { idTemplate } = useParams();
  const [context, setContext] = useContext(Context);
  const [showNewTemplateLayout, setShowNewTemplateLayout] = useState();
  const [newTemplate, setNewTemplate] = useState({
    titolo: '',
    descrizione: '',
    creatore: {
      nome: '',
      cognome: '',
    },
    dataCreazione: '',
    autoreUltimaModifica: {
      nome: '',
      cognome: '',
    },
    dataUltimaModifica: '',
    sezioni: {},
  });
  const [sezione, setSezione] = useState({
    titolo: '',
    descrizione: '',
    template: {},
  });

  async function getTemplate() {
    try {
      const response = await templateService.getTemplateById(idTemplate);
      setNewTemplate(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  function showNewTemplateLayoutHandle() {
    setShowNewTemplateLayout(!showNewTemplateLayout);
  }

  useEffect(() => {
    getTemplate();
  }, []);
  return (
    <div>
      <TitlePage
        title="Template"
        subtitle="Visualizza Template"
        showIndietro
        functionIndietro={() => history.push('/area-riservata/template')}
      />

      <div>
        Creata da
        <strong>
          {' '}
          {newTemplate?.creatore.nome}
          {' '}
          {newTemplate?.creatore.cognome}
        </strong>
        {' '}
        il
        {' '}
        <strong>{formatoDataEOra(newTemplate.dataCreazione)}</strong>
        <br />
        Ultima modifica:
        <strong>
          {' '}
          {newTemplate?.autoreUltimaModifica?.nome}
          {' '}
          {newTemplate?.autoreUltimaModifica?.cognome}
        </strong>
        {' '}
        il
        {' '}
        <strong>{formatoDataEOra(newTemplate.dataUltimaModifica)}</strong>
      </div>
      <br />

      {!showNewTemplateLayout ? (
        <div>
          <Row>
            <Col>
              <p className="h4" style={{ lineHeight: '1.6' }}>
                <FontAwesomeIcon aria-hidden="true" icon="info" color={themeColors.primary} />
                {' '}
                Informazioni principali
              </p>
            </Col>
          </Row>
          <Form className="mt-3">
            <Row className="my-3">
              <Form.Group as={Col} lg controlId="titolo">
                <Form.Label>Titolo</Form.Label>
                <Form.Control value={newTemplate.titolo} disabled />
              </Form.Group>
            </Row>

            <Row className="my-3">
              <Form.Group as={Col} lg controlId="descrizione">
                <Form.Label>Descrizione</Form.Label>
                <Form.Control value={newTemplate.descrizione} disabled />
              </Form.Group>
            </Row>
          </Form>
          <Row>
            <Col className="mb-3">
              <Form.Group as={Col} lg controlId="tipo">
                <Form.Label>Stato</Form.Label>
                <Form.Control value={newTemplate.stato} disabled />
              </Form.Group>
            </Col>
            <Col />
          </Row>

          <Row>
            <Col>
              <p className="h4" style={{ lineHeight: '1.6' }}>
                <FontAwesomeIcon aria-hidden="true" icon="file-alt" color={themeColors.primary} />
                {' '}
                Sezioni Template
              </p>
            </Col>
          </Row>
          <CardGroupSezioni
            sezioni={newTemplate.sezioni}
            functionShow={showNewTemplateLayoutHandle}
            setEditedSezione={setSezione}
            readOnly
          />
        </div>
      ) : (
        <NewTemplateSection
          template={sezione.template || {}}
          functionCancel={showNewTemplateLayoutHandle}
          editedSezione={sezione}
          readOnly
        />
      )}
    </div>
  );
}

export default VisualizzaTemplate;
