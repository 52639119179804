/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty-function */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {
  Modal, Button, Breadcrumb,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import repositoryService from '../../services/repositoryService';
import { Context } from '../../utils/Context';
import Table from '../Shared/Table';
import fields, { selectRows } from './fieldsTableSposta';

const ModaleSposta = ({
  show, setModalShow, pathRepo, setPathRepo, breadcumbsItem, cartella, file, cartellaOrFile,
}) => {
  const [context, setContext] = useContext(Context);
  const [data, setData] = useState([]);
  const [pathDestinazione, setPathDestinazione] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  async function handleOkConferma() {
    try {
      let res = null;
      if (cartellaOrFile === 'cartella') {
        res = await repositoryService
          .spostaCartella(cartella._id, { newPathParent: pathDestinazione });
      } else if (cartellaOrFile === 'file') {
        res = await repositoryService
          .spostaFile(file._id, { newPathParent: pathDestinazione });
      }
      setContext(({
        ...context, openConferma: true, testo: res.message,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    } finally {
      setModalShow(false);
    }
  }
  async function getElementiByPath(offset, limit, page) {
    const response = await repositoryService.getElementiPath(null, offset, limit,
      encodeURIComponent(pathRepo));
    setPagination((prevPag) => ({
      ...prevPag,
      page,
      sizePerPage: limit,
      totalSize: response.nCartelle,
    }));
    setData([...response.cartelle]);
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getElementiByPath(currentIndex, sizePerPage, page);
  };

  useEffect(() => {
    getElementiByPath(0, 5, 1);
  }, [pathRepo]);
  return (
    <>
      <Modal
        show={show}
        onHide={() => setModalShow(false)}
        role="dialog"
        size="xl"
        style={{ backdropFilter: 'blur(80px)' }}
      >
        <Modal.Header closeButton closeLabel="Chiudi">
          <Modal.Title>
            Sposta
            {' '}
            {cartellaOrFile === 'cartella' ? 'la cartella' : 'il file'}
            {' '}
            {cartellaOrFile === 'cartella' ? cartella?.nome : file?.nome}
            {' '}
            in:
            {' '}
            {pathDestinazione}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/area-riservata/repository/main">
                Home
              </Link>
            </li>
            {breadcumbsItem.map((el, index) => (
              <li key={el.to} className={index + 1 === breadcumbsItem.length ? 'breadcrumb-item active' : 'breadcrumb-item'}>
                {index + 1 === breadcumbsItem.length
                  ? el.text
                  : (
                    <Link to={el.to}>
                      {el.text}
                    </Link>
                  )}
              </li>
            ))}
          </Breadcrumb>
          <Table
            data={data}
            fields={fields(setPathRepo)}
            pagination={pagination}
            onTableChange={handleTableChange}
            selectRow={selectRows(setPathDestinazione)}
            keyField="_id"
            striped
            notBordered
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="grey" onClick={() => setModalShow(false)}>
            Chiudi
          </Button>
          <Button variant="primary" onClick={handleOkConferma}>
            Salva
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModaleSposta;
