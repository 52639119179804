/* eslint-disable no-underscore-dangle */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row, Button, InputGroup,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AutorizzazioniForum from '../../../../components/Forum/AutorizzazioniForum';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import ModaleRicercaUtenti from '../../../../components/Repository/ModaleRicercaUtenti';
import appService from '../../../../services/appService';
import canaliService from '../../../../services/canaliService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

const urlIconaPrefix = process.env.REACT_APP_ESTERNE_URL_ICONA;

function CreaAppEsterna() {
  const [context, setContext] = useContext(Context);
  const { idApp } = useParams();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [editedApp, setEditedApp] = useState({
    visibilita: [],
    descrizione: '',
    nome: '',
    link: '',
    note: '',
    urlIcona: '',
    urlIconaSuffix: '',
  });
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [identitaScelta, setIdentitaScelta] = useState();
  const [listaIdentita, setListaIdentita] = useState([]);
  const [indexAutorizzazioneScelta, setIndexAutorizzazioneScelta] = useState();

  const onChangeAutorizzazioni = (prop, event, index, type) => {
    const old = editedApp.visibilita[index];
    const updated = { ...old, [prop]: type === 'int' ? parseInt(event.target.value, 10) : event.target.value };
    const clone = [...editedApp.visibilita];
    clone[index] = updated;
    setEditedApp({
      ...editedApp,
      visibilita: clone,
    });
  };

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  const handleCloseModaleUtenti = () => setShowModaleUtenti(!showModaleUtenti);

  async function onChange(e, type) {
    const { id, value, checked } = e.target;
    setEditedApp({
      ...editedApp,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function aggiungiRow() {
    setEditedApp({
      ...editedApp,
      visibilita: [
        ...editedApp.visibilita,
        {
          idIdentita: 0,
          codiceFiscale: null,
        },
      ],
    });
  }

  function eliminaRow(index) {
    setEditedApp({
      ...editedApp,
      visibilita: [
        ...editedApp.visibilita.filter((el, indexEl) => indexEl !== index)],
    });
  }

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
    setEditedApp({
      ...editedApp,
      urlIcona: urlIconaPrefix + editedApp.urlIconaSuffix,
    });
  }

  async function getAppById() {
    const response = await appService.getAppById(idApp);
    setEditedApp({
      nome: response[0].desc_nome_appl_est,
      descrizione: response[0].desc_appl_est,
      link: response[0].desc_link_appl_est,
      urlIcona: response[0].desc_url_icona_appl_est,
      urlIconaSuffix: response[0].desc_url_icona_appl_est.slice(urlIconaPrefix.length),
      note: response[0].desc_note_appl_est,
      visibilita: response[0].desc_visibilita_appl_est,
    });
  }

  async function editApp() {
    await appService.editApp(idApp, editedApp)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
        history.goBack();
      });
  }

  useEffect(() => {
    getAppById();
    getListaIdentita();
  }, []);

  return (
    <div>
      <TitlePage
        title="Applicazione esterna"
        subtitle="Modifica applicazione esterna esistente"
        showIndietro
        functionIndietro={() => history.goBack()}
      />

      <Form className="mt-3">

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="nome">
            <Form.Label>Nome *</Form.Label>
            <Form.Control placeholder="Inserire il nome per identificare l'Applicazione esterna" onChange={onChange} required value={editedApp.nome} />
          </Form.Group>
          <Form.Group as={Col} lg controlId="descrizione">
            <Form.Label>Descrizione *</Form.Label>
            <Form.Control placeholder="Descrivere l'Applicazione esterna" onChange={onChange} required value={editedApp.descrizione} />
          </Form.Group>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="link">
            <Form.Label>Link *</Form.Label>
            <Form.Control placeholder="Specificare l'indirizzo tramite cui è possibile raggiungere l'Applicazione esterna" as="textarea" onChange={onChange} required value={editedApp.link} />
          </Form.Group>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="note">
            <Form.Label>Note</Form.Label>
            <Form.Control placeholder="Inserire delle note relative all'Applicazione esterna" as="textarea" onChange={onChange} value={editedApp.note} />
          </Form.Group>
        </Row>

        <Form.Group as={Col} controlId="urlIconaSuffix">
          <Form.Label>url Icona *</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">{urlIconaPrefix}</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Specificare il nome del file icona"
              aria-describedby="inputGroupPrepend"
              onChange={onChange}
              required
              value={editedApp.urlIconaSuffix}
            />
          </InputGroup>
        </Form.Group>
      </Form>
      <br />
      <div className="separator border-1 border-bottom" />
      <Row>
        <Col>
          <p className="h4" style={{ lineHeight: '1.6' }}>
            Regole di visibilità
          </p>
        </Col>
      </Row>
      <AutorizzazioniForum
        autorizzazioni={editedApp.visibilita}
        listaIdentita={listaIdentita}
        aggiungiRow={aggiungiRow}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        handleCloseModaleUtenti={handleCloseModaleUtenti}
        setIndexAutorizzazioneScelta={setIndexAutorizzazioneScelta}
        eliminaRow={eliminaRow}
        setIdentitaScelta={setIdentitaScelta}
      />
      <Button
        variant="primary"
        className="text-uppercase float-end"
        onClick={handleStateModaleConferma}
        disabled={!(editedApp.nome && editedApp.descrizione && editedApp.link
          && editedApp.urlIcona)}
      >
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        {' '}
        Modifica
      </Button>
      <br />

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione modifcherà l'applicazione esterna: ${editedApp.nome}`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={editApp}
      />
      {showModaleUtenti && (
      <ModaleRicercaUtenti
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        idIdentita={identitaScelta}
        listaIdentita={listaIdentita}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        indexAutorizzazioneScelta={indexAutorizzazioneScelta}
      />
      )}
    </div>
  );
}

export default CreaAppEsterna;
