import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const forumUrl = process.env.REACT_APP_FORUM_URL;

async function getSezioni(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${forumUrl}/sezione?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getSezioneById(id) {
  return api.get(`${forumUrl}/sezione/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createNewSezione(sezione) {
  return api.post(`${forumUrl}/sezione`, sezione)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteSection(sezioneId) {
  return api.delete(`${forumUrl}/sezione/${sezioneId}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editSection(id, body) {
  return api.patch(`${forumUrl}/sezione/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function chiudiSezione(id, body) {
  return api.put(`${forumUrl}/sezione/${id}/chiudi`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const sezioneService = {
  getSezioni,
  getSezioneById,
  createNewSezione,
  deleteSection,
  editSection,
  chiudiSezione,
};

export default sezioneService;
