/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row, Button,
} from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import sezioneService from '../../../services/sezioneService';
import ModaleConferma from '../../../components/modali/ModaleConferma/index.js';
import { Context } from '../../../utils/Context';
import ModaleRicercaUtenti from '../../../components/Repository/ModaleRicercaUtenti';
import canaliService from '../../../services/canaliService';
import AutorizzazioniForum from '../../../components/Forum/AutorizzazioniForum';

function ForumSezione() {
  const [context, setContext] = useContext(Context);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [newSezione, setNewSezione] = useState({
    autorizzazioni: [],
    descrizione: '',
    titolo: '',
    inEvidenza: false,
  });
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [identitaScelta, setIdentitaScelta] = useState();
  const [listaIdentita, setListaIdentita] = useState([]);
  const [indexAutorizzazioneScelta, setIndexAutorizzazioneScelta] = useState();

  const onChangeAutorizzazioni = (prop, event, index, type) => {
    const old = newSezione.autorizzazioni[index];
    const updated = { ...old, [prop]: type === 'int' ? parseInt(event.target.value, 10) : event.target.value };
    const clone = [...newSezione.autorizzazioni];
    clone[index] = updated;
    setNewSezione({
      ...newSezione,
      autorizzazioni: clone,
    });
  };

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  const handleCloseModaleUtenti = () => setShowModaleUtenti(!showModaleUtenti);

  async function onChange(e, type) {
    const { id, value, checked } = e.target;
    setNewSezione({
      ...newSezione,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  async function createNewSezione() {
    await sezioneService.createNewSezione(newSezione)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  function aggiungiRow() {
    setNewSezione({
      ...newSezione,
      autorizzazioni: [
        ...newSezione.autorizzazioni,
        {
          idIdentita: 0,
          codiceFiscale: null,
        },
      ],
    });
  }

  function eliminaRow(index) {
    setNewSezione({
      ...newSezione,
      autorizzazioni: [
        ...newSezione.autorizzazioni.filter((el, indexEl) => indexEl !== index)],
    });
  }

  useEffect(() => {
    getListaIdentita();
  }, []);

  return (
    <div>
      <TitlePage
        title="Forum"
        subtitle="Creazione nuova sezione"
        showIndietro
        functionIndietro={() => history.push('/area-riservata/forum')}
      />
      <Form className="mt-3">

        <Row>
          <Col className="my-3">
            <Form.Check
              type="switch"
              id="inEvidenza"
              label="Sezione in evidenza"
              onChange={(e) => onChange(e, 'checkbox')}
              value={newSezione.inEvidenza}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Form.Group as={Col} lg controlId="titolo">
            <Form.Label>Titolo *</Form.Label>
            <Form.Control placeholder="Inserisci il titolo dell'articolo" onChange={onChange} value={newSezione.titolo} />
          </Form.Group>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="descrizione">
            <Form.Label>Descrizione *</Form.Label>
            <Form.Control placeholder="Inserisci la descrizione dell'articolo" onChange={onChange} value={newSezione.descrizione} />
          </Form.Group>
        </Row>

      </Form>
      <div className="separator border-1 border-bottom" />
      <Row>
        <Col>
          <p className="h4" style={{ lineHeight: 1.6 }}>
            Regole di visibilità
          </p>
        </Col>
      </Row>
      <AutorizzazioniForum
        autorizzazioni={newSezione.autorizzazioni}
        listaIdentita={listaIdentita}
        aggiungiRow={aggiungiRow}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        handleCloseModaleUtenti={handleCloseModaleUtenti}
        setIndexAutorizzazioneScelta={setIndexAutorizzazioneScelta}
        eliminaRow={eliminaRow}
        setIdentitaScelta={setIdentitaScelta}
      />
      <Button
        variant="primary"
        className="text-uppercase float-end"
        onClick={handleStateModaleConferma}
        disabled={!(newSezione.titolo && newSezione.descrizione)}
      >
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        {' '}
        Salva
      </Button>

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione aggiungerà una nuova sezione: ${newSezione.titolo}`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={createNewSezione}
      />
      {showModaleUtenti && (
      <ModaleRicercaUtenti
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        idIdentita={identitaScelta}
        listaIdentita={listaIdentita}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        indexAutorizzazioneScelta={indexAutorizzazioneScelta}
      />
      )}
    </div>
  );
}

export default ForumSezione;
