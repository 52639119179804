import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import {
  getBackendOptions, getDescendants, MultiBackend, Tree,
} from '@minoru/react-dnd-treeview';
import FormFile from '../../../../../components/AmmTrasparente/FormFile';

import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import ElementModal from '../../../../../components/Pagina/ElementModal';
import FormParagrafo from '../../../../../components/Pagina/FormParagrafo';
import Table from '../../../../../components/Shared/Table';
import versioniService from '../../../../../services/versioniService';
import fileAmmTraspService from '../../../../../services/fileAmmTraspService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';
import fieldsContent from './fieldsContent';
import styles from '../App.module.css';
import CustomNodeRappGrafica from '../../../../../components/GestioneAmmTrasparenza/CustomNodeRappGrafica';
import ModaleCreazioneNuovoElemento from '../../../../../components/GestioneAmmTrasparenza/ModaleCreazioneNuovoElemento';

const getLastId = (treeData) => {
  const reversedArray = [...treeData].sort((a, b) => {
    if (a.id < b.id) {
      return 1;
    } if (a.id > b.id) {
      return -1;
    }

    return 0;
  });

  if (reversedArray.length > 0) {
    return reversedArray[0].id;
  }

  return 0;
};

const ModificaPaginaAmmTrasparente = () => {
  const { idLivello } = useParams();
  const params = new URLSearchParams(window.location.search);
  const nomeLivello = params.get('nomeLivello');
  const flagRappGrafica = params.get('flagRappGraf');
  const [rappGrafica, setRappGrafica] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [indexArray, setIndexArray] = useState(0);
  const { idVersione } = useParams();
  const [modifica, setModifica] = useState(0);
  const [context, setContext] = useContext(Context);
  // Pagina Main
  const [pagina, setPagina] = useState({
    desc_nome: '',
    fk_sequ_livello_disponibile: '',
    fk_sequ_versione: '',
    contenuti: {},
  });
  // Nuovo Paragrafo da aggiungere nei contenuti
  const [paragrafo, setParagrafo] = useState({
    tipo: 'paragrafo',
    nome: '',
    contenuto: '',
    dataCreazione: new Date(),
  });
  const [file, setFile] = useState({
    tipo: 'file',
    nome: '',
    dataCreazione: new Date(),
  });
  const [indexRow, setIndexRow] = useState(1);
  const [openModaleModifica, setopenModaleModifica] = useState(false);

  const [openModaleNuovoElemento, setOpenModaleNuovoElemento] = useState(false);
  function openCloseModaleAggiungiElemento() {
    setOpenModaleNuovoElemento(!openModaleNuovoElemento);
  }

  const handleDrop = (
    newTree,
    // {
    //   dragSourceId, dropTargetId, dragSource, dropTarget,
    // },
  ) => {
    // console.log(dragSourceId, dropTargetId, dragSource, dropTarget);

    // Do something
    setPagina({
      ...pagina,
      contenuti: { tree: newTree },
    });
  };

  const handleCopy = (id) => {
    const targetNode = pagina.contenuti.tree.find((n) => n.id === id);
    const descendants = getDescendants(pagina.contenuti.tree, id);
    const partialTree = descendants.map((node) => ({
      ...node,
      id: node.id,
      parent: node.parent,
    }));

    setPagina({
      ...pagina,
      contenuti: {
        tree: [
          ...pagina.contenuti.tree,
          {
            ...targetNode,
            id: targetNode.id,
          },
          ...partialTree,
        ],
      },
    });
  };

  const handleSubmit = (newNode) => {
    const lastId = getLastId(pagina.contenuti.tree) + 1;

    setPagina({
      ...pagina,
      contenuti: {
        tree: [
          ...pagina.contenuti.tree,
          {
            ...newNode,
            contenutiArray: [],
            text: newNode.text,
            parent: newNode.parent,
            id: lastId,
          },
        ],
      },
    });
    openCloseModaleAggiungiElemento();
  };

  const handleTextChange = (id, value) => {
    const newTree = pagina.contenuti.tree.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          text: value,
        };
      }

      return node;
    });

    setPagina({
      ...pagina,
      contenuti: { tree: newTree },
    });
  };

  function handleOpen(id) {
    setRappGrafica(false);
    pagina.contenuti.tree.forEach((element, index) => {
      if (element.id === id)setIndexArray(index);
    });
  }

  function handleStateNuovoParagrafo() {
    pagina.contenuti.tree[indexArray].contenutiArray.push(paragrafo);
    setPagina(pagina);

    setParagrafo({
      nome: '',
      contenuto: '',
      tipo: 'paragrafo',
      dataCreazione: new Date(),
    });
  }

  async function uploadFile() {
    try {
      const response = await fileAmmTraspService.uploadFile(file);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      file.idMongo = response.insertedId;
      file.nomeFile = response.nomeFile;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function handleStateNuovoFile() {
    pagina.contenuti.tree[indexArray].contenutiArray.push(file);
    setPagina(pagina);
    uploadFile();

    setFile({
      nome: '',
      tipo: 'file',
      dataCreazione: new Date(),
    });
  }

  function handleTableChange() {}

  async function getPagina() {
    try {
      const paginaById = await versioniService.getPagina(idVersione, idLivello);
      if (Object.keys(paginaById).length !== 0) {
        setPagina({
          desc_nome: nomeLivello,
          fk_sequ_livello_disponibile: paginaById.documentoMongo.fk_sequ_livello_disponibile,
          fk_sequ_versione: paginaById.documentoMongo.fk_sequ_versione,
          contenuti: { tree: paginaById.documentoMongo.contenuti.tree },
        });
        setParagrafo({
          ...paragrafo,
        });
        setModifica(idLivello);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  async function salvaPagina() {
    try {
      let response = null;

      if (modifica === 0) {
        response = await versioniService.creaPagina(
          idVersione,
          pagina,
        );
      } else {
        response = await versioniService.modificaPagina(
          idVersione,
          modifica,
          pagina,
        );
      }

      history.goBack();
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function eliminaPagina() {
    try {
      const response = await versioniService.eliminaPagina(
        idVersione,
        modifica, // idPagina
      );

      history.goBack();
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function eliminaContenuto() {
    try {
      pagina.contenuti.tree = pagina.contenuti.tree
        .filter((el) => el.id !== pagina.contenuti.tree[indexArray].id);

      const response = await versioniService.modificaPagina(
        idVersione,
        modifica, // id Pagina
        pagina,
      );

      setRappGrafica(false);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // const handleDelete = (id) => {
  //   pagina.contenuti.tree.forEach((element, index) => {
  //     if (element.id === id)setIndexArray(index);
  //   });
  //   eliminaContenuto();
  // };

  function handleStateModaleModifica(row, rowIndex) {
    if (row) {
      setParagrafo(row);
      setIndexRow(rowIndex);
    }
    setTimeout(() => {
      setopenModaleModifica(!openModaleModifica);
    }, 150);
  }

  function modificaElemento() {
    pagina.contenuti.tree[indexArray].contenutiArray[indexRow] = paragrafo;

    setParagrafo({
      nome: '',
      contenuto: '',
      tipo: 'paragrafo',
    });

    setopenModaleModifica(!openModaleModifica);
  }

  function eliminaElemento(row) {
    pagina.contenuti.tree[indexArray].contenutiArray = pagina.contenuti.tree[indexArray]
      .contenutiArray.filter((el) => el !== row);
    setPagina({
      ...pagina,
      contenuti: {
        tree: pagina.contenuti.tree,
      },
    });
  }

  function move(oldIndex, newIndex) {
    const arr = pagina.contenuti.tree[indexArray].contenutiArray;
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    pagina.contenuti.tree[indexArray].contenutiArray = arr;
    setPagina({
      ...pagina,
      contenuti: {
        tree: pagina.contenuti.tree,
      },
    });
  }

  useEffect(() => {
    getPagina();
    if (modifica === 0) {
      if (flagRappGrafica) {
        setRappGrafica(true);
      }
      setPagina({
        desc_nome: nomeLivello,
        fk_sequ_livello_disponibile: Number(idLivello),
        fk_sequ_menu_disponibile: 0,
        fk_sequ_versione: parseFloat(idVersione),
        flag_sezione_non_applicabile: false,
        contenuti: { tree: [] },
      });
    }
  }, []);

  return (
    <>
      {!rappGrafica ? (
        <>
          <TitlePage
            title="Gestione livello Amministrazione trasparente"
            subtitle="Questo tipo di pagina permette di inserire un titolo, uno o più testi ed eventualmente una o più immagini"
            showIndietro
            functionIndietro={() => history.goBack()}
            showDelete
            functionDelete={eliminaContenuto}
            showSave
            functionSave={salvaPagina}
          />
          <p>
            Nome sezione:
            {' '}
            <strong>{pagina.desc_nome}</strong>
          </p>
          <br />
          <FormParagrafo
            paragrafo={paragrafo}
            setParagrafo={setParagrafo}
            handleStateNuovoParagrafo={handleStateNuovoParagrafo}
          />
          <FormFile
            file={file}
            setFile={setFile}
            handleStateNuovoFile={handleStateNuovoFile}
          />

          {pagina.contenuti.tree[indexArray].contenutiArray
      && pagina.contenuti.tree[indexArray].contenutiArray !== [] ? (
        <Table
          title="Contenuti inseriti:"
          data={pagina.contenuti.tree[indexArray].contenutiArray
            ? pagina.contenuti.tree[indexArray].contenutiArray : []}
          fields={fieldsContent(
            handleStateModaleModifica,
            eliminaElemento, move, pagina.contenuti.tree[indexArray].contenutiArray
              ? pagina.contenuti.tree[indexArray].contenutiArray : [],
          )}
          onTableChange={handleTableChange}
          keyField="id"
        />
            ) : (
              <>
                <p className="fw-bold fs-3">
                  Contenuti inseriti:
                  {' '}
                </p>
                <div>La lista contenuti è vuota</div>

              </>
            )}

          <ElementModal
            setParagrafo={setParagrafo}
            paragrafo={paragrafo}
            show={openModaleModifica}
            handleClose={handleStateModaleModifica}
            handleSave={modificaElemento}
          />
        </>
      ) : (
        <>
          <TitlePage
            title="Gestione contenuto - rappresentazione grafica"
            subtitle="È possibile gestire un contenuto che è stato scelto per la rappresentazione grafica."
            showDelete
            functionDelete={eliminaPagina}
            showSave
            functionSave={salvaPagina}
            showAdd
            functionAdd={openCloseModaleAggiungiElemento}
            showIndietro
            functionIndietro={() => setRappGrafica(false)}
          />
          <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <Tree
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                dropTarget: styles.dropTarget,
              }}
              tree={pagina.contenuti.tree || []}
              rootId={0}
              onDrop={handleDrop}
              dragPreviewRender={(monitorProps) => {
                const { item } = monitorProps;

                return (
                  <div>
                    <p>{item.text}</p>
                  </div>
                );
              }}
              render={(node, options) => (
                <CustomNodeRappGrafica
                  node={node}
                  {...options}
                  onCopy={handleCopy}
                  onTextChange={handleTextChange}
                  onOpen={handleOpen}
                />
              )}
            />
            {openModaleNuovoElemento && (
            <ModaleCreazioneNuovoElemento
              tree={pagina.contenuti.tree}
              onClose={openCloseModaleAggiungiElemento}
              onSubmit={handleSubmit}
              show={openModaleNuovoElemento}
            />
            )}
          </DndProvider>
        </>
      )}

    </>
  );
};

export default ModificaPaginaAmmTrasparente;
