export const fields = [
  {
    dataField: 'sequ_k_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '33%' }),
  },
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '42%' }),
  },
  {
    dataField: 'desc_nome_tag_identita',
    text: 'TAG',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_tipo_identita',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },

];

export const selectRows = (setIdentitaScelte, identitaScelte, identitaRimosse,
  setIdentitaRimosse,
  identitaAggiunte,
  setIdentitaAggiunte) => {
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...identitaScelte].filter((el) => !el.data_fine_validita)
      .map((el) => el.sequ_k_identita),
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setIdentitaAggiunte([...identitaAggiunte, row]);
        setIdentitaScelte([...identitaScelte, row]);
      } else {
        setIdentitaRimosse([...identitaRimosse, row]);
        setIdentitaScelte(identitaScelte.filter(
          (funzione) => funzione.sequ_k_identita !== row.sequ_k_identita,
        ));
      }
    },

  };
  return selectRow;
};
