/* eslint-disable prefer-template */
import React from 'react';
import {
  Form, Row, Button, Col, Accordion,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { controlla } from '../../utils/verificaPermessi';

function FiltriRicercaForum({
  filtri,
  onChange,
  getSezioni,
}) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <Col className="mb-3">
                <Form.Group as={Col} lg controlId="titolo">
                  <Form.Label>Titolo</Form.Label>
                  <Form.Control placeholder="Inserisci il titolo" onChange={onChange} value={filtri.titolo} />
                </Form.Group>
              </Col>
              <Col className="mb-3">
                <Form.Group as={Col} lg controlId="descrizione">
                  <Form.Label>Descrizione</Form.Label>
                  <Form.Control placeholder="Inserisci la descrizione" onChange={onChange} value={filtri.descrizione} />
                </Form.Group>
              </Col>
              {controlla('W2') && (
              <Col>
                <Form.Check
                  type="switch"
                  id="profonditaStorica"
                  label="Visualizza profondità storica"
                  onChange={(e) => onChange(e, 'checkbox')}
                  value={filtri.profonditaStorica}
                />
              </Col>
              )}
            </Row>
          </Form>
          <Button variant="primary" onClick={() => { getSezioni(0, 5); }}>
            <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
            CERCA
          </Button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriRicercaForum;
