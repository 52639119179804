import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React } from 'react';
import {
  Button,
  Col, Form, Row,
} from 'react-bootstrap';
import { formatoData } from '../../utils/formatData';

function FormInformazioni({
  onChangeForm, nuovoRuolo, tagsRuolo, disabled, disabledNome, handleModaleNewTagRuolo,
}) {
  return (
    <>
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="desc_nome_ruolo">
            <Form.Label>Nome ruolo*</Form.Label>
            <Form.Control
              placeholder="Inserisci il nome del ruolo"
              onChange={onChangeForm}
              value={nuovoRuolo.desc_nome_ruolo}
              disabled={disabled || disabledNome}
            />
          </Form.Group>

          <Form.Group as={Col} lg controlId="desc_ruolo">
            <Form.Label>Descrizione ruolo*</Form.Label>
            <Form.Control
              placeholder="Inserisci la descrizione del ruolo"
              onChange={onChangeForm}
              value={nuovoRuolo.desc_ruolo}
              disabled={disabled}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="desc_note_ruolo">
            <Form.Label>Note ruolo</Form.Label>
            <Form.Control
              placeholder="Inserisci le note del ruolo"
              onChange={onChangeForm}
              value={nuovoRuolo.desc_note_ruolo}
              disabled={disabled}
            />
          </Form.Group>
          <Form.Group as={Col} lg controlId="flag_default">
            <Form.Label>Ruolo di default?*</Form.Label>
            <Form.Select
              onChange={onChangeForm}
              value={nuovoRuolo.flag_default}
              disabled={disabled}
            >
              <option value="true">Sì</option>
              <option value="false">No</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="col-md-6" controlId="data_inizio_validita">
            <Form.Label>Data inizio validità</Form.Label>
            <Form.Control
              value={formatoData(nuovoRuolo.data_inizio_validita)}
              disabled
            />
          </Form.Group>
          {nuovoRuolo.data_fine_validita && (
          <Form.Group as={Col} lg controlId="data_fine_validita">
            <Form.Label>Data fine validità</Form.Label>
            <Form.Control
              value={formatoData(nuovoRuolo.data_fine_validita)}
              disabled
            />
          </Form.Group>
          )}
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="fk_sequ_tag_ruolo" className="col-lg-6">
            <Form.Label>Tag*</Form.Label>
            <Form.Select
              onChange={onChangeForm}
              value={nuovoRuolo.fk_sequ_tag_ruolo}
              disabled={disabled}
            >
              <option> </option>
              {tagsRuolo.map((tag) => (
                <option
                  key={tag.sequ_k_tag_ruolo}
                  value={tag.sequ_k_tag_ruolo}
                >
                  {tag.desc_nome_tag_ruolo}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {!disabled
            ? (
              <Col className="d-flex align-items-end">
                <Button variant="primary" onClick={handleModaleNewTagRuolo}>
                  <FontAwesomeIcon aria-hidden="true" icon="plus" />
                  {' '}
                  CREA TAG
                </Button>
              </Col>
            ) : null }
        </Row>
      </Form>
    </>
  );
}

export default FormInformazioni;
