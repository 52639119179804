import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getTagRuoli(filtri, offset, limit) {
  const params = createQueryString({ ...filtri, offset, limit });

  return api.get(`${accreditamentoUrl}/gestione-tag-ruolo?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoTagRuolo(data) {
  return api.post(`${accreditamentoUrl}/gestione-tag-ruolo`, data)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function cancellaTagRuolo(id) {
  return api.delete(`${accreditamentoUrl}/gestione-tag-ruolo/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const tagRuoliService = {
  getTagRuoli,
  nuovoTagRuolo,
  cancellaTagRuolo,
};

export default tagRuoliService;
