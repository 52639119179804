/** Restituisce la query string per i parametri inviati
 * @param filters - (Object) L'oggetto contenente i filtri
 * @returns (String) la query string se i filtri hanno valori accettabili, altrimenti stringa vuota
 */
export default function createQueryString(filters) {
  if (filters) {
    return Object.keys(filters)
      .filter((key) => filters[key] !== null && filters[key] !== undefined && filters[key] !== '')
      .map((key) => `${key}=${filters[key]}`).join('&');
  }
  return '';
}
