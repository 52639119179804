import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useCookies } from 'react-cookie';
import SpidButton from '../../components/SpidButton';
import LoginSviluppoModal from '../../components/LoginSviluppoModal';
import TitlePage from '../../components/GestisciUtenti/TitoloPagina';
import logoAgidLogin from '../../images/agid-login-logo-white.svg';
import { clear } from '../../utils/storage';

const accessoConAgidLogin = process.env.REACT_APP_ACCESSO_AGID_LOGIN;
const accessoSviluppo = process.env.REACT_APP_ACCESSO_SVILUPPO;
const agidLoginUrl = process.env.REACT_APP_AGID_LOGIN_URL;
const agidLoginClientId = process.env.REACT_APP_AGID_LOGIN_CLIENT_ID;
const agidLoginUriPostLogin = process.env.REACT_APP_AGID_LOGIN_REDIRECT_URI_POST_LOGIN;

const Login = () => {
  const [modalShow, setModalShow] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  useEffect(() => {
    clear();
    removeCookie('accessoOspite');
  }, []);
  return (
    <>
      <TitlePage title="Pagina di accesso" subtitle="Scegli uno dei metodi di autenticazione presenti." />
      <Card className="m-5">
        <Card.Body>
          <Card.Title>Accedi con identità digitale</Card.Title>
          {accessoConAgidLogin === 'false'
            ? <SpidButton />
            : (
              <a className="italia-it-button italia-it-button-size-m button-spid ms-2" href={`${agidLoginUrl}/auth?client_id=${agidLoginClientId}&redirect_uri=${agidLoginUriPostLogin}&response_type=code&scope=openid+profile&state=test`}>
                <img
                  className="logo-header"
                  src={logoAgidLogin}
                  alt="AgID Login"
                  style={{
                    height: '46.031px',
                  }}
                />
              </a>
            )}
        </Card.Body>
      </Card>
      {accessoSviluppo === 'true'
        ? (
          <>
            <Card className="m-5">
              <Card.Body>
                <Card.Title>Accedi con codice identificativo (SVILUPPO)</Card.Title>
                <Button variant="primary" onClick={() => setModalShow(true)}>
                  Accedi
                </Button>
              </Card.Body>
            </Card>
            <LoginSviluppoModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </>
        )
        : null}
    </>
  );
};

export default Login;
