/* eslint-disable no-underscore-dangle */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AutorizzazioniForum from '../../../../components/Forum/AutorizzazioniForum';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import ModaleRicercaUtenti from '../../../../components/Repository/ModaleRicercaUtenti';
import canaliService from '../../../../services/canaliService';
import sezioneService from '../../../../services/sezioneService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

function ModificaSezione() {
  const [context, setContext] = useContext(Context);
  const { idSezione } = useParams();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [editedSezione,
    seteditedSezione,
  ] = useState({
    autorizzazioni: [],
    descrizione: '',
    titolo: '',
    inEvidenza: false,
  });
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [identitaScelta, setIdentitaScelta] = useState();
  const [listaIdentita, setListaIdentita] = useState([]);
  const [indexAutorizzazioneScelta, setIndexAutorizzazioneScelta] = useState();

  const onChangeAutorizzazioni = (prop, event, index, type) => {
    const old = editedSezione.autorizzazioni[index];
    const updated = { ...old, [prop]: type === 'int' ? parseInt(event.target.value, 10) : event.target.value };
    const clone = [...editedSezione.autorizzazioni];
    clone[index] = updated;
    seteditedSezione({
      ...editedSezione,
      autorizzazioni: clone,
    });
  };

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  const handleCloseModaleUtenti = () => setShowModaleUtenti(!showModaleUtenti);

  async function onChange(e, type) {
    const { id, value, checked } = e.target;
    seteditedSezione({
      ...editedSezione,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function aggiungiRow() {
    seteditedSezione({
      ...editedSezione,
      autorizzazioni: [
        ...editedSezione.autorizzazioni,
        {
          idIdentita: 0,
          codiceFiscale: null,
        },
      ],
    });
  }

  function eliminaRow(index) {
    seteditedSezione({
      ...editedSezione,
      autorizzazioni: [
        ...editedSezione.autorizzazioni.filter((el, indexEl) => indexEl !== index)],
    });
  }

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  async function getSezione() {
    seteditedSezione(await sezioneService.getSezioneById(idSezione));
  }

  async function editSezione() {
    await sezioneService.editSection(editedSezione._id,
      editedSezione)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  useEffect(() => {
    getSezione();
    getListaIdentita();
  }, []);

  return (
    <div>
      <TitlePage
        title="Forum"
        subtitle="Modifica sezione esistente"
        showIndietro
        functionIndietro={() => history.push('/area-riservata/forum')}
      />
      <Form className="mt-3">

        <Row>
          <Col className="my-3">
            <Form.Check
              type="switch"
              id="inEvidenza"
              label="Sezione in evidenza"
              onChange={(e) => onChange(e, 'checkbox')}
              checked={editedSezione?.inEvidenza}
            />
          </Col>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="titolo">
            <Form.Label>Titolo *</Form.Label>
            <Form.Control
              placeholder="Inserisci il titolo dell'articolo"
              onChange={onChange}
              value={editedSezione
                .titolo}
              disabled
            />
          </Form.Group>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="descrizione">
            <Form.Label>Descrizione *</Form.Label>
            <Form.Control
              placeholder="Inserisci la descrizione dell'articolo"
              onChange={onChange}
              value={editedSezione
                .descrizione}
            />
          </Form.Group>
        </Row>

      </Form>
      <div className="separator border-1 border-bottom" />
      <Row>
        <Col>
          <p className="h4" style={{ lineHeight: 1.6 }}>
            Regole di visibilità
          </p>
        </Col>
      </Row>
      <AutorizzazioniForum
        autorizzazioni={editedSezione.autorizzazioni}
        listaIdentita={listaIdentita}
        aggiungiRow={aggiungiRow}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        handleCloseModaleUtenti={handleCloseModaleUtenti}
        setIndexAutorizzazioneScelta={setIndexAutorizzazioneScelta}
        eliminaRow={eliminaRow}
        setIdentitaScelta={setIdentitaScelta}
      />
      <Button
        variant="primary"
        className="text-uppercase float-end"
        onClick={handleStateModaleConferma}
        disabled={!(editedSezione
          .titolo && editedSezione
          .descrizione)}
      >
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        {' '}
        Modifica
      </Button>

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione modificherà la sezione: ${editedSezione.titolo}`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={editSezione}
      />
      {showModaleUtenti && (
      <ModaleRicercaUtenti
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        idIdentita={identitaScelta}
        listaIdentita={listaIdentita}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        indexAutorizzazioneScelta={indexAutorizzazioneScelta}
      />
      )}
    </div>
  );
}

export default ModificaSezione;
