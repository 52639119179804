/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import canaliService from '../../services/canaliService';
import chatService from '../../services/chatService';
import newsletterService from '../../services/newsletterService';
import { Context } from '../../utils/Context';
import history from '../../utils/history';
import { client } from '../../utils/matrix';
import FiltriRicercaUtentiChat from '../Chat/NewPeopleModal/FiltriRicercaUtentiChat';
import Table from '../Shared/Table';
import { fieldsUtenti, selectRows } from './fieldsUtenti';

const NewsletterModal = ({
  show,
  handleClose,
  listInfo,
}) => {
  const [context, setContext] = useContext(Context);
  const [listaIdentita, setListaIdentita] = useState([]);
  const [listaUtenti, setListaUtenti] = useState([]);
  const [utentiScelti, setUtentiScelti] = useState(listInfo.listmonkUserId.map(
    (el) => ({ codi_listmonk_user_id: el.id }),
  ));
  const [utentiSceltiCopia, setUtentiSceltiCopia] = useState(listInfo.listmonkUserId.map(
    (el) => ({ codi_listmonk_user_id: el.id }),
  ));
  const [filtri, setFiltri] = useState({
    testo: '',
    identita: null,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  async function getListaUtenti(offset, limit, page) {
    const response = await canaliService.getUtentiByCanali(filtri, offset, limit);
    setListaUtenti(response.data);
    setPagination((prevPag) => ({
      ...prevPag,
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    }));
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
  };

  async function aggiungiUtentiInCoda() {
    const body = utentiScelti
    // Filtro per distinguere gli utenti già aggiunti da quelli scelti
      .filter((el) => el.codi_id_fiscale)
      .map((el) => el.codi_listmonk_user_id);

    await newsletterService.aggiungiUtentiInCoda(body, Number(listInfo.codi_k_id_lista))
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        history.push(`/area-riservata/newsletter/utenti/${listInfo.codi_k_id_lista}`);
      });
  }

  useEffect(async () => {
    await getListaIdentita();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="xl"
        role="dialog"
        aria-labelledby="modal-room-creation"
      >
        <Modal.Header closeButton closeLabel="Chiudi">
          <Modal.Title>Seleziona gli utenti da aggiungere alla lista</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FiltriRicercaUtentiChat
            listaIdentitaCanali={listaIdentita}
            getListaUtenti={getListaUtenti}
            onChangeFiltriUtenti={onChangeFiltri}
          />
          <Table
            title="Ricerca utenti"
            data={listaUtenti}
            fields={fieldsUtenti}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="codi_listmonk_user_id"
            selectRow={selectRows(setUtentiScelti, utentiScelti, utentiSceltiCopia)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="grey" onClick={handleClose}>
            Chiudi
          </Button>
          {/* disabled={utentiScelti?.length === utentiSceltiCopia?.length} */}
          <Button variant="primary" onClick={aggiungiUtentiInCoda}>
            Aggiungi
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default NewsletterModal;
