import React from 'react';
import TitlePage from '../components/GestisciUtenti/TitoloPagina';

const NoteLegali = () => (
  <>
    <TitlePage title="Note legali" subtitle="Ultimo aggiornamento 10-11-2021" />
  </>
);

export default NoteLegali;
