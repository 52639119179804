/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Card, Col, Row, Button,
} from 'react-bootstrap';

function CardGroupDocument({
  data, functionDelete,
}) {
  return (

    <div className="mt-3">
      <Row lg={1} xs={1} className="g-4 mt-2 mb-2">
        {Object.values(data).map((el) => (
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>
                  {el.nome}
                  <Button
                    className="float-end"
                    variant="danger"
                    onClick={() => {
                      functionDelete(el);
                    }}
                    title={`Elimina la sezione ${el}`}
                  >
                    <FontAwesomeIcon aria-hidden="true" icon="trash" />
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

  );
}

export default CardGroupDocument;
