import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';
import { formatoData } from '../../../../utils/formatData';
import { controlla } from '../../../../utils/verificaPermessi';

const fieldsProfonditaStorica = (actions) => [
  {
    dataField: 'sequ_k_tag_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome_tag_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
  },
  {
    dataField: 'desc_tag_identita',
    text: 'Descrizione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_note_tag_identita',
    text: 'Note',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'flag_protetto',
    text: 'Protetto',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
    formatter: (e) => (e ? 'Sì' : 'No'),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
      }}
      >
        {controlla('X15') && !row.flag_protetto && !row.data_fine_validita ? (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash-alt']}
            size="lg"
            title={`Elimina TAG ${row.desc_nome_tag_identita}`}
            id={`elimina-${row.sequ_k_tag_identita}`}
            style={{ color: themeColors.primary }}
            onClick={() => actions.delete(row.sequ_k_tag_identita)}
          />
        ) : null }
      </div>

    ),
  },
];

const fieldsNoProfonditaStorica = (actions) => [
  {
    dataField: 'sequ_k_tag_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome_tag_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_tag_identita',
    text: 'Descrizione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_note_tag_identita',
    text: 'Note',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
  },
  {
    dataField: 'flag_protetto',
    text: 'Protetto',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
    formatter: (e) => (e ? 'Sì' : 'No'),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
      }}
      >
        {controlla('X16') && !row.flag_protetto && !row.data_fine_validita ? (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash-alt']}
            size="lg"
            title={`Elimina TAG ${row.desc_nome_tag_identita}`}
            id={`elimina-${row.sequ_k_tag_identita}`}
            style={{ color: themeColors.primary }}
            onClick={() => actions.delete(row.sequ_k_tag_identita)}
          />
        ) : null }
      </div>

    ),
  },
];

export { fieldsProfonditaStorica, fieldsNoProfonditaStorica };
