import api from '../utils/api';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

async function getAlbero(idVersione) {
  return api.get(`${sitiWebUrl}/siti-web/gestione-albero${idVersione ? `?idVersione=${idVersione}` : ''}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function upsertAlbero(albero) {
  return api.post(`${sitiWebUrl}/siti-web/gestione-albero`, { albero })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getAlberoAmmTrasparente(idVersione) {
  return api.get(`${sitiWebUrl}/amm-trasparente/gestione-albero${idVersione ? `?idVersione=${idVersione}` : ''}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function upsertAlberoAmmTrasparente(albero) {
  return api.post(`${sitiWebUrl}/amm-trasparente/gestione-albero`, { albero })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const sitiWebService = {
  getAlbero,
  upsertAlbero,
  getAlberoAmmTrasparente,
  upsertAlberoAmmTrasparente,
};

export default sitiWebService;
