import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import Table from '../../../../components/Shared/Table';
import contenutiDinamiciService from '../../../../services/contenutiDinamiciService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';
import fields from './fieldsTable';

const GestioneEntitaDinamica = () => {
  const { nome } = useParams();
  const [context, setContext] = useContext(Context);
  const [lista, setLista] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  // eslint-disable-next-line no-unused-vars
  const [filtri, setFiltri] = useState({
    entita: nome,
  });
  const [contenutoScelto, setContenutoScelto] = useState();
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function getLista(offset, limit, page) {
    try {
      const response = await contenutiDinamiciService
        .getListaContenutiDinamici(filtri, offset, limit);
      setLista(response.contenuti);
      setPagination({
        page,
        sizePerPage: limit,
        totalSize: response.totalRows,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getLista(currentIndex, sizePerPage, page);
  };

  async function eliminaContenuto() {
    try {
      const response = await contenutiDinamiciService.eliminaContenutoDinamico(contenutoScelto._id);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      getLista(0, 5, 1);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    } finally {
      handleStateModaleConferma();
    }
  }

  useEffect(() => {
    getLista(0, 5, 1);
  }, []);

  return (
    <>
      <TitlePage
        title={`Gestione sezione "${nome}"`}
        subtitle="È possibile gestire la sezione dinamica scelta."
        showIndietro
        functionIndietro={() => history.goBack()}
        showAdd
        functionAdd={() => history.push(`/area-riservata/siti-web/contenuti-dinamici/${nome}/crea`)}
      />
      <Table
        title="Elenco"
        data={lista}
        fields={fields(handleStateModaleConferma, setContenutoScelto)}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="_id"
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione eliminerà il contenuto selezionato'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={eliminaContenuto}
      />
    </>
  );
};

export default GestioneEntitaDinamica;
