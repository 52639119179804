export const fields = [
  {
    dataField: 'sequ_k_funzione',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome_funzione',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '50%' }),
  },
  {
    dataField: 'desc_nome_tag_funzione',
    text: 'TAG',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
];

export const selectRows = (setFunzioniScelte, funzioniScelte, funzioniRimosse,
  setFunzioniRimosse,
  funzioniAggiunte,
  setFunzioniAggiunte) => {
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...funzioniScelte]
      .filter((el) => !el.data_fine_validita)
      .map((el) => el.sequ_k_funzione),
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFunzioniAggiunte([...funzioniAggiunte, row]);
        setFunzioniScelte([...funzioniScelte, row]);
      } else {
        setFunzioniRimosse([...funzioniRimosse, row]);
        setFunzioniScelte(funzioniScelte.filter(
          (funzione) => funzione.sequ_k_funzione !== row.sequ_k_funzione,
        ));
      }
    },

  };
  return selectRow;
};
