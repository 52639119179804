import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.css';
import avatar from '../../../images/default-avatar.jpg';
import { getItem } from '../../../utils/storage';

function Header() {
  return (
    <div>
      <Row className="justify-content-center mt-3">
        <Col className="col-auto">
          <img className={`${styles.img}`} alt="Foto profilo" src={avatar} />
        </Col>
        <Col className="col-auto">
          <h1>
            Salve
            {' '}
            {(JSON.parse(getItem('user')).desc_nome)}
            {' '}
            {(JSON.parse(getItem('user')).desc_cognome)}
          </h1>
          <h2>{'Scegli l\'identità con cui lavorare nel sistema.'.toUpperCase()}</h2>
          <h3 className="font-weight-normal lead">Puoi scegliere l&apos;identità di default selezionandola.</h3>
        </Col>
      </Row>
    </div>
  );
}

export default Header;
