import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';
import { formatoData } from '../../../../utils/formatData';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';

const fields = () => [
  {
    dataField: 'sequ_k_richiesta',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'codi_id_fiscale',
    text: 'CF Richiedente',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome',
    text: 'Nome richiedente',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_cognome',
    text: 'Cognome richiedente',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_msg_richiesta',
    text: 'Richiesta',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'data_richiesta',
    text: 'Data',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'codi_stato_richiesta',
    text: 'Stato',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => (e === 'A' ? 'In attesa' : e === 'C' ? 'Presa in carico' : 'Evasa'),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
      }}
      >

        {controlla('X18') && (
        <FontAwesomeIcon
          aria-hidden="true"
          icon={row.codi_stato_richiesta === 'A' || row.codi_stato_richiesta === 'C' ? ['fas', 'cogs'] : ['fas', 'search']}
          size="lg"
          title={row.codi_stato_richiesta === 'A' || row.codi_stato_richiesta === 'C' ? 'Gestisci' : 'Visualizza'}
          id={`Visualizza${row.sequ_k_richiesta}`}
          style={{ color: themeColors.primary }}
          onClick={() => history.push(`/area-riservata/amministrazione/gestione-richieste-identita/${row.sequ_k_richiesta}`)}
        />
        ) }
        {controlla('X17') && row.codi_stato_richiesta === 'C'
         && (
         <FontAwesomeIcon
           aria-hidden="true"
           icon={['fas', 'user-edit']}
           size="lg"
           title="Modifica utente"
           id={`Modifica utente${row.codi_id_fiscale}`}
           style={{ color: themeColors.primary }}
           onClick={() => history.push(`/area-riservata/amministrazione/gestione-utenti/${row.fk_sequ_utente_trusted}/modifica?trusted=true`)}
         />
         )}
      </div>

    ),
  },
];

export default fields;
