import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';

const fields = (eliminaRow) => [{
  dataField: 'id',
  text: 'ID',
  editable: false,
}, {
  dataField: 'chiave',
  text: 'Chiave',
}, {
  dataField: 'etichetta',
  text: 'Etichetta',
},
{
  dataField: 'df1',
  isDummyField: true,
  text: 'Azioni',
  headerStyle: () => ({ width: '10%' }),
  formatter: (e, row) => (
    <FontAwesomeIcon
      title={`Elimina il tag ${row.chiave}`}
      icon={['fas', 'trash']}
      size="lg"
      id={`elimina-${row.chiave}`}
      style={{ color: themeColors.danger }}
      onClick={() => eliminaRow(row.id)}
    />
  ),
},
];

export default fields;
