/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import RenderHTML from '../../../components/Shared/RenderHTML';
import { client } from '../../../utils/matrix';

const Notifiche = () => {
  const [eventoUltimaLettura, setEventoUltimaLettura] = useState();
  const [rooms, setRooms] = useState([]);
  const [roomNotifiche, setRoomNotifiche] = useState();
  const [moreEvents, setMoreEvents] = useState(true);

  useEffect(() => {
    client?.on('event', () => {
      setRooms(client?.getRooms());
    });

    const roomNotifications = client?.getRooms().filter((el) => el.name === 'Notifiche')?.[0];
    setRoomNotifiche(roomNotifications);

    // Verifica se l'utente fa parte della room dedicata alle notifiche, altrimenti lo fa entrare
    if (roomNotifications?.selfMembership === 'invite') {
      client?.joinRoom(roomNotifications?.roomId, { syncRoom: true });
    }

    // Salvataggio dell'ultima notifica di lettura per impostare il componente grafico
    setEventoUltimaLettura(roomNotifications?.receipts['m.read'][client?.getUserId()]?.eventId);

    // Invio notifica di lettura
    setTimeout(() => {
      const eventsLength = roomNotifications?.getLiveTimeline().getEvents().length;
      if (roomNotifications) {
        client?.sendReadReceipt(
          roomNotifications?.getLiveTimeline()?.getEvents()?.[eventsLength - 1],
        );
      }
    }, 1000);
  }, []);

  // Funzione di scrollBack per caricare le notifiche meno recenti
  async function scrollBack() {
    const roomAggiornata = await client.scrollback(client.getRoom(roomNotifiche.roomId), 5);
    setMoreEvents(!!roomAggiornata?.oldState?.paginationToken);
    setRooms(client?.getRooms());
  }

  return (
    <>
      <TitlePage
        title="Notifiche"
        subtitle="Di seguito puoi trovare le notifiche."
      />
      {roomNotifiche && roomNotifiche
        .getLiveTimeline()
        .getEvents()
        .filter((el) => el.getContent()?.body?.includes('"titolo\\"'))
        .reverse()
        .map((el, index) => (
          <div key={el.getId()}>
            {el.getId() === eventoUltimaLettura && index !== 0 ? <hr className="readMarker" /> : null}
            <Card className="mb-2">
              <Card.Header as="h5">{(JSON.parse(JSON.parse(el.getContent().body))).titolo}</Card.Header>
              <Card.Body>
                <Card.Title as="h6">{(JSON.parse(JSON.parse(el.getContent().body))).sottotitolo}</Card.Title>
                <div>
                  <RenderHTML html={(JSON.parse(JSON.parse(el.getContent().body))).corpo} />
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      <div className="text-center">
        {moreEvents
          ? (
            <Button onClick={scrollBack}>
              Carica altre notifiche
            </Button>
          )
          : null }
      </div>
    </>
  );
};

export default Notifiche;
