const Regex = {
  cf: /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/,
  mail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  urlSenzaHttp: /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/,
  urlConHttp: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/,
  numeroItaliano: /^[\\+]?[(]?[0-9 ]{3}[)]?[-\s\\.]?[0-9 ]{3}[-\s\\.]?[0-9 ]{0,6}$/,
  pIva: /^[0-9]{11}$/,
  relativeUrl: /\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/,

};

module.exports = Regex;
