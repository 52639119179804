/* eslint-disable react/no-array-index-key */
/* eslint-disable global-require */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import spidIcoCircleBB from './img/spid-ico-circle-bb.png';
import spidList from '../../utils/spid_list_config';

const images = require.context('./img/', true);
const loadImage = (imageName) => (images(`./${imageName}`).default);

const SpidButton = () => {
  const randomProvider = (providerSpidList) => {
    let j; let x; let
      i;
    for (i = providerSpidList.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = providerSpidList[i];
      providerSpidList[i] = providerSpidList[j];
      providerSpidList[j] = x;
    }
    return providerSpidList;
  };

  return (
    <>
      <a href="#" className="italia-it-button italia-it-button-size-m button-spid" spid-idp-button="#spid-idp-button-medium-get" aria-haspopup="true" aria-expanded="false">
        <span className="italia-it-button-icon"><img src={spidIcoCircleBB} alt="Logo SPID" aria-hidden="true" /></span>
        <span className="italia-it-button-text">Entra con SPID</span>
      </a>
      <div id="spid-idp-button-medium-get" className="spid-idp-button spid-idp-button-tip spid-idp-button-relative">
        {/* <div className='link-list-wrapper'> */}
        <ul id="spid-idp-list-medium-root-get" aria-labelledby="spid-idp" className="spid-idp-button-menu">
          {randomProvider(spidList).map((el, i) => (
            <li className="spid-idp-button-link" data-idp={el.name} key={i}>
              <a href={el.url}>
                <span className="spid-sr-only">{el.title}</span>
                <img src={loadImage(el.logo)} alt={el.title} style={{ boxSizing: 'content-box' }} />
              </a>
            </li>
          ))}
          <li className="spid-idp-support-link">
            <a href="https://po-int.sistinf.it/iam/Login1?entityID=http://10.50.201.195:8088&target=https://po-int.sistinf.it/login">
              IdP Locale
            </a>
          </li>
          <li className="spid-idp-support-link">
            <a href="https://10.201.0.195/iam/Login1?entityID=http://10.115.0.130:8088&target=https://10.201.0.195/login">
              Validatore Locale
            </a>
          </li>
          <li className="spid-idp-support-link">
            <a href="https://10.201.0.195/iam/Login1?entityID=https://validator.spid.gov.it&target=https://10.201.0.195/login">
              Validatore AgID
            </a>
          </li>
          <li className="spid-idp-support-link">
            <a href="https://www.spid.gov.it">Maggiori informazioni</a>
          </li>
          <li className="spid-idp-support-link">
            <a href="https://www.spid.gov.it/richiedi-spid">Non hai SPID?</a>
          </li>
          <li className="spid-idp-support-link">
            <a href="https://www.spid.gov.it/serve-aiuto">Serve aiuto?</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SpidButton;
