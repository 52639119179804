import React, {
  useContext, useEffect, useState,
} from 'react';
import { Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import entitaTrasparenzaService from '../../../../services/entitaTrasparenzaService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';
import { options } from '../../../../utils/tableUtils';
import fields from './Tables/fieldsTableEntities';

export default function ContenutiDinamiciAmmTrasparente() {
  const [context, setContext] = useContext(Context);
  const [entities, setEntities] = useState([]);
  const [functionConferma, setFunctionConferma] = useState();
  const [titleModaleConferma, setTitleModaleConferma] = useState('');
  const [openModaleConferma, setOpenModaleConferma] = useState(false);

  async function getEntities() {
    try {
      const response = await entitaTrasparenzaService.getListaEntita();
      setEntities(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function cloneEntity(id) {
    await entitaTrasparenzaService.clonaEntita(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getEntities();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setOpenModaleConferma(false);
      });
  }

  function handleClona(id) {
    setTitleModaleConferma('L operazione clonerà l\'entità selezionata');
    setFunctionConferma(() => () => cloneEntity(id));
    setOpenModaleConferma(true);
  }

  async function deleteEntity(id) {
    await entitaTrasparenzaService.eliminaEntita(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getEntities();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setOpenModaleConferma(false);
      });
  }

  function handleDelete(id) {
    setTitleModaleConferma(`L operazione eliminerà l'entità con id ${id}`);
    setFunctionConferma(() => () => deleteEntity(id));
    setOpenModaleConferma(true);
  }

  function updateData(id) {
    history.push(`/area-riservata/amm-trasparente/entita/${id}/aggiornamento-dati`);
  }

  function entitiesViewManager(id) {
    history.push(`/area-riservata/amm-trasparente/entita/${id}/gestione-visualizzazione`);
  }

  function attachmentManager(id, organizzazione) {
    history.push(`/area-riservata/amm-trasparente/entita/${id}/gestione-allegati?organizzazione=${organizzazione}`);
  }

  useEffect(() => {
    getEntities();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione entità Amministrazione Trasparente"
        subtitle="In questa sezione puoi visualizzare, creare e gestire le entità della versione corrente."
        showAdd
        showRefresh
        functionAdd={() => history.push('/area-riservata/amm-trasparente/entita/crea')}
        functionRefresh={getEntities}
      />
      <Col>
        <p className="fw-bold fs-3">
          Elenco entità
        </p>
      </Col>
      <BootstrapTable
        columns={fields(handleClona, handleDelete, attachmentManager,
          updateData, entitiesViewManager)}
        data={entities}
        keyField="sequ_k_entita"
        pagination={paginationFactory(options)}
      />

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={titleModaleConferma}
        show={openModaleConferma}
        handleClose={() => setOpenModaleConferma(false)}
        handleOk={functionConferma}
      />
    </>
  );
}
