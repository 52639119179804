import api from '../utils/api';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

async function getFile() {
  return api.get(`${sitiWebUrl}/file`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function uploadFile(fileInfo) {
  const formData = new FormData();
  formData.append('file', fileInfo.fileCaricato);
  formData.append('nome', fileInfo.nome);
  return api.post(`${sitiWebUrl}/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaFile(id) {
  return api.get(`${sitiWebUrl}/file/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const fileAmmTraspService = {
  getFile,
  uploadFile,
  eliminaFile,
};

export default fileAmmTraspService;
