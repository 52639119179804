const listmonkMediaUrl = process.env.REACT_APP_LISTMONK_MEDIA_URL;

export default function fromArrayToHtml(listaArticoli) {
  let html = '';
  listaArticoli.forEach((el) => {
    let articolo = '';
    articolo += '<div style="border-radius: 5px;border: 1px solid gray; width: 45%; margin: 5px">';
    articolo += `<h2 style="text-align: center;">${el.titolo}</h2>`;
    articolo += `<p style="padding: 10px;text-align: justify;">${el.testo}</p>`;
    if (el.nomeImmagine) {
      articolo += `<img heigth="200px" width="200px" src="${listmonkMediaUrl}/${el.nomeImmagine}" id="${el.nomeImmagine}">`;
    }
    articolo += '</div>';
    html += articolo;
  });
  return html;
}
