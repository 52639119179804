/* eslint-disable max-len */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import Title from '../../../../components/GestisciUtenti/TitoloPagina/index';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import gestionePiattaformaService from '../../../../services/gestionePiattaformaService';
import { Context } from '../../../../utils/Context';
import { formatoData } from '../../../../utils/formatData';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';

function GestionePiattaforma() {
  const [codiceStatoPiattaforma, setCodiceStatoPiattaforma] = useState();
  const [descrizione, setDescrizione] = useState();
  const [statiPiattaforma, setStatiPiattaforma] = useState([]);
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [value, setValue] = useContext(Context);

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function onChangeStatoPiattaforma(event) {
    setCodiceStatoPiattaforma(parseInt(event.target.value, 10));
  }

  async function cambiaStato() {
    gestionePiattaformaService.setStatoPiattaforma(codiceStatoPiattaforma, descrizione)
      .then((res) => {
        setValue(() => ({
          ...value, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setValue(() => ({
          ...value, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status,
        }));
      });
  }

  useEffect(async () => {
    setStatiPiattaforma(await gestionePiattaformaService.getStatiPiattaforma());
  }, []);

  return (
    <>
      <Title
        title="Gestione piattaforma"
        subtitle="È possibile gestire lo stato della piattaforma"
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione')}
      />
      Attualmente, la piattaforma si trova nello stato
      {' '}
      <strong className="text-lowercase">{statiPiattaforma.length && statiPiattaforma.find((statoPiattaforma) => statoPiattaforma.flag_stato_attuale).desc_stato}</strong>
      {' '}
      dal
      {' '}
      <strong>{formatoData(statiPiattaforma.length && statiPiattaforma.find((statoPiattaforma) => statoPiattaforma.flag_stato_attuale).data_stato_attuale)}</strong>
      {' '}
      con il messaggio
      {' '}
      <strong>{statiPiattaforma.length && statiPiattaforma.find((statoPiattaforma) => statoPiattaforma.flag_stato_attuale).desc_msg_stato}</strong>
      <br />
      <br />
      <Form.Group as={Col} lg>
        <Form.Label htmlFor="select">Scegli uno stato</Form.Label>
        <Form.Select id="select" onChange={onChangeStatoPiattaforma}>
          <option> </option>
          {statiPiattaforma.length && statiPiattaforma
            .filter((statoPiattaforma) => !statoPiattaforma.flag_stato_attuale)
            .map((statoPiattaforma) => (<option key={statoPiattaforma.codi_k_stato} value={statoPiattaforma.codi_k_stato}>{statoPiattaforma.desc_stato}</option>))}
        </Form.Select>
      </Form.Group>
      <Form.Group as={Col} lg controlId="cf">
        <Form.Label>Descrizione stato</Form.Label>
        <Form.Control placeholder="Inserisci la descrizione" as="textarea" value={descrizione} onChange={(e) => setDescrizione(e.target.value)} />
      </Form.Group>
      <br />
      <div className="d-flex justify-content-end">
        {controlla('X9') && (
        <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
          <FontAwesomeIcon aria-hidden="true" icon="save" />
          {' '}
          Salva
        </Button>
        ) }
      </div>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione cambierà lo stato della piattaforma'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={cambiaStato}
      />
    </>
  );
}

export default GestionePiattaforma;
