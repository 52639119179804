import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const forumUrl = process.env.REACT_APP_FORUM_URL;

async function getMessaggiById(filtro, id, offset, limit) {
  const filters = {
    ...filtro, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${forumUrl}/messaggio/sezione/${id}?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteMessaggio(id) {
  return api.delete(`${forumUrl}/messaggio/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function newMessaggio(body) {
  return api.post(`${forumUrl}/messaggio`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const messaggioService = {
  getMessaggiById,
  deleteMessaggio,
  newMessaggio,
};

export default messaggioService;
