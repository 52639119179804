/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import * as SurveyCreator from 'survey-creator-react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import italianTranslation from '../../utils/traduzione_ita_surveyJs';

SurveyCreator
  .localization
  .locales.it = italianTranslation;

SurveyCreator.localization.currentLocale = 'it';
// var editorDefinition = SurveyCreator.SurveyQuestionEditorDefinition.definition["survey"];
/* console.log(editorDefinition); */
// editorDefinition.tabs = [];
// editorDefinition.properties = ["showPageTitles"];

/* Survey.Serializer.findProperty("survey", "locale").visible = false;
Survey.Serializer.findProperty("survey", "mode").visible = false; */

function newSezioneSection({
  template, functionCancel, functionAdd, editedSezione, readOnly,
}) {
  const options = {
    showLogicTab: false,
    haveCommercialLicense: true,
    showJSONEditorTab: false,
    questionTypes: [
      'text',
      'checkbox',
      'radiogroup',
      'dropdown',
      'file',
    ],
    pageEditMode: 'single',
    showPagesToolbox: false,
    allowEditSurveyTitle: false,
  };

  const creator = new SurveyCreator.SurveyCreator(options);
  // Add event handlers, survey JSON and any customization code here:
  // ...
  const propertyStopList = [
    'renderMode',
    'bindings',
    'defaultValueExpression',
    'columnsVisibleIf',
    'rowsVisibleIf',
    'hideIfChoicesEmpty',
    'choicesVisibleIf',
    'choicesEnableIf',
    'minValueExpression',
    'maxValueExpression',
    'calculatedValues',
    'triggers',
    'page',
    'startWithNewLine',
    'hideNumber',
    'state',
    'titleLocation',
    'descriptionLocation',
    'indent',
    'width',
    'minWidth',
    'maxWidth',
    'size',
    'inputType',
    'minErrorText',
    'maxErrorText',
    'calculatedvalue@items',
    'title',
    'autoComplete',
    'dataList',
    'textUpdateMode',
    'valueName',
    'readOnly',
    'readonlyRenderAs',
    'useDisplayValuesInTitle',
    'defaultValue',
    'correctAnswer',
    'clearIfInvisible',
    'validators',
    'choicesByUrl',
    'description',
    'showTitle',
    'locale',
    'mode',
    'cookieName',
    'widthMode',
    'showPreviewBeforeComplete',
    'pagePrevText',
    'pageNextText',
    'completeText',
    'previewText',
    'editText',
    'startSurveyText',
    'showNavigationButtons',
    'showPrevButton',
    'firstPageIsStarted',
    'goNextPageAutomatic',
    'showProgressBar',
    'progressBarType',
    'questionsOnPageMode',
    'questionTitleLocation',
    'questionDescriptionLocation',
    // "showQuestionNumbers",
    'questionTitlePattern',
    'requiredText',
    'questionStartIndex',
    'questionErrorLocation',
    'focusFirstQuestionAutomatic',
    // "questionsOrder",
    'maxTextLength',
    'maxOthersLength',
    'autoGrowComment',
    'showPageTitles',
    'showPageNumbers',
    'calculatedValues',
    'triggers',
    'clearInvisibleValues',
    'textUpdateMode',
    'sendResultOnPageNext',
    'storeOthersAsComment',
    'focusOnFirstError',
    'checkErrorsMode',
    'navigateToUrl',
    'showCompletedPage',
    'completedHtml',
    'navigateToUrlOnCondition',
    'completedHtmlOnCondition',
    'loadingHtml',
    'completedBeforeHtml',
    'maxTimeToFinish',
    'maxTimeToFinishPage',
    'showTimerPanel',
    'showTimerPanelMode',
    'logo',
    'logoPosition',
    'logoWidth',
    'logoHeight',
    'logoFit',
  ];

  // Hide properties contained in the black list, show all other properties
  creator.onShowingProperty
    .add((sender, options) => {
      options.canShow = propertyStopList.indexOf(options.property.name) === -1;
    });

  creator.selectElement(creator.survey.getPage(0));

  creator.survey.locale = 'it';
  creator.toolbarItems.splice(2, 5);
  creator.sidebar.toolbar.actions.splice(1, 1);
  creator.isAutoSave = false;

  // creator prende le domande create in precedenza, se esistono
  creator.JSON = template;

  if (readOnly) creator.readOnly = true;

  return (
    <div>
      <Row>
        <Col>
          <p className="h4" style={{ lineHeight: '1.6' }}>
            {'Sezione: '}
            {editedSezione.titolo}
          </p>
        </Col>
      </Row>
      <SurveyCreator.SurveyCreatorComponent creator={creator} />
      {readOnly ? (
        <div className="my-2 float-end">
          <Button className="me-2" onClick={functionCancel}>
            <span>
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'arrow-left']} />
              {' '}
              Indietro
            </span>
          </Button>
        </div>
      )
        : (
          <div className="my-2 float-end">
            <Button className="me-2" variant="danger" onClick={functionCancel}>
              <span>
                <FontAwesomeIcon aria-hidden="true" icon="times-circle" />
                {' '}
                Annulla
              </span>
            </Button>
            <Button
              className="me-2"
              onClick={() => functionAdd(creator.JSON, creator.survey.getAllQuestions().length)}
            >
              <span>
                <FontAwesomeIcon aria-hidden="true" icon="plus" />
                {' '}
                Salva
              </span>
            </Button>
          </div>
        )}
    </div>
  );
}

export default newSezioneSection;
