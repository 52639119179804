/* eslint-disable import/prefer-default-export */
import { getItem } from './storage';

/**
 * La funzione verifica se è possibile visualizzare l'elemento dato un permesso
 * @param permesso - String - permesso da valutare
 * @returns - Boolean - true se sì, altrimenti false
 */
export function controlla(permesso) {
  const permessi = JSON.parse(getItem('permessi'));
  const user = JSON.parse(getItem('user'));
  const permessoTrovato = permessi?.filter((el) => el.codi_k_permesso === permesso);
  if (permessoTrovato?.length >= 1) {
    if (user?.trusted === false && permessoTrovato[0].flag_untrusted === true) {
      return true;
    }
    if (user?.trusted === true) {
      return true;
    }
  }
  return false;
}
