import {
  React, useContext, useEffect, useState,
} from 'react';
import {
  Tabs, Tab, Button, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import history from '../../../utils/history';
import fieldsListe from './fieldsListe';
import fieldsCampagne from './fieldsCampagne';
// import FiltriRicercaNewsletter from '../../../components/Newsletter/FiltriRicerca';
import newsletterService from '../../../services/newsletterService';
import { Context } from '../../../utils/Context';
import { options } from '../../../utils/tableUtils';
import campaignService from '../../../services/campaignService';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import { controlla } from '../../../utils/verificaPermessi';

function Newsletter() {
  const [context, setContext] = useContext(Context);
  const [listeDistribuzione, setListeDistribuzione] = useState([]);
  const [campagne, setCampagne] = useState([]);
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [idCampagnaScelta, setIdCampagnaScelta] = useState(null);
  const [idListaScelta, setIdListaScelta] = useState(null);
  const [openCloseModaleConfermaLista, setOpenCloseModaleConfermaLista] = useState(false);

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function handleStateModaleConfermaLista() {
    setOpenCloseModaleConfermaLista(!openCloseModaleConfermaLista);
  }

  // const [filtri, setFiltri] = useState({
  //   nome: '',
  // });

  // function onChangeFiltri(e, type) {
  //   const { value, id, checked } = e.target;
  //   setFiltri({
  //     ...filtri,
  //     [id]: type === 'checkbox' ? checked : value,
  //   });
  // }

  async function getListeDistribuzione() {
    try {
      const response = await newsletterService.getListeDistribuzione();
      setListeDistribuzione(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function getCampagneInformative() {
    try {
      const response = await campaignService.getListaCampagne();
      setCampagne(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function deleteLista() {
    await newsletterService.deleteLista(idListaScelta)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getListeDistribuzione();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConfermaLista();
      });
  }

  async function deleteCampagna() {
    await campaignService.eliminaCampagna(idCampagnaScelta)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getCampagneInformative();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  useEffect(() => {
    getListeDistribuzione();
    getCampagneInformative();
  }, []);

  return (
    <>
      <Title
        title="Newsletter e Mailinglist"
        subtitle="Sottotitolo newsletter"
        showRefresh
        functionRefresh={() => { getListeDistribuzione(); }}
      />
      {/* <FiltriRicercaNewsletter
        filtri={filtri}
        onChange={onChangeFiltri}
        getListeNewsletter={getListeDistribuzione}
      /> */}

      <Tabs defaultActiveKey="lista" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="lista" title="Liste distribuzione">

          {controlla('X72') && (
          <Row>
            <div className="col align-self-end">
              <Button className="float-end mb-2" onClick={() => history.push('/area-riservata/newsletter/lista')}>
                <FontAwesomeIcon aria-hidden="true" icon="plus" />
                {' '}
                AGGIUNGI LISTA DISTRIBUZIONE
                {' '}
              </Button>
            </div>
          </Row>
          )}
          {/* Qui viene utilizzato direttamente il componente perché non paginata */}
          <BootstrapTable
            defaultSorted={[{
              dataField: 'id',
              order: 'desc',
            }]}
            columns={fieldsListe(handleStateModaleConfermaLista, setIdListaScelta)}
            data={listeDistribuzione}
            keyField="id"
            pagination={paginationFactory(options)}
          />
        </Tab>
        <Tab eventKey="campagna" title="Campagne informative">
          {controlla('X76') && (
          <Row>
            <div className="col align-self-end">
              <Button className="float-end mb-2" onClick={() => history.push('/area-riservata/newsletter/campagna/creazione')}>
                <FontAwesomeIcon aria-hidden="true" icon="plus" />
                {' '}
                AGGIUNGI CAMPAGNA INFORMATIVA
                {' '}
              </Button>
            </div>
          </Row>
          )}
          {/* Qui viene utilizzato direttamente il componente perché non paginata */}
          <BootstrapTable
            defaultSorted={[{
              dataField: 'id',
              order: 'desc',
            }]}
            columns={fieldsCampagne(handleStateModaleConferma, setIdCampagnaScelta)}
            data={campagne}
            keyField="id"
            pagination={paginationFactory(options)}
          />
        </Tab>
      </Tabs>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione eliminerà la campagna selezionata'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={deleteCampagna}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione eliminerà la lista selezionata'}
        show={openCloseModaleConfermaLista}
        handleClose={handleStateModaleConfermaLista}
        handleOk={deleteLista}
      />
    </>
  );
}

export default Newsletter;
