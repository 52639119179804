/* eslint-disable prefer-template */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Accordion,
  Button,
  Col,
  Form,
  Image,
  Row,
} from 'react-bootstrap';
import styles from './FormImmagini.module.css';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

const FormImmagini = ({
  onChange, listaImmagini, disabled, versione, immagine, onUpload, onChangeAltImmagine,
  uploadImmagine,
}) => (
  <Accordion className="mb-3">
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        Scelta logo
      </Accordion.Header>
      <Accordion.Body>
        {disabled
          ? (
            <>
              <div>
                Il logo scelto è
              </div>
              <Image
                height="200"
                src={sitiWebUrl + '/galleria/' + versione.fk_logo_mongo_db}
                alt={versione.desc_filename_logo}
                className={styles.logoSelected}
              />
            </>
          )
          : (
            <>
              <strong>
                Si prega il logo dalla galleria qui sotto oppure aggiungendone uno nuovo.
              </strong>
              <Row>
                {listaImmagini?.length
                  ? listaImmagini?.map((el) => (
                    <Col className="m-1" key={el._id}>
                      <Image
                        key={el._id}
                        height="200"
                        src={sitiWebUrl + '/galleria/' + el._id}
                        alt={el.alt}
                        onClick={() => onChange(el)}
                        tabIndex={0}
                        className={versione.fk_logo_mongo_db === el._id ? styles.logoSelected : 'logo'}
                      />
                    </Col>
                  ))
                  : <p>Nessuna immagine presente.</p>}
              </Row>
              <div className="separator border-1 border-bottom" />
              <Form.Group className="my-3" as={Col} controlId="nomeImmagine">
                <Form.Label>Immagine*</Form.Label>
                <Form.Control value={immagine.nomeImmagine} type="file" size="sm" onChange={onUpload} />
                <Form.Text muted>
                  È possibile inserire soltanto file di dimensioni non superiori a 5 MB.
                  Sono supportati soltanto i seguenti formati: .jpg, .png, .gif
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="alt" className="mb-3">
                <Form.Label>Testo alternativo*</Form.Label>
                <Form.Control
                  placeholder="Inserisci il testo alternativo"
                  value={immagine.alt}
                  onChange={onChangeAltImmagine}
                  disabled={disabled}
                />
              </Form.Group>
              <Button disabled={immagine.nomeImmagine === '' || immagine.alt.trim() === ''} onClick={uploadImmagine}>
                <FontAwesomeIcon aria-hidden="true" icon={['fas', 'upload']} />
                CARICA
              </Button>
            </>
          )}
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
);

export default FormImmagini;
