import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';

const CampiDiRicerca = ({
  aggiungiCampoScelto, campiScelti, onChangeCampo, listaCampiTabella, eliminaCampoScelto,
}) => (
  <Card className="mb-3" body>
    <p className="h6">Campi di ricerca</p>
    <Button onClick={aggiungiCampoScelto}>
      <FontAwesomeIcon icon="plus" aria-hidden="true" />
      Aggiungi campo di ricerca
    </Button>
    {campiScelti.map((el, index) => (
      <Row key={`campo-${el.id}`} className="py-3 border-bottom">
        <Form.Group as={Col} lg controlId={`campo-${index}`}>
          <Form.Label>Campo</Form.Label>
          <Form.Select
            onChange={(e) => onChangeCampo(e, el.id)}
            value={el.campo}
          >
            <option> </option>
            {listaCampiTabella.map((campo) => (
              <option
                key={campo.nume_posizione}
                value={campo.desc_colonna}
              >
                {campo.desc_colonna}

              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} lg controlId={`tipologia-${index}`}>
          <Form.Label>Tipologia</Form.Label>
          <Form.Select
            onChange={(e) => onChangeCampo(e, el.id)}
            value={el.tipologia}
          >
            <option value=""> </option>
            <option value="Campo di testo">Campo di testo</option>
            <option value="Campo numerico">Campo numerico</option>
            <option value="Data">Data</option>
            <option value="Combo">Combo</option>
          </Form.Select>
        </Form.Group>
        <Col>
          {el.campo !== '' && (
            <Button variant="danger" onClick={() => eliminaCampoScelto(el.id)}>
              <FontAwesomeIcon icon="trash-alt" aria-hidden="true" />
              Elimina
            </Button>
          )}
        </Col>
      </Row>
    ))}
  </Card>
);

export default CampiDiRicerca;
