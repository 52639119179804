/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const ModaleEvasioneRichiesta = ({
  richiestaIdentita,
  onChange,
  evasione,
  handleStateModaleEvasione,
  show,
}) => (
  <Modal
    role="dialog"
    size="lg"
    centered
    show={show}
    onHide={handleStateModaleEvasione}
  >
    <Modal.Header closeButton closeLabel="Chiudi">
      <Modal.Title id="contained-modal-title-vcenter">
        Evasione richiesta
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group className="mb-3" controlId="desc_msg_risposta">
        <Form.Label>Risposta</Form.Label>
        <Form.Control type="text" placeholder="Inserisci risposta" value={richiestaIdentita.desc_msg_risposta} onChange={onChange} />
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="grey" onClick={handleStateModaleEvasione}>Chiudi</Button>
      <Button variant="primary" onClick={evasione}>Evadi</Button>
    </Modal.Footer>
  </Modal>
);

export default ModaleEvasioneRichiesta;
