import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import FormNomeAlias from '../../../../components/Versioni/FormNomeAlias';
import versioniService from '../../../../services/versioniService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

const ModificaVersioneAmmTrasparente = () => {
  const { idVersione } = useParams();
  const [context, setContext] = useContext(Context);
  const [versione, setVersione] = useState({
    desc_nome: '',
    desc_alias: '',
  });

  async function getEntita() {
    try {
      const versioneById = await versioniService.getVersione(idVersione);
      setVersione(
        versioneById,
      );
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  async function modificaVersione() {
    try {
      const response = await versioniService.modificaVersione(
        idVersione,
        versione,
      );
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChange(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setVersione({
      ...versione,
      [e.target.id]: value,
    });
  }

  useEffect(() => {
    getEntita();
  }, []);

  return (
    <>
      <TitlePage
        title="Modifica versione Amministrazione Trasparente"
        subtitle="È possibile modificare la versione dell'Amministrazione Trasparente."
        showIndietro
        functionIndietro={() => history.goBack()}
        showAvanti
        functionAvanti={() => { modificaVersione(); history.push(`/area-riservata/amm-trasparente/${idVersione}/albero`); }}
      />
      <p className="h5">Sezione 1:</p>
      <div className="h6">
        In questa prima sezione, è obbligatorio inserire:
        <ol>
          <li>il nome della versione, ovvero un nome mnemonico che si vuole dare alla versione;</li>
          <li>l&apos;alias del sito, ovvero l&apos;indirizzo di pubblicazione del sito;</li>
        </ol>
      </div>
      <p className="h6"> I campi contrassegnati con * sono obbligatori </p>
      <FormNomeAlias
        versione={versione}
        onChange={onChange}
      />
    </>
  );
};

export default ModificaVersioneAmmTrasparente;
