/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import React from 'react';
import {
  Button, Form, Modal, Row,
} from 'react-bootstrap';

function ModalRequest({
  showModalRequest, setShowModalRequest, request,
  titleModale, functionInvia,
}) {
  return (

    <Modal show={showModalRequest} onHide={() => setShowModalRequest(false)}>
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>
          {titleModale}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="my-3">
            <Form.Label>Note:</Form.Label>
            <Form.Control
              placeholder="Inserisci delle note"
              as="textarea"
              ref={request}
            />
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={() => setShowModalRequest(false)}>
          Chiudi
        </Button>
        <Button variant="primary" onClick={() => functionInvia()}>
          Invia
        </Button>
      </Modal.Footer>
    </Modal>

  );
}

export default ModalRequest;
