/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import CardGroupSezioni from '../../../components/Template/CardGroupSezioni';
import NewTemplateSection from '../../../components/Template/NewTemplateSection';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import { Context } from '../../../utils/Context';
import templateService from '../../../services/templateService';
import Autorizzazioni from '../../../components/Forum/AutorizzazioniForum';
import canaliService from '../../../services/canaliService';
import ModaleRicercaUtenti from '../../../components/Repository/ModaleRicercaUtenti';

function GestioneTemplate() {
  const { idTemplate } = useParams();
  const [context, setContext] = useContext(Context);
  const [showNewFormSezione, setShowNewFormSezione] = useState();
  const [showNewTemplateLayout, setShowNewTemplateLayout] = useState();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [testoModaleConferma, setTestoModaleConferma] = useState('');
  const [funzioneModaleConferma, setFunzioneModaleConferma] = useState();
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [identitaScelta, setIdentitaScelta] = useState();
  const [listaIdentita, setListaIdentita] = useState([]);
  const [indexAutorizzazioneScelta, setIndexAutorizzazioneScelta] = useState();
  // add e edit sezione usano lo stesso form
  const [addOrEdit, setAddOrEdit] = useState(true);
  const [newQuestionario, setNewQuestionario] = useState({
    titolo: '',
    descrizione: '',
    visibilita: [],
    sezioni: {},
    isEsterno: false,
    isTracciato: false,
  });
  const [newSezione, setNewSezione] = useState({
    titolo: '',
    descrizione: '',
    template: {},
  });
  const [editedSezione, setEditedSezione] = useState({
    titolo: '',
    descrizione: '',
    template: {},
  });

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  const handleCloseModaleUtenti = () => setShowModaleUtenti(!showModaleUtenti);

  /**
   * onChange per compilare i form (testo,descrizione)
  */
  async function onChangeTemplate(e) {
    const {
      id, value, checked, type,
    } = e.target;
    setNewQuestionario({
      ...newQuestionario,
      [id]: type === 'checkbox' ? checked : value,
    });

    // La riga di codice serve per fare "uncheck" dell'operazione C quando si fa uncheck di M
    if (document.getElementById('isEsterno').checked) {
      setNewQuestionario((prevState) => ({
        ...prevState,
        visibilita: [],
      }));
    } else {
      setNewQuestionario((prevState) => ({
        ...prevState,
        isTracciato: false,
      }));
    }
  }
  async function onChangeSezione(e) {
    const { id, value } = e.target;
    if (addOrEdit) {
      setNewSezione({
        ...newSezione,
        [id]: value,
      });
    } else {
      setEditedSezione({
        ...editedSezione,
        [id]: value,
      });
    }
  }

  async function getTemplate() {
    try {
      const response = await templateService.getTemplateById(idTemplate);
      setNewQuestionario(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  async function getSezioni() {
    try {
      const response = await templateService.getTemplateById(idTemplate);
      setNewQuestionario({
        ...newQuestionario,
        sezioni: response.sezioni,
      });
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  async function editTemplate() {
    try {
      const res = await templateService.editTemplate(idTemplate, newQuestionario);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function showNewFormSezioneHandle() {
    setShowNewFormSezione(!showNewFormSezione);
    setAddOrEdit(true);
    setNewSezione({
      titolo: '',
      descrizione: '',
      template: {},
    });
  }
  function showNewTemplateLayoutHandle() {
    setShowNewTemplateLayout(!showNewTemplateLayout);
  }

  /**
   * Buttons delle Sezioni (aggiungi domande, modifica, clona, elimina)
   */
  async function addSezione() {
    try {
      const res = await templateService.creaSezioneTemplate(newQuestionario._id, newSezione);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getSezioni();
      showNewFormSezioneHandle();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }
  async function deleteSezione(titoloSezione) {
    try {
      const res = await templateService.eliminaSezioneTemplate(newQuestionario._id,
        titoloSezione);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getSezioni();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }
  async function cloneSezione(titoloSezione) {
    try {
      const res = await templateService.clonaSezioneTemplate(newQuestionario._id,
        titoloSezione);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getSezioni();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }
  function handleEditSezione(el) {
    // age case form già aperto
    if (!showNewFormSezione) showNewFormSezioneHandle();
    setNewSezione(el);
    setEditedSezione(el);
    setAddOrEdit(false);
  }

  /**
   * Aggiunge le domande ,create nel layout NewTemplateSection.js, nella sezione scelta
   */
  async function modificaSezione() {
    try {
      const res = await templateService.modificaSezioneTemplate(newQuestionario._id,
        newSezione.titolo, editedSezione);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      setShowNewFormSezione(false);
      getSezioni();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function modificaTemplateSezione(newTemplate, numeroTotaleDomande) {
    editedSezione.totaleDomande = numeroTotaleDomande;
    editedSezione.template = newTemplate;
    try {
      const res = await templateService.modificaSezioneTemplate(newQuestionario._id,
        editedSezione.titolo, editedSezione);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      setShowNewTemplateLayout(false);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function aggiungiRow() {
    setNewQuestionario({
      ...newQuestionario,
      visibilita: [
        ...newQuestionario.visibilita,
        {
          idIdentita: 0,
          codiceFiscale: null,
        },
      ],
    });
  }

  function eliminaRow(index) {
    setNewQuestionario({
      ...newQuestionario,
      visibilita: [
        ...newQuestionario.visibilita.filter((el, indexEl) => indexEl !== index)],
    });
  }

  const onChangeAutorizzazioni = (prop, event, index, type) => {
    const old = newQuestionario.visibilita[index];
    const updated = { ...old, [prop]: type === 'int' ? parseInt(event.target.value, 10) : event.target.value };
    const clone = [...newQuestionario.visibilita];
    clone[index] = updated;
    setNewQuestionario({
      ...newQuestionario,
      visibilita: clone,
    });
  };

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  useEffect(() => {
    getTemplate();
    getListaIdentita();
  }, []);

  return (
    <div>
      <TitlePage
        title="Template"
        subtitle="Gestione template"
        showIndietro
        functionIndietro={!showNewTemplateLayout ? () => history.push('/area-riservata/template') : showNewTemplateLayoutHandle}
        showSave
        functionSave={editTemplate}
      />
      {!newQuestionario.visibilita.length && !newQuestionario.isEsterno
        ? (
          <Alert variant="warning">
            Si ricorda che, all&apos;atto della pubblicazione e del ritiro,
            sarà inviata una notifica a tutti gli utenti dell&apos;organizzazione
            presenti in piattaforma, dato che si tratta di un questionario interno e non è stata
            effettuata alcuna scelta di visibilità.
          </Alert>
        ) : null }
      {!showNewTemplateLayout ? (
        <div>
          <Row>
            <Col>
              <p className="h4" style={{ lineHeight: '1.6' }}>
                Informazioni principali
              </p>
            </Col>
          </Row>
          <Form className="mt-1">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="titolo">
                <Form.Label>Titolo *</Form.Label>
                <Form.Control placeholder="Inserisci il titolo del template" onChange={onChangeTemplate} value={newQuestionario.titolo} />
              </Form.Group>
            </Row>

            <Row className="my-3">
              <Form.Group as={Col} lg controlId="descrizione">
                <Form.Label>Descrizione *</Form.Label>
                <Form.Control placeholder="Inserisci la descrizione del template" onChange={onChangeTemplate} value={newQuestionario.descrizione} />
              </Form.Group>
            </Row>
          </Form>
          <div className="separator border-1 border-bottom" />
          <Row>
            <Col>
              <p className="h4" style={{ lineHeight: 1.6 }}>
                Regole di visibilità
              </p>
              <p className="h6" style={{ lineHeight: 1.6 }}>
                Si può scegliere se il template potrà essere compilato dagli utenti
                in piattaforma o da utenti esterni. In quest&apos;ultimo caso, si può scegliere
                se farlo compilare una sola volta o meno.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="switch"
                id="isEsterno"
                label="Il questionario è esterno?"
                onChange={onChangeTemplate}
                value={newQuestionario.isEsterno}
                checked={newQuestionario.isEsterno}
              />
            </Col>
            <Col>
              <Form.Check
                type="switch"
                id="isTracciato"
                label="Il questionario deve essere compilato solo una volta?"
                onChange={onChangeTemplate}
                value={newQuestionario.isTracciato}
                checked={newQuestionario.isTracciato}
                disabled={!newQuestionario.isEsterno}
              />
            </Col>
          </Row>
          {!newQuestionario.isEsterno
            ? (
              <>
                <div className="separator border-1 border-bottom my-1" />
                <p className="h6" style={{ lineHeight: 1.6 }}>
                  Essendo un questionario interno, si può procedere scegliendo
                  le identità o le coppie identità-utenti che possono compilare
                  il template.
                  Nessuna valorizzazione permetterà a tutti gli utenti dell&apos;organizzazione
                  la compilazione del template.
                </p>
                <Autorizzazioni
                  autorizzazioni={newQuestionario.visibilita}
                  listaIdentita={listaIdentita}
                  aggiungiRow={aggiungiRow}
                  onChangeAutorizzazioni={onChangeAutorizzazioni}
                  handleCloseModaleUtenti={handleCloseModaleUtenti}
                  setIndexAutorizzazioneScelta={setIndexAutorizzazioneScelta}
                  eliminaRow={eliminaRow}
                  setIdentitaScelta={setIdentitaScelta}
                />
              </>
            )
            : null}
          <div className="separator border-1 border-bottom" />
          <Row>
            <Col>
              <p className="h4" style={{ lineHeight: '1.6' }}>
                Sezioni Questionario
              </p>
            </Col>
          </Row>
          {showNewFormSezione
            ? (
              <div>
                <Form className="mt-1">
                  <Row className="mb-3">
                    <Form.Group as={Col} lg controlId="titolo">
                      <Form.Label>Titolo Sezione</Form.Label>
                      <Form.Control placeholder="Inserisci il titolo della sezione" onChange={onChangeSezione} value={addOrEdit ? newSezione.titolo : editedSezione.titolo} />
                    </Form.Group>
                  </Row>
                  <Row className="my-3">
                    <Form.Group as={Col} lg controlId="descrizione">
                      <Form.Label>Descrizione Sezione</Form.Label>
                      <Form.Control placeholder="Inserisci la descrizione della sezione" onChange={onChangeSezione} value={addOrEdit ? newSezione.descrizione : editedSezione.descrizione} />
                    </Form.Group>
                  </Row>
                </Form>
                <div>
                  <Button className="me-2 text-uppercase" variant="danger" onClick={showNewFormSezioneHandle}>
                    <span>
                      <FontAwesomeIcon aria-hidden="true" icon="times-circle" />
                      {' '}
                      Annulla
                    </span>
                  </Button>
                  {addOrEdit ? (
                    <Button
                      className="me-2 text-uppercase"
                      onClick={() => {
                        setFunzioneModaleConferma(() => addSezione);
                        setTestoModaleConferma('creerà');
                        handleStateModaleConferma();
                      }}
                      disabled={!newSezione.titolo || !newSezione.descrizione}
                    >
                      <span>
                        <FontAwesomeIcon aria-hidden="true" icon="plus" />
                        {' '}
                        Aggiungi
                      </span>
                    </Button>
                  ) : (
                    <Button className="me-2 text-uppercase" onClick={modificaSezione} disabled={!editedSezione.titolo || !editedSezione.descrizione}>
                      <span>
                        <FontAwesomeIcon aria-hidden="true" icon="plus" />
                        {' '}
                        Modifica
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            )
            : (
              <Row>
                <Col className="col-lg-auto mt-2 text-end">
                  <Button variant="primary" onClick={showNewFormSezioneHandle}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
                    {' '}
                    Aggiungi Nuova Sezione
                    {' '}
                  </Button>
                </Col>
              </Row>
            )}
          <CardGroupSezioni
            sezioni={newQuestionario.sezioni}
            setEditedSezione={setEditedSezione}
            functionEdit={handleEditSezione}
            functionClone={cloneSezione}
            functionDelete={deleteSezione}
            handleStateModaleConferma={handleStateModaleConferma}
            functionShow={showNewTemplateLayoutHandle}
            setFunzioneModaleConferma={setFunzioneModaleConferma}
            setTestoModaleConferma={setTestoModaleConferma}
          />
        </div>
      ) : (
        <NewTemplateSection
          template={editedSezione.template || {}}
          functionCancel={showNewTemplateLayoutHandle}
          functionAdd={modificaTemplateSezione}
          editedSezione={editedSezione}
        />
      )}
      {openModaleConferma && (
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione ${testoModaleConferma} la sezione selezionata`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => {
          funzioneModaleConferma(editedSezione.titolo);
          handleStateModaleConferma();
        }}
      />
      )}
      {showModaleUtenti && (
      <ModaleRicercaUtenti
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        idIdentita={identitaScelta}
        listaIdentita={listaIdentita}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        indexAutorizzazioneScelta={indexAutorizzazioneScelta}
      />
      )}
    </div>
  );
}

export default GestioneTemplate;
