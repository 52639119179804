import React, { useContext, useEffect, useState } from 'react';
import Pagination from '../../../components/Forum/Pagination';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import CardGroup from '../../../components/Template/CardGroup';
import FiltriRicercaQuestionari from '../../../components/Template/FiltriRicercaQuestionari';
import templateService from '../../../services/templateService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';

const baseUrl = process.env.REACT_APP_BASE_URL;
const templateUrl = process.env.REACT_APP_QUESTIONARI_URL;

function Template() {
  const [context, setContext] = useContext(Context);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [testoModaleConferma, setTestoModaleConferma] = useState('');
  const [funzioneModaleConferma, setFunzioneModaleConferma] = useState();
  const [idTemplate, setIdTemplate] = useState();
  const [templates, setTemplates] = useState([]);
  const [filtri, setFiltri] = useState({
    titolo: '',
    stato: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  function onChangeFiltri(e) {
    const {
      id, value, checked, type,
    } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  function copyLink(template) {
    navigator.clipboard.writeText(`
    ${baseUrl}/${template.isTracciato ? 'area-ospite/' : ''}questionari/${template._id}
    `);
    try {
      navigator.clipboard.writeText(`
    ${baseUrl}/${template.isTracciato ? 'area-ospite/' : ''}questionari/${template._id}
    `);
      setContext(() => ({
        ...context, openConferma: true, testo: 'Il link è stato correttamente copiato',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore', statusCode: 400,
      }));
    }
  }

  function showTemplate(id) {
    history.push(`/area-riservata/template/visualizza-template/${id}`);
  }

  function showStatistiche(id) {
    history.push(`/area-riservata/template/visualizza-statistiche/${id}`);
  }

  async function editTemplate(id) {
    history.push(`/area-riservata/template/gestione-template/${id}`);
  }

  async function getTemplates(offset, limit) {
    try {
      const response = await templateService.getTemplates(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setTemplates(response.listaTemplate);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function createTemplate() {
    try {
      const res = await templateService.createTemplate();
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      editTemplate(res.id);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function deleteTemplate(id) {
    try {
      const res = await templateService.deleteTemplate(id);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getTemplates(0, 5);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function publishTemplate(id) {
    try {
      const res = await templateService.publishTemplate(id);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getTemplates(0, 5);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function cloneTemplate(id) {
    try {
      const res = await templateService.cloneTemplate(id);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      editTemplate(res.id);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function retireTemplate(id) {
    try {
      const res = await templateService.retireTemplate(id);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getTemplates(0, 5);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function exportTemplate(id, formato) {
    const a = document.createElement('A');
    a.href = `${templateUrl}/template/${id}/esporta?formato=${formato}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => {
    getTemplates(0, 5);
  }, []);

  return (
    <div>
      <TitlePage
        title="Template"
        subtitle="Gestione template"
        showAdd={controlla('X59') === true}
        showRefresh
        functionAdd={createTemplate}
        functionRefresh={() => { getTemplates(0, 5); }}
      />
      <FiltriRicercaQuestionari
        filtri={filtri}
        getElements={getTemplates}
        onChange={onChangeFiltri}
      />
      <CardGroup
        templates={templates}
        functionShow={showTemplate}
        functionEdit={editTemplate}
        functionDelete={deleteTemplate}
        functionPublish={publishTemplate}
        functionClone={cloneTemplate}
        functionRetire={retireTemplate}
        functionExport={exportTemplate}
        functionSeeStats={showStatistiche}
        setIdTemplate={setIdTemplate}
        handleStateModaleConferma={handleStateModaleConferma}
        setFunzioneModaleConferma={setFunzioneModaleConferma}
        setTestoModaleConferma={setTestoModaleConferma}
        copyLink={copyLink}
      />

      {pagination.totalSize !== 0 ? (
        <Pagination
          functionShow={getTemplates}
          pagination={pagination}
        />
      ) : null}

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione ${testoModaleConferma} il template selezionato`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => {
          funzioneModaleConferma(idTemplate);
          handleStateModaleConferma();
        }}
      />
    </div>
  );
}

export default Template;
