import { React, useEffect, useState } from 'react';
import Title from '../../../../components/GestisciUtenti/TitoloPagina/index';
import FormGestione from '../../../../components/GestisciUtenti/Form/index';
import Table from '../../../../components/Shared/Table';
import { fieldsProfonditaStorica, fieldsNoProfonditaStorica } from './fieldsTable';
import history from '../../../../utils/history';
import utenteService from '../../../../services/utentiService';
import tipiConnettoriService from '../../../../services/tipiConnettoriService';
import checkStorage from '../../../../utils/checkSessionStorage';
import { setItem } from '../../../../utils/storage';

function GestisciUtenti() {
  const [utenti, setUtenti] = useState([]);
  const [listaTipiConnettore, setListaTipiConnettore] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    identificativo: '',
    desc_nome: '',
    desc_cognome: '',
    trusted: null,
    fk_codi_tipo_connettore: null,
    data_registrazione_from: null,
    data_registrazione_to: null,
    profondita_storica: false,
  });

  async function getUtenti(offset, limit, page, filters) {
    const filtriSession = filters || filtri;
    const response = await utenteService.getUtenti(filtriSession, offset, limit);
    setUtenti(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getListaTipiConnettore() {
    setListaTipiConnettore(await tipiConnettoriService.getTipiConnettori());
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getUtenti(currentIndex, sizePerPage, page);
  };

  function onChangeFiltri(e) {
    const { id, value } = e.target;
    switch (id) {
      case 'trusted': {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: value === 'true',
        }));
        break;
      }
      case 'fk_codi_tipo_connettore': {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: parseInt(value, 10),
        }));
        break;
      }
      default: {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: value,
        }));
        break;
      }
    }
  }

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getUtenti(offset, 5, storage.page, storage.filtri);
    getListaTipiConnettore();
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);
  return (
    <>
      <Title
        title="Gestione utenti"
        subtitle="È possibile ricercare e gestire gli utenti"
        showRefresh
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione')}
        functionRefresh={() => {
          getUtenti(0, 5, 1);
        }}
      />

      <FormGestione
        filtri={filtri}
        onChange={onChangeFiltri}
        getUtenti={getUtenti}
        listaTipiConnettore={listaTipiConnettore}
      />
      <Table
        title="Elenco utenti"
        data={utenti}
        fields={filtri.profondita_storica === 'true' ? fieldsProfonditaStorica : fieldsNoProfonditaStorica}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="ordinamento"
      />

    </>
  );
}

export default GestisciUtenti;
