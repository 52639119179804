/* eslint-disable consistent-return */
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import questionariTracciatiService from '../../../services/questionariTracciatiService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';

const AccettazioneTemplateOspite = () => {
  const { idTemplate } = useParams();
  const [context, setContext] = useContext(Context);
  const [template, setTemplate] = useState({
    titolo: '',
    descrizione: '',
  });
  const [esistenzaQuestionario, setEsistenzaQuestionario] = useState(false);
  const [idQuestionarioEsistente, setIdQuestionarioEsistente] = useState();
  const [messaggio, setMessaggio] = useState('');

  async function getTemplate() {
    try {
      const response = await questionariTracciatiService.getTemplateById(idTemplate);
      setTemplate(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  async function verificaEsistenzaQuestionario() {
    try {
      const response = await questionariTracciatiService.verificaEsistenzaQuestionario(idTemplate);
      setEsistenzaQuestionario(
        response.nextWithNewPoll ? false : !!response.nextWithExistingPoll,
      );
      setIdQuestionarioEsistente(response.id);
      setMessaggio(response.message);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  async function createQuestionario() {
    try {
      const response = await questionariTracciatiService.createQuestionario(idTemplate);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      history.push(`/area-ospite/questionari/compila-questionario/${response.id}`);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  useEffect(() => {
    getTemplate();
    verificaEsistenzaQuestionario();
  }, []);
  return (
    <>
      {
      template ? (
        <div className="m-2" style={{ textAlign: 'center' }}>
          <h1>
            Sei stato invitato a compilare il questionario &quot;
            {template.titolo}
            &quot;
          </h1>
          {messaggio ? (
            <p id="desc">{ messaggio }</p>
          ) : esistenzaQuestionario
            ? (
              <>
                <p id="desc">Il questionario risulta già iniziato. Si può procedere con la compilazione utilizzando il bottone in basso</p>
                <Button
                  onClick={() => history.push(`/area-ospite/questionari/compila-questionario/${idQuestionarioEsistente}`)}
                >
                  Continua
                </Button>
              </>
            )
            : (
              <>
                <p id="desc">Clicca il pulsante qui sotto per cominciare.</p>
                <Button onClick={createQuestionario}>Inizia</Button>
              </>
            )}
        </div>
      ) : (
        <div className="m-2" style={{ textAlign: 'center' }}>
          <h1>Link non valido</h1>
          <Link to="/">Torna alla home</Link>
        </div>
      )
    }
    </>
  );
};

export default AccettazioneTemplateOspite;
