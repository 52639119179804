/* eslint-disable prefer-template */
import React from 'react';
import {
  Accordion,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import Regex from '../../utils/regex';

const regexMail = new RegExp(Regex.mail);
const regexUrl = new RegExp(Regex.urlSenzaHttp);
const regexTelefono = new RegExp(Regex.numeroItaliano);
const regexPIva = new RegExp(Regex.pIva);

const FormContatti = ({ onChange, listaRecapiti, disabled }) => (
  <Accordion className="mb-3">
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        Contatti
      </Accordion.Header>
      <Accordion.Body>
        <strong>
          Si prega di compilare i seguenti contatti.
        </strong>
        {listaRecapiti?.map((el) => (
          <Row className="mb-3" key={el.fk_codi_tipo_recapito}>
            <Form.Group as={Col} controlId={el.fk_codi_tipo_recapito}>
              <Form.Label>{el.nome}</Form.Label>
              <Form.Control
                placeholder={'Inserisci ' + el.nome}
                value={el.desc_recapito}
                onChange={onChange}
                disabled={disabled}
                isInvalid={
                    ((el.fk_codi_tipo_recapito === 5 || el.fk_codi_tipo_recapito === 6)
                    && !regexMail.test(el.desc_recapito) && el.desc_recapito !== '')
                    || ((el.fk_codi_tipo_recapito === 7 || el.fk_codi_tipo_recapito === 8
                        || el.fk_codi_tipo_recapito === 9
                        || el.fk_codi_tipo_recapito === 10 || el.fk_codi_tipo_recapito === 11)
                        && !regexUrl.test(el.desc_recapito) && el.desc_recapito !== '')
                    || ((el.fk_codi_tipo_recapito === 3 || el.fk_codi_tipo_recapito === 4)
                    && !regexTelefono.test(el.desc_recapito) && el.desc_recapito !== '')
                    || (el.fk_codi_tipo_recapito === 2 && !regexPIva.test(el.desc_recapito) && el.desc_recapito !== '')
                }
              />
              <Form.Control.Feedback type="invalid">
                Si prega di inserire un formato corretto.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
);

export default FormContatti;
