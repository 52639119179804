import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getRuoli(filtri, offset, limit) {
  const filters = { ...filtri, offset, limit };
  // VEDI: mi sono permesso di fare questa modifica per farti vedere questo metodo che può essere
  // usato generalmente. In ogni caso, il tutto funziona anche nell'altro modo quindi se vuoi
  // tornare alla situazione precedente cancella e decommenta.
  const params = createQueryString(filters);
  // if (filtri.desc_nome_ruolo) {
  //   params += `desc_nome_ruolo=${filtri.desc_nome_ruolo}&`;
  // }
  // if (filtri.fk_sequ_tag_ruolo) {
  //   params += `fk_sequ_tag_ruolo=${filtri.fk_sequ_tag_ruolo}&`;
  // }
  // if (typeof filtri.flag_admin === 'boolean') {
  //   params += `flag_admin=${filtri.flag_admin}&`;
  // }
  // if (typeof filtri.flag_protetto === 'boolean') {
  //   params += `flag_protetto=${filtri.flag_protetto}&`;
  // }
  // if (filtri.data_inizio_validita_from) {
  //   params += `data_inizio_validita_from=${filtri.data_inizio_validita_from}&`;
  // }
  // if (filtri.data_inizio_validita_to) {
  //   params += `data_inizio_validita_to=${filtri.data_inizio_validita_to}&`;
  // }
  // if (filtri.data_fine_validita_from) {
  //   params += `data_fine_validita_from=${filtri.data_fine_validita_from}&`;
  // }
  // if (filtri.data_fine_validita_to) {
  //   params += `data_fine_validita_to=${filtri.data_fine_validita_to}&`;
  // }
  // if (offset || offset === 0) {
  //   params += `offset=${offset}&`;
  // }
  // if (limit) {
  //   params += `limit=${limit}&`;
  // }
  return api.get(`${accreditamentoUrl}/gestione-ruolo?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getRuoloById(id, filtri) {
  const params = createQueryString(filtri);
  return api.get(`${accreditamentoUrl}/gestione-ruolo/${id}?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoRuolo(ruolo) {
  return api.post(`${accreditamentoUrl}/gestione-ruolo`, ruolo)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaRuolo(ruolo) {
  return api.put(`${accreditamentoUrl}/gestione-ruolo/${ruolo.sequ_k_ruolo}`, ruolo)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaRuolo(id) {
  return api.delete(`${accreditamentoUrl}/gestione-ruolo/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const ruoliService = {
  getRuoli,
  getRuoloById,
  nuovoRuolo,
  modificaRuolo,
  eliminaRuolo,
};

export default ruoliService;
