import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getRichiesteIdentita(filtri, offset, limit) {
  let params = '';
  if (filtri.codi_stato_richiesta) {
    params += `codi_stato_richiesta=${filtri.codi_stato_richiesta}&`;
  }
  if (filtri.data_richiesta_from) {
    params += `data_richiesta_from=${filtri.data_richiesta_from}&`;
  }
  if (filtri.data_richiesta_to) {
    params += `data_richiesta_to=${filtri.data_richiesta_to}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getRichiestaIdentitaById(id) {
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita/${id}`)
    .then((res) => res && res.data[0])
    .catch((err) => Promise.reject(err.response));
}

async function presaIncarico(id) {
  return api.put(`${accreditamentoUrl}/gestione-richiesta-identita/${id}/carico`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function evasione(id, risposta) {
  return api.put(`${accreditamentoUrl}/gestione-richiesta-identita/${id}/evadi`, { desc_msg_risposta: risposta })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getRichiesteIdentitaPerUtente(filtri, offset, limit) {
  let params = '';
  if (filtri.codi_stato_richiesta) {
    params += `codi_stato_richiesta=${filtri.codi_stato_richiesta}&`;
  }
  if (filtri.data_richiesta_from) {
    params += `data_richiesta_from=${filtri.data_richiesta_from}&`;
  }
  if (filtri.data_richiesta_to) {
    params += `data_richiesta_to=${filtri.data_richiesta_to}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita/utente?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteRichiesta(id) {
  return api.delete(`${accreditamentoUrl}/gestione-richiesta-identita/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovaRichiesta(richiesta) {
  return api.post(`${accreditamentoUrl}/gestione-richiesta-identita/utente`, richiesta)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const richiestIdentitaService = {
  getRichiesteIdentita,
  getRichiestaIdentitaById,
  presaIncarico,
  evasione,
  getRichiesteIdentitaPerUtente,
  deleteRichiesta,
  nuovaRichiesta,
};

export default richiestIdentitaService;
