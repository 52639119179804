/* eslint-disable import/no-mutable-exports */
import * as sdk from 'matrix-js-sdk';

export let client;

export function setAndStartMatrix(matrixData) {
  // Verifica se l'oggetto in ingresso è quello dato da Matrix
  if (!matrixData.status) {
    client = sdk.createClient({
      baseUrl: matrixData.homeServer,
      accessToken: matrixData.access_token,
      userId: matrixData.user_id,
    });
    client.startClient({ pollTimeout: 30000 });
    setTimeout(async () => {
      const roomNotifications = client?.getRooms().filter((el) => el.name === 'Notifiche')?.[0];
      // Verifica se l'utente fa parte della room dedicata alle notifiche, altrimenti lo fa entrare
      if (roomNotifications?.selfMembership === 'invite') {
        await client?.joinRoom(roomNotifications?.roomId, { syncRoom: false });
      }
    }, 2500);
  }
}
