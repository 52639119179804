/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { controlla } from '../../utils/verificaPermessi';
import { client } from '../../utils/matrix';
import history from '../../utils/history';

function Sidebar() {
  const [notification, setNotification] = useState(0);

  useEffect(() => {
    client?.on(
      'event',
      () => {
        // let unreadMessage = 0;
        let unreadRoom = 0;
        client.getRooms().forEach((el) => {
          if (el.name !== 'Notifiche' && el.notificationCounts.total) {
            // unreadMessage += el.notificationCounts.total;
            unreadRoom += 1;
          }
        });
        setNotification(unreadRoom);
      },
    );
  }, []);
  return (
    <nav className="border-end bg-white" id="sidebar-wrapper" role="navigation" aria-label="Menu laterale sinistro">
      <div className="sidebar-heading border-bottom ms-1" />
      <ListGroup>
        <ListGroup.Item>
          <Link to="/area-riservata">
            <FontAwesomeIcon aria-hidden="true" icon="home" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Home
          </Link>
        </ListGroup.Item>
        {controlla('Z1') && (
        <ListGroup.Item>
          <Link to="/area-riservata/amministrazione">
            <FontAwesomeIcon aria-hidden="true" icon="users" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Amministrazione
          </Link>
        </ListGroup.Item>
        )}
        {controlla('Z2') && (
        <ListGroup.Item>
          <Link to="/area-riservata/richiesta-identita">
            <FontAwesomeIcon aria-hidden="true" icon="id-badge" tyle={{ marginRight: '4px' }} size="lg" />
            {' '}
            Richiesta identità
          </Link>
        </ListGroup.Item>
        )}
        {client && controlla('Z3')
          ? (
            <ListGroup.Item>
              <Link to="/area-riservata/chat">
                <FontAwesomeIcon aria-hidden="true" icon="comment" style={{ marginRight: '4px' }} size="lg" />
                {' '}
                Chat
                {' '}
                {notification && !history.location.pathname.includes('chat')
                  ? <Badge bg="grey">{notification}</Badge>
                  : null}
              </Link>
            </ListGroup.Item>
          )
          : null }
        {controlla('Z4') && (
        <ListGroup.Item>
          <Link to="/area-riservata/repository/main">
            <FontAwesomeIcon aria-hidden="true" icon="folder" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Repository
          </Link>
        </ListGroup.Item>
        )}
        {controlla('Z5') && (
        <ListGroup.Item>
          <Link to="/area-riservata/forum">
            <FontAwesomeIcon aria-hidden="true" icon="comments" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Forum
          </Link>
        </ListGroup.Item>
        )}
        {controlla('Z6') && (
        <ListGroup.Item>
          <Link to="/area-riservata/template">
            <FontAwesomeIcon aria-hidden="true" icon="square-poll-horizontal" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Gestione questionari
          </Link>
        </ListGroup.Item>
        )}
        {controlla('Z7') && (
        <ListGroup.Item>
          <Link to="/area-riservata/questionari">
            <FontAwesomeIcon aria-hidden="true" icon="file-circle-question" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Questionari
          </Link>
        </ListGroup.Item>
        )}
        <>
          {controlla('Z8') && (
          <ListGroup.Item>
            <Link to="/area-riservata/news">
              <FontAwesomeIcon aria-hidden="true" icon="newspaper" size="lg" />
              {' '}
              News
            </Link>
          </ListGroup.Item>
          )}
          {controlla('Z9') && (
          <ListGroup.Item>
            <Link to="/area-riservata/faq">
              <FontAwesomeIcon aria-hidden="true" icon="question-circle" size="lg" />
              {' '}
              FAQ
            </Link>
          </ListGroup.Item>
          )}
          {controlla('Z10') && (
          <ListGroup.Item>
            <Link to="/area-riservata/gestione-faq">
              <FontAwesomeIcon aria-hidden="true" icon="question-circle" size="lg" />
              {' '}
              Gestione FAQ
            </Link>
          </ListGroup.Item>
          )}
          {controlla('Z11') && (
          <ListGroup.Item>
            <Link to="/area-riservata/newsletter">
              <FontAwesomeIcon aria-hidden="true" icon="newspaper" style={{ marginRight: '4px' }} size="lg" />
              {' '}
              Newsletter
            </Link>
          </ListGroup.Item>
          )}
          {controlla('Z12') && (
          <ListGroup.Item>
            <Link to="/area-riservata/gestione-app-esterne">
              <FontAwesomeIcon aria-hidden="true" icon="external-link" style={{ marginRight: '4px' }} size="lg" />
              {' '}
              Gestione Applicazioni Esterne
            </Link>
          </ListGroup.Item>
          )}
          {controlla('Z13') && (
          <ListGroup.Item>
            <Link to="/area-riservata/app-esterne">
              <FontAwesomeIcon aria-hidden="true" icon="external-link" style={{ marginRight: '4px' }} size="lg" />
              {' '}
              Applicazioni Esterne
            </Link>
          </ListGroup.Item>
          )}
        </>
      </ListGroup>
      <ListGroup className="mt-2">
        {controlla('Z14') && (
        <ListGroup.Item>
          <Link to="/area-riservata/gestione-sito-web">
            <FontAwesomeIcon aria-hidden="true" icon="diagram-project" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Conf. sito web
          </Link>
        </ListGroup.Item>
        )}
        {controlla('Z15') && (
        <ListGroup.Item>
          <Link to="/area-riservata/siti-web">
            <FontAwesomeIcon aria-hidden="true" icon="earth-europe" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Sito Web
          </Link>
        </ListGroup.Item>
        )}
        {controlla('Z16') && (
        <ListGroup.Item>
          <Link to="/area-riservata/gestione-amm-trasparente">
            <FontAwesomeIcon aria-hidden="true" icon="diagram-project" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Conf. Trasparenza
          </Link>
        </ListGroup.Item>
        )}
        {controlla('Z17')
        && (
        <ListGroup.Item>
          <Link to="/area-riservata/amm-trasparente">
            <FontAwesomeIcon aria-hidden="true" icon="folder" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Amm. Trasparente
          </Link>
        </ListGroup.Item>
        )}
      </ListGroup>
    </nav>
  );
}

export default Sidebar;
