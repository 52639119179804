import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const appUrl = process.env.REACT_APP_APP_ESTERNE_URL;

async function getListaApp(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${appUrl}?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getAppById(id) {
  return api.get(`${appUrl}/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createApp(app) {
  return api.post(`${appUrl}`, app)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteApp(id) {
  return api.delete(`${appUrl}/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editApp(id, body) {
  return api.patch(`${appUrl}/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const appService = {
  getListaApp,
  getAppById,
  createApp,
  deleteApp,
  editApp,
};

export default appService;
