import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getStatiPiattaforma() {
  return api.get(`${accreditamentoUrl}/gestione-piattaforma`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function setStatoPiattaforma(id, descrizioneStato) {
  return api.put(`${accreditamentoUrl}/gestione-piattaforma/${id}`, { desc_msg_stato: descrizioneStato })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const gestionePiattaformaService = {
  getStatiPiattaforma,
  setStatoPiattaforma,
};

export default gestionePiattaformaService;
