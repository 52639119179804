import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useCookies } from 'react-cookie';
import SpidButton from '../../components/SpidButton';
import LoginSviluppoModal from '../../components/LoginSviluppoModal';
import TitlePage from '../../components/GestisciUtenti/TitoloPagina';
import logoAgidLogin from '../../images/agid-login-logo-white.svg';
import { setItem } from '../../utils/storage';
import history from '../../utils/history';
import useQuery from '../../utils/useQuery';

const accessoConAgidLogin = process.env.REACT_APP_ACCESSO_AGID_LOGIN;
const agidLoginUrl = process.env.REACT_APP_AGID_LOGIN_URL_OSPITE;
const agidLoginClientId = process.env.REACT_APP_AGID_LOGIN_CLIENT_ID_OSPITE;
const agidLoginUriPostLogin = process.env.REACT_APP_AGID_LOGIN_REDIRECT_URI_POST_LOGIN_OSPITE;

const LoginOspite = () => {
  const query = useQuery();
  const [modalShow, setModalShow] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  useEffect(() => {
    const redirectUrl = query.get('redirect-url');
    if (!redirectUrl) {
      history.push('/');
    }
    setCookie('accessoOspite', true, { path: '/' });
    setItem('redirectUrl', redirectUrl);
  }, []);
  return (
    <>
      <TitlePage
        title="Pagina di accesso"
        subtitle="Si prega di effettuare l'accesso per essere ridirezionati alla pagina visitata."
      />
      <Card className="m-5">
        <Card.Body>
          <Card.Title>Accedi con identità digitale</Card.Title>
          {accessoConAgidLogin === 'false'
            ? <SpidButton />
            : (
              <a className="italia-it-button italia-it-button-size-m button-spid ms-2" href={`${agidLoginUrl}/auth?client_id=${agidLoginClientId}&redirect_uri=${agidLoginUriPostLogin}&response_type=code&scope=openid+profile&state=test`}>
                <img
                  className="logo-header"
                  src={logoAgidLogin}
                  alt="AgID Login"
                  style={{
                    height: '46.031px',
                  }}
                />
              </a>
            )}
        </Card.Body>
      </Card>
      <LoginSviluppoModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default LoginOspite;
