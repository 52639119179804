/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal } from 'react-bootstrap';
import NuovoTagIdentita from './NuovoTagIdentita';

const ModaleNuovoTagIdentita = ({
  show,
  handleClose,

}) => (
  <>
    <Modal show={show} onHide={handleClose} centered size="xl" role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Creazione nuovo tag identità</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NuovoTagIdentita closeModal={handleClose} />
      </Modal.Body>

    </Modal>
  </>
);

export default ModaleNuovoTagIdentita;
