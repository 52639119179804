import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo-point.png';
// import history from '../../utils/history';

function HeaderHtml() {
  return (
    <header className="it-header-wrapper it-header-sticky">
      <div className="it-header-slim-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="it-header-slim-wrapper-content">
                <a className="d-lg-block navbar-brand" href="https://www.agid.gov.it" target="_blank" rel="noreferrer">AGID</a>
                <div className="it-access-top-wrapper">
                  <Link className="btn btn-primary btn-full" to="/pagina-login" title="Accedi all'area riservata">
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'user-circle']} size="lg" />
                    &nbsp;
                    Accedi all&apos;area riservata
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="it-nav-wrapper">
        <div className="it-header-center-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-center-content-wrapper">
                  <div className="it-brand-wrapper">
                    <Link to="/" title="Vai alla pagina: Homepage">
                      <img
                        alt="Logo AGID"
                        src={logo}
                        className="icon"
                      />
                    </Link>
                  </div>
                  <div className="it-right-zone">
                    <div className="it-socials d-none d-sm-flex">
                      <span>Seguici su</span>
                      <ul style={{ padding: '5px' }}>
                        <li style={{ padding: '5px' }}>
                          <a
                            href="https://www.facebook.com/AgIDGovIT/"
                            target="_blank"
                            title="Collegamento a sito esterno - Facebook - si apre una nuova finestra"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon aria-hidden="true" icon={['fab', 'facebook']} size="lg" color="white" />
                          </a>

                        </li>
                        <li style={{ padding: '5px' }}>
                          <a
                            href="https://twitter.com/AgidGov"
                            target="_blank"
                            title="Collegamento a sito esterno - Twitter - si apre una nuova finestra"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon aria-hidden="true" icon={['fab', 'twitter']} size="lg" color="white" />
                          </a>
                        </li>
                        <li style={{ padding: '5px' }}>
                          <a
                            href="https://www.youtube.com/channel/UCxJmOWf__YcLgB19gy5dGQQ"
                            target="_blank"
                            title="Collegamento a sito esterno - Youtube - si apre una nuova finestra"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon aria-hidden="true" icon={['fab', 'youtube']} size="lg" color="white" />
                          </a>
                        </li>
                        <li style={{ padding: '5px' }}>
                          <a
                            href="https://medium.com/@AgidGov"
                            target="_blank"
                            title="Collegamento a sito esterno - Medium - si apre una nuova finestra"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon aria-hidden="true" icon={['fab', 'medium-m']} size="lg" color="white" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="it-header-navbar-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="navbar navbar-expand-lg has-megamenu"
                 aria-label="Menu di navigazione">
                  <button
                    aria-controls="nav10"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    className="custom-navbar-toggler"
                    data-target="#nav10"
                    type="button"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'bars']}
                    size="lg" color="white" />
                  </button>
                  <div className="navbar-collapsable" id="nav10">
                    <div className="overlay" />
                    <div className="close-div sr-only">
                      <button className="btn close-menu" type="button">
                        <span className="it-close" />
                        chiudi
                      </button>
                    </div>
                    <div className="menu-wrapper">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <Link
                            className={history.location.pathname.includes('notizie') ?
                             'nav-link active' : 'nav-link'}
                            to="/notizie"
                            title="Vai alla pagine: Notizie"
                          >
                            <span>Notizie</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </header>
  );
}

export default HeaderHtml;
