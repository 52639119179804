import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card, Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FormEntita from '../../../../../components/AmmTrasparente/FormEntita';
import FormFile from '../../../../../components/AmmTrasparente/FormFile';
import ModalMoveContent from '../../../../../components/AmmTrasparente/ModalMoveContent';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import ElementModal from '../../../../../components/Pagina/ElementModal';
import FormParagrafo from '../../../../../components/Pagina/FormParagrafo';
import Table from '../../../../../components/Shared/Table';
import entitaTrasparenzaService from '../../../../../services/entitaTrasparenzaService';
import fileAmmTraspService from '../../../../../services/fileAmmTraspService';
import sitiWebService from '../../../../../services/sitiWebService';
import versioniService from '../../../../../services/versioniService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';
import fieldsContent from './fieldsContent';

const ModificaPaginaAmmTrasparente = () => {
  const { idLivello } = useParams();
  const params = new URLSearchParams(window.location.search);
  const nomeLivello = params.get('nomeLivello');
  const { idVersione } = useParams();
  const [modifica, setModifica] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [context, setContext] = useContext(Context);
  // Pagina Main
  const [pagina, setPagina] = useState({
    desc_nome: '',
    fk_sequ_livello_disponibile: '',
    fk_sequ_versione: '',
    flag_sezione_non_applicabile: false,
    entita_scelta: '',
    contenuti: { contenutiArray: [] },
  });
  const [listaEntita, setListaEntita] = useState([]);
  // Nuovo Paragrafo da aggiungere nei contenuti
  const [paragrafo, setParagrafo] = useState({
    tipo: 'paragrafo',
    nome: '',
    contenuto: '',
    selected: false,
    dataCreazione: new Date(),
  });
  const [file, setFile] = useState({
    tipo: 'file',
    nome: '',
    selected: false,
    dataCreazione: new Date(),
  });
  const [indexRow, setIndexRow] = useState(1);
  const [openModaleModifica, setopenModaleModifica] = useState(false);

  function handleStateNuovoParagrafo() {
    setPagina({
      ...pagina,
      contenuti: {
        contenutiArray: [
          ...pagina.contenuti.contenutiArray,
          paragrafo,
        ],
      },
    });

    setParagrafo({
      nome: '',
      contenuto: '',
      tipo: 'paragrafo',
      selected: false,
      dataCreazione: new Date(),
    });
  }

  async function uploadFile() {
    try {
      const response = await fileAmmTraspService.uploadFile(file);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      file.idMongo = response.insertedId;
      file.nomeFile = response.nomeFile;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function handleStateNuovoFile() {
    uploadFile();

    setPagina({
      ...pagina,
      contenuti: {
        contenutiArray: [
          ...pagina.contenuti.contenutiArray,
          file,
        ],
      },
    });

    setFile({
      nome: '',
      tipo: 'file',
      selected: false,
      dataCreazione: new Date(),
    });
  }

  function handleTableChange() {}

  async function getPagina() {
    try {
      const paginaById = await versioniService.getPagina(idVersione, idLivello);
      if (Object.keys(paginaById).length !== 0) {
        setPagina({
          desc_nome: paginaById.documentoMongo.desc_nome || nomeLivello,
          fk_sequ_livello_disponibile: paginaById.documentoMongo.fk_sequ_livello_disponibile,
          fk_sequ_versione: paginaById.documentoMongo.fk_sequ_versione,
          flag_sezione_non_applicabile: paginaById.flag_sezione_non_applicabile,
          entita_scelta: paginaById.documentoMongo.entita_scelta,
          contenuti: paginaById.documentoMongo.contenuti,
        });
        setParagrafo({
          ...paragrafo,
        });
        setModifica(idLivello);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  async function getListaEntita() {
    try {
      const response = await entitaTrasparenzaService.getListaEntita();
      setListaEntita(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  function handleStateEntita(entita) {
    setPagina({
      ...pagina,
      entita_scelta: entita,
    });
  }

  async function salvaPagina() {
    try {
      let response = null;

      pagina.contenuti.contenutiArray.forEach((element, index) => {
        if (element.tipo === paragrafo) {
          pagina.contenuti.contenutiArray[index] = {
            tipo: element.tipo,
            nome: element.nome,
            dataCreazione: element.dataCreazione,
            contenuto: element.contenuto,
          };
        } else {
          pagina.contenuti.contenutiArray[index] = {
            tipo: element.tipo,
            nome: element.nome,
            dataCreazione: element.dataCreazione,
            contenuto: element.contenuto,
            idMongo: element.idMongo,
            file: element.file,
          };
        }
      });

      if (modifica === 0) {
        response = await versioniService.creaPagina(
          idVersione,
          pagina,
        );
      } else {
        response = await versioniService.modificaPagina(
          idVersione,
          modifica, // idPagina
          pagina,
        );
      }

      history.goBack();
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function eliminaPagina() {
    try {
      const response = await versioniService.eliminaPagina(
        idVersione,
        modifica, // idPagina
      );

      history.goBack();
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function handleStateModaleModifica(row, rowIndex) {
    if (row) {
      setParagrafo(row);
      setIndexRow(rowIndex);
    }
    setTimeout(() => {
      setopenModaleModifica(!openModaleModifica);
    }, 150);
  }

  function modificaElemento() {
    pagina.contenuti.contenutiArray[indexRow] = paragrafo;

    setParagrafo({
      nome: '',
      contenuto: '',
      tipo: 'paragrafo',
    });

    setopenModaleModifica(!openModaleModifica);
  }

  function eliminaElemento(row) {
    setPagina({
      ...pagina,
      contenuti: {
        contenutiArray: pagina.contenuti.contenutiArray
          .filter((el) => el !== row),
      },
    });
  }

  function move(oldIndex, newIndex) {
    const arr = pagina.contenuti.contenutiArray;
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    setPagina({
      contenuti: {
        contenutiArray: arr,
      },
    });
  }

  const [showModalMove, setShowModalMove] = useState(false);
  const [treeData, setTreeData] = useState([]);

  async function spostaElemento() {
    try {
      const response = await sitiWebService.getAlberoAmmTrasparente(idVersione);
      setTreeData(response);
      setShowModalMove(true);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function handleSpostaElementoNuovaPagina(idPagina) {
    const newPagina = {
      desc_nome: '',
      fk_sequ_livello_disponibile: idPagina,
      fk_sequ_versione: Number(idVersione),
      flag_sezione_non_applicabile: false,
      contenuti: { contenutiArray: [] },
    };
    pagina.contenuti.contenutiArray.forEach((element) => {
      if (element.selected) {
        eliminaElemento(element);
        let content = {};
        if (element.tipo === paragrafo) {
          content = {
            tipo: element.tipo,
            nome: element.nome,
            dataCreazione: element.dataCreazione,
            contenuto: element.contenuto,
          };
        } else {
          content = {
            tipo: element.tipo,
            nome: element.nome,
            dataCreazione: element.dataCreazione,
            contenuto: element.contenuto,
            idMongo: element.idMongo,
            file: element.file,
          };
        }

        newPagina.contenuti.contenutiArray.push(
          content,
        );
      }
    });

    const response = await versioniService.creaPagina(
      idVersione,
      newPagina,
    );

    return response;
  }

  async function handleSpostaElemento(idPagina) {
    try {
      const paginaScelta = await versioniService.getPagina(idVersione, idPagina);
      let response = '';
      if (Object.keys(paginaScelta).length !== 0) {
        pagina.contenuti.contenutiArray.forEach((element) => {
          if (element.selected) {
            eliminaElemento(element);
            let content = {};
            if (element.tipo === paragrafo) {
              content = {
                tipo: element.tipo,
                nome: element.nome,
                dataCreazione: element.dataCreazione,
                contenuto: element.contenuto,
              };
            } else {
              content = {
                tipo: element.tipo,
                nome: element.nome,
                dataCreazione: element.dataCreazione,
                contenuto: element.contenuto,
                idMongo: element.idMongo,
                file: element.file,
              };
            }

            if (paginaScelta.documentoMongo.flag_sezione_non_applicabile) {
              paginaScelta.documentoMongo.flag_sezione_non_applicabile = false;
            }
            paginaScelta.documentoMongo.contenuti.contenutiArray.push(
              content,
            );
          }
        });

        response = await versioniService.modificaPagina(
          idVersione,
          idPagina,
          paginaScelta.documentoMongo,
        );
      } else {
        response = handleSpostaElementoNuovaPagina(idPagina);
      }

      setShowModalMove(false);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function selezionaElemento(rowIndex) {
    setPagina({
      ...pagina,
      contenuti: {
        contenutiArray:
          pagina.contenuti.contenutiArray.map((el, index) => {
            if (index === rowIndex) {
              const obj = {
                ...el,
                selected: !el.selected,
              };
              return obj;
            }
            return el;
          }),

      },
    });
  }

  async function onChange() {
    if (!pagina.flag_sezione_non_applicabile) {
      setPagina({
        ...pagina,
        flag_sezione_non_applicabile: true,
        contenuti: { contenutiArray: [] },
      });
    } else {
      setPagina({
        ...pagina,
        flag_sezione_non_applicabile: false,
      });
    }
  }

  useEffect(() => {
    getPagina();
    getListaEntita();
    if (modifica === 0) {
      setPagina({
        desc_nome: nomeLivello,
        fk_sequ_livello_disponibile: Number(idLivello),
        fk_sequ_menu_disponibile: 0,
        fk_sequ_versione: parseFloat(idVersione),
        flag_sezione_non_applicabile: false,
        entita_scelta: '',
        contenuti: { contenutiArray: [] },
      });
    }
  }, []);

  return (
    <>
      <>
        <TitlePage
          title="Gestione contenuto - testo"
          subtitle="È possibile gestire un contenuto contenente una lista di paragrafi e/o file."
          showIndietro
          functionIndietro={() => history.goBack()}
          showDelete
          functionDelete={eliminaPagina}
          showSave
          functionSave={salvaPagina}
        />
        <p>
          Nome sezione:
          {' '}
          <strong>{pagina.desc_nome}</strong>
        </p>

        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col className="my-3">
                <Form.Check
                  type="checkbox"
                  id="flag_sezione_non_applicabile"
                  label="Sezione non applicabile"
                  onChange={(e) => onChange(e, 'checkbox')}
                  value={pagina.flag_sezione_non_applicabile}
                  checked={pagina.flag_sezione_non_applicabile}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div>
          * Selezionando questa casella verranno cancellati tutti i contenuti caricati fin ora,
          tuttavia le modifiche saranno applicate al salvataggio della pagina.
        </div>
        <br />

        <FormParagrafo
          paragrafo={paragrafo}
          setParagrafo={setParagrafo}
          handleStateNuovoParagrafo={handleStateNuovoParagrafo}
        />

        <FormFile
          file={file}
          setFile={setFile}
          handleStateNuovoFile={handleStateNuovoFile}
        />

        <FormEntita
          entita={pagina.entita_scelta}
          handleStateEntita={handleStateEntita}
          listaEntita={listaEntita}
        />

        {pagina.contenuti.contenutiArray
        && pagina.contenuti.contenutiArray !== [] ? (
          <Table
            title="Contenuti inseriti:"
            data={pagina.contenuti.contenutiArray
              ? pagina.contenuti.contenutiArray : []}
            fields={fieldsContent(
              handleStateModaleModifica,
              eliminaElemento, move,
              pagina.contenuti.contenutiArray
                ? pagina.contenuti.contenutiArray : [],
              pagina, setPagina, selezionaElemento,
            )}
            onTableChange={handleTableChange}
            keyField="id"
          />
          ) : (
            <>
              <p className="fw-bold fs-3">
                Contenuti inseriti:
                {' '}
              </p>
              <div>La lista contenuti è vuota</div>

            </>
          )}
        <Row className="mb-3">
          <Col>
            <Form.Check
              type="checkbox"
              id="selected"
              label={!selectAll ? 'Seleziona tutti i contenuti' : 'Deseleziona tutti i contenuti'}
              onChange={() => {
                setPagina({
                  ...pagina,
                  contenuti: {
                    contenutiArray: pagina.contenuti.contenutiArray.map((el) => {
                      const obj = {
                        ...el,
                        selected: !selectAll,
                      };
                      return obj;
                    }),
                  },
                });
                setSelectAll(!selectAll);
              }}
              value={selectAll}
              checked={selectAll}
            />
          </Col>
        </Row>

        <Button variant="primary" className=" float-left" onClick={spostaElemento}>
          {' '}
          <FontAwesomeIcon aria-hidden="true" icon={['fas', 'arrow-right']} />
          {' '}
          SPOSTA CONTENUTI
          {' '}
        </Button>

        <ElementModal
          setParagrafo={setParagrafo}
          paragrafo={paragrafo}
          show={openModaleModifica}
          handleClose={handleStateModaleModifica}
          handleSave={modificaElemento}
        />
        <ModalMoveContent
          showModalMove={showModalMove}
          setShowModalMove={setShowModalMove}
          tree={treeData}
          handleSpostaElemento={handleSpostaElemento}
          idPagina={modifica}
        />
      </>
    </>
  );
};

export default ModificaPaginaAmmTrasparente;
