import React from 'react';
import { Route } from 'react-router-dom';
import Error from '../components/Alert';
import ErrorBoundary from '../components/ErrorBoundary';
import Footer from '../components/Footer/FooterHtml';
import Header from '../components/HeaderPubblica/HeaderHtml';
import Spinner from '../components/Spinner';

import { ContextProvider } from '../utils/Context';

const PublicLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <>
        <ContextProvider>
          <Header />
          <Error />
          <div id="content">
            <ErrorBoundary>
              <div className="m-4" id="main-content" role="main">
                <Component {...matchProps} />
              </div>
            </ErrorBoundary>
          </div>
          <Footer />
          <Spinner />
        </ContextProvider>

      </>
    )}
  />
);

export default PublicLayout;
