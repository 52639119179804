/* eslint-disable no-plusplus */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CampiDiRicerca from '../../../../../components/ContenutiDinamiciTrasparenza/CampiDiRicerca';
import OrdinamentoTabella from '../../../../../components/ContenutiDinamiciTrasparenza/OrdinamentoTabella';
import Sezioni from '../../../../../components/ContenutiDinamiciTrasparenza/Sezioni';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../../utils/history';
import entitaTrasparenzaService from '../../../../../services/entitaTrasparenzaService';
import { Context } from '../../../../../utils/Context';

const GestioneVisualizzazioneEntita = () => {
  const { idEntita } = useParams();
  const [context, setContext] = useContext(Context);
  const [listaCampiTabella, setListaCampiTabella] = useState([]);
  const [campiScelti, setCampiScelti] = useState([]);
  const [ordinamentoTabella, setOrdinamentoTabella] = useState([]);
  const [campoSelect, setCampoSelect] = useState('');
  const [sezioni, setSezioni] = useState([]);

  // INIZIO FUNZIONALITA' RICERCA CAMPI
  function aggiungiCampoScelto() {
    setCampiScelti([
      ...campiScelti,
      {
        id: Date.now(),
        campo: '',
        tipologia: '',
      },
    ]);
  }

  function eliminaCampoScelto(id) {
    setCampiScelti(campiScelti.filter((el) => el.id !== id));
  }

  async function getEntita() {
    try {
      const response = await entitaTrasparenzaService.getEntitaById(idEntita);
      setListaCampiTabella(response?.campi);
      setCampiScelti(response?.gestioneVisualizzazione?.campiRicerca || []);
      setOrdinamentoTabella(response?.gestioneVisualizzazione?.tabella || []);
      setSezioni(response?.gestioneVisualizzazione?.sezioni || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChangeCampo(e, idObj) {
    const { id, value } = e.target;
    setCampiScelti(campiScelti.map((el) => {
      if (el.id === idObj) {
        return {
          ...el,
          campo: id.includes('campo') ? value : el.campo,
          tipologia: id.includes('tipologia') ? value : el.tipologia,
        };
      }
      return el;
    }));
  }

  // FINE FUNZIONALITA' RICERCA CAMPI
  // -------------------------------------

  // INIZIO FUNZIONALITA' ORDINAMENTO TABELLA

  function aggiungiColonna() {
    setOrdinamentoTabella([
      ...ordinamentoTabella,
      {
        id: Date.now(),
        campo: campoSelect,
      },
    ]);
  }

  function eliminaColonna(id) {
    setOrdinamentoTabella(ordinamentoTabella.filter((el) => el.id !== id));
  }

  function onChangeCampoSelect(e) {
    const { value } = e.target;
    setCampoSelect(value);
  }

  function arrayMove(oldIndex, newIndex) {
    const arr = ordinamentoTabella;
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    setOrdinamentoTabella([]);
    setTimeout(() => {
      setOrdinamentoTabella(arr);
    }, 100);
  }

  // FINE FUNZIONALITA' ORDINAMENTO TABELLA
  // -------------------------------------

  // INIZIO FUNZIONALITA' SEZIONI

  function aggiungiSezione() {
    setSezioni([
      ...sezioni,
      {
        id: Date.now(),
        titolo: '',
        paragrafo: '',
        campi: [],
      },
    ]);
  }

  function eliminaSezione(id) {
    setSezioni(sezioni.filter((el) => el.id !== id));
  }

  function onChangeTitoloSezione(e, id) {
    const { value } = e.target;
    setSezioni(sezioni.map((el) => {
      if (el.id === id) {
        return {
          ...el, titolo: value,
        };
      }
      return el;
    }));
  }

  async function onChangeParagrafoSezione(content, id) {
    setSezioni(sezioni.map((el) => {
      if (el.id === id) {
        return {
          ...el, paragrafo: content,
        };
      }
      return el;
    }));
  }

  async function onChangeCampoSezione(e, idObj) {
    const { id, value } = e.target;
    setSezioni(sezioni.map((el) => {
      if (el.id === idObj) {
        return {
          ...el,
          campi: el.campi.map((elem) => {
            if (elem.id === Number(id)) {
              return { ...elem, campo: value };
            }
            return elem;
          }),
        };
      }
      return el;
    }));
  }

  function aggiungiCampoInSezione(id) {
    setSezioni(sezioni.map((el) => {
      if (el.id === id) {
        return {
          ...el, campi: [...el.campi, { id: Date.now(), campo: '' }],
        };
      }
      return el;
    }));
  }

  async function salvaVisualizzazione() {
    const body = {
      campiRicerca: campiScelti,
      tabella: ordinamentoTabella,
      sezioni,
    };
    await entitaTrasparenzaService.salvaVisualizzazione(idEntita, body)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.push(`/area-riservata/amm-trasparente/entita/${res.sequ_k_entita}/gestione-visualizzazione`);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // FINE FUNZIONALITA' SEZIONI

  useEffect(() => {
    getEntita();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione visualizzazione entità"
        subtitle="È possibile gestire i campi di ricerca, gli elementi da visualizzare in tabella ed i contenuti delle sezioni."
        showIndietro
        functionIndietro={() => history.goBack()}
        showSave
        functionSave={salvaVisualizzazione}
      />
      <CampiDiRicerca
        aggiungiCampoScelto={aggiungiCampoScelto}
        campiScelti={campiScelti}
        onChangeCampo={onChangeCampo}
        listaCampiTabella={listaCampiTabella}
        eliminaCampoScelto={eliminaCampoScelto}
      />
      <OrdinamentoTabella
        onChangeCampoSelect={onChangeCampoSelect}
        listaCampiTabella={listaCampiTabella}
        aggiungiColonna={aggiungiColonna}
        ordinamentoTabella={ordinamentoTabella}
        eliminaColonna={eliminaColonna}
        arrayMove={arrayMove}
        campoSelect={campoSelect}
      />
      <Sezioni
        aggiungiSezione={aggiungiSezione}
        sezioni={sezioni}
        eliminaSezione={eliminaSezione}
        onChangeTitoloSezione={onChangeTitoloSezione}
        onChangeParagrafoSezione={onChangeParagrafoSezione}
        aggiungiCampoInSezione={aggiungiCampoInSezione}
        onChangeCampoSezione={onChangeCampoSezione}
        listaCampiTabella={listaCampiTabella}
      />
    </>
  );
};

export default GestioneVisualizzazioneEntita;
