import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card, Col, Form, Row,
} from 'react-bootstrap';

function FormFile({
  file, setFile, handleStateNuovoFile,
}) {
  function onChangeTitolo(e) {
    const { value } = e.target;
    setFile({
      ...file,
      nome: value,
    });
  }

  const onUpload = (e) => {
    const { id, value, files } = e.target;
    setFile({
      ...file,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="nome">
            <Form.Label>Nome File:</Form.Label>
            <Form.Control
              placeholder="Inserisci il nome del file"
              value={file?.nome}
              onChange={onChangeTitolo}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Label>Carica File:</Form.Label>
          <Row className="mb-3">
            <small>Sono accettati i seguenti formati: pdf</small>
            <Form.Group className="mb-3" controlId="file">
              <Form.Control value={file.contenuto} type="file" size="sm" onChange={onUpload} />
            </Form.Group>
          </Row>
        </Row>
        <Button variant="primary" className="float-end" onClick={() => { handleStateNuovoFile(); }} disabled={!file.nome}>
          <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
          {' '}
          AGGIUNGI DOCUMENTO
        </Button>
      </Card.Body>
    </Card>
  );
}

export default FormFile;
