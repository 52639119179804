import { React, useEffect, useState } from 'react';
import Title from '../../../../components/GestisciUtenti/TitoloPagina/index';
import Table from '../../../../components/Shared/Table';
import { fieldsNoProfonditaStorica, fieldsProfonditaStorica } from './fieldsTable';
import history from '../../../../utils/history';
import Filtri from './filtri';
import identitaService from '../../../../services/identitaService';
import tipoIdentitaService from '../../../../services/tipoIdentitaService';
import TagIdentitaService from '../../../../services/tagIdentitaService';
import checkStorage from '../../../../utils/checkSessionStorage';
import { setItem } from '../../../../utils/storage';
import { controlla } from '../../../../utils/verificaPermessi';

function GestioneIdentita() {
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [listaIdentita, setIListaIdentita] = useState([]);
  const [listaTipiIdentita, setIListaTipiIdentita] = useState([]);
  const [listaTagIdentita, setListaTagIdentita] = useState([]);
  const [filtri, setFiltri] = useState({
    flag_admin: false,
    desc_nome_identita: '',
    fk_sequ_tag_identita: '',
    fk_codi_tipo_identita: '',
    data_inizio_validita_from: null,
    data_inizio_validita_to: null,
    data_fine_validita_from: null,
    data_fine_validita_to: null,
    profondita_storica: false,
  });

  async function getListaIdentita(offset, limit, page, filters) {
    const filtriSession = filters || filtri;
    const response = await identitaService.getIdentita(filtriSession, offset, limit);
    setIListaIdentita(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getListaTipiIdentita() {
    const response = await tipoIdentitaService.getTipoIdentita();
    setIListaTipiIdentita(response);
  }

  async function getTagIdentita() {
    const response = await TagIdentitaService.getTagIdentita();
    setListaTagIdentita(response.data);
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getListaIdentita(currentIndex, sizePerPage, page);
  };

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    switch (id) {
      case 'fk_sequ_tag_identita': {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: parseInt(value, 10),
        }));
        break;
      }
      case 'fk_codi_tipo_identita': {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: parseInt(value, 10),
        }));
        break;
      }
      default: {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: value,
        }));
        break;
      }
    }
  }

  function refresh() {
    getListaIdentita(0, 5, 1);
  }

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getListaIdentita(offset, 5, storage.page, storage.filtri);
    getListaTipiIdentita();
    getTagIdentita();
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  return (
    <>
      <Title
        title="Gestione identità"
        subtitle="È possibile ricercare, gestire e creare identità"
        showRefresh
        showAdd={controlla('X2') === true}
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione')}
        functionRefresh={refresh}
        functionAdd={() => history.push('/area-riservata/amministrazione/gestione-identita/aggiungi')}
      />

      <Filtri
        filtri={filtri}
        onChange={onChangeFiltri}
        getListaIdentita={getListaIdentita}
        listaTipiIdentita={listaTipiIdentita}
        listaTagIdentita={listaTagIdentita}
      />
      <Table
        title="Elenco identità"
        data={listaIdentita}
        fields={filtri.profondita_storica === 'true' ? fieldsProfonditaStorica : fieldsNoProfonditaStorica}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="sequ_k_identita"
      />

    </>
  );
}

export default GestioneIdentita;
