import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const repositoryUrl = process.env.REACT_APP_REPOSITORY_URL;

async function getElementiPath(filtri, offset, limit, path) {
  const filters = {
    ...filtri, offset, limit, path,
  };
  filters.metadati = JSON.stringify(filters.metadati);
  const params = createQueryString(filters);
  return api.get(`${repositoryUrl}/cartella/contenuto?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function uploadFile(fileInfo) {
  const formData = new FormData();
  formData.append('metadati', JSON.stringify(fileInfo.metadati));
  formData.append('file', fileInfo.fileCaricato);
  formData.append('path', `${fileInfo.path}/${fileInfo.nome}`);
  return api.post(`${repositoryUrl}/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function creaCartella(path) {
  return api.post(`${repositoryUrl}/cartella`, { path })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaCartella(id, path, nome, autorizzazioni) {
  const body = autorizzazioni || { path, nome };
  return api.patch(`${repositoryUrl}/cartella/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaCartella(id) {
  return api.delete(`${repositoryUrl}/cartella/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getConfigurazione() {
  return api.get(`${repositoryUrl}/configurazione`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}
async function modificaConfigurazione(configurazione) {
  return api.put(`${repositoryUrl}/configurazione`, configurazione)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaFile(id) {
  return api.delete(`${repositoryUrl}/file/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function uploadVersione(id, fileInfo) {
  const formData = new FormData();
  formData.append('metadati', JSON.stringify(fileInfo.metadati));
  formData.append('autorizzazioni', JSON.stringify([]));
  formData.append('file', fileInfo.fileCaricato);
  return api.post(`${repositoryUrl}/versione?id=${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaFile(id, body) {
  return api.put(`${repositoryUrl}/file/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getFile(id) {
  return api.get(`${repositoryUrl}/file/info/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaAutorizzazioni(id, body) {
  return api.patch(`${repositoryUrl}/file/autorizzazioni/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListaVersioni(id, dataFineValidita) {
  return api.get(`${repositoryUrl}/file/versioni/${id}?${!dataFineValidita ? 'profonditaStorica=false' : ''}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaVersione(id) {
  return api.delete(`${repositoryUrl}/file/version/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getInfoCartella(id) {
  return api.get(`${repositoryUrl}/cartella/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function spostaFile(id, body) {
  return api.put(`${repositoryUrl}/file/sposta/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function spostaCartella(id, body) {
  return api.put(`${repositoryUrl}/cartella/sposta/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// async function downloadFile(id) {
//   return api.get(`${repositoryUrl}/file/${id}`)
//     .then((res) => res && res.data)
//     .catch((err) => Promise.reject(err.response));
// }

const repositoryService = {
  getElementiPath,
  uploadFile,
  creaCartella,
  modificaCartella,
  eliminaCartella,
  getConfigurazione,
  modificaConfigurazione,
  eliminaFile,
  uploadVersione,
  modificaFile,
  getFile,
  modificaAutorizzazioni,
  getListaVersioni,
  eliminaVersione,
  getInfoCartella,
  spostaFile,
  spostaCartella,
  // downloadFile,
};

export default repositoryService;
