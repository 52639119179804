/* eslint-disable jsx-a11y/label-has-associated-control */
import { Editor } from '@tinymce/tinymce-react';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tiny from '../../../../utils/TinyMCEConfiguration';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';
import newsService from '../../../../services/newsService';
import { Context } from '../../../../utils/Context';
import ModaleConferma from '../../../../components/modali/ModaleConferma';

function ModificaNews() {
  const [context, setContext] = useContext(Context);
  const { idNews } = useParams();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const buttonCreateDisabled = useRef(true);
  const [nuovaNews, setNuovaNews] = useState({
    contenuto: '',
    titolo: '',
    inEvidenza: false,
    nomeImmagine: '',
    nomeImmagineEdit: '',
  });

  function onChangeTitle(event) {
    buttonCreateDisabled.current = false;

    setNuovaNews({ ...nuovaNews, titolo: event.target.value });
  }

  function handleEditorChange(event) {
    buttonCreateDisabled.current = false;
    setNuovaNews({ ...nuovaNews, contenuto: event.target.getContent() });
  }

  const onUpload = (e) => {
    buttonCreateDisabled.current = false;
    const { id, value, files } = e.target;
    setNuovaNews({
      ...nuovaNews,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  function onChangeEvidenza(event) {
    buttonCreateDisabled.current = false;
    setNuovaNews({ ...nuovaNews, inEvidenza: event.target.checked });
  }

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  async function getNews() {
    const response = await newsService.getNewsById(idNews);
    setNuovaNews(response);
  }

  async function editNews() {
    await newsService.editNews(idNews, nuovaNews)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  useEffect(() => {
    getNews();
  }, []);

  return (
    <section id="aggiungi-news">
      <TitlePage
        title="Modifica notizia"
        subtitle="Utilizza l'editor presente per modificare la notizia."
        showIndietro
        functionIndietro={() => { history.push('/area-riservata/news'); }}
      />
      <Form.Group className="mb-3" controlId="title">
        <Form.Label>Titolo *</Form.Label>
        <Form.Control value={nuovaNews.titolo} onChange={onChangeTitle} />
      </Form.Group>
      <label htmlFor="content-html" className="mb-1">Contenuto *</label>
      <Editor
        id="content-html"
        initialValue={nuovaNews.contenuto}
        init={{
          plugins: tiny.plugins,
          toolbar: tiny.toolbar,
        }}
        apiKey={tiny.apiKey}
        onChange={handleEditorChange}
      />
      <Row>
        <Form.Group className="mt-3" as={Col} controlId="nomeImmagine">
          <Form.Label>Immagine</Form.Label>
          <Form.Control value={nuovaNews.nomeImmagineEdit} type="file" size="sm" onChange={onUpload} />
          <p>
            Immagine attualmente scelta:
            {' '}
            <strong>{nuovaNews?.nomeImmagine?.split('\\')?.at(-1) || 'nessuna'}</strong>
          </p>
          <Form.Text muted>
            È possibile inserire soltanto file di dimensioni non superiori a 5 MB.
            Sono supportati soltanto i seguenti formati: .jpg, .png, .gif
          </Form.Text>
        </Form.Group>
        <Col>
          <div className="d-flex flex-column align-items-end mt-5">
            <strong>Scegli se inserire la notizia in evidenza</strong>
            {' '}
            <span>
              <label>
                <input type="checkbox" checked={nuovaNews.inEvidenza} onChange={onChangeEvidenza} />
                {' '}
                in Evidenza
              </label>
            </span>
          </div>
        </Col>
      </Row>
      <Button
        disabled={buttonCreateDisabled.current}
        onClick={handleStateModaleConferma}
        className="float-end mt-3"
      >
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        SALVA
      </Button>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione modificherà la notizia "${nuovaNews.titolo}"`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={editNews}
      />
    </section>
  );
}

export default ModificaNews;
