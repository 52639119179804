/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Context } from '../../../../utils/Context';
import templateService from '../../../../services/templateService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import { formatoDataEOra } from '../../../../utils/formatData';
import themeColors from '../../../../utils/contants/theme-colors';
import CardGroupStatistiche from '../../../../components/Template/CardGroupStatistiche';
import history from '../../../../utils/history';
import ExportButton from '../../../../components/ExportButton/ExportButton';
import CardGroupStatisticheQuestionari from '../../../../components/Template/CardGroupStatisticheQuestionari';

const templateUrl = process.env.REACT_APP_QUESTIONARI_URL;

function VisualizzaStatistiche() {
  const { idTemplate } = useParams();
  const [context, setContext] = useContext(Context);
  const [showSezioneQuestionari, setShowSezioneQuestionari] = useState(false);
  const [questionari, setQuestionari] = useState();
  const [newTemplate, setNewTemplate] = useState({
    titolo: '',
    descrizione: '',
    creatore: {
      nome: '',
      cognome: '',
    },
    dataCreazione: '',
    autoreUltimaModifica: {
      nome: '',
      cognome: '',
    },
    dataUltimaModifica: '',
    sezioni: {},
  });
  const [statistiche, setStatistiche] = useState({
    titolo: '',
    descrizione: '',
    template: {},
  });

  function handleSezioneQuestionari() {
    setShowSezioneQuestionari(!showSezioneQuestionari);
  }

  async function getTemplate() {
    try {
      const response = await templateService.getTemplateById(idTemplate);
      setNewTemplate(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  async function getStatisticheTemplate() {
    try {
      const response = await templateService.getStatisticaByTemplate(idTemplate);
      setStatistiche(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  async function getListaQuestionariByTemplate() {
    try {
      const response = await templateService.getListaQuestionariByTemplate(idTemplate);
      setQuestionari(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  function showQuestionario(idQuestionario) {
    history.push(`/area-riservata/questionari/visualizza-questionario/${idQuestionario}`);
  }

  async function exportTemplate(id, formato) {
    const a = document.createElement('A');
    a.href = `${templateUrl}/template/${id}/statistica/esporta?formato=${formato}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => {
    getTemplate();
    getStatisticheTemplate();
    getListaQuestionariByTemplate();
  }, []);

  return (
    <div>
      <TitlePage
        title="Template"
        subtitle="Visualizza Statistiche Template"
        showIndietro
        functionIndietro={() => history.push('/area-riservata/template')}
        showButtonExport
        buttonExport={(
          <ExportButton
            functionExport={exportTemplate}
            id={idTemplate}
          />
)}
      />

      <div>
        Creata da
        <strong>
          {' '}
          {newTemplate?.creatore?.nome}
          {' '}
          {newTemplate?.creatore?.cognome}
        </strong>
        {' '}
        il
        {' '}
        <strong>{formatoDataEOra(newTemplate.dataCreazione)}</strong>
        <br />
        Ultima modifica:
        <strong>
          {' '}
          {newTemplate?.autoreUltimaModifica?.nome}
          {' '}
          {newTemplate?.autoreUltimaModifica?.cognome}
        </strong>
        {' '}
        il
        {' '}
        <strong>{formatoDataEOra(newTemplate.dataUltimaModifica)}</strong>
      </div>
      <br />

      <div>
        <Row>
          <Col>
            <p className="h4" style={{ lineHeight: '1.6' }}>
              <FontAwesomeIcon aria-hidden="true" icon="info" color={themeColors.primary} />
              {' '}
              Informazioni principali
            </p>
          </Col>
        </Row>
        <Form className="mt-3">
          <Row className="my-3">
            <Form.Group as={Col} lg controlId="titolo">
              <Form.Label>Titolo</Form.Label>
              <Form.Control value={newTemplate.titolo} disabled />
            </Form.Group>
          </Row>

          <Row className="my-3">
            <Form.Group as={Col} lg controlId="descrizione">
              <Form.Label>Descrizione</Form.Label>
              <Form.Control value={newTemplate.descrizione} disabled />
            </Form.Group>
          </Row>
        </Form>
        <Row>
          <Col className="mb-3">
            <Form.Group as={Col} lg controlId="tipo">
              <Form.Label>Stato</Form.Label>
              <Form.Control value={newTemplate.stato} disabled />
            </Form.Group>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col className="mb-3">
            <strong>
              Numero questionari compilati:
            </strong>
            {' '}
            {statistiche.numeroQuestionariCompilati}
            <br />
            <strong>
              Numero questionari non completati:
            </strong>
            {' '}
            {statistiche.numeroQuestionariNonCompletati}
          </Col>
          <Col>
            <Col className="col-lg-auto mt-2 text-end">
              <Button variant="primary" onClick={handleSezioneQuestionari}>
                {' '}
                <FontAwesomeIcon aria-hidden="true" icon={['fas', 'file-alt']} />
                {' '}
                {!showSezioneQuestionari ? 'VISUALIZZA QUESTIONARI' : 'NASCONDI QUESTIONARI'}
                {' '}
              </Button>
            </Col>
          </Col>
        </Row>

        <div className="separator border-1 border-bottom" />
        <br />

        {showSezioneQuestionari ? (
          <CardGroupStatisticheQuestionari
            questionari={questionari}
            functionShow={showQuestionario}
          />
        ) : null }

        <Row>
          <Col>
            <p className="h4" style={{ lineHeight: '1.6' }}>
              <FontAwesomeIcon aria-hidden="true" icon="file-alt" color={themeColors.primary} />
              {' '}
              Sezioni Template
            </p>
          </Col>
        </Row>
        <CardGroupStatistiche
          sezioni={newTemplate.sezioni}
          statistiche={statistiche.sezioni}
          numQuestionari={statistiche.numeroQuestionariCompilati}
        />
      </div>
    </div>
  );
}

export default VisualizzaStatistiche;
