import React from 'react';
import {
  Form, Row, Button, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FiltriRicercaRuoli({ onChangeFiltriRuolo, getListaRuoli, listaTagRuolo }) {
  return (
    <Row id="form-cerca-utenti">
      <Row>
        {' '}
        <h2>Filtri ricerca</h2>
      </Row>
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="fk_sequ_tag_ruolo">
            <Form.Label>TAG ruolo</Form.Label>
            <Form.Select
              onChange={onChangeFiltriRuolo}
            >
              <option> </option>
              {listaTagRuolo.map((tag) => (
                <option
                  key={tag.sequ_k_tag_ruolo}
                  value={tag.sequ_k_tag_ruolo}
                >
                  {tag.desc_nome_tag_ruolo}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>

        <Button variant="primary" className="float-end" onClick={() => getListaRuoli(0, 5, 1)} aria-label="Ricerca le informazioni secondo i filtri inseriti">
          <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} />
          {' '}
          CERCA
        </Button>
      </Form>
    </Row>
  );
}

export default FiltriRicercaRuoli;
