import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Table,
} from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import sitiWebService from '../../../services/sitiWebService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';

const GestioneTraduzioni = () => {
  const [context, setContext] = useContext(Context);
  const [treeData, setTreeData] = useState([]);

  async function getAlbero() {
    try {
      const response = await sitiWebService.getAlbero();
      setTreeData(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChange(e, traduzioni) {
    const { id, value } = e.target;

    setTreeData(
      treeData
        .map((el) => (el.traduzioni.sequ_k_menu_disponibile === traduzioni.sequ_k_menu_disponibile
          ? ({ ...el, traduzioni: { ...el.traduzioni, [id]: value } })
          : el)),
    );
  }

  async function upsertAlbero() {
    try {
      const response = await sitiWebService.upsertAlbero(treeData);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getAlbero();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione traduzioni sito web"
        subtitle="È possibile gestire le traduzioni delle sezioni del sito web."
        showSave
        functionSave={upsertAlbero}
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Italiano</th>
            <th>Inglese</th>
            <th>Francese</th>
            <th>Tedesco</th>
            <th>Spagnolo</th>
          </tr>
        </thead>
        <tbody>
          {
          treeData.map((el) => (
            <>
              <tr className="mb-3">
                <td>
                  <Form.Group as={Col} controlId="desc_menu_it">
                    <Form.Label>Nome sezione</Form.Label>
                    <Form.Control
                      placeholder="Inserisci il nome"
                      value={el.traduzioni.desc_menu_it}
                      disabled
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} controlId="desc_menu_en">
                    <Form.Label>Nome sezione</Form.Label>
                    <Form.Control
                      placeholder="Inserisci il nome"
                      value={el.traduzioni.desc_menu_en}
                      onChange={(e) => onChange(e, el.traduzioni)}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} controlId="desc_menu_fr">
                    <Form.Label>Nome sezione</Form.Label>
                    <Form.Control
                      placeholder="Inserisci il nome"
                      value={el.traduzioni.desc_menu_fr}
                      onChange={(e) => onChange(e, el.traduzioni)}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} controlId="desc_menu_de">
                    <Form.Label>Nome sezione</Form.Label>
                    <Form.Control
                      placeholder="Inserisci il nome"
                      value={el.traduzioni.desc_menu_de}
                      onChange={(e) => onChange(e, el.traduzioni)}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group as={Col} controlId="desc_menu_es">
                    <Form.Label>Nome sezione</Form.Label>
                    <Form.Control
                      placeholder="Inserisci il nome"
                      value={el.traduzioni.desc_menu_es}
                      onChange={(e) => onChange(e, el.traduzioni)}
                    />
                  </Form.Group>
                </td>
              </tr>
            </>
          ))
      }
        </tbody>
      </Table>
    </>
  );
};

export default GestioneTraduzioni;
