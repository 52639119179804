/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import tiny from '../../../utils/TinyMCEConfiguration';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import faqService from '../../../services/faqService';
import canaliService from '../../../services/canaliService';
import ModaleRicercaUtenti from '../../../components/Repository/ModaleRicercaUtenti';
import AutorizzazioniForum from '../../../components/Forum/AutorizzazioniForum';

function AggiungiFaq() {
  const [faq, setFaq] = useState({
    autorizzazioni: [],
    argomento: '',
    domanda: '',
    risposta: '',
  });
  const { idFaq } = useParams();
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [showModaleConferma, setShowModaleConferma] = useState(false);
  const [context, setContext] = useContext(Context);
  const [listaIdentita, setListaIdentita] = useState([]);
  const [identitaScelta, setIdentitaScelta] = useState();
  const [indexAutorizzazioneScelta, setIndexAutorizzazioneScelta] = useState();

  const handleCloseModaleConferma = () => setShowModaleConferma(!showModaleConferma);
  const handleCloseModaleUtenti = () => setShowModaleUtenti(!showModaleUtenti);

  const onChangeAutorizzazioni = (prop, event, index, type) => {
    const old = faq.autorizzazioni[index];
    const updated = { ...old, [prop]: type === 'int' ? parseInt(event.target.value, 10) : event.target.value };
    const clone = [...faq.autorizzazioni];
    clone[index] = updated;
    setFaq({
      ...faq,
      autorizzazioni: clone,
    });
  };

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  function aggiungiRow() {
    setFaq({
      ...faq,
      autorizzazioni: [
        ...faq.autorizzazioni,
        {
          idIdentita: 0,
          codiceFiscale: null,
        },
      ],
    });
  }

  function eliminaRow(index) {
    setFaq({
      ...faq,
      autorizzazioni: [
        ...faq.autorizzazioni.filter((el, indexEl) => indexEl !== index)],
    });
  }

  async function getFaq() {
    const response = await faqService.getFaqById(idFaq);
    setFaq({
      autorizzazioni: response.autorizzazioni,
      argomento: response.argomento,
      domanda: response.domanda,
      risposta: response.risposta,
    });
  }

  async function editFaq() {
    await faqService.editFaq(idFaq, faq)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleCloseModaleConferma();
      });
  }

  const onChange = (e) => {
    const { value, id } = e.target;
    setFaq({
      ...faq,
      [id]: value,
    });
  };

  const handleEditorChange = (e) => {
    setFaq({ ...faq, risposta: e.target.getContent() });
  };

  useEffect(() => {
    getListaIdentita();
    getFaq();
  }, []);

  return (
    <>
      <TitlePage
        title="Modifica FAQ"
        subtitle="In questa sezione puoi modificare una FAQ (Frequently Asked Question)."
        showIndietro
        showSave
        functionIndietro={() => {
          history.push('/area-riservata/gestione-faq');
        }}
        functionSave={handleCloseModaleConferma}
      />
      <Form>
        <Form.Group className="mb-3" controlId="argomento">
          <Form.Label>Argomento</Form.Label>
          <Form.Control placeholder="Inserisci l'argomento" value={faq.argomento} onChange={onChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="domanda">
          <Form.Label>Domanda</Form.Label>
          <Form.Control placeholder="Inserisci la domanda" value={faq.domanda} onChange={onChange} />
        </Form.Group>

        <label htmlFor="risposta" className="mb-1">Risposta*</label>
        <Editor
          id="risposta"
          value={faq.risposta}
          init={{
            plugins: tiny.plugins,
            toolbar: tiny.toolbar,
          }}
          apiKey={tiny.apiKey}
          onChange={handleEditorChange}
        />
      </Form>

      <div className="separator border-1 border-bottom mt-1" />

      <Row>
        <Col>
          <p className="h4" style={{ lineHeight: '1.6' }}>
            Regole di visibilità
          </p>
        </Col>
      </Row>
      <AutorizzazioniForum
        autorizzazioni={faq.autorizzazioni}
        listaIdentita={listaIdentita}
        aggiungiRow={aggiungiRow}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        handleCloseModaleUtenti={handleCloseModaleUtenti}
        setIndexAutorizzazioneScelta={setIndexAutorizzazioneScelta}
        eliminaRow={eliminaRow}
        setIdentitaScelta={setIdentitaScelta}
      />

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione modificherà questa FAQ"
        show={showModaleConferma}
        handleClose={handleCloseModaleConferma}
        handleOk={editFaq}
      />

      {showModaleUtenti && (
      <ModaleRicercaUtenti
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        idIdentita={identitaScelta}
        listaIdentita={listaIdentita}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        indexAutorizzazioneScelta={indexAutorizzazioneScelta}
      />
      )}
    </>
  );
}

export default AggiungiFaq;
