import { useContext, useEffect, useState } from 'react';
import {
  Row, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Title from '../../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import FormRichiestaIdentita from '../../../../../components/RichiesteIdentita/Form';
import ModaleEvasioneRichiesta from '../../../../../components/RichiesteIdentita/ModaleEvasioneRichiesta';
import richiestIdentitaService from '../../../../../services/richiestaIdentitaService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';

function DettaglioRichiestaIdentitaAmministratore() {
  const { idRichiesta } = useParams();
  const [context, setContext] = useContext(Context);
  const [richiestaIdentita, setRichiestaIdentita] = useState({});
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [openCloseModaleEvasione, setOpenCloseModaleEvasione] = useState(false);
  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function handleStateModaleEvasione() {
    setOpenCloseModaleEvasione(!openCloseModaleEvasione);
  }

  async function presaInCarico() {
    richiestIdentitaService.presaIncarico(idRichiesta)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-richieste-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function evasione() {
    richiestIdentitaService.evasione(idRichiesta, richiestaIdentita.desc_msg_risposta || '')
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-richieste-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  function onChange(e) {
    const { value } = e.target;
    const { id } = e.target;
    setRichiestaIdentita({
      ...richiestaIdentita,
      [id]: value,
    });
  }

  async function getRichiestaIdentita() {
    setRichiestaIdentita(await richiestIdentitaService.getRichiestaIdentitaById(idRichiesta));
  }

  useEffect(() => {
    getRichiestaIdentita();
  }, []);

  return (
    <>
      <Row id="form-richiesta-identita">
        <Row>
          <Title
            title="Dettaglio richiesta identità"
            subtitle="È possibile prendere in carico o evadere la richiesta"
            showIndietro
            functionIndietro={() => history.push('/area-riservata/amministrazione/gestione-richieste-identita')}
          />
        </Row>
        <FormRichiestaIdentita
          richiestaIdentita={richiestaIdentita}
          disabled
        />
        <div className="d-flex justify-content-between">
          {richiestaIdentita.codi_stato_richiesta === 'A'
            ? (
              <Button variant="primary" onClick={handleStateModaleConferma}>
                Prendi in carico
              </Button>
            ) : null }
          {richiestaIdentita.codi_stato_richiesta === 'C'
            ? (
              <Button variant="primary" onClick={handleStateModaleEvasione}>
                Evadi
              </Button>
            ) : null }
        </div>
      </Row>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione effettuerà la presa in carico'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={presaInCarico}
      />
      <ModaleEvasioneRichiesta
        richiestaIdentita={richiestaIdentita}
        onChange={onChange}
        evasione={evasione}
        handleStateModaleEvasione={handleStateModaleEvasione}
        show={openCloseModaleEvasione}
      />
    </>
  );
}

export default DettaglioRichiestaIdentitaAmministratore;
