/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState, useContext } from 'react';
import {
  Col, Row, Button, Form, Card, Accordion,
} from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import messaggioService from '../../../services/messaggioService';
import history from '../../../utils/history';
import tiny from '../../../utils/TinyMCEConfiguration';
import { Context } from '../../../utils/Context';
import Pagination from '../../../components/Forum/Pagination';
import CardGroupMessaggi from '../../../components/Forum/CardGroupMessaggi';
import { controlla } from '../../../utils/verificaPermessi';
import Regex from '../../../utils/regex';

function ForumMessaggi() {
  const [context, setContext] = useContext(Context);
  const params = new URLSearchParams(window.location.search);
  const idSezione = params.get('id');
  const [messaggi, setMessaggi] = useState([]);
  const [sezione, setSezione] = useState([]);
  const [richText, setRichText] = useState('');
  const [link, setLink] = useState('');
  const [showModaleConfermaElimina, setShowModaleConfermaElimina] = useState(false);
  const [showFormConfermaInvio, setShowFormConfermaInvio,
  ] = useState(false);
  const [showModaleConfermaInvio, setShowModaleConfermaInvio] = useState(false);
  const [idMessaggio, setIdMessaggio] = useState('');
  const [filtro, setFiltro] = useState({
    messaggio: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  function onChangeFiltro(e, type) {
    const { value, id, checked } = e.target;
    setFiltro({
      ...filtro,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function handleStateDeleteMessage(id) {
    setShowModaleConfermaElimina(!showModaleConfermaElimina);
    setIdMessaggio(id);
  }

  function handleStateNewMessageForm() {
    setShowFormConfermaInvio(!showFormConfermaInvio);
  }

  function handleStateNewMessageModal() {
    setShowModaleConfermaInvio(!showModaleConfermaInvio);
  }

  async function onChangeRichText(content) {
    setRichText(content);
  }

  async function onChangeLink(e) {
    setLink(e.target.value);
  }

  async function getMessaggiById(offset, limit) {
    try {
      const response = await messaggioService.getMessaggiById(filtro, idSezione, offset, limit);
      setSezione(response.sezione);
      setMessaggi(response?.messaggi || []);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.numMessaggi,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function deleteMessaggio() {
    await messaggioService.deleteMessaggio(idMessaggio)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getMessaggiById(0, 5);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateDeleteMessage();
      });
  }

  async function newMessaggio() {
    const messaggio = {
      idSezione,
      messaggio: richText,
      link,
    };

    if ((link && Regex.urlConHttp.test(link)) || !link) {
      await messaggioService.newMessaggio(messaggio)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
          }));
          getMessaggiById(0, 5);
          setLink('');
          setRichText('');
          handleStateNewMessageForm();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        })
        .finally(() => {
          handleStateNewMessageModal();
        });
    } else {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Link non valido', statusCode: 400,
      }));
      handleStateNewMessageModal();
    }
  }

  useEffect(() => {
    getMessaggiById(0, 5);
  }, []);

  return (
    <div>
      <TitlePage
        title="Forum"
        subtitle="Gestione messaggi"
        showIndietro
        showRefresh
        functionIndietro={() => history.push('/area-riservata/forum')}
        functionRefresh={() => {
          getMessaggiById(0, 5);
        }}
      />

      <p>
        Sezione visualizzata:
        {' '}
        <strong>{sezione.titolo}</strong>
      </p>
      <Accordion className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filtri di ricerca</Accordion.Header>
          <Accordion.Body>
            <Form>
              <Col className="mb-3">
                <Form.Group as={Col} lg controlId="messaggio">
                  <Form.Label>Testo da cercare</Form.Label>
                  <Form.Control
                    placeholder="Cerca messaggi"
                    onChange={onChangeFiltro}
                    value={filtro.messaggio}
                  />
                </Form.Group>
              </Col>
              {controlla('W2') && (
              <Col>
                <Form.Check
                  type="switch"
                  id="profonditaStorica"
                  label="Visualizza profondità storica"
                  onChange={(e) => onChangeFiltro(e, 'checkbox')}
                  value={filtro.profonditaStorica}
                />
              </Col>
              )}
            </Form>
            <Button variant="primary" onClick={() => { getMessaggiById(0, 5); }}>
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <CardGroupMessaggi
        sezione={sezione}
        messaggi={messaggi}
        title="Messaggi:"
        functionDelete={handleStateDeleteMessage}
        button={(
          <Button className="float-end" onClick={handleStateNewMessageForm}>
            {!showFormConfermaInvio
              ? (
                <>
                  <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
                  {' '}
                  AGGIUNGI
                </>
              )
              : (
                <>
                  <FontAwesomeIcon aria-hidden="true" icon={['fas', 'minus']} />
                  {' '}
                  CHIUDI
                </>
              )}
          </Button>
        )}
        boxNuovoMessaggio={
          showFormConfermaInvio
            ? (
              <>
                <Card className="g-4 mb-3">
                  <Card.Header>
                    <Form>
                      <Row className="mb-3">
                        <label htmlFor="content-html">Inserisci il messaggio</label>
                        <Editor
                          id="content-html"
                          init={{
                            plugins: tiny.plugins,
                            toolbar: tiny.toolbar,
                          }}
                          apiKey={tiny.apiKey}
                          onEditorChange={onChangeRichText}
                        />
                      </Row>
                      <Row>
                        <Form.Group as={Col} lg controlId="link" className="mb-3">
                          <Form.Label>Link</Form.Label>
                          <Form.Control
                            placeholder="Inserisci il link"
                            onChange={onChangeLink}
                            value={link}
                          />
                        </Form.Group>
                      </Row>
                    </Form>
                    <Button disabled={!richText.trim()} variant="primary" className="float-end" onClick={handleStateNewMessageModal}>
                      <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
                      {' '}
                      INVIA
                    </Button>
                  </Card.Header>
                </Card>

              </>
            )
            : null
}
      />

      {pagination.totalSize !== 0 ? (
        <Pagination
          functionShow={getMessaggiById}
          pagination={pagination}
        />
      ) : <p className="mt-3">Questa sezione non contiene messaggi</p>}

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione invierà il messaggio inserito'}
        show={showModaleConfermaInvio}
        handleClose={handleStateNewMessageModal}
        handleOk={() => newMessaggio()}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione eliminerà il seguente messaggio"
        show={showModaleConfermaElimina}
        handleClose={handleStateDeleteMessage}
        handleOk={() => deleteMessaggio()}
      />
    </div>
  );
}

export default ForumMessaggi;
