/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card, Col, Row,
} from 'react-bootstrap';
import { formatoDataEOra } from '../../utils/formatData';
import { controlla } from '../../utils/verificaPermessi';
import ExportButton from '../ExportButton/ExportButton';

function CardGroup({
  questionari, functionCompile, functionExport, functionShow, functionAdd,
}) {
  return (

    <div className="mt-3">
      <Row lg={1} xs={1} className="g-4 mt-2 mb-2">
        {questionari.length !== 0 ? questionari.map((el) => (
          <Col key={el._id}>
            <Card>
              <Card.Header>
                <Row>
                  <Col lg={10}>
                    <h3>
                      {el?.titolo}
                    </h3>
                    {el.stato === 'pubblicato' ? (
                      <div>
                        Creato da
                        <strong>
                          {' '}
                          {el?.creatore?.nome}
                          {' '}
                          {el?.creatore?.cognome}
                        </strong>
                        {' '}
                        il
                        {' '}
                        <strong>{formatoDataEOra(el.dataCreazione)}</strong>
                        <br />
                        Ultima modifica:
                        <strong>
                          {' '}
                          {el?.autoreUltimaModifica?.nome}
                          {' '}
                          {el?.autoreUltimaModifica?.cognome}
                        </strong>
                        {' '}
                        il
                        {' '}
                        <strong>{formatoDataEOra(el.dataUltimaModifica)}</strong>
                      </div>
                    ) : (
                      <div>
                        Data inizio Compilazione:
                        {' '}
                        <strong>
                          {formatoDataEOra(el.dataInizioCompilazione)}
                        </strong>
                        <br />
                        {el?.dataFineCompilazione
                          ? (
                            <>
                              Data fine compilazione:
                              {' '}
                              <strong>{formatoDataEOra(el.dataFineCompilazione)}</strong>
                            </>
                          )
                          : (
                            <>
                              Ultima modifica:
                              {' '}
                              <strong>{formatoDataEOra(el.dataUltimaModifica)}</strong>
                            </>
                          )}

                      </div>
                    )}
                  </Col>
                  <Col lg={2}>
                    <ButtonGroup className="float-end" aria-label={`Azioni disponibili per il questionario ${el.titolo}`}>
                      {el.stato === 'bozza' && controlla('X61')
                        ? (
                          <Button
                            className="me-2"
                            onClick={() => functionCompile(el._id)}
                            title={`Compila il questionario ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="edit" />
                          </Button>
                        ) : null}
                      {el.stato === 'bozza' && controlla('X63')
                        ? (
                          <Button
                            className="me-2"
                            onClick={() => functionShow(el._id)}
                            title={`Visualizza il questionario ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="search" />
                          </Button>
                        ) : null}
                      {el.stato === 'pubblicato' && controlla('X60')
                        ? (
                          <Button
                            className="me-2"
                            onClick={() => functionAdd(el._id)}
                            title={`Inizia la compilazione del questionario ${el.titolo}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="plus" />
                          </Button>
                        ) : null}
                    </ButtonGroup>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <strong>Descrizione: </strong>
                    {el?.descrizione}
                    <br />
                    <strong>Domande: </strong>
                    {el?.totaleDomande}
                    <br />
                    <strong>Stato: </strong>
                    {el.stato}
                  </Col>
                  {el.stato !== 'pubblicato'
                    ? (
                      <Col>
                        <div className="float-end">
                          <ExportButton
                            id={el._id}
                            functionExport={functionExport}
                          />
                        </div>
                      </Col>
                    ) : null}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )) : <p><strong>Non sono presenti ancora questionari.</strong></p>}
      </Row>
    </div>

  );
}

export default CardGroup;
