import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatoDataEOra } from '../../../../utils/formatData';
import { controlla } from '../../../../utils/verificaPermessi';

function controllaStato(liste, idLista) {
  const lista = liste.filter((el) => el.id === idLista);
  if (lista?.subscription_status === 'unsubscribed') {
    return '✔';
  }
  return '-';
}
const fields = (deleteUtente, idLista) => [

  {
    dataField: 'name',
    text: 'Nominativo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'subscription_status',
    text: 'Eliminato',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (
      <>
        {controllaStato(row.lists, idLista)}
      </>
    ),
  },
  {
    dataField: 'created_at',
    text: 'Data Creazione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => (formatoDataEOra(e)),
  },
  {
    dataField: 'updated_at',
    text: 'Data Modifica',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => (formatoDataEOra(e)),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '10%' }),
    // text: "Azioni"
    // eslint-disable-next-line no-unused-vars
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
      }}
      >
        <>

          {controlla('X80') && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash-alt']}
            size="lg"
            title={`Elimina l'utente ${row.name} dalla lista`}
            id={`Elimina${row.id}`}
            style={{ color: '#0059b3' }}
            onClick={() => { deleteUtente(row.id); }}
          />
          )}

        </>
      </div>

    ),
  },
];

export default fields;
