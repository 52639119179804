/* eslint-disable jsx-a11y/label-has-associated-control */
import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import tiny from '../../utils/TinyMCEConfiguration';

export default function InfoPagina({
  onChangePagina,
  onChangeDescrizione, contenuto, currentLingua, showTextEditor,
}) {
  return (
    <Form className="mb-3">
      <Row className="mb-3">
        <Form.Group as={Col} lg controlId="titolo">
          <Form.Label>Titolo*</Form.Label>
          <Form.Control
            placeholder="Inserisci il titolo"
            onChange={onChangePagina}
            value={contenuto.contenuti[currentLingua].titolo}
          />
        </Form.Group>
        <Form.Group as={Col} lg controlId="sottotitolo">
          <Form.Label>Sottotitolo*</Form.Label>
          <Form.Control
            placeholder="Inserisci il sottotitolo"
            onChange={onChangePagina}
            value={contenuto.contenuti[currentLingua].sottotitolo}
          />
        </Form.Group>
      </Row>
      {showTextEditor ? (
        <Row>
          <label htmlFor="content-html">Inserisci la descrizione*</label>
          <Editor
            value={contenuto.contenuti[currentLingua].descrizione}
            id="content-html"
            init={{
              height: 200,
              plugins: tiny.plugins,
              toolbar: tiny.toolbar,
            }}
            apiKey={tiny.apiKey}
            onEditorChange={(text) => onChangeDescrizione(text)}
          />
        </Row>
      ) : null }
    </Form>
  );
}
