import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from '../../components/HeaderPrivata';
import './styles.css';
import { clear, getItem, removeItem } from '../../utils/storage';
import Footer from '../../components/Footer/FooterHtml';
import { ContextProvider } from '../../utils/Context';
import ErrorBoundary from '../../components/ErrorBoundary';
import Error from '../../components/Alert';
import history from '../../utils/history';
import Spinner from '../../components/Spinner';
import { controlla } from '../../utils/verificaPermessi';
import authProviderService from '../../services/authProviderService';
import api from '../../utils/api';

const OspiteLayout = ({
  component: Component, code, ...rest
}) => {
  const [show, setShow] = useState(false);

  async function getNuovoCsrfToken() {
    if (!api.defaults.headers.common['X-CSRF-Token']) {
      await authProviderService.nuovoCsrfToken()
        .then((response) => {
          api.defaults.headers.common['X-CSRF-Token'] = response.csrfToken;
        })
        .catch(() => {
          removeItem('user');
          history.push('/');
        });
    }
  }

  useEffect(async () => {
    if (getItem('user') && JSON.parse(getItem('user')).trusted === undefined) {
      await getNuovoCsrfToken();
      setTimeout(() => {
        setShow(true);
      }, 200);
    } else {
      history.push(`/pagina-login-ospite?redirect-url=${history.location.pathname}`);
      clear();
    }
  }, []);

  useEffect(() => {
    if (code && !controlla(code)) {
      history.push('/agid-logout');
    }
  }, [window.location.href]);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <ContextProvider>
            <div className="d-flex" id="wrapper">

              <div id="page-content-wrapper">
                <Header areaOspite />
                <Error />
                <Container className="my-4" id="content" role="main">
                  <ErrorBoundary>
                    {show && <Component {...matchProps} />}
                  </ErrorBoundary>
                </Container>
              </div>
            </div>
            <Footer />
            <Spinner />
          </ContextProvider>
        </>
      )}
    />
  );
};

export default OspiteLayout;
