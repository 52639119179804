import { formatoData } from '../../utils/formatData';

export const fieldsProfonditaStorica = [
  {
    dataField: 'sequ_k_funzione',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome_funzione',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '40%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
];

export const fieldsNoProfonditaStorica = [
  {
    dataField: 'sequ_k_funzione',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome_funzione',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '50%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '40%' }),
    formatter: (e) => formatoData(e),
  },
];
