/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import canaliService from '../../../services/canaliService';
import chatService from '../../../services/chatService';
import { Context } from '../../../utils/Context';
import { client } from '../../../utils/matrix';
import Table from '../../Shared/Table';
import { fieldsUtenti, selectRows } from './fieldsUtenti';
import FiltriRicercaUtentiChat from './FiltriRicercaUtentiChat';

const NewPeopleModal = ({
  show,
  handleClose,
  room,
  people,
}) => {
  const [context, setContext] = useContext(Context);
  const [listaIdentita, setListaIdentita] = useState([]);
  const [listaUtenti, setListaUtenti] = useState([]);
  const [utentiScelti, setUtentiScelti] = useState(people.map(
    (el) => ({ codi_matrix_user_id: el.userId }),
  ));
  const [utentiSceltiCopia, setUtentiSceltiCopia] = useState(people.map(
    (el) => ({ codi_matrix_user_id: el.userId }),
  ));
  const [filtri, setFiltri] = useState({
    testo: '',
    identita: null,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  async function getListaUtenti(offset, limit, page) {
    const response = await canaliService.getUtentiByCanali(filtri, offset, limit);
    setListaUtenti(response.data);
    setPagination((prevPag) => ({
      ...prevPag,
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    }));
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getListaUtenti(currentIndex, sizePerPage, page);
  };

  async function aggiungiUtentiInStanza() {
    chatService.aggiungiUtentiInStanza(utentiScelti, client.getHomeserverUrl(), room.roomId)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: 'Gli utenti sono stati inseriti correttamente. Entro breve faranno parte della stanza',
        }));
        handleClose();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: 400,
        }));
      });
  }

  useEffect(async () => {
    await getListaIdentita();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="xl"
      role="dialog"
      aria-labelledby="modal-room-creation"
    >
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Seleziona gli utenti da aggiungere alla stanza</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FiltriRicercaUtentiChat
          listaIdentitaCanali={listaIdentita}
          getListaUtenti={getListaUtenti}
          onChangeFiltriUtenti={onChangeFiltri}
        />
        <Table
          title="Ricerca utenti"
          data={listaUtenti}
          fields={fieldsUtenti}
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="codi_matrix_user_id"
          selectRow={selectRows(setUtentiScelti, utentiScelti, utentiSceltiCopia)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
        <Button variant="primary" onClick={aggiungiUtentiInStanza} disabled={utentiScelti.length === utentiSceltiCopia.length}>
          Aggiungi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewPeopleModal;
