import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Form, Row, Button, Col,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import { fieldsProfonditaStoricaIdentita, fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Identita/fieldsTableIdentita';
import { fieldsProfonditaStorica, fieldsNoProfonditaStorica } from '../../../../../components/Identita/fieldsTableRuoli';
import FormDettaglioIdentita from '../../../../../components/Identita/FormDettaglioIdentita';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import Table from '../../../../../components/Shared/Table';
import identitaService from '../../../../../services/identitaService';
import organizzazioniService from '../../../../../services/organizzazioniService';
import TagIdentitaService from '../../../../../services/tagIdentitaService';
import tipoIdentitaService from '../../../../../services/tipoIdentitaService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';

function DettaglioIdentita() {
  const { idIdentita } = useParams();
  const [context, setContext] = useContext(Context);
  const [listaTagIdentita, setListaTagIdentita] = useState([]);
  const [listaOrganizzazioni, setListaOrganizzazioni] = useState([]);
  const [listaTipoIdentita, setListaTipoIdentita] = useState([]);
  const [nuovaIdentita, setNuovaIdentita] = useState({
    desc_nome_identita: '',
    desc_identita: '',
    desc_note_identita: '',
    fk_codi_tipo_identita: 0,
    fk_sequ_tag_identita: 0,
    fk_sequ_organizzazione: 0,
    data_inizio_validita: '',
    identita: [],
    ruoli: [],
  });
  const [filtriIdentita, setFiltriIdentita] = useState({
    profondita_storica_ruoli: false,
    profondita_storica_figlie: false,
    profondita_storica_madri: false,
  });
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function getListaTagIdentita() {
    const response = await TagIdentitaService.getTagIdentita();
    setListaTagIdentita(response.data);
  }

  async function getListaOrganizzazioni() {
    const response = await organizzazioniService.getOrganizzazioni();
    setListaOrganizzazioni(response);
  }

  async function getListaTipoIdentita() {
    const response = await tipoIdentitaService.getTipoIdentita();
    setListaTipoIdentita(response);
  }

  async function getIdentita() {
    setNuovaIdentita(await identitaService.getIdentitaById(idIdentita, filtriIdentita));
  }

  async function deleteIdentita() {
    identitaService.eliminaIdentita(nuovaIdentita.sequ_k_identita)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  function onChangeFiltriIdentita(e) {
    const { id } = e.target;
    const { value } = e.target;
    setFiltriIdentita({
      ...filtriIdentita,
      [id]: value,
    });
  }

  useEffect(() => {
    getIdentita();
  }, [filtriIdentita]);

  useEffect(() => {
    getListaTagIdentita();
    getListaOrganizzazioni();
    getListaTipoIdentita();
    getIdentita();
  }, []);

  return (
    <>
      <TitlePage
        title="Dettaglio identità"
        subtitle="È possibile visualizzare i dettagli dell'identità"
        showIndietro
        functionIndietro={() => {
          history.push('/area-riservata/amministrazione/gestione-identita');
        }}
      />
      <Row className="mb-3 w-25">
        <Form.Group as={Col} controlId="sequ_k_identita">
          <Form.Label>ID</Form.Label>
          <Form.Control
            placeholder="ID identità"
            value={nuovaIdentita.sequ_k_identita}
            disabled
          />
        </Form.Group>
      </Row>

      <FormDettaglioIdentita
        listaTagIdentita={listaTagIdentita}
        listaOrganizzazioni={listaOrganizzazioni}
        listaTipoIdentita={listaTipoIdentita}
        nuovaIdentita={nuovaIdentita}
        disabled
      />
      <Row className="mb-3 border-top border-dark">
        <Table
          title="Elenco ruoli associati"
          data={nuovaIdentita.ruoli}
          fields={filtriIdentita.profondita_storica_ruoli === 'true' ? fieldsProfonditaStorica : fieldsNoProfonditaStorica}
          keyField="sequ_k_ruolo"
          profonditaStorica={(
            <Form.Group controlId="profondita_storica_ruoli">
              <Form.Label>Visualizza profondità storica</Form.Label>
              <Form.Select
                onChange={onChangeFiltriIdentita}
                value={filtriIdentita.profondita_storica_ruoli}
              >
                <option value="true">Sì</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Group>
        )}
        />
      </Row>
      <Row className="mb-3 border-top border-dark">
        <Table
          title="Lista identità referenziate da questa identità"
          data={nuovaIdentita.identita || []}
          fields={filtriIdentita.profondita_storica_figlie === 'true' ? fieldsProfonditaStoricaIdentita : fieldsNoProfonditaStoricaIdentita}
          keyField="sequ_k_identita"
          profonditaStorica={(
            <Form.Group controlId="profondita_storica_figlie">
              <Form.Label>Visualizza profondità storica</Form.Label>
              <Form.Select
                onChange={onChangeFiltriIdentita}
                value={filtriIdentita.profondita_storica_figlie}
              >
                <option value="true">Sì</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Group>
        )}
        />
      </Row>
      <Row className="mb-3 border-top border-dark">
        <Table
          title="Lista identità che referenziano questa identità"
          data={nuovaIdentita.identitaMadri || []}
          fields={filtriIdentita.profondita_storica_madri === 'true' ? fieldsProfonditaStoricaIdentita : fieldsNoProfonditaStoricaIdentita}
          keyField="sequ_k_identita"
          profonditaStorica={(
            <Form.Group controlId="profondita_storica_madri">
              <Form.Label>Visualizza profondità storica</Form.Label>
              <Form.Select
                onChange={onChangeFiltriIdentita}
                value={filtriIdentita.profondita_storica_madri}
              >
                <option value="true">Sì</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Group>
        )}
        />
      </Row>
      <br />
      {new URLSearchParams(window.location.search).get('elimina')
        ? (
          <>
            <div className="d-flex justify-content-end">
              <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
                <FontAwesomeIcon aria-hidden="true" icon="trash" />
                {' '}
                Elimina
              </Button>
            </div>
            <ModaleConferma
              labelBottone="Conferma"
              coloreAzione="primary"
              title="Sei sicuro di procedere?"
              body={'L\'operazione eliminerà l\'identità selezionata'}
              show={openCloseModaleConferma}
              handleClose={handleStateModaleConferma}
              handleOk={deleteIdentita}
            />
          </>
        )
        : null }
    </>
  );
}

export default DettaglioIdentita;
