/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';

const Context = React.createContext([{}, () => {}]);

const ContextProvider = (props) => {
  const [state, setState] = useState({
    open: false,
    openConferma: false,
    testo: '',
    statusCode: null,
  });

  return (
    <Context.Provider value={[state, setState]}>
      {props.children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
