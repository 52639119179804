import api from '../utils/api';

const chatUrl = process.env.REACT_APP_CHAT_URL;

async function eliminaStanza(idRoom, urlHomeserver) {
  return api.delete(`${chatUrl}/room/${idRoom}`, { params: { desc_url_interno: urlHomeserver } })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function loginMatrix(identificativo) {
  return api.post(`${chatUrl}/login/${identificativo}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// eslint-disable-next-line camelcase
async function aggiungiUtentiInStanza(utenti, desc_url, codi_k_id_room) {
  const body = {
    utenti: utenti
    // Filtro per distinguere gli utenti già aggiunti da quelli scelti
      .filter((el) => el.codi_id_fiscale)
      .map((el) => el.codi_matrix_user_id),
    desc_url,
    codi_k_id_room,
  };
  return api.post(`${chatUrl}/room/aggiungi-utenti`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const chatService = {
  eliminaStanza,
  loginMatrix,
  aggiungiUtentiInStanza,
};

export default chatService;
