import React, { useContext, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import repositoryService from '../../services/repositoryService';
import { Context } from '../../utils/Context';

const ModaleNuovaCartella = ({
  show, setModalShow, pathRepo, getElementiByPath,
}) => {
  const [nomeCartella, setNomeCartella] = useState();
  const [context, setContext] = useContext(Context);

  async function handleOkConferma() {
    await repositoryService.creaCartella(`${pathRepo}/${nomeCartella}`)
      .then((res) => {
        if (res.status !== 'error') {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
          }));
        } else {
          setContext(() => ({
            ...context, open: true, testoErrore: res.message, statusCode: 400,
          }));
        }
        getElementiByPath(0, 5, 1, pathRepo);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setModalShow(false);
      });
  }
  const onChange = (e) => {
    setNomeCartella(e.target.value);
  };
  return (
    <>
      <Modal show={show} onHide={() => setModalShow(false)} role="dialog">
        <Modal.Header closeButton closeLabel="Chiudi">
          <Modal.Title>Creazione nuova cartella</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="nomeCartella">
            <Form.Label>Nome cartella</Form.Label>
            <Form.Control placeholder="Inserisci il nome della cartella" onChange={onChange} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="grey" onClick={() => setModalShow(false)}>
            Chiudi
          </Button>
          <Button variant="primary" onClick={handleOkConferma} disabled={!nomeCartella}>
            Crea
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModaleNuovaCartella;
