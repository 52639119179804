export const fields = [
  {
    dataField: 'sequ_k_funzione',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '33%' }),
  },
  {
    dataField: 'desc_nome_funzione',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '42%' }),
  },
  {
    dataField: 'desc_nome_tag_funzione',
    text: 'Tag',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },

];

export const selectRows = (setFunzioniScelte, funzioniScelte) => {
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...funzioniScelte].map((el) => el.sequ_k_funzione),
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFunzioniScelte([...funzioniScelte, row]);
      } else {
        setFunzioniScelte(funzioniScelte.filter(
          (funzione) => funzione.sequ_k_funzione !== row.sequ_k_funzione,
        ));
      }
    },

  };
  return selectRow;
};
