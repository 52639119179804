import { useContext, useState } from 'react';
import {
  Col, Form, Row, Button,
} from 'react-bootstrap';
import tagRuoliService from '../../services/tagRuoliService';
import { Context } from '../../utils/Context';

function NuovoTagRuolo({ closeModal }) {
  const [context, setContext] = useContext(Context);
  const [nome, setNome] = useState('');
  const [descrizione, setDescrizione] = useState('');
  const [note, setNote] = useState('');

  function onChangeNome(event) {
    setNome(event.target.value);
  }

  function onChangeDescrizione(event) {
    setDescrizione(event.target.value);
  }

  function onChangeNote(event) {
    setNote(event.target.value);
  }

  function checkValidate() {
    return nome && descrizione;
  }

  async function addTag() {
    const tagModel = {
      desc_nome_tag_ruolo: nome,
      desc_tag_ruolo: descrizione,
      desc_note_tag_ruolo: note,
    };
    if (checkValidate()) {
      await tagRuoliService.nuovoTagRuolo(tagModel)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          closeModal();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Alcuni campi non sono valorizzati', statusCode: 400,
      }));
    }
  }

  return (
    <>
      <Row id="form-inserimento-tag-ruolo">
        <Form className="mt-3">
          <Row className="mb-3">
            <Form.Group as={Col} lg controlId="nome">
              <Form.Label>Nome*</Form.Label>
              <Form.Control placeholder="Inserisci nome" value={nome} onChange={onChangeNome} />
            </Form.Group>

            <Form.Group as={Col} lg controlId="descrizione">
              <Form.Label>Descrizione*</Form.Label>
              <Form.Control placeholder="Inserisci descrizione" value={descrizione} onChange={onChangeDescrizione} />
            </Form.Group>

          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg controlId="cf">
              <Form.Label>Note</Form.Label>
              <Form.Control placeholder="Inserisci note" as="textarea" value={note} onChange={onChangeNote} />
            </Form.Group>
          </Row>

          <Button variant="primary" className="float-end" onClick={addTag}>
            AGGIUNGI
          </Button>
        </Form>
      </Row>
    </>
  );
}

export default NuovoTagRuolo;
