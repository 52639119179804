import { getItem } from './storage';

export default function controllaRepository(azione, oggetto) {
  const user = JSON.parse(getItem('user'));
  const identita = JSON.parse(getItem('identita'));
  const permessi = JSON.parse(getItem('permessi'));
  if (permessi?.filter((el) => el.codi_k_permesso === 'W1').length) {
    return true;
  }
  if (user.identificativo === oggetto?.creatore?.identificativo) {
    return true;
  }
  if (oggetto?.autorizzazioni?.length === 0) {
    return true;
  }
  const autorizzazione = oggetto?.autorizzazioni?.filter(
    (el) => el.idIdentita === identita.idIdentita
    || (el.idIdentita === identita.idIdentita && el.identificativo === user.identificativo),
  );
  if (autorizzazione?.[0]?.operazioni?.includes(azione)) {
    return true;
  }
  return false;
}
