export default function parseNome(nome) {
  let stringa;
  const primaParte = nome.split(' ')[0].substring(0, 1);
  const secondaParte = nome.split(' ')[1]?.substring(0, 1);
  if (secondaParte) {
    stringa = primaParte + secondaParte;
  } else {
    stringa = primaParte;
  }
  return stringa;
}
