/* eslint-disable no-underscore-dangle */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Badge } from 'react-bootstrap';
import trimContent from '../../../utils/trimContent';
import { controlla } from '../../../utils/verificaPermessi';
import styles from './index.module.scss';

const RoomsList = ({
  onClickRoom,
  clickedRoom,
  rooms,
  onOpenNewRoomModal,
}) => {
  function onClickCreateRoom() {
    onOpenNewRoomModal();
  }
  // Funzione che parsa l'ultimo evento
  function parseLastMessage(event) {
    if (event?.type === 'm.room.message') {
      if (event.content.msgtype === 'm.text') {
        return event.content.body;
      }
      return 'File';
    }
    return '-';
  }

  return (
    <Card className={`p-3 mb-3 ${styles.roomsListCard}`}>
      {controlla('X21') && (
      <Button
        onClick={onClickCreateRoom}
        className=" p-2 mb-3 "
      >
        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'plus-circle']}
          size="1x"
          style={{ color: '#fff' }}
        />
        {' '}
        <b>Crea stanza</b>
      </Button>
      )}
      <div><strong>Elenco stanze</strong></div>
      <div className={styles.roomsList}>
        {
        rooms
          .filter((room) => room.name !== 'Notifiche')
          .map((roomElement) => (
            <div key={roomElement.roomId}>
              <button
                type="button"
                className={`p-3 d-flex align-items-center justify-content-between w-100 ${styles.roomsItem} ${roomElement === clickedRoom ? styles.roomsItemActive : null}`}
                onClick={() => onClickRoom(roomElement)}
              >
                <span>
                  <p className={styles.roomName}><strong>{roomElement.name}</strong></p>
                  <p className={styles.roomArgument}>
                    {trimContent(parseLastMessage(
                      roomElement.timeline[roomElement.timeline.length - 1]?.event,
                    ), 30)}
                  </p>
                </span>
                {roomElement.notificationCounts.total
                && roomElement.roomId !== clickedRoom?.roomId
                  ? <Badge bg="primary">{roomElement.notificationCounts.total}</Badge>
                  : null }
                <FontAwesomeIcon
                  aria-hidden="true"
                  icon={['fas', 'chevron-right']}
                  size="1x"
                  style={{ color: '#fff' }}
                />
              </button>
              {/* <div className="separator border-1 border-bottom my-3" /> */}
            </div>
          ))
        }
      </div>
    </Card>
  );
};

export default RoomsList;
