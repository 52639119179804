import React from 'react';
import {
  Accordion,
  Form,
} from 'react-bootstrap';

const FormNewsletter = ({ versione, onChange, disabled }) => (
  <Accordion className="mb-3">
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        Newsletter
      </Accordion.Header>
      <Accordion.Body>
        <strong>
          Se abilitata, tale funzione permette di gestire una newsletter per
          tenere informate le persone interessate alle varie attività del museo.

        </strong>
        <br />
        Coloro che volessero iscriversi alla newsletter potranno farlo inserendo
        la propria mail nell&apos;apposito campo del sito web.
        <Form.Check
          type="switch"
          id="flag_newsletter"
          label="Si desidera aggiungere la newsletter al sito?"
          className="mt-3"
          onChange={onChange}
          value={versione.flag_newsletter}
          checked={versione.flag_newsletter}
          disabled={disabled}
        />
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
);

export default FormNewsletter;
