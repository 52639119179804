/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useEffect, useState } from 'react';
import {
  Tree,
  getBackendOptions,
  MultiBackend,
  getDescendants,
} from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import styles from './App.module.css';
import CustomNode from '../../../components/GestioneSitoWeb/CustomNode';
import ModaleCreazioneNuovoElemento from '../../../components/GestioneSitoWeb/ModaleCreazioneNuovoElemento';
import sitiWebService from '../../../services/sitiWebService';
import { Context } from '../../../utils/Context';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';

const getLastId = (treeData) => {
  const reversedArray = [...treeData].sort((a, b) => {
    if (a.id < b.id) {
      return 1;
    } if (a.id > b.id) {
      return -1;
    }

    return 0;
  });

  if (reversedArray.length > 0) {
    return reversedArray[0].id;
  }

  return 0;
};

export default function App() {
  const [context, setContext] = useContext(Context);
  const [treeData, setTreeData] = useState([]);
  const [openModaleNuovoElemento, setOpenModaleNuovoElemento] = useState(false);
  // const [elementiEliminati, setElementiEliminati] = useState([]);

  function openCloseModaleAggiungiElemento() {
    setOpenModaleNuovoElemento(!openModaleNuovoElemento);
  }

  const handleDrop = (
    newTree,
    // {
    //   dragSourceId, dropTargetId, dragSource, dropTarget,
    // },
  ) => {
    // console.log(dragSourceId, dropTargetId, dragSource, dropTarget);

    // Do something
    setTreeData(newTree);
  };

  // FUNZIONE DEPRECATA - non si eliminano, ma si deprecano
  // const handleDelete = (id, sequ_k_menu_disponibile) => {
  //   const deleteIds = [
  //     id,
  //     ...getDescendants(treeData, id).map((node) => node.id),
  //   ];
  //   const deleteSequ = [
  //     sequ_k_menu_disponibile,
  //     ...getDescendants(treeData, id).map((node) => node.sequ_k_menu_disponibile),
  //   ];
  //   const newTree = treeData.filter((node) => !deleteIds.includes(node.id));
  //   setElementiEliminati([...elementiEliminati, ...deleteSequ]);
  //   setTreeData(newTree);
  // };

  const handleCopy = (id) => {
    const lastId = getLastId(treeData);
    const targetNode = treeData.find((n) => n.id === id);
    const descendants = getDescendants(treeData, id);
    const partialTree = descendants.map((node) => ({
      ...node,
      id: node.id + lastId,
      parent: node.parent + lastId,
    }));

    setTreeData([
      ...treeData,
      {
        ...targetNode,
        id: targetNode.id + lastId,
      },
      ...partialTree,
    ]);
  };

  const handleSubmit = (newNode) => {
    const lastId = getLastId(treeData) + 1;

    setTreeData([
      ...treeData,
      {
        ...newNode,
        id: lastId,
        flagDeprecato: false,
      },
    ]);
    openCloseModaleAggiungiElemento();
  };

  const handleTextChange = (id, value) => {
    const newTree = treeData.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          text: value,
        };
      }

      return node;
    });

    setTreeData(newTree);
  };

  const handleFlagDinamicoChange = (id, value) => {
    const newTree = treeData.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          flagDinamico: value,
        };
      }

      return node;
    });

    setTreeData(newTree);
  };

  const handleFlagDeprecatoChange = (id, value) => {
    const newTree = treeData.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          flagDeprecato: value,
        };
      }

      return node;
    });

    setTreeData(newTree);
  };

  async function getAlbero() {
    try {
      const response = await sitiWebService.getAlbero();
      if (!response.length) {
        setTreeData([{
          text: 'Homepage',
          id: 1,
          droppable: true,
          parent: 0,
          flagDinamico: false,
          flagDeprecato: false,
        }]);
      } else {
        setTreeData(response);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function upsertAlbero() {
    try {
      const response = await sitiWebService.upsertAlbero(treeData);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getAlbero();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione albero siti web"
        subtitle="È possibile gestire la struttura del sito web."
      />
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Button onClick={openCloseModaleAggiungiElemento} className="m-2 text-uppercase">
          <FontAwesomeIcon aria-hidden="true" icon="plus" />
          Aggiungi elemento
        </Button>
        <Button onClick={upsertAlbero} className="m-2 float-end text-uppercase">
          <FontAwesomeIcon aria-hidden="true" icon="save" />
          Salva
        </Button>
        <Tree
          classes={{
            root: styles.treeRoot,
            draggingSource: styles.draggingSource,
            dropTarget: styles.dropTarget,
          }}
          tree={treeData}
          rootId={0}
          onDrop={handleDrop}
          dragPreviewRender={(monitorProps) => {
            const { item } = monitorProps;

            return (
              <div>
                <p>{item.text}</p>
              </div>
            );
          }}
          render={(node, options) => (
            <CustomNode
              node={node}
              {...options}
              // onDelete={handleDelete}
              onCopy={handleCopy}
              onTextChange={handleTextChange}
              onFlagDinamicoChange={handleFlagDinamicoChange}
              onFlagDeprecatoChange={handleFlagDeprecatoChange}
            />
          )}
        />
        {openModaleNuovoElemento && (
        <ModaleCreazioneNuovoElemento
          tree={treeData}
          onClose={openCloseModaleAggiungiElemento}
          onSubmit={handleSubmit}
          show={openModaleNuovoElemento}
        />
        )}
      </DndProvider>
    </>
  );
}
