import React from 'react';
import {
  Card, Col, Form, Row,
} from 'react-bootstrap';

const FormTitolo = ({ titolo, onChange, disabled }) => (
  <Card className="mb-3">
    <Card.Body>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="titolo">
          <Form.Label>Inserisci il titolo:</Form.Label>
          <Form.Control
            placeholder="Inserisci il titolo della pagina"
            value={titolo}
            onChange={onChange}
            disabled={disabled}
          />
        </Form.Group>
      </Row>
    </Card.Body>
  </Card>
);

export default FormTitolo;
