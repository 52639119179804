import React from 'react';
import {
  Form, Row, Button, Col, Accordion,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setItem } from '../../../utils/storage';
import { dataAttuale } from '../../../utils/dataAttuale';

function FiltriUtente({
  filtri,
  onChange,
  getUtenti,
  listaTipiConnettore,
}) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="desc_nome">
                <Form.Label>Nome</Form.Label>
                <Form.Control placeholder="Inserisci il nome" onChange={onChange} value={filtri.desc_nome} />
              </Form.Group>

              <Form.Group as={Col} lg controlId="desc_cognome">
                <Form.Label>Cognome</Form.Label>
                <Form.Control placeholder="Inserisci il cognome" onChange={onChange} value={filtri.desc_cognome} />
              </Form.Group>

              <Form.Group as={Col} lg controlId="identificativo">
                <Form.Label>Identificativo</Form.Label>
                <Form.Control placeholder="Inserisci il C.F. o il codice connettore" onChange={onChange} value={filtri.identificativo} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="fk_codi_tipo_connettore">
                <Form.Label>Tipo connettore</Form.Label>
                <Form.Select
                  onChange={onChange}
                  value={filtri.fk_codi_tipo_connettore}
                >
                  <option> </option>
                  {listaTipiConnettore.map((tag) => (
                    <option
                      key={tag.codi_k_tipo_connettore}
                      value={tag.codi_k_tipo_connettore}
                    >
                      {tag.desc_tipo_connettore}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} lg controlId="trusted">
                <Form.Label>Trusted?</Form.Label>
                <Form.Select
                  onChange={onChange}
                  value={filtri.trusted}
                >
                  <option> </option>
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_registrazione_from">Data registrazione dal</Form.Label>
                <input
                  type="date"
                  id="data_registrazione_from"
                  name="dataRegistrazioneFrom"
                  min="2021-01-01"
                  max={dataAttuale()}
                  value={filtri.data_registrazione_from}
                  className="form-control"
                  onChange={onChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_registrazione_to">Data registrazione al</Form.Label>
                <input
                  type="date"
                  id="data_registrazione_to"
                  name="dataRegistrazioneTo"
                  min={filtri.data_registrazione_from || '2021-01-01'}
                  max={dataAttuale()}
                  value={filtri.data_registrazione_to}
                  className="form-control"
                  onChange={onChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="col-lg-6" controlId="profondita_storica">
                <Form.Label>Visualizza profondità storica</Form.Label>
                <Form.Select
                  onChange={onChange}
                  value={filtri.profondita_storica}
                >
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Button variant="primary" onClick={() => { setItem('filtri', JSON.stringify(filtri)); getUtenti(0, 5, 1); }}>
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriUtente;
