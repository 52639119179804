import React, { useContext, useEffect, useState } from 'react';
import sezioneService from '../../../services/sezioneService';
import CardGroup from '../../../components/Forum/CardGroup';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import FiltriRicercaForum from '../../../components/Forum/FiltriRicerca';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import { Context } from '../../../utils/Context';
import Pagination from '../../../components/Forum/Pagination';
import { controlla } from '../../../utils/verificaPermessi';

function Forum() {
  const [context, setContext] = useContext(Context);
  const [sezioni, setSezioni] = useState([]);
  const [sezioniInEvidenza, setSezioniInEvidenza] = useState([]);
  const [idSezione, setIdSezione] = useState();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [openModaleConfermaChiusura, setOpenModaleConfermaChiusura] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    titolo: '',
    descrizione: '',
  });

  function handleStateModaleConferma(id) {
    setOpenModaleConferma(!openModaleConferma);
    setIdSezione(id);
  }

  function handleStateModaleConfermaChiusura(id) {
    setOpenModaleConfermaChiusura(!openModaleConfermaChiusura);
    setIdSezione(id);
  }

  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  async function getSezioni(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await sezioneService.getSezioni(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setSezioni(response?.sezioni || []);
      setSezioniInEvidenza(response?.sezioniInEvidenza || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function deleteSection() {
    await sezioneService.deleteSection(idSezione)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getSezioni(0, 5);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  async function editSection(sezioneId) {
    history.push(`/area-riservata/forum/edit/${sezioneId}`);
  }

  async function closeSection() {
    await sezioneService.chiudiSezione(idSezione)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getSezioni(0, 5);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConfermaChiusura();
      });
  }

  function showSection(id) {
    history.push(`/area-riservata/forum/messaggi?id=${id}`);
  }

  useEffect(() => {
    getSezioni(0, 5);
  }, []);

  return (
    <div>
      <TitlePage
        title="Forum"
        subtitle="Gestione sezioni"
        showAdd={controlla('X46') === true}
        showRefresh
        functionAdd={() => history.push('/area-riservata/forum/sezione')}
        functionRefresh={() => {
          getSezioni(0, 5);
        }}
      />
      <FiltriRicercaForum
        filtri={filtri}
        getSezioni={getSezioni}
        onChange={onChangeFiltri}
      />
      {sezioniInEvidenza.length
        ? (
          <CardGroup
            sections={sezioniInEvidenza}
            title="Sezioni in evidenza:"
            type="sezione"
            functionShow={showSection}
            functionEdit={editSection}
            functionDelete={handleStateModaleConferma}
            functionClose={handleStateModaleConfermaChiusura}
          />
        ) : null}
      <CardGroup
        sections={sezioni.filter((el) => !el.inEvidenza)}
        title="Sezioni:"
        type="sezione"
        functionShow={showSection}
        functionEdit={editSection}
        functionDelete={handleStateModaleConferma}
        functionClose={handleStateModaleConfermaChiusura}
      />
      {pagination.totalSize !== 0 ? (
        <Pagination
          functionShow={getSezioni}
          pagination={pagination}
        />
      ) : <strong>Non sono presenti sezioni</strong>}
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione eliminerà la sezione selezionata"
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => deleteSection()}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione chiuderà la sezione selezionata"
        show={openModaleConfermaChiusura}
        handleClose={handleStateModaleConfermaChiusura}
        handleOk={() => closeSection()}
      />
    </div>
  );
}

export default Forum;
