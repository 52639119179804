import React from 'react';
import { Link } from 'react-router-dom';

const ConfermaInvioQuestionario = () => (
  <>
    <div className="m-2" style={{ textAlign: 'center' }}>
      <h1>Conferma invio questionario</h1>
      <p id="desc">Il questionario è stato correttamente compilato ed inviato.</p>
      <Link to="/">Torna alla home</Link>
    </div>
  </>
);

export default ConfermaInvioQuestionario;
