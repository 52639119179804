import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const fields = (
  setPath,
) => [
  {
    dataField: 'nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '90%' }),
    formatter: (cell, row) => (
      <>
        <FontAwesomeIcon aria-hidden="true" icon="folder" />
        {' '}
        <Link to={`/area-riservata/repository/${encodeURIComponent(row.path.substring(1))}`} onClick={() => setPath(row.path)}>
          {cell}
        </Link>
      </>
    ),
  },
];

export const selectRows = (setPathDestinazione) => {
  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row) => {
      setPathDestinazione(row.path);
    },

  };
  return selectRow;
};

export default fields;
