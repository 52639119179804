import { Router, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import PageNotFound from './pages/NotFound';
import HomePage from './pages';
import Home from './pages/area-riservata';
import Login from './pages/pagina-login';
import history from './utils/history';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout/index';
import GestisciUtenti from './pages/area-riservata/amministrazione/gestione-utenti';
import Chat from './pages/area-riservata/chat';
import Faq from './pages/area-riservata/faq';
import GestioneFaq from './pages/area-riservata/gestione-faq';
import AggiungiFaq from './pages/area-riservata/gestione-faq/aggiungi';
import Newsletter from './pages/area-riservata/newsletter';
import HandleLista from './pages/area-riservata/newsletter/lista';
import CreaCampagna from './pages/area-riservata/newsletter/campagna/creazione';
import ModificaCampagna from './pages/area-riservata/newsletter/campagna/_id/modifica';
import TornaSu from './components/TornaSu';
import HandleUtenti from './pages/area-riservata/newsletter/utenti';
import Forum from './pages/area-riservata/forum';
import Repository from './pages/area-riservata/repository/_path';
import Configurazione from './pages/area-riservata/repository/configurazione';
import Upload from './pages/area-riservata/repository/upload/_path';
import ForumMessaggi from './pages/area-riservata/forum/messaggi';
import ForumSezione from './pages/area-riservata/forum/sezione';
import AggiungiNews from './pages/area-riservata/news/aggiungi';
import ListaNews from './pages/area-riservata/news';
import UserInformation from './pages/area-riservata/account';
import Amministrazione from './pages/area-riservata/amministrazione';
import GestioneRuoli from './pages/area-riservata/amministrazione/gestione-ruolo';
import GestioneIdentita from './pages/area-riservata/amministrazione/gestione-identita';
import SceltaIdentita from './pages/area-riservata/scelta-identita';
import AgidLogin from './pages/agid-login';
import GestionePiattaforma from './pages/area-riservata/amministrazione/gestione-piattaforma';
import GestioneTagRuoli from './pages/area-riservata/amministrazione/gestione-tag-ruolo';
import AggiungiTagRuolo from './pages/area-riservata/amministrazione/gestione-tag-ruolo/aggiungi';
import FormNuovoRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/inserimento';
import FormModificaRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/_id/modifica';
import FormDettaglioRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/_id/dettaglio';
import GestioneTagIdentita from './pages/area-riservata/amministrazione/gestione-tag-identita';
import AggiungiTagIdentita from './pages/area-riservata/amministrazione/gestione-tag-identita/aggiungi';
import AggiungIdentita from './pages/area-riservata/amministrazione/gestione-identita/aggiungi';
import DettaglioIdentita from './pages/area-riservata/amministrazione/gestione-identita/_id/dettaglio';
import ModificaIdentita from './pages/area-riservata/amministrazione/gestione-identita/_id/modifica';
import DettaglioUtentePerAmministratore from './pages/area-riservata/amministrazione/gestione-utenti/_id/dettaglio';
import ModificaUtentePerAmministratore from './pages/area-riservata/amministrazione/gestione-utenti/_id/modifica';
import GestioneRichiesteIdentita from './pages/area-riservata/amministrazione/gestione-richieste-identita';
import DettaglioRichiestaIdentitaAmministratore from './pages/area-riservata/amministrazione/gestione-richieste-identita/_id/dettaglio';
import GestioneRichiesteIdentitaPerUtente from './pages/area-riservata/richiesta-identita';
import DettaglioRichiestaIdentitaPerUtente from './pages/area-riservata/richiesta-identita/_id/dettaglio';
import NuovaRichiestaIdentitaPerUtente from './pages/area-riservata/richiesta-identita/aggiungi';
import LoginSpid from './pages/login';
import ErrorPage from './pages/error';
import DichiarazioneAccessibilita from './pages/dichiarazione-accessibilita';
import PrivacyPolicy from './pages/privacy-policy';
import NoteLegali from './pages/note-legali';
import ScrollToTop from './utils/ScrollToTop';
import AgidLogout from './pages/agid-logout';
import Notifiche from './pages/area-riservata/notifiche';
import FileInfo from './pages/area-riservata/repository/file/_id';
import AutorizzazioniFile from './pages/area-riservata/repository/file/autorizzazioni/idFile';
import VersioniFile from './pages/area-riservata/repository/file/versioni/_idFile';
import AutorizzazioniCartella from './pages/area-riservata/repository/cartella/autorizzazioni/_idCartella';
import ModificaSezione from './pages/area-riservata/forum/edit/idSezione';
import Template from './pages/area-riservata/template';
import Questionari from './pages/area-riservata/questionari';
import GestioneTemplate from './pages/area-riservata/template/gestione-template';
import VisualizzaTemplate from './pages/area-riservata/template/visualizza-template';
import CompilaQuestionario from './pages/area-riservata/questionari/compila-questionario';
import OspiteLayout from './layouts/OspiteLayout';
import LoginOspite from './pages/pagina-login-ospite/index';
import AgidLoginOspite from './pages/agid-login-ospite';
import VisualizzaQuestionario from './pages/area-riservata/questionari/visualizza-questionario';
import VisualizzaStatistiche from './pages/area-riservata/template/statistiche/visualizza-statistiche';
import CompilaQuestionarioEsterno from './pages/questionari/compila-questionario';
import CompilaQuestionarioOspite from './pages/area-ospite/questionari/compila-questionario';
import AccettazioneTemplateOspite from './pages/area-ospite/questionari/_idQuestionario';
import AccettazioneTemplateEsterno from './pages/questionari/_idQuestionario';
import ConfermaInvioQuestionario from './pages/questionari/conferma';
import GestioneSitoWeb from './pages/area-riservata/gestione-sito-web';
import GestioneAmmTrasparente from './pages/area-riservata/gestione-amm-trasparenza';
import GestioneVersioniSitiWeb from './pages/area-riservata/siti-web';
import CreazioneVersioneSitoWeb from './pages/area-riservata/siti-web/crea';
import DettaglioVersioneSitoWeb from './pages/area-riservata/siti-web/_id/dettaglio';
import ModificaVersioneSitoWeb from './pages/area-riservata/siti-web/_id/modifica';
import GestioneTraduzioni from './pages/area-riservata/siti-web/gestisci-traduzioni';
import VisualizzazioneAlbero from './pages/area-riservata/siti-web/_id/albero';
import ModificaPagina from './pages/area-riservata/siti-web/_id/albero/template-statico/_id';
import ModificaPaginaHomepage from './pages/area-riservata/siti-web/_id/albero/template-homepage/_id';
import GestioneContenutiDinamici from './pages/area-riservata/siti-web/contenuti-dinamici';
import GestioneEntitaDinamica from './pages/area-riservata/siti-web/contenuti-dinamici/_nome';
import CreaContenutoDinamico from './pages/area-riservata/siti-web/contenuti-dinamici/_nome/crea';
import ModificaContenutoDinamico from './pages/area-riservata/siti-web/contenuti-dinamici/by-id/_id/modifica';
import ModificaNews from './pages/area-riservata/news/modifica/_id';
import DettaglioNews from './pages/area-riservata/news/dettaglio/_id';
import ModificaFaq from './pages/area-riservata/gestione-faq/modifica';
import HandleEditLista from './pages/area-riservata/newsletter/lista/edit/idLista';
import GestioneVersioniAmmTrasparente from './pages/area-riservata/amm-trasparente';
import CreazioneVersioneAmmTrasparente from './pages/area-riservata/amm-trasparente/crea';
import DettaglioVersioneAmmTrasparente from './pages/area-riservata/amm-trasparente/_id/dettaglio';
import ModificaVersioneAmmTrasparente from './pages/area-riservata/amm-trasparente/_id/modifica';
import VisualizzazioneAlberoAmmTrasparente from './pages/area-riservata/amm-trasparente/_id/albero';
import ModificaPaginaAmmTrasparente from './pages/area-riservata/amm-trasparente/_id/albero/_id';
import ModificaAppEsterna from './pages/area-riservata/gestione-app-esterne/modifica/idApp';
import VisualizzaAppEsterna from './pages/area-riservata/gestione-app-esterne/visualizza/idApp';
import CreaAppEsterna from './pages/area-riservata/gestione-app-esterne/creaApp';
import AppEsterne from './pages/area-riservata/app-esterne';
import GestioneAppEsterne from './pages/area-riservata/gestione-app-esterne';
import ModificaLivelloRappGrafica from './pages/area-riservata/amm-trasparente/_id/albero/rappresentazione-grafica.js';
import CreazioneEntita from './pages/area-riservata/amm-trasparente/contenuti-dinamici/crea';
import GestioneVisualizzazioneEntita from './pages/area-riservata/amm-trasparente/contenuti-dinamici/idEntita/gestione-visualizzazione';
import AggiornamentoDatiEntita from './pages/area-riservata/amm-trasparente/contenuti-dinamici/idEntita/aggiornamento-dati';
import ContenutiDinamiciAmmTrasparente from './pages/area-riservata/amm-trasparente/contenuti-dinamici';
import GestioneAllegatiAmmTrasparente from './pages/area-riservata/amm-trasparente/contenuti-dinamici/idEntita/gestione-allegati';

function App() {
  library.add(fab, fas, far);
  return (
    <div>
      <Router history={history}>
        <Switch>
          <PublicLayout path="/" exact component={HomePage} />
          <PublicLayout path="/pagina-login" exact component={Login} />
          <PublicLayout path="/pagina-login-ospite" exact component={LoginOspite} />
          <PublicLayout path="/login" exact component={LoginSpid} />
          <PublicLayout path="/error" exact component={ErrorPage} />
          <PublicLayout path="/agid-login" exact component={AgidLogin} />
          <PublicLayout path="/agid-login-ospite" exact component={AgidLoginOspite} />
          <PublicLayout path="/agid-logout" exact component={AgidLogout} />
          <PublicLayout path="/note-legali" exact component={NoteLegali} />
          <PublicLayout path="/privacy-policy" exact component={PrivacyPolicy} />
          <PublicLayout path="/dichiarazione-accessibilita" exact component={DichiarazioneAccessibilita} />

          {/* INIZIO PATH COMPILAZIONE QUESTIONARI AREA PUBBLICA  */}
          <PublicLayout path="/questionari/conferma" exact component={ConfermaInvioQuestionario} />
          <PublicLayout path="/questionari/:idTemplate" exact component={AccettazioneTemplateEsterno} />
          <PublicLayout path="/questionari/compila-questionario/:idQuestionario" exact component={CompilaQuestionarioEsterno} />
          {/* FINE PATH COMPILAZIONE QUESTIONARI AREA PUBBLICA  */}

          {/* INIZIO PATH AREA RISERVATA  */}
          <PrivateLayout path="/area-riservata" exact component={Home} />
          <PrivateLayout path="/area-riservata/account" exact component={UserInformation} />
          <PrivateLayout path="/area-riservata/scelta-identita" exact component={SceltaIdentita} />
          <PrivateLayout path="/area-riservata/notifiche" exact component={Notifiche} />
          {/* INIZIO PATH AMMINISTRAZIONE */}
          <PrivateLayout path="/area-riservata/amministrazione" exact component={Amministrazione} code="Y1" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti" exact component={GestisciUtenti} code="Y2" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti/:idUtente/dettaglio" exact component={DettaglioUtentePerAmministratore} code="Y3" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti/:idUtente/modifica" exact component={ModificaUtentePerAmministratore} code="Y4" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-identita" exact component={GestioneIdentita} code="Y5" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/aggiungi" exact component={AggiungIdentita} code="Y6" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/:idIdentita/dettaglio" exact component={DettaglioIdentita} code="Y7" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/:idIdentita/modifica" exact component={ModificaIdentita} code="Y8" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-ruolo" exact component={GestioneTagRuoli} code="Y9" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-ruolo/aggiungi" exact component={AggiungiTagRuolo} code="Y10" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-identita" exact component={GestioneTagIdentita} code="Y11" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-identita/aggiungi" exact component={AggiungiTagIdentita} code="Y12" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo" exact component={GestioneRuoli} code="Y13" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/inserimento" exact component={FormNuovoRuolo} code="Y14" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/:idRuolo/modifica" exact component={FormModificaRuolo} code="Y15" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/:idRuolo/dettaglio" exact component={FormDettaglioRuolo} code="Y16" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-piattaforma" exact component={GestionePiattaforma} code="Y17" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-richieste-identita" exact component={GestioneRichiesteIdentita} code="Y18" />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-richieste-identita/:idRichiesta" exact component={DettaglioRichiestaIdentitaAmministratore} code="Y19" />
          {/* FINE PATH AMMINISTRAZIONE */}

          {/* INIZIO PATH RICHIESTA IDENTITA */}
          <PrivateLayout path="/area-riservata/richiesta-identita" exact component={GestioneRichiesteIdentitaPerUtente} code="Y20" />
          <PrivateLayout path="/area-riservata/richiesta-identita/aggiungi" exact component={NuovaRichiestaIdentitaPerUtente} code="Y21" />
          <PrivateLayout path="/area-riservata/richiesta-identita/:idRichiesta" exact component={DettaglioRichiestaIdentitaPerUtente} code="Y22" />
          {/* FINE PATH RICHIESTA IDENTITA */}

          {/* INIZIO PATH CHAT */}
          <PrivateLayout path="/area-riservata/chat" exact component={Chat} code="Y23" />
          {/* FINE PATH CHAT */}

          {/* INIZIO PATH NEWS */}
          <PrivateLayout path="/area-riservata/news/modifica/:idNews" exact component={ModificaNews} code="Y42" />
          <PrivateLayout path="/area-riservata/news/aggiungi" exact component={AggiungiNews} code="Y43" />
          <PrivateLayout path="/area-riservata/news" exact component={ListaNews} code="Y44" />
          <PrivateLayout path="/area-riservata/news/dettaglio/:id" exact component={DettaglioNews} code="Y45" />
          {/* FINE PATH NEWS */}

          {/* INIZIO PATH FAQ E GESTIONE FAQ */}
          <PrivateLayout path="/area-riservata/faq" exact component={Faq} code="Y46" />
          <PrivateLayout path="/area-riservata/gestione-faq" exact component={GestioneFaq} code="Y47" />
          <PrivateLayout path="/area-riservata/gestione-faq/aggiungi" exact component={AggiungiFaq} code="Y48" />
          <PrivateLayout path="/area-riservata/gestione-faq/modifica/:idFaq" exact component={ModificaFaq} code="Y49" />
          {/* FINE PATH FAQ E GESTIONE FAQ */}

          {/* INIZIO PATH FORUM */}
          <PrivateLayout path="/area-riservata/forum" exact component={Forum} code="Y31" />
          <PrivateLayout path="/area-riservata/forum/messaggi" exact component={ForumMessaggi} code="Y32" />
          <PrivateLayout path="/area-riservata/forum/sezione" exact component={ForumSezione} code="Y33" />
          <PrivateLayout path="/area-riservata/forum/edit/:idSezione" exact component={ModificaSezione} code="Y34" />
          {/* FINE PATH FORUM */}

          {/* INIZIO PATH TEMPLATE */}
          <PrivateLayout path="/area-riservata/template" exact component={Template} code="Y35" />
          <PrivateLayout path="/area-riservata/template/gestione-template/:idTemplate" exact component={GestioneTemplate} code="Y36" />
          <PrivateLayout path="/area-riservata/template/visualizza-template/:idTemplate" exact component={VisualizzaTemplate} code="Y37" />
          <PrivateLayout path="/area-riservata/template/visualizza-statistiche/:idTemplate" exact component={VisualizzaStatistiche} code="Y38" />
          {/* FINE PATH TEMPLATE */}

          {/* INIZIO PATH QUESTIONARI */}
          <PrivateLayout path="/area-riservata/questionari" exact component={Questionari} code="Y39" />
          <PrivateLayout path="/area-riservata/questionari/compila-questionario/:idQuestionario" exact component={CompilaQuestionario} code="Y40" />
          <PrivateLayout path="/area-riservata/questionari/visualizza-questionario/:idQuestionario" exact component={VisualizzaQuestionario} code="Y41" />
          {/* FINE PATH QUESTIONARI */}

          {/* INIZIO PATH NEWSLETTER */}
          <PrivateLayout path="/area-riservata/newsletter" exact component={Newsletter} code="Y50" />
          <PrivateLayout path="/area-riservata/newsletter/lista" exact component={HandleLista} code="Y51" />
          <PrivateLayout path="/area-riservata/newsletter/campagna/creazione" exact component={CreaCampagna} code="Y52" />
          <PrivateLayout path="/area-riservata/newsletter/campagna/:idCampagna/modifica" exact component={ModificaCampagna} code="Y53" />
          <PrivateLayout path="/area-riservata/newsletter/lista/edit/:id" exact component={HandleEditLista} code="Y54" />
          <PrivateLayout path="/area-riservata/newsletter/utenti/:id" exact component={HandleUtenti} code="Y55" />
          {/* FINE PATH NEWSLETTER */}

          {/* INIZIO PATH APP ESTERNE */}
          <PrivateLayout path="/area-riservata/gestione-app-esterne" exact component={GestioneAppEsterne} code="Y56" />
          <PrivateLayout path="/area-riservata/gestione-app-esterne/creazione" exact component={CreaAppEsterna} code="Y57" />
          <PrivateLayout path="/area-riservata/gestione-app-esterne/modifica/:idApp" exact component={ModificaAppEsterna} code="Y58" />
          <PrivateLayout path="/area-riservata/gestione-app-esterne/visualizza/:idApp" exact component={VisualizzaAppEsterna} code="Y59" />
          <PrivateLayout path="/area-riservata/app-esterne" exact component={AppEsterne} code="Y60" />
          {/* FINE PATH APP ESTERNE */}

          {/* INIZIO PATH REPOSITORY */}
          <PrivateLayout path="/area-riservata/repository/configurazione" exact component={Configurazione} code="Y25" />
          <PrivateLayout path="/area-riservata/repository/:path" exact component={Repository} code="Y24" />
          <PrivateLayout path="/area-riservata/repository/upload/:path" exact component={Upload} code="Y26" />
          <PrivateLayout path="/area-riservata/repository/file/:idFile" exact component={FileInfo} code="Y27" />
          <PrivateLayout path="/area-riservata/repository/file/autorizzazioni/:idFile" exact component={AutorizzazioniFile} code="Y28" />
          <PrivateLayout path="/area-riservata/repository/file/versioni/:idFile" exact component={VersioniFile} code="Y29" />
          <PrivateLayout path="/area-riservata/repository/cartella/autorizzazioni/:idCartella" exact component={AutorizzazioniCartella} code="Y30" />
          {/* FINE PATH REPOSITORY */}

          {/* INIZIO PATH GESTIONE SITI WEB */}
          <PrivateLayout path="/area-riservata/gestione-sito-web" exact component={GestioneSitoWeb} code="Y61" />
          {/* FINE PATH GESTIONE SITI WEB */}

          {/* INIZIO PATH SITI WEB */}
          <PrivateLayout path="/area-riservata/siti-web" exact component={GestioneVersioniSitiWeb} code="Y62" />
          <PrivateLayout path="/area-riservata/siti-web/crea" exact component={CreazioneVersioneSitoWeb} code="Y63" />
          <PrivateLayout path="/area-riservata/siti-web/gestisci-traduzioni" exact component={GestioneTraduzioni} code="Y64" />
          <PrivateLayout path="/area-riservata/siti-web/contenuti-dinamici" exact component={GestioneContenutiDinamici} code="Y65" />
          <PrivateLayout path="/area-riservata/siti-web/contenuti-dinamici/:nome" exact component={GestioneEntitaDinamica} code="Y66" />
          <PrivateLayout path="/area-riservata/siti-web/contenuti-dinamici/:nome/crea" exact component={CreaContenutoDinamico} code="Y67" />
          <PrivateLayout path="/area-riservata/siti-web/contenuti-dinamici/by-id/:idContenuto/modifica" exact component={ModificaContenutoDinamico} code="Y68" />
          <PrivateLayout path="/area-riservata/siti-web/:idVersione/dettaglio" exact component={DettaglioVersioneSitoWeb} code="Y69" />
          <PrivateLayout path="/area-riservata/siti-web/:idVersione/modifica" exact component={ModificaVersioneSitoWeb} code="Y70" />
          <PrivateLayout path="/area-riservata/siti-web/:idVersione/albero" exact component={VisualizzazioneAlbero} code="Y71" />
          <PrivateLayout path="/area-riservata/siti-web/:idVersione/albero/template-homepage/:idMenu" exact component={ModificaPaginaHomepage} code="Y72" />
          <PrivateLayout path="/area-riservata/siti-web/:idVersione/albero/template-statico/:idMenu" exact component={ModificaPagina} code="Y73" />
          {/* FINE PATH SITI WEB */}

          {/* INIZIO PATH GESTIONE AMMINISTRAZIONE TRASPARENTE */}
          <PrivateLayout path="/area-riservata/gestione-amm-trasparente" exact component={GestioneAmmTrasparente} code="Y86" />
          {/* FINE PATH GESTIONE AMMINISTRAZIONE TRASPARENTE */}

          {/* INIZIO PATH AMMINISTRAZIONE TRASPARENTE */}
          <PrivateLayout path="/area-riservata/amm-trasparente" exact component={GestioneVersioniAmmTrasparente} code="Y74" />
          <PrivateLayout path="/area-riservata/amm-trasparente/crea" exact component={CreazioneVersioneAmmTrasparente} code="Y75" />
          <PrivateLayout path="/area-riservata/amm-trasparente/:idVersione/dettaglio" exact component={DettaglioVersioneAmmTrasparente} code="Y76" />
          <PrivateLayout path="/area-riservata/amm-trasparente/:idVersione/modifica" exact component={ModificaVersioneAmmTrasparente} code="Y77" />
          <PrivateLayout path="/area-riservata/amm-trasparente/:idVersione/albero" exact component={VisualizzazioneAlberoAmmTrasparente} code="Y78" />
          <PrivateLayout path="/area-riservata/amm-trasparente/:idVersione/albero/:idLivello" exact component={ModificaPaginaAmmTrasparente} code="Y79" />
          <PrivateLayout path="/area-riservata/amm-trasparente/:idVersione/albero/:idLivello/rappresentazione-grafica" exact component={ModificaLivelloRappGrafica} code="Y80" />
          <PrivateLayout path="/area-riservata/amm-trasparente/entita" exact component={ContenutiDinamiciAmmTrasparente} code="Y81" />
          <PrivateLayout path="/area-riservata/amm-trasparente/entita/crea" exact component={CreazioneEntita} code="Y82" />
          <PrivateLayout path="/area-riservata/amm-trasparente/entita/:idEntita/gestione-visualizzazione" exact component={GestioneVisualizzazioneEntita} code="Y83" />
          <PrivateLayout path="/area-riservata/amm-trasparente/entita/:idEntita/aggiornamento-dati" exact component={AggiornamentoDatiEntita} code="Y84" />
          <PrivateLayout path="/area-riservata/amm-trasparente/entita/:idEntita/gestione-allegati" exact component={GestioneAllegatiAmmTrasparente} code="Y85" />
          {/* FINE PATH AMMINISTRAZIONE TRASPARENTE */}

          {/* INIZIO PATH AREA ESTERNA - ATTUALMENTE PER QUESTIONARI TRACCIATI */}
          <OspiteLayout path="/area-ospite/questionari/:idTemplate" exact component={AccettazioneTemplateOspite} />
          <OspiteLayout path="/area-ospite/questionari/compila-questionario/:idQuestionario" exact component={CompilaQuestionarioOspite} />
          {/* FINE PATH AREA ESTERNA */}

          <PrivateLayout path="/area-ospite/*" component={PageNotFound} />
          <PrivateLayout path="/area-riservata/*" component={PageNotFound} />
          {/* FINE PATH AREA RISERVATA  */}
          <PublicLayout path="*" component={PageNotFound} />
        </Switch>
        {/* COMPONENTE CHE FA TORNARE SU AD OGNI CAMBIO PAGINA */}
        <ScrollToTop />
      </Router>
      {/* COMPONENTE GRAFICO PER TORNARE SU */}
      <TornaSu />
    </div>
  );
}

export default App;
