import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getTipoIdentita(filtri) {
  let params = '';
  if (typeof filtri?.flag_protetto === 'boolean') {
    params += `flag_protetto=${filtri.flag_protetto}`;
  }
  return api.get(`${accreditamentoUrl}/gestione-tipo-identita?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const tipoIdentitaService = {
  getTipoIdentita,
};

export default tipoIdentitaService;
