import React from 'react';
import {
  Card, Button, Form, Col,
} from 'react-bootstrap';
import { getItem } from '../../../utils/storage';

function IdentitaCard({
  id, identita, isDefault, handleOnChange, getPermessiByIdentita,
}) {
  return (
    <Col>
      <Card className="text-center" style={{ height: '100%' }}>
        <Card.Body>
          {(JSON.parse(getItem('user'))).trusted
            ? (
              <Form.Check
                type="checkbox"
                id={id}
              >
                <Form.Check.Input
                  type="checkbox"
                  checked={isDefault}
                  onChange={handleOnChange}
                />
                <Form.Check.Label className="fw-bold" htmlFor={id}>
                &nbsp;
                  {identita}
                </Form.Check.Label>
              </Form.Check>
            )
            : <p className="fw-bold">{identita}</p>}

          <br />
          <Button className="mt-3" onClick={() => { getPermessiByIdentita(id, identita); }} aria-label={`Entra con ${identita}`}>Entra</Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default IdentitaCard;
