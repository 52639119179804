import { formatoData } from '../../utils/formatData';

export const fields = [
  {
    dataField: 'sequ_k_ruolo',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
  },
  {
    dataField: 'desc_nome_ruolo',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
  },

  {
    dataField: 'desc_nome_tag_ruolo',
    text: 'Tag',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
    formatter: (e) => formatoData(e),
  },
];

export const selectRows = (setRuoliScelti, ruoliScelti, ruoliRimossi,
  setRuoliRimossi,
  ruoliAggiunti,
  setRuoliAggiunti) => {
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...ruoliScelti].filter((el) => !el.data_fine_validita)
      .map((el) => el.sequ_k_ruolo),
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setRuoliAggiunti([...ruoliAggiunti, row]);
        setRuoliScelti([...ruoliScelti, row]);
      } else {
        setRuoliRimossi([...ruoliRimossi, row]);
        setRuoliScelti(ruoliScelti.filter(
          (funzione) => funzione.sequ_k_ruolo !== row.sequ_k_ruolo,
        ));
      }
    },

  };
  return selectRow;
};
