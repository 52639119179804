import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import FormColori from '../../../../components/Versioni/FormColori';
import FormContatti from '../../../../components/Versioni/FormContatti';
import FormImmagini from '../../../../components/Versioni/FormImmagini';
import FormLingue from '../../../../components/Versioni/FormLingue';
import FormNewsletter from '../../../../components/Versioni/FormNewsletter';
import FormNomeAlias from '../../../../components/Versioni/FormNomeAlias';
import versioniService from '../../../../services/versioniService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

const DettaglioVersioneSitoWeb = () => {
  const { idVersione } = useParams();
  const [context, setContext] = useContext(Context);
  const [versione, setVersione] = useState({
    desc_nome: '',
    desc_alias: '',
    flag_trasparenza: false,
    flag_newsletter: false,
    fk_codi_colore: null,
    lingue: [],
    recapiti: [
      {
        nome: 'Indirizzo struttura', desc_recapito: '', fk_codi_tipo_recapito: 1,
      },
      {
        nome: 'P.IVA/Codice fiscale', desc_recapito: '', fk_codi_tipo_recapito: 2,
      },
      {
        nome: 'Telefono aziendale', desc_recapito: '', fk_codi_tipo_recapito: 3,
      },
      {
        nome: 'Telefono', desc_recapito: '', fk_codi_tipo_recapito: 4,
      },
      {
        nome: 'Email', desc_recapito: '', fk_codi_tipo_recapito: 5,
      },
      {
        nome: 'PEC', desc_recapito: '', fk_codi_tipo_recapito: 6,
      },
      {
        nome: 'Facebook', desc_recapito: '', fk_codi_tipo_recapito: 7,
      },
      {
        nome: 'Twitter', desc_recapito: '', fk_codi_tipo_recapito: 8,
      },
      {
        nome: 'Instagram', desc_recapito: '', fk_codi_tipo_recapito: 9,
      },
      {
        nome: 'Youtube', desc_recapito: '', fk_codi_tipo_recapito: 10,
      },
      {
        nome: 'Portale amministrazione trasparente', desc_recapito: '', fk_codi_tipo_recapito: 11,
      },
    ],
  });
  const [listaColori, setListaColori] = useState([]);
  const [listaLingue, setListaLingue] = useState([]);

  async function getEntita() {
    try {
      const coloriDisponibili = await versioniService.getColoriDisponibili();
      const lingueDisponibili = await versioniService.getLingueDisponibili();
      const versioneById = await versioniService.getVersione(idVersione);
      setListaColori(coloriDisponibili);
      setListaLingue(lingueDisponibili);
      setVersione({
        ...versioneById,
        recapiti: versione.recapiti
          .map((el) => ({
            ...el,
            desc_recapito: versioneById.recapiti
              .find((elem) => elem.fk_codi_tipo_recapito === el.fk_codi_tipo_recapito)
              ?.desc_recapito,
          })),

      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  useEffect(() => {
    getEntita();
  }, []);

  return (
    <>
      <TitlePage
        title="Visualizzazione versione sito web"
        subtitle="È possibile visualizzare la versione del sito web."
        showIndietro
        functionIndietro={() => history.goBack()}
        showAvanti
        functionAvanti={() => history.push(`/area-riservata/siti-web/${idVersione}/albero`)}
      />
      <p className="h5">Sezione 1:</p>
      <div className="h6">
        In questa prima sezione, è obbligatorio inserire:
        <ol>
          <li>il nome della versione, ovvero un nome mnemonico che si vuole dare alla versione;</li>
          <li>l&apos;alias del sito, ovvero l&apos;indirizzo di pubblicazione del sito;</li>
          <li>Il colore che si vuole dare al sito (rosso è di default)</li>
          <li>I recapiti del museo contrassegnati con * (indirizzo, telefono, ecc...)</li>
          <li>Il logo del museo</li>
        </ol>
      </div>
      <p className="h6"> I campi contrassegnati con * sono obbligatori </p>
      <FormNomeAlias
        versione={versione}
        disabled
      />
      <FormNewsletter
        versione={versione}
        disabled
      />
      <FormColori
        versione={versione}
        listaColori={listaColori}
        disabled
      />
      <FormLingue
        versione={versione}
        listaLingue={listaLingue}
        disabled
      />
      <FormContatti
        listaRecapiti={versione.recapiti}
        disabled
      />
      <FormImmagini
        versione={versione}
        disabled
      />
    </>
  );
};

export default DettaglioVersioneSitoWeb;
