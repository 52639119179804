import {
  Col, Form, Row,
} from 'react-bootstrap';
import { formatoData } from '../../utils/formatData';

function FormRichiestaIdentita({
  richiestaIdentita,
  onChange,
  disabled,
  disabledRichiedente,
}) {
  return (
    <>
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="codi_id_fiscale">
            <Form.Label>Richiedente</Form.Label>
            <Form.Control
              placeholder="Inserisci nome"
              value={`${richiestaIdentita.desc_nome} ${richiestaIdentita.desc_cognome}`}
              onChange={onChange}
              disabled={disabled || disabledRichiedente}
            />
          </Form.Group>

          <Form.Group as={Col} lg controlId="data_richiesta">
            <Form.Label>Data richiesta</Form.Label>
            <Form.Control placeholder="Inserisci descrizione" value={formatoData(richiestaIdentita.data_richiesta)} onChange={onChange} disabled={disabled || disabledRichiedente} />
          </Form.Group>

          {!disabledRichiedente ? (
            <Form.Group as={Col} lg controlId="codi_stato_richiesta">
              <Form.Label>Stato Richiesta</Form.Label>
              <Form.Control placeholder="Inserisci nome" value={richiestaIdentita.codi_stato_richiesta === 'A' ? 'In attesa' : richiestaIdentita.codi_stato_richiesta === 'C' ? 'Presa in carico' : 'Evasa'} onChange={onChange} disabled={disabled} />
            </Form.Group>
          ) : null }
        </Row>

        <Row className="mb-3">

          <Form.Group as={Col} lg controlId="desc_msg_richiesta">
            <Form.Label>
              Messaggio di richiesta
              {!disabled ? '*' : ''}
            </Form.Label>
            <Form.Control placeholder="Inserisci descrizione" as="textarea" value={richiestaIdentita.desc_msg_richiesta} onChange={onChange} disabled={disabled} />
          </Form.Group>
        </Row>

        {(!disabledRichiedente && richiestaIdentita.desc_msg_risposta) && (
          <Row className="mb-3">

            <Form.Group as={Col} lg controlId="desc_msg_risposta">
              <Form.Label>Messaggio di risposta</Form.Label>
              <Form.Control placeholder="Risposta" as="textarea" value={richiestaIdentita.desc_msg_risposta} onChange={onChange} disabled={disabled} />
            </Form.Group>
          </Row>
        ) }
      </Form>
    </>
  );
}

export default FormRichiestaIdentita;
