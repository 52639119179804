/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Card, Col, Row, Button, ButtonGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatoData } from '../../utils/formatData';
import RenderHTML from '../Shared/RenderHTML';
import trimContent from '../../utils/trimContent';
import { controlla } from '../../utils/verificaPermessi';

function CardGroup({
  news, title, functionDelete, functionEdit, styles, showButtons,
}) {
  return (

    <div className="mt-3">
      {title ? <h2>{title}</h2> : null}
      <Row xs={1} md={2} className="g-4">
        {news.map((el) => (
          <Col key={el._id}>
            <Card className={`${styles.newsCard} p-2`}>
              {/* <Card.Img variant="top" src={newsAgid} alt="Log
              o notizia" height={250} width={100} /> */}
              <Card.Body>
                <Card.Title>
                  <Row>
                    <Col md={9}>
                      <span className="mb-3">
                        <FontAwesomeIcon
                          aria-hidden="true"
                          icon={['fas', 'calendar']}
                          size="lg"
                          title="Data"
                          style={{ color: 'primary', fontSize: '2.5rem' }}
                        />
                        {' '}
                      </span>
                      {controlla('X65') && <Link to={`/area-riservata/news/dettaglio/${el._id}`}>{el?.titolo}</Link>}
                    </Col>
                    <Col md={3}>
                      <ButtonGroup className="float-end" aria-label={`Azioni disponibili per la news ${el.titolo}`}>
                        {(!el.dataFineValidita && showButtons)
                          ? (
                            <>
                              {controlla('X66')
                              && (
                              <Button
                                className="me-2"
                                onClick={() => functionEdit(el._id)}
                                title={`Modifica la news ${el.titolo}`}
                              >
                                <FontAwesomeIcon aria-hidden="true" icon="edit" />
                              </Button>
                              )}
                              {controlla('X67')
                              && (
                              <Button
                                variant="danger"
                                onClick={() => functionDelete(el._id)}
                                title={`Elimina la news ${el.titolo}`}
                              >
                                <FontAwesomeIcon aria-hidden="true" icon="trash" />
                              </Button>
                              )}
                            </>
                          ) : null}
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Card.Title>
                <pre style={{ overflow: 'hidden' }}>
                  <RenderHTML html={trimContent(el.contenuto, 500)} />
                </pre>
                <div className="separator border-1 border-bottom mt-2 mb-2" />
                <div>
                  Pubblicato da
                  <strong>
                    {' '}
                    {el?.creatore?.nome}
                    {' '}
                    {el?.creatore?.cognome}
                  </strong>
                  {' '}
                  il
                  {' '}
                  <strong>{formatoData(el.dataCreazione)}</strong>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

  );
}

export default CardGroup;
