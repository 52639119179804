import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, FormControl, InputGroup, Row,
} from 'react-bootstrap';
import themeColors from '../../../utils/contants/theme-colors';
import { formatoDataEOra } from '../../../utils/formatData';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';

const fields = (setVersioneScelta, setShowModalNote, richiestaPreview,
  handleDelete, visualizzaVersione, modificaVersione,
  clonaVersione, revocaVersione, pubblicaVersione,
  handleStateModaleRichiestaApprovazione, accettaApprovazione, rifiutaApprovazione) => [
  {
    dataField: 'desc_nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'stato',
    text: 'Stato',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e, row) => (
      <>
        {row.desc_stato_primario}
        {row.desc_stato_secondario ? ` - ${row.desc_stato_secondario}` : ''}
      </>
    ),
  },
  {
    dataField: 'data_ultimo_aggiornamento',
    text: 'Data ultimo aggiornamento',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => (formatoDataEOra(e)),
  },
  {
    dataField: 'note',
    text: 'Note',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
    formatter: (e, row) => (
      <>
        <Button
          variant="outline-secondary"
          id={`apri-note-${row.sequ_k_versione}`}
          onClick={() => { setVersioneScelta(row); setShowModalNote(true); }}
          title={`Apri le note della versione ${row.desc_nome}`}
        >
          <FontAwesomeIcon aria-hidden="true" icon="info" />
        </Button>
      </>
    ),
  },
  {
    dataField: 'desc_link_preview',
    text: 'Link preview',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
    formatter: (e, row) => (
      <>
        {e
          ? (
            <Row>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Link preview"
                  aria-label="Link preview"
                  aria-describedby={`copia-label-${row.sequ_k_versione}`}
                  value={e}
                  readOnly
                />
                <Button variant="outline-secondary" id={`copia-bottone-${row.sequ_k_versione}`} onClick={() => { navigator.clipboard.writeText(e); }}>
                  Copia
                </Button>
              </InputGroup>
            </Row>
          )
          : '-'}
      </>
    ),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (
      <div>
        {(controlla('X86') || true) && (
        <FontAwesomeIcon
          icon={['fas', 'search']}
          title={`Visualizza la versione ${row.desc_nome}`}
          size="lg"
          id={`visualizza-${row.sequ_k_versione}`}
          style={{ color: themeColors.primary }}
          className="me-2"
          onClick={() => visualizzaVersione(row.sequ_k_versione)}
        />
        )}
        {row.fk_codi_stato_secondario !== 5
          && row.fk_codi_stato_secondario !== 7
          && row.fk_codi_stato_primario !== 4
          && row.fk_codi_stato_primario !== 2
          && (controlla('X87') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'edit']}
              size="lg"
              title={`Modifica la versione ${row.desc_nome}`}
              id={`modifica-${row.sequ_k_versione}`}
              style={{ color: themeColors.primary }}
              onClick={() => modificaVersione(row.sequ_k_versione)}
            />
          ) : null}
        {row.fk_codi_stato_secondario !== 5
          && row.fk_codi_stato_secondario !== 6
          && row.fk_codi_stato_primario === 1
          && (controlla('X88') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'window-maximize']}
              size="lg"
              title={`Richiedi la preview della versione ${row.desc_nome}`}
              id={`preview-${row.sequ_k_versione}`}
              style={{ color: themeColors.primary }}
              onClick={() => { richiestaPreview(row.sequ_k_versione); }}
            />
          ) : null}
        {row.fk_codi_stato_secondario === 6
          && row.fk_codi_stato_primario === 1
          && (controlla('X89') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'clipboard-check']}
              size="lg"
              title={`Richiedi l'approvazione della versione ${row.desc_nome}`}
              id={`approvazione-${row.sequ_k_versione}`}
              style={{ color: themeColors.primary }}
              onClick={() => { handleStateModaleRichiestaApprovazione(row.sequ_k_versione); }}
            />
          ) : null}

        {row.fk_codi_stato_secondario !== 7
          && row.fk_codi_stato_primario === 3
          && (controlla('X90') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'share']}
              size="lg"
              title={`Pubblica la versione ${row.desc_nome}`}
              id={`pubblica-${row.sequ_k_versione}`}
              style={{ color: themeColors.primary }}
              onClick={() => { pubblicaVersione(row.sequ_k_versione); }}
            />
          ) : null}

        {row.fk_codi_stato_secondario !== 5
          && row.fk_codi_stato_secondario !== 7
          && row.fk_codi_stato_secondario !== 6
          && (controlla('X91') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'copy']}
              size="lg"
              title={`Clona la versione ${row.desc_nome}`}
              id={`clona-${row.sequ_k_versione}`}
              style={{ color: themeColors.primary }}
              onClick={() => { clonaVersione(row.sequ_k_versione); }}
            />
          ) : null}

        {row.fk_codi_stato_secondario !== 8
          && row.fk_codi_stato_primario === 4
          && (controlla('X92') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'times']}
              size="lg"
              title={`Revoca la versione ${row.desc_nome}`}
              id={`revoca-${row.sequ_k_versione}`}
              style={{ color: themeColors.danger }}
              onClick={() => { revocaVersione(row.sequ_k_versione); }}
            />
          ) : null}

        {
          row.fk_codi_stato_primario !== 4
          && row.fk_codi_stato_primario !== 2
          && row.fk_codi_stato_primario !== 3
          && row.fk_codi_stato_secondario !== 5
          && row.fk_codi_stato_secondario !== 7
          && row.fk_codi_stato_secondario !== 8
          && (controlla('X93') || true)
            ? (
              <FontAwesomeIcon
                icon={['fas', 'trash']}
                size="lg"
                title={`Elimina la versione ${row.desc_nome}`}
                id={`elimina-${row.sequ_k_versione}`}
                style={{ color: themeColors.danger }}
                onClick={() => handleDelete(row.sequ_k_versione)}
              />
            ) : null
}

        {row.fk_codi_stato_primario === 2
        && (controlla('X94') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'check']}
              size="lg"
              title={`Accetta la richiesta di approvazione della versione ${row.desc_nome}`}
              id={`approva-${row.sequ_k_versione}`}
              style={{ color: themeColors.primary }}
              onClick={() => { accettaApprovazione(row.sequ_k_versione); }}
            />
          ) : null}
        {row.fk_codi_stato_primario === 2
        && (controlla('X95') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'times']}
              size="lg"
              title={`Rifiuta la richiesta di approvazione della versione ${row.desc_nome}`}
              id={`rifiuta-${row.sequ_k_versione}`}
              style={{ color: themeColors.primary }}
              onClick={() => { rifiutaApprovazione(row.sequ_k_versione); }}
            />
          ) : null}
        {row.fk_codi_stato_primario === 4
          && row.fk_codi_stato_secondario !== 8
          && row.newsletter
          && (controlla('X96') || true)
          ? (
            <FontAwesomeIcon
              icon={['fas', 'newspaper']}
              size="lg"
              title={`Gestisci le newsletter della versione ${row.desc_nome}`}
              id={`newsletter-${row.sequ_k_versione}`}
              style={{ color: themeColors.primary }}
              onClick={() => { history.push('/area-riservata/newsletter'); }}
            />
          ) : null}
      </div>

    ),
  },
];

export default fields;
