import { Button, Modal } from 'react-bootstrap';
import RenderHTML from '../Shared/RenderHTML';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

function ModalePreview({
  page, show, handleClose, contenuto,
}) {
  return (
    <>
      <>
        <Modal size="lg" centered aria-labelledby="modal-message-title" show={show} onHide={handleClose} role="dialog">
          <Modal.Header closeButton closeLabel="Chiudi">
            <Modal.Title>Anteprima pagina</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid container-single-title">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <header>
                      <h1 id="titolo">{page.titolo || 'Titolo'}</h1>
                    </header>
                    <hr align="left" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>

            <div
              id="scheda-opera"
              className="container-fluid background-container-scheda-opera"
            >

              {contenuto?.length ? contenuto.map((el) => (
                <div className="container-lg container-scheda-opera">
                  <div className="row">
                    <div className="scheda-opera border m-1">
                      {el.tipo === 'paragrafo'
                        ? (
                          <div className="container-s">
                            <h2>{el.nome}</h2>
                            <RenderHTML html={el.contenuto} />
                          </div>
                        )
                        : null}
                      {el.tipo === 'immagine'
                        ? (
                          <div className="container-single-img">
                            <img className="img-fluid" src={`${sitiWebUrl}/galleria/${el.id}`} alt={el.nome} />
                          </div>
                        )
                        : null}
                    </div>
                  </div>
                </div>
              )) : <p>Si prega di inserire dei contenuti per visualizzare l&apos;anteprima.</p>}

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="grey" onClick={handleClose}>Chiudi</Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}

export default ModalePreview;
