import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../../utils/contants/theme-colors';
import { formatoDataEOra } from '../../../../../utils/formatData';

const fields = (cloneEntity, deleteEntity, attachmentManager,
  updateData, entitiesViewManager) => [
  {
    dataField: 'desc_nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'row_created_dttm',
    text: 'Data creazione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => (formatoDataEOra(e)),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (
      <div>
        {true && (
        <FontAwesomeIcon
          icon={['fas', 'copy']}
          title={`Clona l'entità ${row.desc_nome}`}
          size="lg"
          id={`clona-${row.sequ_k_entita}`}
          style={{ color: themeColors.primary }}
          className="me-2"
          onClick={() => cloneEntity(row.sequ_k_entita)}
        />
        )}
        {true && (
        <FontAwesomeIcon
          icon={['fas', 'trash']}
          size="lg"
          title={`Elimina l'entità ${row.desc_nome}`}
          id={`elimina-${row.sequ_k_entita}`}
          style={{ color: themeColors.danger }}
          className="me-2"
          onClick={() => deleteEntity(row.sequ_k_entita)}
        />
        )}
        {true && (
        <FontAwesomeIcon
          icon={['fas', 'eye']}
          title={`Gestione Vis. Entità ${row.desc_nome}`}
          size="lg"
          id={`gestione-${row.sequ_k_entita}`}
          style={{ color: themeColors.primary }}
          className="me-2"
          onClick={() => entitiesViewManager(row.sequ_k_entita)}
        />
        )}
        {true && (
        <FontAwesomeIcon
          icon={['fas', 'edit']}
          title={`Aggiorna dati ${row.desc_nome}`}
          size="lg"
          id={`aggiorna-${row.sequ_k_entita}`}
          style={{ color: themeColors.primary }}
          className="me-2"
          onClick={() => updateData(row.sequ_k_entita)}
        />
        )}
        {row.flag_allegati && (
        <FontAwesomeIcon
          icon={['fas', 'chain']}
          title={`Gestione allegati ${row.desc_nome}`}
          size="lg"
          id={`allegati-${row.sequ_k_entita}`}
          style={{ color: themeColors.primary }}
          className="me-2"
          onClick={() => attachmentManager(row.sequ_k_entita, row.organizzazione)}
        />
        )}
      </div>

    ),
  },
];

export default fields;
