import React from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';

function CardGroupApp({
  app,
}) {
  return (

    <div className="mt-3">
      <Row lg={1} xs={1} className="g-4 mt-2 mb-2">
        {app ? app.map((el) => (
          <Col key={el.sequ_k_appl_est}>
            <Card>
              <Card.Header>
                <Row>
                  <Col lg={10}>
                    <h3>
                      <img width="40px" height="40px" src={el.desc_url_icona_appl_est} aria-hidden="true" alt="" />
                      {el?.desc_nome_appl_est}
                      {' '}
                    </h3>
                    <div>
                      {/* <strong>Link: </strong> */}
                      <a
                        href={`${el.desc_link_appl_est}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Accedi
                      </a>
                      <br />
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <strong>Descrizione: </strong>
                  {el?.desc_appl_est}
                  <br />
                  <strong>Note: </strong>
                  {el?.desc_note_appl_est}
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )) : null}
      </Row>
    </div>

  );
}

export default CardGroupApp;
