import React, { useContext, useEffect, useState } from 'react';
import * as Survey from 'survey-react';
import { useParams } from 'react-router-dom';
import 'survey-react/survey.css';
import questionariService from '../../../services/questionariService';
import { Context } from '../../../utils/Context';
import CardGroupSezioni from '../../../components/Questionari/CardGroupSezioni';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';

Survey.StylesManager.applyTheme('blue');

Survey.surveyLocalization.currentLocale = 'it';

function VisualizzaQuestionario() {
  const [questionario, setQuestionario] = useState();
  const [sezioni, setSezioni] = useState();
  const [showSurvey, setShowSurvey] = useState(false);
  // eslint-disable-next-line prefer-const
  let [model, setModel] = useState();
  const { idQuestionario } = useParams();
  const [context, setContext] = useContext(Context);

  async function getQuestionarioById() {
    try {
      const response = await questionariService.getQuestionarioById(idQuestionario);
      setQuestionario(response);
      setSezioni(response.sezioni);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function showSezione(titolo) {
    setShowSurvey(true);
    const modelSurveyJs = new Survey.Model(sezioni[titolo].template);
    modelSurveyJs.data = sezioni[titolo].domandeCompilate;
    modelSurveyJs.pages[0].readOnly = true;
    setModel(modelSurveyJs);
    setTimeout(() => {
      const completeBtn = document.getElementById('sv-nav-complete');
      if (completeBtn) { completeBtn.style.display = 'none'; }
    }, 150);
  }

  function onComplete() {
    setShowSurvey(!showSurvey);
  }

  useEffect(() => {
    getQuestionarioById();
  }, []);

  return (
    <div className="container">
      <TitlePage
        title="Visualizza Questionario"
        subtitle={questionario ? questionario.titolo : null}
        showIndietro
        functionIndietro={() => history.goBack()}
      />

      {sezioni ? (
        <CardGroupSezioni
          sezioni={sezioni}
          functionShow={showSezione}
          onlyView
        />
      ) : null}
      <br />
      {showSurvey ? (
        <Survey.Survey
          model={model}
          onComplete={onComplete}
        />
      ) : null}
    </div>
  );
}

export default VisualizzaQuestionario;
