/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import appService from '../../../../services/appService';
import canaliService from '../../../../services/canaliService';
import history from '../../../../utils/history';

function VisualizzaAppEsterna() {
  const { idApp } = useParams();
  const [editedApp, setEditedApp] = useState({
    visibilita: [],
    descrizione: '',
    nome: '',
    link: '',
    note: '',
    urlIcona: '',
  });
  const [listaIdentita, setListaIdentita] = useState([]);

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  async function getAppById() {
    const response = await appService.getAppById(idApp);
    setEditedApp({
      nome: response[0].desc_nome_appl_est,
      descrizione: response[0].desc_appl_est,
      link: response[0].desc_link_appl_est,
      urlIcona: response[0].desc_url_icona_appl_est,
      note: response[0].desc_note_appl_est,
      visibilita: response[0].desc_visibilita_appl_est,
    });
  }

  useEffect(() => {
    getAppById();
    getListaIdentita();
  }, []);

  return (
    <div>
      <TitlePage
        title="Applicazione esterna"
        subtitle="Visualliza applicazione esterna esistente"
        showIndietro
        functionIndietro={() => { history.goBack(); }}
      />

      <Form className="mt-3">

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="nome">
            <Form.Label>Nome *</Form.Label>
            <Form.Control placeholder="Inserire il nome per identificare l'Applicazione esterna" value={editedApp.nome} disabled />
          </Form.Group>
          <Form.Group as={Col} lg controlId="descrizione">
            <Form.Label>Descrizione *</Form.Label>
            <Form.Control placeholder="Descrivere l'Applicazione esterna" value={editedApp.descrizione} disabled />
          </Form.Group>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="link">
            <Form.Label>Link *</Form.Label>
            <Form.Control placeholder="Specificare l'indirizzo tramite cui è possibile raggiungere l'Applicazione esterna" as="textarea" value={editedApp.link} disabled />
          </Form.Group>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="note">
            <Form.Label>Note</Form.Label>
            <Form.Control placeholder="Inserire delle note relative all'Applicazione esterna" as="textarea" value={editedApp.note} disabled />
          </Form.Group>
        </Row>

        <Form.Group as={Col} controlId="urlIcona">
          <Form.Label>url Icona *</Form.Label>
          <Form.Control
            type="text"
            value={editedApp.urlIcona}
            disabled
          />
        </Form.Group>
      </Form>
      <br />
      {editedApp.visibilita.length !== 0 ? (
        <>
          <div className="separator border-1 border-bottom" />
          <Row>
            <Col>
              <p className="h4" style={{ lineHeight: '1.6' }}>
                Regole di visibilità
              </p>
            </Col>
          </Row>
        </>
      ) : null}
      <Form>
        {editedApp.visibilita?.map((el) => (
          <>
            <Row className="my-1" key={el.idIdentita}>
              <Form.Group as={Col} controlId="idIdentita">
                <Form.Label>Lista identità</Form.Label>
                <Form.Select
                  value={el.idIdentita}
                  disabled={el.codiceFiscale}
                >
                  <option> </option>
                  {listaIdentita.map((tipo) => (
                    <option
                      key={tipo.sequ_k_identita}
                      value={tipo.sequ_k_identita}
                    >
                      {tipo.desc_nome_identita}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Col>
                <Form.Label>Utente autorizzato</Form.Label>
                <br />
                {el.codiceFiscale}
              </Col>
            </Row>
            <div className="separator border-1 border-bottom py-1" />
          </>
        ))}
      </Form>
      <br />
    </div>
  );
}

export default VisualizzaAppEsterna;
