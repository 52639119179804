import { useState, useEffect } from 'react';
import throttle from '../utils/throttle';

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const DEFAULT_DELAY = 200;

const useLayoutBreakpoint = (delay) => {
  function getBreakPoint() {
    const size = window.innerWidth;
    if (size < breakpoints.sm) {
      return 'xs';
    }
    if (size < breakpoints.md) {
      return 'sm';
    }
    if (size < breakpoints.lg) {
      return 'md';
    }
    return 'lg';
  }

  const [breakpoint, setBreakpoint] = useState(getBreakPoint);

  useEffect(() => {
    const efficientGetBreakPoint = throttle(() => {
      const newBreakPoint = getBreakPoint();
      if (newBreakPoint !== breakpoint) {
        setBreakpoint(newBreakPoint);
      }
    }, delay || DEFAULT_DELAY);

    window.addEventListener('resize', efficientGetBreakPoint);

    return () => window.removeEventListener('resize', efficientGetBreakPoint);
  }, [breakpoint, delay]);

  return breakpoint;
};

export default useLayoutBreakpoint;
