/* eslint-disable */
$(function() {
    $('a[data-attribute*="forward"]').on("click", function(t) {
        var e = $(this.hash);
        e.length && (t.preventDefault(), $("html, body").animate({
            scrollTop: e.offset().top
        }, 500))
    })
}), 
$(function() {
    var t = $(".custom-navbar-toggler"),
        e = $(".close-div"),
        i = $(".overlay"),
        n = $(".it-back-button"),
        s = $(".navbar-collapsable a");
    $(t).on("click", function(t) {
        var e = $(this).attr("data-target"),
            i = $(e).find(".overlay");
        $(this).attr("aria-expanded", "true"), $(n).fadeIn(), $(e).show(), $(i).fadeIn(), $(e).addClass("expanded")
    }), $(i).on("click", function() {
        var t = $(this).closest(".navbar-collapsable"),
            e = $(this).closest(".navbar").find(".custom-navbar-toggler"),
            i = $(t).find(".overlay");
        $(e).attr("aria-expanded", "false"), $(t).removeClass("expanded"), $(i).fadeOut(), setTimeout(function() {
            $(t).hide()
        }, 300)
    }), $(e).on("click", function(t) {
        var e = $(this).closest(".navbar-collapsable"),
            i = $(this).closest(".navbar").find(".custom-navbar-toggler"),
            n = $(e).find(".overlay");
        $(i).attr("aria-expanded", "false"), $(e).removeClass("expanded"), $(n).fadeOut(), setTimeout(function() {
            $(e).hide()
        }, 300)
    }), $(s).on("blur", function(t) {
        closemenu = $(this).closest(".navbar-collapsable").find(".close-div .btn"), $(this).closest(".navbar-collapsable").hasClass("expanded") && setTimeout(function() {
            var t = document.activeElement;
            0 == $(t).closest(".navbar-collapsable").length && $(closemenu).trigger("click")
        }, 50)
    }), $(e).on("blur", function(t) {
        closemenu = $(this), $(this).closest(".navbar-collapsable").hasClass("expanded") && setTimeout(function() {
            var t = document.activeElement;
            0 == $(t).closest(".navbar-collapsable").length && $(closemenu).trigger("click")
        }, 50)
    })
}),
$(function() {
    $(".custom-navbar-toggler"), $(".close-div");
    var i = $(".overlay"),
        t = $(".it-back-button");
    $(".navbar-collapsable a");
    $('.it-bottom-navscroll ul li a[href^="#"]').on("click", function(t) {
        t.preventDefault();
        var e = this.hash;
        $("html, body").animate({
            scrollTop: $(e).offset().top
        }, 600, function() {
            history.pushState ? history.pushState(null, null, e) : location.hash = e
        }), $(i).trigger("click")
    }), $(t).click(function(t) {
        $(i).trigger("click"), $(this).fadeOut(), t.preventDefault()
    }), $(window).on("scroll", function() {
        var t = $(".it-page-sections-container").length ? $(".it-page-sections-container").offset().top : 0,
            a = $(window).scrollTop() - t;
        $(".it-page-section").each(function(t) {
            var e, i, n, s, o;
            $(this).position().top <= a && ($(".it-navscroll-wrapper .menu-wrapper a.active").removeClass("active"), $(".it-navscroll-wrapper .menu-wrapper a").eq(t).addClass("active"), e = $(".it-navscroll-wrapper .menu-wrapper a").eq(t).closest("ul").prev("a"), i = $(e).closest("ul").prev("a"), $(e).addClass("active"), $(i).addClass("active"), n = $(".it-navscroll-wrapper .menu-wrapper a").eq(t).find("span").text(), o = (s = $(".it-navscroll-wrapper .custom-navbar-toggler")).find("span.it-list"), s.text(n), s.prepend(o))
        })
    }).scroll()
})

$(function() {
    var t, a, p, r, e, i, n, f = document.querySelector(".it-header-sticky");
    f && (t = document.querySelector(".custom-navbar-toggler"), n = !1, (e = t) && (n = "none" === (i = window.getComputedStyle(e)).display || "hidden" === i.visibility), a = !1, r = void(p = 0), function(e) {
        var t = document.querySelector(".it-header-slim-wrapper"),
            i = document.querySelector(".it-header-center-wrapper"),
            n = document.querySelector(".it-header-navbar-wrapper"),
            d = n && n.offsetHeight || 0,
            s = t && t.offsetHeight || 0,
            u = s;
        e && d && (u = s + i ? i.offsetHeight : 0);

        function o(t, e, i) {
            var n, s, o, a, r, l, c, h;
            void 0 === e && (e = !0), t && (n = document.querySelector(".menu-wrapper"), e ? (s = document.querySelector(".it-brand-wrapper"), o = document.querySelector(".it-search-wrapper"), a = document.querySelector(".it-user-wrapper"), r = s ? s.cloneNode(!0) : null, l = o ? o.cloneNode(!0) : null, c = a ? a.cloneNode(!0) : null, r && n && n.insertBefore(r, n.childNodes[0]).classList.add("cloned"), l && n.appendChild(l).classList.add("cloned"), c && n.appendChild(c).classList.add("cloned").remove("show")) : ((h = document.getElementsByClassName("cloned")) && Array.from(h).forEach(function(t) {
                t.parentElement.removeChild(t)
            }), "function" == typeof i && i())), f.nextElementSibling ? f.nextElementSibling.style.paddingTop = e ? d + (t ? u - p : d - p) + "px" : "0px" : null
        }
        r = function() {
            var t = d;
            window.scrollY + p >= u && !a ? (a = !0, f.classList.add("is-sticky"), o(e, !0), t !== d && (p = d - t)) : window.scrollY + p < u && a && (a = !1, f.classList.remove("is-sticky"), o(e, !1))
        }, window.addEventListener("scroll", r), r()
    }(n))
})