import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Table from '../Shared/Table';
import { fieldsNoProfonditaStoricaIdentita, selectRows } from './fieldsTableIdentita';
import FiltriRicercaIdentita from './FiltriRicercaIdentita';

const ModaleRicercaIdentita = ({
  show,
  handleClose,
  identita,
  getListaIdentita,
  identitaScelte,
  setIdentitaScelte,
  handleTableChangeIdentita,
  listaTagIdentita,
  listaTipoIdentita,
  paginationIdentita,
  onChangeFiltriIdentita,
}) => (
  <>
    <Modal show={show} onHide={handleClose} centered size="xl" role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Ricerca identità da associare</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FiltriRicercaIdentita
          onChangeFiltriIdentita={onChangeFiltriIdentita}
          getListaIdentita={getListaIdentita}
          listaTagIdentita={listaTagIdentita}
          listaTipoIdentita={listaTipoIdentita}
        />
        <Table
          title="Ricerca identità"
          data={identita}
          fields={fieldsNoProfonditaStoricaIdentita}
          pagination={paginationIdentita}
          onTableChange={handleTableChangeIdentita}
          keyField="sequ_k_identita"
          selectRow={selectRows(setIdentitaScelte, identitaScelte)}
          handleTableChange={handleTableChangeIdentita}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

export default ModaleRicercaIdentita;
