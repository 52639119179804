import api from '../utils/api';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

async function getGalleria() {
  return api.get(`${sitiWebUrl}/galleria`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function uploadImmagine(fileInfo) {
  const formData = new FormData();
  formData.append('file', fileInfo.fileCaricato);
  formData.append('alt', fileInfo.alt);
  return api.post(`${sitiWebUrl}/galleria/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaImmagine(id) {
  return api.get(`${sitiWebUrl}/galleria/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const galleriaService = {
  getGalleria,
  uploadImmagine,
  eliminaImmagine,
};

export default galleriaService;
