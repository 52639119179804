/* eslint-disable prefer-destructuring */
import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import authProviderService from '../services/authProviderService';
import chatService from '../services/chatService';
import api from '../utils/api';
import { Context } from '../utils/Context';
import history from '../utils/history';
import { setAndStartMatrix } from '../utils/matrix';
import { globalVariables } from '../utils/refreshToken';
import { getItem, setItem } from '../utils/storage';

const LoginSpid = () => {
  const [value, setValue] = useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  const login = async () => {
    authProviderService.spidLogin()
      .then(async (response) => {
        setItem('user', JSON.stringify(response.utente));
        api.defaults.headers.common['X-CSRF-Token'] = response.csrfToken;
        globalVariables.refreshToken = response.refresh_token;
        if (!cookies.accessoOspite) {
          const responseMatrix = await chatService.loginMatrix(response.utente.identificativo);
          setAndStartMatrix(responseMatrix);
          history.push('/area-riservata/scelta-identita');
        } else {
          history.push(getItem('redirectUrl'));
        }
      })
      .catch((err) => {
        setValue(() => ({
          ...value, open: true, testoErrore: 'Ops... qualcosa è andato storto', statusCode: err?.status,
        }));
        history.push('/');
      });
  };
  useEffect(() => {
    login();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="inline-block align-middle">
          <h1 className="font-weight-normal lead" id="desc">Caricamento...</h1>
        </div>
      </div>
    </>
  );
};

export default LoginSpid;
