/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import AutorizzazioniFileCartelle from '../../../../../components/Repository/AutorizzazioniFileCartelle';
import ModaleRicercaUtenti from '../../../../../components/Repository/ModaleRicercaUtenti';
import canaliService from '../../../../../services/canaliService';
import repositoryService from '../../../../../services/repositoryService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';

const AutorizzazioniCartella = () => {
  const { idCartella } = useParams();
  const [cartella, setCartella] = useState({});
  const [listaIdentita, setListaIdentita] = useState([]);
  const [context, setContext] = useContext(Context);
  const [showModaleConferma, setShowModaleConferma] = useState(false);
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [indexAutorizzazioneScelta, setIndexAutorizzazioneScelta] = useState();
  const [identitaScelta, setIdentitaScelta] = useState();

  const handleCloseModaleConferma = () => setShowModaleConferma(!showModaleConferma);
  const handleCloseModaleUtenti = () => setShowModaleUtenti(!showModaleUtenti);

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  async function modificaConfigurazioneFile() {
    await repositoryService.modificaCartella(cartella._id, null, null,
      { autorizzazioni: cartella.autorizzazioni })
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleCloseModaleConferma();
      });
  }

  async function getFile() {
    try {
      const cartellaInfo = await repositoryService.getInfoCartella(idCartella);
      setCartella(cartellaInfo);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function aggiungiRow() {
    setCartella({
      ...cartella,
      autorizzazioni: [
        ...cartella.autorizzazioni,
        {
          operazioni: ['V'],
          idIdentita: 0,
          codiceFiscale: null,
        },
      ],
    });
  }

  function eliminaRow(index) {
    setCartella({
      ...cartella,
      autorizzazioni: [
        ...cartella.autorizzazioni.filter((el, indexEl) => indexEl !== index)],
    });
  }

  const onChangeAutorizzazioni = (prop, event, index, type) => {
    const old = cartella.autorizzazioni[index];
    const updated = { ...old, [prop]: type === 'int' ? parseInt(event.target.value, 10) : event.target.value };
    const clone = [...cartella.autorizzazioni];
    clone[index] = updated;
    setCartella({
      ...cartella,
      autorizzazioni: clone,
    });
  };

  const onChangeOperazioni = (prop, event, index, idIdentita) => {
    const old = cartella.autorizzazioni[index];
    const updated = {
      ...old,
      [prop]: event.target.checked
        ? [...cartella.autorizzazioni[index].operazioni, event.target.value]
        : event.target.value === 'M'
          ? cartella.autorizzazioni[index].operazioni = ['V']
          : cartella.autorizzazioni[index].operazioni.filter((el) => el !== event.target.value),
    };
    // La riga di codice serve per fare "uncheck" dell'operazione C quando si fa uncheck di M
    if (document.getElementById(`${idIdentita}C`)) {
      document.getElementById(`${idIdentita}C`).checked = false;
    }

    const clone = [...cartella.autorizzazioni];
    clone[index] = updated;
    setCartella({
      ...cartella,
      autorizzazioni: clone,
    });
  };

  useEffect(() => {
    getFile();
    getListaIdentita();
  }, []);
  return (
    <>
      <TitlePage
        title="Repository - modifica autorizzazioni"
        subtitle={`È possibile visualizzare e modificare le autorizzazioni della cartella ${cartella.nome}.`}
        showIndietro
        functionIndietro={() => {
          history.goBack();
        }}
      />
      <p>
        Stai modificando la cartella:
        {' '}
        <strong>{cartella.path}</strong>
      </p>
      <h3>COME FARE?</h3>
      <p>
        L&apos;assenza di regole garantisce la Visualizzazione, la Modifica e la Cancellazione
        della risorsa a tutti gli utenti del Repository.
        È possibile limitare l&apos;accesso alla risorsa mediante la creazione di una o più regole.
        In ogni regola è possibile definire gli utenti che possono
        Visualizzare, Modificare e Cancellare la risorsa.
        Se la risorsa è una cartella l&apos;autorizzazione di Visualizzazione
        si applica alla cartella stessa (contenitore) e le autorizzazioni
        di Modifica e Cancellazione si applicano alle risorse in essa contenute.
        Se la risorsa è un documento tutte le autorizzazioni
        (Visualizzazione, Modifica e Cancellazione) si applicano al documento stesso.
      </p>
      <AutorizzazioniFileCartelle
        autorizzazioni={cartella.autorizzazioni}
        listaIdentita={listaIdentita}
        aggiungiRow={aggiungiRow}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        onChangeOperazioni={onChangeOperazioni}
        handleCloseModaleUtenti={handleCloseModaleUtenti}
        setIndexAutorizzazioneScelta={setIndexAutorizzazioneScelta}
        eliminaRow={eliminaRow}
        setIdentitaScelta={setIdentitaScelta}
        tipoOggetto="cartella"
      />
      <Button variant="primary" className="m-3 float-end" onClick={handleCloseModaleConferma}>
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        {' '}
        SALVA
      </Button>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione modificherà le autorizzazioni del file."
        show={showModaleConferma}
        handleClose={handleCloseModaleConferma}
        handleOk={modificaConfigurazioneFile}
      />
      {showModaleUtenti && (
      <ModaleRicercaUtenti
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        idIdentita={identitaScelta}
        listaIdentita={listaIdentita}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        indexAutorizzazioneScelta={indexAutorizzazioneScelta}
      />
      )}
    </>
  );
};

export default AutorizzazioniCartella;
