/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useDragOver } from '@minoru/react-dnd-treeview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import styles from './CustomNode.module.css';

export default function CustomNode(props) {
  const { id, text, sequ_k_menu_disponibile } = props.node;
  const [hover, setHover] = useState(false);
  const [visibleInput, setVisibleInput] = useState(false);
  const [labelText, setLabelText] = useState(text);
  const [dinamico, setDinamico] = useState(false);
  const [deprecato, setDeprecato] = useState(false);

  const indent = props.depth * 24;

  // Apri/chiudi nodo
  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  // Rende visibile la sezione per modificare il nome
  const handleShowInput = () => {
    setVisibleInput(true);
  };

  // Ripristina la visualizzazione del nome
  const handleCancel = () => {
    setLabelText(text);
    setVisibleInput(false);
  };

  // onChange per il cambio nome
  const handleChangeText = (e) => {
    setLabelText(e.target.value);
  };

  const handleChangeDinamico = (e) => {
    setDinamico(e.target.checked);
    props.onFlagDinamicoChange(id, e.target.checked);
  };

  const handleChangeDeprecato = (e) => {
    setDeprecato(e.target.checked);
    props.onFlagDeprecatoChange(id, e.target.checked);
  };

  // Conferma cambio nome
  const handleSubmit = () => {
    setVisibleInput(false);
    props.onTextChange(id, labelText);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  return (
    <div
      className={`tree-node ${styles.root}`}
      style={{ paddingInlineStart: indent }}
      {...dragOverProps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          props.isOpen ? styles.isOpen : ''
        }`}
      >
        {props.node.droppable && props.node.text !== 'Homepage' ? (
          <FontAwesomeIcon
            title={`${props.isOpen ? 'Chiudi' : 'Apri'} la sezione ${props.node.text}`}
            onClick={handleToggle}
            icon="arrow-right"
            color="black"
          />
        ) : null}
      </div>
      <div />
      {/* Sezione per il cambio nome */}
      {visibleInput ? (
        <div className={styles.inputWrapper}>
          <Form.Group controlId="labelText">
            <Form.Label>Nome</Form.Label>
            <Form.Control placeholder="Inserisci nome" value={labelText} onChange={handleChangeText} />
          </Form.Group>

          <FontAwesomeIcon
            title="Conferma il cambio nome"
            icon="check"
            className={styles.editButton}
            onClick={handleSubmit}
            disabled={labelText === ''}
          />
          <FontAwesomeIcon
            title="Annulla il cambio nome"
            icon="x"
            className={styles.editButton}
            onClick={handleCancel}
            disabled={labelText === ''}
          />
        </div>
      )
        : (
          <>
            {/* Sezione visualizzazione nome e pulsanti */}
            <div className={styles.labelGridItem}>
              <p>{props.node.text}</p>
            </div>
            <div>
              {!props.node.droppable && props.node.text !== 'Homepage'
            && (
              <Form.Check
                type="switch"
                id="droppable"
                label="Contenuto dinamico?"
                checked={props.node.flagDinamico}
                onChange={handleChangeDinamico}
              />
            )}
              {props.node.text !== 'Homepage'
                ? (
                  <Form.Check
                    type="switch"
                    id="droppable"
                    label="Deprecato?"
                    checked={props.node.flagDeprecato}
                    onChange={handleChangeDeprecato}
                  />
                ) : null }
            </div>
            <div>
              {/*
              DEPRECATO - non si elimina, ma si depreca
              <FontAwesomeIcon title={`Cancella ${props.node.text}`} icon="trash-alt"
               onClick={() => props.onDelete(id, sequ_k_menu_disponibile)} /> */}

              {props.node.text !== 'Homepage'
                ? (
                  <>
                    <FontAwesomeIcon title={`Clona ${props.node.text}`} icon="file" onClick={() => props.onCopy(id)} />
                    <FontAwesomeIcon title={`Modifica il nome di ${props.node.text}`} icon="edit" onClick={handleShowInput} />
                  </>
                ) : null}

            </div>
            {/* )} */}
          </>
        )}
    </div>
  );
}
