import { formatoData } from '../../utils/formatData';

export const fieldsProfonditaStoricaIdentita = [
  {
    dataField: 'sequ_k_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'desc_tag_identita',
    text: 'Tag',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
  },
  {
    dataField: 'desc_tipo_identita',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => formatoData(e),
  },
];

export const fieldsNoProfonditaStoricaIdentita = [
  {
    dataField: 'sequ_k_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '40%' }),
  },
  {
    dataField: 'desc_tag_identita',
    text: 'Tag',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_tipo_identita',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => formatoData(e),
  },
];
