import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getTagFunzioni() {
  return api.get(`${accreditamentoUrl}/gestione-tag-funzione`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const tagFunzioniService = {
  getTagFunzioni,
};

export default tagFunzioniService;
