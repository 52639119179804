import React from 'react';
import TitlePage from '../components/GestisciUtenti/TitoloPagina';

const PrivacyPolicy = () => (
  <>
    <TitlePage
      title="Informativa sul trattamento dei dati personali"
      subtitle="ai sensi degli artt. 13-14 del Regolamento (UE) 2016/679 del Parlamento europeo e del Consiglio.

      Questa pagina descrive le modalità di trattamento dei dati personali degli utenti che consultano il presente sito web.

      Le informazioni rese non riguardano altri siti, pagine o servizi online raggiungibili tramite link ipertestuali riferiti a risorse esterne al dominio del presente sito web."
    />
  </>
);

export default PrivacyPolicy;
