/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styles from '../GestioneAmmTrasparenza/CustomNode.module.css';

export default function CustomNodeModal(props) {
  // eslint-disable-next-line no-unused-vars
  const [hover, setHover] = useState(false);

  const indent = props.depth * 24;

  // Apri/chiudi nodo
  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const handleMove = () => {
    props.handleSpostaElemento(props.node.id);
  };

  return (
    <div
      className={`tree-node ${styles.root}`}
      style={{ paddingInlineStart: indent }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          props.isOpen ? styles.isOpen : ''
        }`}
      >
        {props.node.droppable && props.node.text !== 'Homepage' && (
          <FontAwesomeIcon
            title={`${props.isOpen ? 'Chiudi' : 'Apri'} la sezione ${props.node.text}`}
            onClick={handleToggle}
            icon="arrow-right"
            color="black"
          />
        )}
      </div>
      <div />
      <div className={styles.labelGridItem}>
        {props.node.flagDinamico
          ? (
            <p>
              {props.node.text}
              *
            </p>
          )
          : props.node.text === 'Homepage' ? (
            <Link to={`/area-riservata/siti-web/${props.idVersione}/albero/template-homepage/${props.node.sequ_k_menu_disponibile}?nomeMenu=${props.node.text}`}>
              {props.node.text}
            </Link>
          ) : (
            props.node.flagRappGrafica || props.node.flagDeprecato
              ? (
                <p>
                  {props.node.text}
                </p>
              )
              : (props.node.id !== Number(props.idPagina) ? (
                <>
                  <p>
                    {props.node.text}
                    <FontAwesomeIcon
                      title="Sposta qui"
                      onClick={handleMove}
                      icon="arrow-right"
                      color="black"
                    />
                  </p>
                </>
              ) : (
                <p>
                  {props.node.text}
                </p>
              ))
          )}
      </div>
    </div>
  );
}
