import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Table from '../Shared/Table';
import { fields, selectRows } from './fieldsTableRuoliModifica';
import FiltriRicercaRuoli from './FiltriRicercaRuoli';

const ModaleRicercaRuoliModifica = ({
  show,
  handleClose,
  ruoli,
  ruoliScelti,
  setRuoliScelti,
  onChangeFiltriRuolo,
  getListaRuoli,
  listaTagRuolo,
  ruoliRimossi,
  setRuoliRimossi,
  ruoliAggiunti,
  setRuoliAggiunti,
  handleTableChangeRuoli,
  paginationRuoli,
}) => (
  <>
    <Modal show={show} onHide={handleClose} centered size="xl" role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Ricerca ruoli da associare</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FiltriRicercaRuoli
          onChangeFiltriRuolo={onChangeFiltriRuolo}
          getListaRuoli={getListaRuoli}
          listaTagRuolo={listaTagRuolo}
        />
        <Table
          title="Ricerca ruoli"
          data={ruoli}
          fields={fields}
          pagination={paginationRuoli}
          onTableChange={handleTableChangeRuoli}
          keyField="sequ_k_ruolo"
          selectRow={selectRows(setRuoliScelti, ruoliScelti, ruoliRimossi,
            setRuoliRimossi,
            ruoliAggiunti,
            setRuoliAggiunti)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

export default ModaleRicercaRuoliModifica;
