import api from '../utils/api';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

async function getListaEntita() {
  return api.get(`${sitiWebUrl}/entita`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function creaEntita(body) {
  return api.post(`${sitiWebUrl}/entita`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function salvaEntita(id, body, organizzazione, offset, limit) {
  const bodyPost = {
    ...body,
    offset,
    limit,
    desc_organizzazione: organizzazione,
  };
  return api.put(`${sitiWebUrl}/entita/${id}`, bodyPost)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaEntita(id) {
  return api.delete(`${sitiWebUrl}/entita/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getEntitaById(id) {
  return api.get(`${sitiWebUrl}/entita/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function clonaEntita(id) {
  return api.post(`${sitiWebUrl}/entita/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function salvaVisualizzazione(id, body) {
  return api.put(`${sitiWebUrl}/entita/${id}/gestione-visualizzazione`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getAllegatiByEntita(id, organizzazione, offset, limit) {
  return api.get(`${sitiWebUrl}/entita/${id}/gestione-allegati?desc_organizzazione=${organizzazione}&offset=${offset}&limit=${limit}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function gestioneFileExcel(idEntita, fileInfo, sovrascrivi) {
  const formData = new FormData();
  formData.append('file', fileInfo.fileCaricato);
  formData.append('sovrascrivi', sovrascrivi);
  return api.post(`${sitiWebUrl}/entita/${idEntita}/gestione-file-excel`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const entitaTrasparenzaService = {
  getListaEntita,
  creaEntita,
  getEntitaById,
  eliminaEntita,
  clonaEntita,
  salvaVisualizzazione,
  getAllegatiByEntita,
  salvaEntita,
  gestioneFileExcel,
};

export default entitaTrasparenzaService;
