/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './script';
import './index.css';

const TornaSu = () => (
  <a href="#" data-attribute="back-to-top" className="back-to-top" id="torna-su" aria-label="Tasto per tornare su">
    <FontAwesomeIcon aria-hidden="true" icon="arrow-up" size="2x" className="icon" style={{ color: 'white' }} />
  </a>
);

export default TornaSu;
