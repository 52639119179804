import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getTipiConnettori() {
  return api.get(`${accreditamentoUrl}/gestione-tipo-connettore-untrusted`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const tipiConnettoriService = {
  getTipiConnettori,
};

export default tipiConnettoriService;
