import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function UserInfo({ user }) {
  return (
    <div>
      <h3 style={{ fontSize: '1.75rem' }}> Dati anagrafici</h3>
      <Row className="my-3">
        <Form.Group as={Col}>
          <Form.Label htmlFor="nome">Nome</Form.Label>
          <Form.Control value={user.desc_nome} disabled id="nome" />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label htmlFor="cognome">Cognome</Form.Label>
          <Form.Control value={user.desc_cognome} disabled id="cognome" />
        </Form.Group>

      </Row>

    </div>
  );
}

export default UserInfo;
