import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getIdentita(filtri, offset, limit) {
  const filters = { ...filtri, offset, limit };
  const params = createQueryString(filters);
  return api.get(`${accreditamentoUrl}/gestione-identita?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovaIdentita(identita) {
  return api.post(`${accreditamentoUrl}/gestione-identita`, identita)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getIdentitaById(id, filtri) {
  const params = createQueryString(filtri);
  return api.get(`${accreditamentoUrl}/gestione-identita/${id}?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaIdentita(identita) {
  // eslint-disable-next-line no-param-reassign
  delete identita.fk_sequ_organizzazione;
  return api.put(`${accreditamentoUrl}/gestione-identita/${identita.sequ_k_identita}`, identita)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaIdentita(id) {
  return api.delete(`${accreditamentoUrl}/gestione-identita/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListaIdentitaByUtente(trusted) {
  return api.get(`${accreditamentoUrl}/gestione-identita/utente?trusted=${trusted}&limit=100&offset=0`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function setIdentitaDefault(idIdentita, idUtente, checked) {
  const body = checked ? { id: parseInt(idIdentita, 10) } : {};
  return api.post(`${accreditamentoUrl}/gestione-relazione-utente-identita/trusted/default?idUtente=${idUtente}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const identitaService = {
  getIdentita,
  nuovaIdentita,
  getIdentitaById,
  modificaIdentita,
  eliminaIdentita,
  getListaIdentitaByUtente,
  setIdentitaDefault,
};

export default identitaService;
