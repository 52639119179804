import React, { useContext, useEffect, useState } from 'react';
import * as Survey from 'survey-react';
import { useParams } from 'react-router-dom';
import 'survey-react/survey.css';
import { Context } from '../../../utils/Context';
import CardGroupSezioni from '../../../components/Questionari/CardGroupSezioni';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import questionariTracciatiService from '../../../services/questionariTracciatiService';
import ModaleConferma from '../../../components/modali/ModaleConferma';

Survey.StylesManager.applyTheme('blue');

Survey.surveyLocalization.currentLocale = 'it';

function CompilaQuestionario() {
  const [questionario, setQuestionario] = useState();
  const [sezioni, setSezioni] = useState();
  // sezione su cui sta lavorando
  const [sezione, setSezione] = useState();
  const [showSurvey, setShowSurvey] = useState(false);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  // eslint-disable-next-line prefer-const
  let [model, setModel] = useState();
  const { idQuestionario } = useParams();
  const [context, setContext] = useContext(Context);

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  async function getQuestionarioById() {
    try {
      const response = await questionariTracciatiService.getQuestionarioById(idQuestionario);
      setQuestionario(response);
      setSezioni(response.sezioni);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function editQuestionario(domandeCompilate) {
    try {
      const body = {
        domandeCompilate,
        totaleDomandeCompilate: Object.keys(domandeCompilate).length,
      };
      const res = await questionariTracciatiService.editSezione(idQuestionario, sezione, body);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function closeQuestionario() {
    try {
      const res = await questionariTracciatiService.closeQuestionario(idQuestionario);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      history.push('/questionari/conferma');
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function showSezione(titolo) {
    setSezione(titolo);
    setShowSurvey(true);
    const modelSurveyJs = new Survey.Model(sezioni[titolo].template);
    modelSurveyJs.data = sezioni[titolo].domandeCompilate;
    setModel(modelSurveyJs);
  }

  function onValueChanged() {
    // console.log('value changed!');
  }

  async function onComplete(sender) {
    // console.log('Complete!', sender.data);
    await editQuestionario(sender.data);
    setShowSurvey(false);
    // refresh sezioni
    getQuestionarioById();
  }

  useEffect(() => {
    getQuestionarioById();
  }, []);

  return (
    <div className="container">
      <TitlePage
        title="Compilazione Questionario"
        subtitle={questionario ? questionario.titolo : null}
        showCompleta
        functionCompleta={closeQuestionario}
      />
      {sezioni ? (
        <CardGroupSezioni
          sezioni={sezioni}
          functionShow={showSezione}
        />
      ) : null}
      <br />
      {showSurvey ? (
        <Survey.Survey
          model={model}
          onComplete={onComplete}
          onValueChanged={onValueChanged}
        />
      ) : null}
      {openModaleConferma && (
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione invierà il questionario compilato.'}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={closeQuestionario}
      />
      )}
    </div>
  );
}

export default CompilaQuestionario;
