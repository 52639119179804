import api from '../utils/api';

const newsletterUrl = process.env.REACT_APP_NEWS_FAQ_URL;

async function aggiungiMedia(articolo) {
  const formData = new FormData();
  formData.append('file', articolo.fileCaricato);
  return api.post(`${newsletterUrl}/newsletter/campaigns/media`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovaCampagna(campagna) {
  return api.post(`${newsletterUrl}/newsletter/campaigns`, campagna)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getCampagnaById(id) {
  return api.get(`${newsletterUrl}/newsletter/campaigns/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaCampagna(id, campagna) {
  return api.put(`${newsletterUrl}/newsletter/campaigns/${id}`, campagna)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListaCampagne() {
  return api.get(`${newsletterUrl}/newsletter/campaigns`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaCampagna(id) {
  return api.delete(`${newsletterUrl}/newsletter/campaigns/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function avviaCampagna(id) {
  return api.put(`${newsletterUrl}/newsletter/campaigns/${id}/avvia`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const campaignService = {
  aggiungiMedia,
  nuovaCampagna,
  getCampagnaById,
  modificaCampagna,
  getListaCampagne,
  eliminaCampagna,
  avviaCampagna,
};

export default campaignService;
