/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { getBackendOptions, MultiBackend, Tree } from '@minoru/react-dnd-treeview';
import React from 'react';
import {
  Button, Modal,
} from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import styles from '../../pages/area-riservata/amm-trasparente/_id/App.module.css';
import CustomNodeModal from './CustomNodeModal';

function ModalMoveContent({
  showModalMove, setShowModalMove, tree, handleSpostaElemento, idPagina,
}) {
  return (

    <Modal show={showModalMove} onHide={() => setShowModalMove(false)}>
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>
          Scegliere la sotto-sezione in cui spostare il contenuto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <Tree
            classes={{
              root: styles.treeRoot,
              draggingSource: styles.draggingSource,
            }}
            tree={tree}
            rootId={0}
          // eslint-disable-next-line no-return-assign
            render={(node, options) => (
              <>
                <CustomNodeModal
                  handleSpostaElemento={handleSpostaElemento}
                  node={node}
                  {...options}
                  idPagina={idPagina}
                />
              </>
            )}
          />
        </DndProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowModalMove(false)}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>

  );
}

export default ModalMoveContent;
