import React from 'react';
import {
  Form, Row, Button, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FiltriRicercaIdentita({
  onChangeFiltriIdentita, getListaIdentita, listaTipoIdentita, listaTagIdentita,
}) {
  return (
    <Row id="form-cerca-utenti">
      <Row>
        {' '}
        <h2>Filtri ricerca</h2>
      </Row>
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="desc_nome_identita">
            <Form.Label>Nome identità</Form.Label>
            <Form.Control
              placeholder="Inserisci il nome dell'identità"
              onChange={onChangeFiltriIdentita}
            />
          </Form.Group>
          <Form.Group as={Col} lg controlId="fk_sequ_tag_identita">
            <Form.Label>TAG identità</Form.Label>
            <Form.Select
              onChange={onChangeFiltriIdentita}
            >
              <option> </option>
              {listaTagIdentita.map((tag) => (
                <option
                  key={tag.sequ_k_tag_identita}
                  value={tag.sequ_k_tag_identita}
                >
                  {tag.desc_nome_tag_identita}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} lg controlId="fk_codi_tipo_identita">
            <Form.Label>Tipo identità</Form.Label>
            <Form.Select
              onChange={onChangeFiltriIdentita}
              value={listaTipoIdentita.codi_k_tipo_identita}
            >
              <option> </option>
              {listaTipoIdentita.map((tipo) => (
                <option
                  key={tipo.codi_k_tipo_identita}
                  value={tipo.codi_k_tipo_identita}
                >
                  {tipo.desc_tipo_identita}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>

        <Button variant="primary" className="float-end" onClick={() => getListaIdentita(0, 5, 1)} aria-label="Ricerca le informazioni secondo i filtri inseriti">
          <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} />
          {' '}
          CERCA
        </Button>
      </Form>
    </Row>
  );
}

export default FiltriRicercaIdentita;
