/* eslint-disable prefer-template */
/* eslint template-curly-spacing: ["error", "always"] */
import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const operazioniFile = [{ descrizione: 'Visualizza', id: 'V' }, { descrizione: 'Modifica', id: 'M' }, { descrizione: 'Cancella', id: 'C' }];
const operazioniCartelle = [{ descrizione: 'Visualizza cartella', id: 'V' }, { descrizione: 'Modifica contenuto', id: 'M' }, { descrizione: 'Cancella contenuto', id: 'C' }];

const AutorizzazioniFileCartelle = ({
  autorizzazioni, onChangeAutorizzazioni, listaIdentita, aggiungiRow, handleCloseModaleUtenti,
  onChangeOperazioni, setIndexAutorizzazioneScelta, eliminaRow, setIdentitaScelta, tipoOggetto,
}) => (
  <>
    <Button variant="primary" className="mb-2" onClick={aggiungiRow}>
      <FontAwesomeIcon aria-hidden="true" icon="plus" />
      {' '}
      AGGIUNGI
    </Button>
    <Form>
      {autorizzazioni?.map((el, i) => (
        <>
          <Row className="my-1" key={el.idIdentita}>
            <Form.Group as={Col} controlId={'idIdentita' + el.idIdentita}>
              <Form.Label>Lista identità</Form.Label>
              <Form.Select
                onChange={(e) => onChangeAutorizzazioni('idIdentita', e, i, 'int')}
                value={el.idIdentita}
                disabled={el.codiceFiscale}
              >
                <option> </option>
                {listaIdentita.map((tipo) => (
                  <option
                    key={tipo.sequ_k_identita}
                    value={tipo.sequ_k_identita}
                  >
                    {tipo.desc_nome_identita}

                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Col>
              <Form.Label>Utente autorizzato</Form.Label>
              <br />
              {el.codiceFiscale
                ? (
                  <>
                    {el.codiceFiscale}
                    &nbsp;
                    <Button
                      variant="danger"
                      className="mb-2"
                      onClick={() => { onChangeAutorizzazioni('codiceFiscale', { target: { value: null } }, i); }}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="trash" />
                      {' '}
                      ELIMINA
                    </Button>
                  </>
                )
                : (
                  <Button
                    variant="primary"
                    className="mb-2"
                    onClick={() => {
                      setIdentitaScelta(autorizzazioni[i]?.idIdentita);
                      setIndexAutorizzazioneScelta(i); handleCloseModaleUtenti();
                    }}
                  >
                    <FontAwesomeIcon aria-hidden="true" icon="user" />
                    {' '}
                    SCEGLI UTENTE
                  </Button>
                )}
            </Col>
            <Col>
              <Button
                variant="danger"
                className="mb-2"
                onClick={() => { eliminaRow(i); }}
              >
                <FontAwesomeIcon aria-hidden="true" icon="minus" />
                {' '}
                ELIMINA REGOLA
              </Button>
            </Col>
          </Row>
          <Row className="my-1">
            <div key="inline-checkbox" className="mb-3">
              {tipoOggetto === 'file'
                ? operazioniFile.map((operazione) => (
                  <Form.Check
                    inline
                    label={operazione.descrizione}
                    name="operazioni"
                    type="checkbox"
                    id={el.idIdentita + operazione.id}
                    key={operazione.id}
                    // Qui passo anche l'idIdentità per avere il
                    // riferimento alla checkbox dell'operazione C
                    onChange={(e) => onChangeOperazioni('operazioni', e, i, el.idIdentita)}
                    checked={el?.operazioni?.find((elem) => elem === operazione.id)}
                    value={operazione.id}
                    disabled={operazione.disabled || (operazione.id === 'C' && !el.operazioni.find((elem) => elem === 'M'))}
                  />
                ))
                : operazioniCartelle.map((operazione) => (
                  <Form.Check
                    inline
                    label={operazione.descrizione}
                    name="operazioni"
                    type="checkbox"
                    id={el.idIdentita + operazione.id}
                    key={operazione.id}
                  // Qui passo anche l'idIdentità per avere il
                  // riferimento alla checkbox dell'operazione C
                    onChange={(e) => onChangeOperazioni('operazioni', e, i, el.idIdentita)}
                    checked={el?.operazioni?.find((elem) => elem === operazione.id)}
                    value={operazione.id}
                    disabled={operazione.disabled || (operazione.id === 'C' && !el.operazioni.find((elem) => elem === 'M'))}
                  />
                ))}
            </div>
          </Row>
          <div className="separator border-1 border-bottom py-1" />
        </>
      ))}
    </Form>
  </>
);

export default AutorizzazioniFileCartelle;
