/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import RenderHTML from '../../../../components/Shared/RenderHTML';
import entitaTrasparenzaService from '../../../../services/entitaTrasparenzaService';
import fileAmmTraspService from '../../../../services/fileAmmTraspService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';
import './crea.css';

const XLSX = require('xlsx');

const CreazioneEntita = () => {
  const [context, setContext] = useContext(Context);
  const [bodyPost, setBodyPost] = useState({
    desc_nome: '',
    colonne: [],
    desc_path_minio: '',
    flag_allegati: false,
  });
  const [html, setHtml] = useState();
  const [chiavi, setChiavi] = useState([]);
  const [file, setFile] = useState({
    nome: '',
    fileCaricato: null,
  });

  function onChangeFile(event) {
    const { files } = event.target;
    const reader = new FileReader();

    setFile({
      nome: files[0].name,
      fileCaricato: files[0],
    });

    reader.onload = (e) => {
      const fileExcel = e.target.result;
      const workbook = XLSX.read(fileExcel, {
        type: 'binary',
        sheetRows: 6,
      });

      workbook.SheetNames.forEach((sheetName) => {
        // Here is your object
        const XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        setHtml(XLSX.utils.sheet_to_html(workbook.Sheets[sheetName], { id: 'search_form' }));

        const chiaviFile = Object.keys(XL_row_object[0]);
        setChiavi(chiaviFile.map((el, index) => ({ desc_colonna: el, nume_posizione: index })));
      });
    };

    reader.onerror = () => {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore nella lettura del file', statusCode: 500,
      }));
    };

    reader.readAsBinaryString(event.target.files[0]);
  }

  function onChangeColonne(e, obj) {
    const {
      checked,
    } = e.target;
    setBodyPost({
      ...bodyPost,
      colonne: checked ? [...bodyPost.colonne, obj]
        : bodyPost.colonne.filter((el) => el.nume_posizione !== obj.nume_posizione),
    });
  }

  function onChange(e) {
    const {
      id,
    } = e.target;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setBodyPost({
      ...bodyPost,
      [id]: value,
    });
  }

  async function creaEntita() {
    try {
      const response = await fileAmmTraspService.uploadFile(file);
      const body = {
        ...bodyPost,
        desc_path_minio: response.pathMinio,
      };
      const res = await entitaTrasparenzaService.creaEntita(body);
      // false è per "sovrascrivi" e qui non serve perché è la creazione
      await entitaTrasparenzaService.gestioneFileExcel(res.sequ_k_entita, file, false);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      history.push(`/area-riservata/amm-trasparente/entita/${res.sequ_k_entita}/gestione-visualizzazione`);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
      }));
    }
  }

  return (
    <>
      <TitlePage
        title="Creazione nuova entità"
        subtitle="È possibile creare una nuova entità inserendo il nome, un file e scegliendo le colonne che si desidera importare."
        showAvanti
        functionAvanti={creaEntita}
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <Form className="mb-3">
        <Row>
          <Form.Group as={Col} lg controlId="desc_nome">
            <Form.Label>Nome entità</Form.Label>
            <Form.Control placeholder="Inserisci il nome entità" onChange={onChange} />
          </Form.Group>
          <Form.Group as={Col} controlId="file" className="mb-3">
            <Form.Label>Allega file</Form.Label>
            <Form.Control type="file" size="sm" onChange={onChangeFile} />
          </Form.Group>
          <Form.Check
            type="switch"
            id="flag_allegati"
            label="Vuoi permettere l'inserimento di allegati?"
            onChange={onChange}
          />
        </Row>
      </Form>

      {html
      && (
      <Row>
        <Col>
          <p className="h6">Preview tabella</p>
          <RenderHTML html={html} />
        </Col>
        <Col>
          <p className="h6">Scelta colonne</p>
          {chiavi.map((el) => (
            <Form.Check
              type="checkbox"
              key={el.nume_posizione}
              id={el.nume_posizione}
              label={el.desc_colonna}
              onChange={(e) => onChangeColonne(e, el)}
            />
          ))}
        </Col>
      </Row>
      )}
    </>
  );
};

export default CreazioneEntita;
