import React from 'react';
import './index.css';

const Spinner = () => (
  <div id="spinner" className="loading-bar-wrapper" style={{ display: 'none' }}>
    <div className="loading-bar" />
  </div>
);

export default Spinner;
