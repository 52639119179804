import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { controlla } from '../../../utils/verificaPermessi';
// import { formatoDataEOra } from '../../../utils/formatData';

const fields = (modificaApp, visualizzaApp, deleteApp) => [

  {
    dataField: 'desc_nome_appl_est',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_appl_est',
    text: 'Descrizione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_link_appl_est',
    text: 'Link',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_note_appl_est',
    text: 'Note',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  // {
  //   dataField: 'dataInizioValidita',
  //   text: 'Data Inizio Validità',
  //   sort: true,
  //   classes: 'column-data',
  //   headerStyle: () => ({ width: '20%' }),
  //   formatter: (e) => (formatoDataEOra(e)),
  // },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
      }}
      >
        <>
          {controlla('X82') && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title={`Modifica l'app ${row.desc_nome_appl_est}`}
            id={`Modifica${row.sequ_k_appl_est}`}
            style={{ color: '#0059b3' }}
            onClick={() => { modificaApp(row.sequ_k_appl_est); }}
          />
          )}

          {controlla('X84') && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title={`Visualizza l'app ${row.desc_nome_appl_est}`}
            id={`Visualizza${row.sequ_k_appl_est}`}
            style={{ color: '#0059b3' }}
            onClick={() => { visualizzaApp(row.sequ_k_appl_est); }}
          />
          )}

          {controlla('X83') && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash-alt']}
            size="lg"
            title={`Elimina l'app ${row.desc_nome_appl_est} dalla lista`}
            id={`Elimina${row.sequ_k_appl_est}`}
            style={{ color: '#0059b3' }}
            onClick={() => { deleteApp(row.sequ_k_appl_est); }}
          />
          )}

        </>
      </div>

    ),
  },
];

export default fields;
