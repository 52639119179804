import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getFunzioni(filtri, offset, limit) {
  const filters = { ...filtri, offset, limit };
  const params = Object.keys(filters).map((key) => `${key}=${filters[key]}`).join('&');

  return api.get(`${accreditamentoUrl}/gestione-funzione?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const funzioniService = {
  getFunzioni,
};

export default funzioniService;
