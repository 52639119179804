/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import FiltriRicercaFaq from '../../../components/Faq/FiltriRicerca';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import RenderHTML from '../../../components/Shared/RenderHTML';
import faqService from '../../../services/faqService';
import { Context } from '../../../utils/Context';

function GestioneFaq() {
  const [context, setContext] = useContext(Context);
  const [argomenti, setArgomenti] = useState(null);
  const [listaFaq, setListaFaq] = useState(null);
  const [filtri, setFiltri] = useState({
    testo: '',
    profonditaStorica: false,
  });

  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  async function getListaFaq(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await faqService.getListaFaq(filtri, offset, limit);
      setListaFaq(response?.listaFaq || []);
      const argomentiTemp = [];
      response?.listaFaq.forEach((faq) => {
        if (!argomentiTemp.includes(faq.argomento)) {
          argomentiTemp.push(faq.argomento);
        }
      });
      setArgomenti(argomentiTemp);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getListaFaq(0, 10);
  }, []);

  return (
    <>
      <Title
        title="FAQ"
        subtitle="In questa sezione puoi consultare le FAQ (Frequently Asked Question)."
        showRefresh
        functionRefresh={() => { getListaFaq(0, 10); }}
      />
      <FiltriRicercaFaq
        filtri={filtri}
        getListaFaq={getListaFaq}
        onChange={onChangeFiltri}
      />
      <br />
      <Accordion>
        {argomenti && argomenti.map((argomento) => (
          <Accordion.Item eventKey={argomento} key={argomento}>
            <Accordion.Header>{argomento}</Accordion.Header>
            <Accordion.Body>
              <Accordion>
                {
                  listaFaq && listaFaq.filter((el) => el.argomento === argomento).map((faq) => (
                    <Accordion.Item eventKey={faq.id} key={faq.id}>
                      <Accordion.Header>{faq.domanda}</Accordion.Header>
                      <Accordion.Body>
                        <RenderHTML html={faq.risposta} />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                }
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}

export default GestioneFaq;
