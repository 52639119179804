import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';
import { formatoData } from '../../../utils/formatData';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';

const fields = () => [
  {
    dataField: 'sequ_k_richiesta',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_msg_richiesta',
    text: 'Richiesta',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '35%' }),
  },
  {
    dataField: 'data_richiesta',
    text: 'Data',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'codi_stato_richiesta',
    text: 'Stato',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => (e === 'A' ? 'In attesa' : e === 'C' ? 'Presa in carico' : 'Evasa'),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e, row) => (

      <div>

        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'search']}
          size="lg"
          title="Visualizza"
          id={`Visualizza${row.sequ_k_richiesta}`}
          style={{ color: themeColors.primary }}
          onClick={() => history.push(`/area-riservata/richiesta-identita/${row.sequ_k_richiesta}`)}
        />
        {controlla('X20') && row.codi_stato_richiesta === 'A' ? (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash']}
            size="lg"
            title="Elimina"
            id={`Visualizza${row.sequ_k_richiesta}`}
            style={{ color: themeColors.primary }}
            onClick={() => history.push(`/area-riservata/richiesta-identita/${row.sequ_k_richiesta}?elimina=true`)}
          />
        ) : null }
      </div>

    ),
  },
];

export default fields;
