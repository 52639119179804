import { React, useEffect, useState } from 'react';
import Title from '../../../../components/GestisciUtenti/TitoloPagina/index';
import FormGestione from '../../../../components/Ruoli/FiltriRicerca';
import Table from '../../../../components/Shared/Table';
import { fieldsNoProfonditaStorica, fieldsProfonditaStorica } from './fieldsTable';
import history from '../../../../utils/history';
import ruoliService from '../../../../services/ruoliService';
import tagRuoliService from '../../../../services/tagRuoliService';
import { getItem, setItem } from '../../../../utils/storage';
import { controlla } from '../../../../utils/verificaPermessi';

function GestioneRuoli() {
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    flag_admin: false,
    flag_default: null,
    desc_nome_ruolo: null,
    fk_sequ_tag_ruolo: null,
    data_inizio_validita_from: null,
    data_inizio_validita_to: null,
    data_fine_validita_from: null,
    data_fine_validita_to: null,
    profondita_storica: false,
  });
  const [tagRuoli, setTagRuoli] = useState([]);
  const [ruoli, setRuoli] = useState([]);

  async function getRuoli(offset, limit, page, filters) {
    const filtriSession = filters || filtri;
    const response = await ruoliService.getRuoli(filtriSession, offset, limit);
    setRuoli(response.data);
    setPagination((prevPag) => ({
      ...prevPag,
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    }));
  }

  async function getTagRuoli() {
    const response = await tagRuoliService.getTagRuoli();
    setTagRuoli(response.data);
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getRuoli(currentIndex, sizePerPage, page);
  };

  const checkStorage = () => {
    const filters = JSON.parse(getItem('filtri'));
    const pag = getItem('page');
    if (filters) {
      setFiltri(filters);
    }
    if (pag) {
      setPagination((prevPag) => ({
        ...prevPag,
        page: pag,
      }));
    }
    return { filtri: filters || filtri, page: parseInt(pag, 10) || 1 };
  };

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage();
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getRuoli(offset, 5, storage.page, storage.filtri);
    getTagRuoli();
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  return (
    <>
      <Title
        title="Gestione ruoli"
        subtitle="È possibile ricercare, gestire e creare ruoli"
        showRefresh
        showAdd={controlla('X5') === true}
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione')}
        functionRefresh={() => getRuoli(0, 5, 1)}
        functionAdd={() => history.push('/area-riservata/amministrazione/gestione-ruolo/inserimento')}
      />

      <FormGestione
        getRuoli={getRuoli}
        onChange={onChangeFiltri}
        filtri={filtri}
        tagRuoli={tagRuoli}
      />
      <Table
        title="Elenco ruoli"
        data={ruoli}
        fields={filtri.profondita_storica === 'true' ? fieldsProfonditaStorica : fieldsNoProfonditaStorica}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="sequ_k_ruolo"
      />

    </>
  );
}

export default GestioneRuoli;
