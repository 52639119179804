import { formatoData } from '../../../../utils/formatData';

const fields = () => [
  {
    dataField: 'contenuti.IT.titolo',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '60%' }),
  },
  {
    dataField: 'entita',
    text: 'Sezione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'dataCreazione',
    text: 'Data',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => (formatoData(e)),
  },
  //   {
  //     dataField: 'df1',
  //     isDummyField: true,
  //     text: 'Azioni',
  //     headerStyle: () => ({ width: '30%' }),
  //     formatter: (e, row) => (
  //       <div>
  //         <FontAwesomeIcon
  //           icon={['fas', 'edit']}
  //           size="lg"
  //           title={`Modifica la versione ${row.contenuti.IT.titolo}`}
  //           id={`modifica-${row._id}`}
  //           style={{ color: themeColors.primary }}
  //           onClick={() => history.push(`/
  //   area-riservata/siti-web/contenuti-dinamici/by-id/${row._id}/modifica`)}
  //         />

  //         <FontAwesomeIcon
  //           icon={['fas', 'trash']}
  //           size="lg"
  //           title={`Gestisci i contenuti dinamici della versione ${row.contenuti.IT.titolo}`}
  //           id={`contenuti-dinamici-${row._id}`}
  //           style={{ color: themeColors.primary }}
  //           onClick={() => { setContenutoScelto(row); handleStateModaleConferma(); }}
  //         />
  //       </div>

//     ),
//   },
];

export default fields;
