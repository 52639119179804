/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import NuovoTagRuolo from './NuovoTagRuolo';
import Table from '../Shared/Table';
import { fields, selectRows } from './fieldsTableFunzioni';
import FiltriRicercaFunzioni from './FiltriRicercaFunzioni';

const ModaleNuovoTagRuolo = ({
  show,
  handleClose,

}) => (
  <>
    <Modal show={show} onHide={handleClose} centered size="xl" role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Creazione nuovo tag ruolo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NuovoTagRuolo closeModal={handleClose} />
      </Modal.Body>

    </Modal>
  </>
);

export default ModaleNuovoTagRuolo;
