/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React } from 'react';
import {
  Button,
  Col, Form, Row,
} from 'react-bootstrap';
import { formatoData } from '../../utils/formatData';

function FormDettaglioIdentita({
  onChangeForm,
  nuovaIdentita,
  listaTagIdentita,
  listaOrganizzazioni,
  listaTipoIdentita,
  disabled,
  disabledModifica,
  handleModaleNewTagIdentita,
}) {
  return (
    <>
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="desc_nome_identita">
            <Form.Label>Nome identità*</Form.Label>
            <Form.Control
              placeholder="Inserisci il nome dell'identità"
              onChange={onChangeForm}
              value={nuovaIdentita.desc_nome_identita}
              disabled={disabled || disabledModifica}
            />
          </Form.Group>
          <Form.Group as={Col} lg controlId="desc_identita">
            <Form.Label>Descrizione identità*</Form.Label>
            <Form.Control
              placeholder="Inserisci la descrizione dell'identià"
              onChange={onChangeForm}
              value={nuovaIdentita.desc_identita}
              disabled={disabled}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="col-lg-6" lg controlId="data_inizio_validita">
            <Form.Label>Data inizio validità</Form.Label>
            <Form.Control
              onChange={onChangeForm}
              value={formatoData(nuovaIdentita.data_inizio_validita)}
              disabled
            />
          </Form.Group>
          {nuovaIdentita.data_fine_validita && (
          <Form.Group as={Col} className="col-lg-6" lg controlId="data_fine_validita">
            <Form.Label>Data fine validità*</Form.Label>
            <Form.Control
              onChange={onChangeForm}
              value={formatoData(nuovaIdentita.data_fine_validita)}
              disabled
            />
          </Form.Group>
          )}
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="desc_note_identita">
            <Form.Label>Note identità</Form.Label>
            <Form.Control
              placeholder="Inserisci le note dell'identità"
              onChange={onChangeForm}
              value={nuovaIdentita.desc_note_identita}
              disabled={disabled}
            />
          </Form.Group>
          <Form.Group as={Col} lg controlId="fk_codi_tipo_identita">
            <Form.Label>Tipo*</Form.Label>
            <Form.Select
              onChange={onChangeForm}
              value={nuovaIdentita.fk_codi_tipo_identita}
              disabled={disabled || disabledModifica}
            >
              <option> </option>
              {listaTipoIdentita.map((tipo) => (
                <option
                  key={tipo.codi_k_tipo_identita}
                  value={tipo.codi_k_tipo_identita}
                >
                  {tipo.desc_tipo_identita}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className={!disabled && nuovaIdentita.fk_sequ_tag_identita !== 1 ? 'col-lg-4' : 'col-lg-6'} controlId="fk_sequ_tag_identita">
            <Form.Label>Tag*</Form.Label>
            <Form.Select
              onChange={onChangeForm}
              disabled={disabled || nuovaIdentita.fk_sequ_tag_identita === 1}
              value={nuovaIdentita.fk_sequ_tag_identita}
            >
              <option> </option>
              {listaTagIdentita.map((tag) => (
                <option
                  key={tag.sequ_k_tag_identita}
                  value={tag.sequ_k_tag_identita}
                >
                  {tag.desc_nome_tag_identita}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {!disabled && nuovaIdentita.fk_sequ_tag_identita !== 1
            ? (
              <Col className="d-flex align-items-end">
                <Button variant="primary" onClick={handleModaleNewTagIdentita}>
                  <FontAwesomeIcon aria-hidden="true" icon="plus" />
                  {' '}
                  CREA TAG
                </Button>
              </Col>
            ) : null }
          {nuovaIdentita.fk_sequ_organizzazione && (
          <Form.Group as={Col} className="col-lg-6" lg controlId="fk_sequ_organizzazione">
            <Form.Label>Organizzazione</Form.Label>
            <Form.Select
              onChange={onChangeForm}
              value={nuovaIdentita.fk_sequ_organizzazione}
              disabled
            >
              <option> </option>
              {listaOrganizzazioni.map((org) => (
                <option
                  key={org.sequ_k_organizzazione}
                  value={org.sequ_k_organizzazione}
                >
                  {org.codi_organizzazione}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
          )}
        </Row>
      </Form>
    </>
  );
}

export default FormDettaglioIdentita;
