/* eslint-disable prefer-template */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
} from 'react-bootstrap';
import styles from '../Versioni/FormImmagini.module.css';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

const FormImmagini = ({
  onChange, listaImmagini, immagineSelected, immagine, onUpload, onChangeAltImmagine,
  uploadImmagine, handleStateNuovaImmagine,
}) => (
  <Card className="mb-3">
    <Card.Body>
      <Accordion className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Seleziona immagini dalla galleria:
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {listaImmagini?.length
                ? listaImmagini?.map((el) => (
                  <Col className="m-1" key={el._id}>
                    <Image
                      key={el._id}
                      height="200"
                      src={sitiWebUrl + '/galleria/' + el._id}
                      alt={el.alt}
                      onClick={() => onChange(el)}
                      tabIndex={0}
                      className={immagineSelected?.id === el._id
                        ? styles.logoSelected : 'logo'}
                    />
                  </Col>
                ))
                : <p>Nessuna immagine presente.</p>}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Aggiungi una nuova immagine alla galleria
          </Accordion.Header>
          <Accordion.Body>
            <Form.Group className="my-3" as={Col} controlId="nomeImmagine">
              <Form.Label>Immagine*</Form.Label>
              <Form.Control value={immagine?.nomeImmagine} type="file" size="sm" onChange={onUpload} />
              <Form.Text muted>
                È possibile inserire soltanto file di dimensioni non superiori a 5 MB.
                Sono supportati soltanto i seguenti formati: .jpg, .png, .gif
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="alt" className="mb-3">
              <Form.Label>Testo alternativo*</Form.Label>
              <Form.Control
                placeholder="Inserisci il testo alternativo"
                value={immagine?.alt}
                onChange={onChangeAltImmagine}
              />
            </Form.Group>
            <Button disabled={immagine?.nomeImmagine === '' || immagine?.alt.trim() === ''} onClick={uploadImmagine}>
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'upload']} />
              CARICA
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Button variant="primary" className="float-end" onClick={handleStateNuovaImmagine} disabled={!immagineSelected.id}>
        <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
        {' '}
        AGGIUNGI IMMAGINE
      </Button>
    </Card.Body>
  </Card>
);

export default FormImmagini;
