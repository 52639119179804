import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import {
  Modal, Button, Form, Row,
} from 'react-bootstrap';
import tiny from '../../utils/TinyMCEConfiguration';

function elementModal({
  setParagrafo, paragrafo, show, handleClose, handleSave,
}) {
  function onChangeTitolo(e) {
    const { value } = e.target;
    setParagrafo({
      ...paragrafo,
      nome: value,
    });
  }

  function onEditorChange(content) {
    setParagrafo({
      ...paragrafo,
      contenuto: content,
    });
  }

  return (
    <div>
      <Modal size="lg" centered aria-labelledby="modal-message-title" show={show} onHide={handleClose} role="dialog">
        <Modal.Header closeButton closeLabel="Chiudi">
          <Modal.Title id="modal-message-title">Modifica Paragrafo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Titolo:</Form.Label>
          <Form.Control
            placeholder="Inserisci il nome del paragrafo"
            value={paragrafo.nome}
            onChange={onChangeTitolo}
          />

          <Form.Label>Inserisci qui un paragrafo:</Form.Label>
          <Form>
            <Row className="mb-3">
              <Editor
                id="content-html2"
                init={{
                  plugins: tiny.plugins,
                  toolbar: tiny.toolbar,
                }}
                apiKey={tiny.apiKey}
                value={paragrafo.contenuto}
                onEditorChange={onEditorChange}
              />
            </Row>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="grey" onClick={handleClose}>Chiudi</Button>
          <Button
            variant="primary"
            onClick={handleSave}
          >
            Aggiungi

          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default elementModal;
