import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import cellEditFactory from 'react-bootstrap-table2-editor';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import Table from '../../../components/Shared/Table';
import repositoryService from '../../../services/repositoryService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import fields from './fieldsTableConf';

const Configurazione = () => {
  const [form, setForm] = useState({
    dimensioneMassima: 0,
    metadati: [{}],
  });
  const [showModaleConferma, setShowModaleConferma] = useState(false);
  const [context, setContext] = useContext(Context);

  const handleCloseModaleConferma = () => setShowModaleConferma(!showModaleConferma);

  async function modificaConfigurazione() {
    await repositoryService.modificaConfigurazione(form)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleCloseModaleConferma();
      });
  }

  async function getConfigurazione() {
    await repositoryService.getConfigurazione()
      .then((res) => {
        setForm(res);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  const onChange = (e) => {
    const { value, id } = e.target;
    setForm({
      ...form,
      [id]: parseInt(value, 10),
    });
  };

  function aggiungiRow() {
    setForm({
      ...form,
      metadati: [...form.metadati, { id: Date.now(), chiave: '', etichetta: '' }],
    });
  }

  function eliminaRow(id) {
    setForm({
      ...form,
      metadati: form.metadati.filter((el) => el.id !== id),
    });
  }

  useEffect(() => {
    getConfigurazione();
  }, []);

  return (
    <>
      <TitlePage
        title="Repository - configurazione"
        subtitle="Attraverso il form seguente puoi configurare il repository."
        showIndietro
        functionIndietro={() => {
          history.goBack();
        }}
      />
      <Form>
        <Form.Group className="w-25 p-3" controlId="dimensioneMassima">
          <Form.Label>Dimensione massima file (in MB)</Form.Label>
          <Form.Control value={form.dimensioneMassima} type="number" placeholder="Inserire dimensione massima file" onChange={onChange} />
        </Form.Group>
        <Table
          data={form.metadati}
          fields={fields(eliminaRow)}
          keyField="id"
          onTableChange={() => {}}
          striped
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            beforeSaveCell:
            (oldValue, newValue, row, column) => {
              form.metadati.find((el) => el.id === row.id)[column.dataField] = newValue;
              setForm({
                ...form,
                metadati: form.metadati,
              });
            },
          })}
          button={(
            <Button variant="primary" className="mb-3 float-end" onClick={aggiungiRow}>
              <FontAwesomeIcon aria-hidden="true" icon="plus" />
              {' '}
              AGGIUNGI
            </Button>
)}
        />
      </Form>
      <Button variant="primary" className="m-3 float-end" onClick={handleCloseModaleConferma}>
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        {' '}
        SALVA
      </Button>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione modificherà la configurazione esistente."
        show={showModaleConferma}
        handleClose={handleCloseModaleConferma}
        handleOk={modificaConfigurazione}
      />
    </>
  );
};

export default Configurazione;
