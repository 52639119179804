import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Form, Row, Button, Accordion,
} from 'react-bootstrap';
import { dataAttuale } from '../../../../utils/dataAttuale';
import { setItem } from '../../../../utils/storage';

function Filtri({
  filtri, onChange, getListaIdentita, listaTipiIdentita, listaTagIdentita,
}) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="desc_nome_identita">
                <Form.Label>Nome</Form.Label>
                <Form.Control placeholder="Inserisci il nome" onChange={onChange} value={filtri.desc_nome_identita} />
              </Form.Group>

              <Form.Group as={Col} lg controlId="fk_sequ_tag_identita">
                <Form.Label>Tag identità</Form.Label>
                <Form.Select
                  onChange={onChange}
                  value={filtri.fk_sequ_tag_identita}
                >
                  <option> </option>
                  {listaTagIdentita.map((tag) => (
                    <option
                      key={tag.sequ_k_tag_identita}
                      value={tag.sequ_k_tag_identita}
                    >
                      {tag.desc_nome_tag_identita}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} lg controlId="fk_codi_tipo_identita">
                <Form.Label>Tipo identità</Form.Label>
                <Form.Select
                  onChange={onChange}
                  value={filtri.fk_codi_tipo_identita}
                >
                  <option> </option>
                  {listaTipiIdentita.map((tipo) => (
                    <option
                      key={tipo.codi_k_tipo_identita}
                      value={tipo.codi_k_tipo_identita}
                    >
                      {tipo.desc_tipo_identita}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_inizio_validita_from">Data inizio validità dal</Form.Label>
                <input
                  type="date"
                  id="data_inizio_validita_from"
                  name="dataInizioValiditaFrom"
                  min="2021-01-01"
                  value={filtri.data_inizio_validita_from}
                  max={dataAttuale()}
                  className="form-control"
                  onChange={onChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_inizio_validita_to">Data inizio validità al</Form.Label>
                <input
                  type="date"
                  id="data_inizio_validita_to"
                  name="dataInizioValiditaTo"
                  min={filtri.data_inizio_validita_from || '2021-01-01'}
                  value={filtri.data_inizio_validita_to}
                  max={dataAttuale()}
                  className="form-control"
                  onChange={onChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_fine_validita_from">Data fine validità dal</Form.Label>
                <input
                  type="date"
                  id="data_fine_validita_from"
                  name="dataFineValiditaFrom"
                  min="2021-01-01"
                  max={dataAttuale()}
                  value={filtri.data_fine_validita_from}
                  className="form-control"
                  onChange={onChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_fine_validita_to">Data fine validità al</Form.Label>
                <input
                  type="date"
                  id="data_fine_validita_to"
                  name="dataFineValiditaTo"
                  min={filtri.data_fine_validita_from || '2021-01-01'}
                  max={dataAttuale()}
                  value={filtri.data_fine_validita_to}
                  className="form-control"
                  onChange={onChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="col-lg-6" controlId="profondita_storica">
                <Form.Label>Visualizza profondità storica</Form.Label>
                <Form.Select
                  onChange={onChange}
                  value={filtri.profondita_storica}
                >
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Button variant="primary" onClick={() => { setItem('filtri', JSON.stringify(filtri)); getListaIdentita(0, 5, 1); }} aria-label="Ricerca le informazioni secondo i filtri inseriti">
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} />
              {' '}
              CERCA
            </Button>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Filtri;
