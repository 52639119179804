import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

async function getListaContenutiDinamici(filtri, offset, limit) {
  const filters = { ...filtri, offset, limit };
  const params = createQueryString(filters);
  return api.get(`${sitiWebUrl}/contenuti-dinamici?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function aggiungiContenutoDinamico(body) {
  return api.post(`${sitiWebUrl}/contenuti-dinamici`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getContenutoDinamico(id) {
  return api.get(`${sitiWebUrl}/contenuti-dinamici/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaContenutoDinamico(id) {
  return api.delete(`${sitiWebUrl}/contenuti-dinamici/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaContenutoDinamico(id, body) {
  return api.put(`${sitiWebUrl}/contenuti-dinamici/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getContenutiHomePage() {
  return api.get(`${sitiWebUrl}/contenuti-dinamici/homepage`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const contenutiDinamiciService = {
  getListaContenutiDinamici,
  aggiungiContenutoDinamico,
  getContenutoDinamico,
  eliminaContenutoDinamico,
  modificaContenutoDinamico,
  getContenutiHomePage,
};

export default contenutiDinamiciService;
