import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Title from '../../../../../components/GestisciUtenti/TitoloPagina';
import { fieldsProfonditaStoricaIdentita, fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Ruoli/fieldsTableIdentita';
import ModaleRicercaIdentitaModifica from '../../../../../components/Identita/ModaleRicercaIdentitaModifica';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import Table from '../../../../../components/Shared/Table';
import identitaService from '../../../../../services/identitaService';
import TagIdentitaService from '../../../../../services/tagIdentitaService';
import tipoIdentitaService from '../../../../../services/tipoIdentitaService';
import utenteService from '../../../../../services/utentiService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';
import { fieldsProfonditaStoricaConnettori, fieldsNoProfonditaStoricaConnettori } from './fieldTableUtentiUntrusted';
import useQuery from '../../../../../utils/useQuery';

function ModificaUtentePerAmministratore() {
  const query = useQuery();
  const { idUtente } = useParams();
  const [context, setContext] = useContext(Context);
  const [utente, setUtente] = useState({});
  const [listaTagIdentita, setListaTagIdentita] = useState([]);
  const [listaTipoIdentita, setListaTipoIdentita] = useState([]);
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [identitaScelte, setIdentitaScelte] = useState([]);
  const [identitaAggiunte, setIdentitaAggiunte] = useState([]);
  const [identitaRimosse, setIdentitaRimosse] = useState([]);
  const [showModalIdentita, setShowModalIdentita] = useState(false);
  const [listaIdentita, setListaIdentita] = useState([]);
  const [paginationIdentita, setPaginationIdentita] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtriIdentita, setFiltriIdentita] = useState({
    flag_base: false,
    fk_sequ_tag_identita: null,
    desc_nome_identita: '',
    fk_codi_tipo_identita: null,
    profondita_storica: false,
  });
  const [filtriUtente, setFiltriUtente] = useState({
    profondita_storica_connettori: 'false',
    profondita_storica_identita: 'false',
  });
  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function handleModaleIdentitaStatus() {
    setShowModalIdentita(!showModalIdentita);
  }

  function onChangeFiltriIdentita(e) {
    let { value } = e.target;
    const { id } = e.target;
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(value)) {
      value = parseInt(value, 10);
    }
    setFiltriIdentita({
      ...filtriIdentita,
      [id]: value,
    });
  }

  function onChangeFiltriUtente(e) {
    const { id } = e.target;
    const { value } = e.target;
    setFiltriUtente({
      ...filtriUtente,
      [id]: value,
    });
  }

  async function modificaUtente() {
    const identitaBody = [];
    const nuoveAggiunte = [...new Set(identitaAggiunte)];
    const nuoveRimosse = [...new Set(identitaRimosse)];
    nuoveRimosse.forEach((el) => {
      identitaBody.push({ ...el, azione: 'elimina' });
    });
    nuoveAggiunte.forEach((el) => {
      identitaBody.push({ ...el, azione: 'aggiungi' });
    });

    utenteService.modificaUtente({
      id: utente.sequ_k_utente_trusted,
      trusted: true,
      identita: identitaBody,
    })
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.goBack();
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function getUtente() {
    const response = await utenteService.getUtenteById(idUtente,
      { trusted: query.get('trusted'), ...filtriUtente });
    setUtente(response);
    setIdentitaScelte(response.identita_associate);
  }
  async function getListaIdentita(offset, limit, page) {
    const response = await identitaService.getIdentita(
      { flag_config: false, ...filtriIdentita }, offset, limit,
    );
    setListaIdentita(response.data);
    setPaginationIdentita({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getListaTagIdentita() {
    const response = await TagIdentitaService.getTagIdentita();
    setListaTagIdentita(response.data);
  }

  async function getListaTipoIdentita() {
    const response = await tipoIdentitaService.getTipoIdentita({ flag_protetto: false });
    setListaTipoIdentita(response);
  }

  const handleTableChangeIdentita = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getListaIdentita(currentIndex, sizePerPage, page);
  };
  useEffect(() => {
    getUtente();
    getListaIdentita(0, 5, 1);
    getListaTagIdentita();
    getListaTipoIdentita();
  }, []);

  useEffect(() => {
    getUtente();
  }, [filtriUtente]);
  return (
    <>
      <Title
        title="Modifica identità utente"
        subtitle="È possibile modificare le identità ad esso associate"
        showRefresh
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="desc_nome">
            <Form.Label>Nome</Form.Label>
            <Form.Control placeholder="Nome" value={utente.desc_nome} disabled />
          </Form.Group>

          <Form.Group as={Col} lg controlId="desc_cognome">
            <Form.Label>Cognome</Form.Label>
            <Form.Control placeholder="Cognome" value={utente.desc_cognome} disabled />
          </Form.Group>
          <Form.Group as={Col} lg controlId="codi_id_fiscale">
            <Form.Label>Codice fiscale</Form.Label>
            <Form.Control placeholder="Codice fiscale" value={utente.codi_id_fiscale} disabled />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="data_registrazione">
            <Form.Label>Data registrazione</Form.Label>
            <Form.Control placeholder="Data registrazione" value={utente.data_registrazione} disabled />
          </Form.Group>
          <Form.Group as={Col} lg controlId="desc_email">
            <Form.Label>Indirizzo email</Form.Label>
            <Form.Control placeholder="Indirizzo email" value={utente.desc_email} disabled />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="codi_area">
            <Form.Label>Area</Form.Label>
            <Form.Control placeholder="Area" value={utente.codi_area} disabled />
          </Form.Group>
          <Form.Group as={Col} lg controlId="codi_servizio">
            <Form.Label>Servizio</Form.Label>
            <Form.Control placeholder="Servizio" value={utente.codi_servizio} disabled />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <span>
            <Form.Label>Identità da associare all&apos;identità*</Form.Label>
            {' '}
            <Button onClick={handleModaleIdentitaStatus}>Modifica</Button>
          </span>
          <Table
            title="Elenco identità associate"
            data={identitaScelte}
            fields={filtriUtente.profondita_storica_identita === 'true'
              ? fieldsProfonditaStoricaIdentita : fieldsNoProfonditaStoricaIdentita}
            keyField="sequ_k_identita"
            profonditaStorica={(
              <Form.Group controlId="profondita_storica_identita">
                <Form.Label>Visualizza profondità storica</Form.Label>
                <Form.Select
                  onChange={onChangeFiltriUtente}
                  value={filtriUtente.profondita_storica_identita}
                >
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
          )}
          />
        </Row>
        <Row className="mb-3">
          {utente.utenti_untrusted_associati && (
          <Table
            title="Elenco utenti untrusted associati"
            data={utente.utenti_untrusted_associati}
            fields={filtriUtente.profondita_storica_connettori === 'true'
              ? fieldsProfonditaStoricaConnettori : fieldsNoProfonditaStoricaConnettori}
            keyField="sequ_k_utente_untrusted"
            profonditaStorica={(
              <Form.Group controlId="profondita_storica_connettori">
                <Form.Label>Visualizza profondità storica</Form.Label>
                <Form.Select
                  onChange={onChangeFiltriUtente}
                  value={filtriUtente.profondita_storica_connettori}
                >
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
          )}
          />
          )}
        </Row>
        <div className="d-flex justify-content-end">
          <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
            <FontAwesomeIcon aria-hidden="true" icon="save" />
            {' '}
            Salva
          </Button>
        </div>
        <ModaleConferma
          labelBottone="Conferma"
          coloreAzione="primary"
          title="Sei sicuro di procedere?"
          body={'L\'operazione modificherà le identità dell\'utente'}
          show={openCloseModaleConferma}
          handleClose={handleStateModaleConferma}
          handleOk={modificaUtente}
        />
        <ModaleRicercaIdentitaModifica
          show={showModalIdentita}
          handleClose={handleModaleIdentitaStatus}
          identita={listaIdentita}
          getListaIdentita={getListaIdentita}
          identitaScelte={identitaScelte}
          setIdentitaScelte={setIdentitaScelte}
          handleTableChangeIdentita={handleTableChangeIdentita}
          listaTagIdentita={listaTagIdentita}
          listaTipoIdentita={listaTipoIdentita}
          paginationIdentita={paginationIdentita}
          setPaginationIdentita={setPaginationIdentita}
          onChangeFiltriIdentita={onChangeFiltriIdentita}
          identitaAggiunte={identitaAggiunte}
          identitaRimosse={identitaRimosse}
          setIdentitaAggiunte={setIdentitaAggiunte}
          setIdentitaRimosse={setIdentitaRimosse}
        />
      </Form>
    </>
  );
}

export default ModificaUtentePerAmministratore;
