const spidList = [
  {
    name: 'aruba',
    url: `https://10.201.0.195/iam/Login1?entityID=${process.env.REACT_APP_ARUBA_URL}&target=https://10.201.0.195/login`,
    title: 'Accedi a SPID con Aruba',
    logo: 'spid-idp-arubaid.svg',
  },

  {
    name: 'infocert',
    url: `https://10.201.0.195/iam/Login1?entityID=${process.env.REACT_APP_INFOCERT_URL}&target=https://10.201.0.195/login`,
    title: 'Accedi a SPID con Infocert',
    logo: 'spid-idp-infocertid.svg',
  },

  {
    name: 'poste',
    url: `https://10.201.0.195/iam/Login1?entityID=${process.env.REACT_APP_POSTE_URL}&target=https://10.201.0.195/login`,
    title: 'Accedi a SPID con Poste',
    logo: 'spid-idp-posteid.svg',

  },
  {
    name: 'intesa',
    url: `https://10.201.0.195/iam/Login1?entityID=${process.env.REACT_APP_INTESA_URL}&target=https://10.201.0.195/login`,
    title: 'Accedi a SPID con Intesa',
    logo: 'spid-idp-intesaid.svg',

  },
  {
    name: 'sielte',
    url: `https://10.201.0.195/iam/Login1?entityID=${process.env.REACT_APP_SIELTE_URL}&target=https://10.201.0.195/login`,
    title: 'Accedi a SPID con Sielte',
    logo: 'spid-idp-sielteid.svg',
  },
  {
    name: 'tim',
    url: `https://10.201.0.195/iam/Login1?entityID=${process.env.REACT_APP_SIELTE_URL}&target=https://10.201.0.195/login`,
    title: 'Accedi a SPID con Tim',
    logo: 'spid-idp-timid.svg',
  },
  {
    name: 'register',
    url: `https://10.201.0.195/iam/Login1?entityID=${process.env.REACT_APP_SIELTE_URL}&target=https://10.201.0.195/login`,
    title: 'Accedi a SPID con Register',
    logo: 'spid-idp-spiditalia.svg',
  },
  {
    name: 'namirialtsp',
    url: `https://10.201.0.195/iam/Login1?entityID=${process.env.REACT_APP_SIELTE_URL}&target=https://10.201.0.195/login`,
    title: 'Accedi a SPID con Namirial',
    logo: 'spid-idp-namirialid.svg',
  },

];

export default spidList;
