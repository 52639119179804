import React, {
  useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../../../components/Shared/Table';
import fileAmmTraspService from '../../../../../services/fileAmmTraspService';
import { Context } from '../../../../../utils/Context';
import fields from '../Tables/fieldsTableElements';
import history from '../../../../../utils/history';
import ModalAttachment from '../../../../../components/AmmTrasparente/ModalAttachment';
import entitaTrasparenzaService from '../../../../../services/entitaTrasparenzaService';

export default function GestioneAllegatiAmmTrasparente() {
  const [context, setContext] = useContext(Context);
  const { idEntita } = useParams();
  const params = new URLSearchParams(window.location.search);
  const organizzazione = params.get('organizzazione');
  const [elements, setElements] = useState([]);
  const [arrayAllegati, setArrayAllegati] = useState([]);
  const [index, setIndex] = useState();
  const [offsetIndex, setOffsetIndex] = useState(0);
  const [limitIndex, setLimitIndex] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState({
    nome: '',
    dataCreazione: new Date(),
  });

  async function getElements(offset, limit, page) {
    try {
      const response = await entitaTrasparenzaService
        .getAllegatiByEntita(idEntita, organizzazione, offset, limit);
      setPagination({
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      });
      setElements(response);
      setOffsetIndex(offset);
      setLimitIndex(limit);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex = 0;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getElements(currentIndex, sizePerPage, page);
  };

  function manageArrayAllegati(allegati, rowIndex) {
    if (!allegati) {
      setElements({
        ...elements,
        elencoRecord: elements.elencoRecord.map((el, rowIndex2) => {
          if (rowIndex2 === rowIndex) {
            const obj = {
              ...el,
              Allegati: [],
            };
            return obj;
          }
          return el;
        }),
      });
      setArrayAllegati([]);
    } else {
      setArrayAllegati(allegati);
    }
  }

  function handleShowModal(ind) {
    setShowModal(true);
    setIndex(ind);
  }

  function updateAllegati() {
    setElements({
      ...elements,
      elencoRecord: elements.elencoRecord.map((el, rowIndex) => {
        if (index === rowIndex) {
          const obj = {
            ...el,
            Allegati: arrayAllegati,
          };
          return obj;
        }
        return el;
      }),
    });
  }

  async function uploadFile() {
    try {
      const response = await fileAmmTraspService.uploadFile(file);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      file.idMongo = response.insertedId;
      file.nomeFile = response.nomeFile;
      setArrayAllegati([
        ...arrayAllegati,
        file,
      ]);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function saveEntity() {
    try {
      const response = await entitaTrasparenzaService
        .salvaEntita(idEntita, elements, organizzazione, offsetIndex, limitIndex);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      getElements(0, 5);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function handleStateNuovoFile() {
    uploadFile();

    setFile({
      nome: '',
      dataCreazione: new Date(),
    });
  }

  useEffect(() => {
    getElements(0, 5);
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione allegati Amministrazione Trasparente"
        subtitle="In questa sezione puoi gestire gli allegati dell' entità corrente."
        showRefresh
        showIndietro
        showSave
        functionSave={() => saveEntity()}
        functionIndietro={() => history.goBack()}
        functionRefresh={() => getElements(0, 5)}
      />
      <Table
        title="Lista elementi dell'Entità"
        data={elements.elencoRecord || []}
        fields={fields(handleShowModal, manageArrayAllegati, elements.elencoRecord
          ? Object.values(elements.elencoRecord)[0] : {})}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField={['Allegati', '']}
      />

      <ModalAttachment
        showModal={showModal}
        setShowModal={setShowModal}
        arrayAllegati={arrayAllegati || []}
        setArrayAllegati={setArrayAllegati}
        file={file}
        setFile={setFile}
        handleStateNuovoFile={handleStateNuovoFile}
        pagination={pagination}
        updateAllegati={updateAllegati}
      />
    </>
  );
}
