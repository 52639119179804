import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';
import {
  Button,
  Card, Col, Form, Row,
} from 'react-bootstrap';
import tiny from '../../utils/TinyMCEConfiguration';

function FormParagrafo({
  paragrafo, setParagrafo, handleStateNuovoParagrafo, disabled,
}) {
  function onChangeTitolo(e) {
    const { value } = e.target;
    setParagrafo({
      ...paragrafo,
      nome: value,
    });
  }

  function onEditorChange(content) {
    setParagrafo({
      ...paragrafo,
      contenuto: content,
    });
  }

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="nome">
            <Form.Label>Inserisci qui i contenuti testuali:</Form.Label>
            <Form.Control
              placeholder="Inserisci il nome del paragrafo"
              value={paragrafo?.nome}
              onChange={onChangeTitolo}
              disabled={disabled}
            />
          </Form.Group>
        </Row>
        <Card className="g-4 mb-3">
          <Card.Header>
            <Form.Label>Inserisci qui un paragrafo:</Form.Label>
            <Form>
              <Row className="mb-3">
                <Editor
                  id="content-html"
                  init={{
                    plugins: tiny.plugins,
                    toolbar: tiny.toolbar,
                  }}
                  apiKey={tiny.apiKey}
                  value={paragrafo.contenuto}
                  onEditorChange={onEditorChange}
                />
              </Row>
            </Form>
          </Card.Header>
        </Card>
        <Button variant="primary" className="float-end" onClick={() => { handleStateNuovoParagrafo(); }} disabled={!paragrafo.contenuto}>
          <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
          {' '}
          AGGIUNGI PARAGRAFO
        </Button>
      </Card.Body>
    </Card>
  );
}

export default FormParagrafo;
