/* eslint-disable no-underscore-dangle */
/* eslint-disable no-mixed-operators */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col, Row, Table,
} from 'react-bootstrap';
import themeColors from '../../utils/contants/theme-colors';
import { formatoDataEOra } from '../../utils/formatData';

function CardGroupStatisticheQuestionari({
  questionari, functionShow,
}) {
  return (

    <div className="mt-3">
      <Row xs={1} md={1} className="g-4">
        <Row>
          <Col>
            <p className="h4" style={{ lineHeight: '1.6' }}>
              <FontAwesomeIcon aria-hidden="true" icon="file-alt" color={themeColors.primary} />
              {' '}
              Questionari Template
            </p>
          </Col>
        </Row>
        <Row>
          <Table responsive>
            <thead>
              <tr>
                <th>Utente</th>
                <th>Data inzio compilazione</th>
                <th>Data Invio</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {questionari ? Object.values(questionari.listaQuestionari).map((questionario) => (
                <tr>
                  <td>
                    {questionario?.autore?.nome
                      ? (
                        <>
                          {questionario?.autore?.cognome}
                          {' '}
                          {questionario?.autore?.nome}
                        </>
                      )
                      : questionario?.autore?.identificativo}

                  </td>
                  <td>
                    {formatoDataEOra(questionario.dataInizioCompilazione)}
                  </td>
                  <td>
                    {formatoDataEOra(questionario.dataUltimaModifica)}
                  </td>
                  <td>
                    <Button
                      className="me-2"
                      onClick={() => functionShow(questionario._id)}
                      title={`Visualizza il questionario di ${questionario.autore.cognome}
                      ${questionario.autore.nome}`}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="search" />
                    </Button>
                  </td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Row>
      </Row>
      <br />
    </div>

  );
}

export default CardGroupStatisticheQuestionari;
