/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import styles from './AddDialog.module.css';

export default function ModaleCreazioneNuovoElemento(props) {
  const [text, setText] = useState('');
  const [parent, setParent] = useState(0);
  const [droppable, setDroppable] = useState(false);

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleChangeParent = (e) => {
    setParent(Number(e.target.value));
  };

  const handleChangeDroppable = (e) => {
    setDroppable(e.target.checked);
  };

  return (
    <Modal show={props.show} onHide={props.onClose} centered role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Aggiungi elemento</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.content}>
        <Form.Group controlId="desc_nome_tag_ruolo">
          <Form.Label>Nome</Form.Label>
          <Form.Control placeholder="Inserisci nome" onChange={handleChangeText} value={text} />
        </Form.Group>
        <Form.Group className="col-lg-6" controlId="profondita_storica">
          <Form.Label>Parent</Form.Label>
          <Form.Select
            onChange={handleChangeParent}
            value={parent}
          >
            <option value={0}>Root</option>
            {props.tree
              .filter((node) => node.droppable === true && node.text !== 'Homepage')
              .map((node) => (
                <option key={node.id} value={node.id}>
                  {node.text}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Check
          type="switch"
          id="droppable"
          label="Nodo?"
          checked={droppable}
          onChange={handleChangeDroppable}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={props.onClose}>
          Chiudi
        </Button>
        <Button onClick={() => props.onSubmit({
          text,
          parent,
          droppable,
          flagDinamico: false,
        })}
        >
          Aggiungi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
