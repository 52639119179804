import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getOrganizzazioni() {
  return api.get(`${accreditamentoUrl}/gestione-organizzazione`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const organizzazioniService = {
  getOrganizzazioni,
};

export default organizzazioniService;
