import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';
import { formatoData } from '../../../../utils/formatData';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';

const fieldsProfonditaStorica = [
  {
    dataField: 'ordinamento',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
  },
  {
    dataField: 'desc_cognome',
    text: 'Cognome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
  },
  {
    dataField: 'identificativo',
    text: 'Identificativo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'trusted',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => (e ? 'Trusted' : 'Untrusted'),
  },
  {
    dataField: 'desc_tipo_connettore',
    text: 'Tipo connettore',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'data_registrazione',
    text: 'Data registrazione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e, row) => (

      <div>
        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'search']}
          title={`Visualizza l'utente ${row.identificativo}`}
          size="lg"
          id={`visualizza-${row.ordinamento}`}
          style={{ color: themeColors.primary }}
          className="me-2"
          onClick={() => history.push(`/area-riservata/amministrazione/gestione-utenti/${row.id}/dettaglio?trusted=${row.trusted}`)}
        />

        {controlla('X1') && row.trusted
          && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title={`Modifica l'utente ${row.identificativo}`}
            id={`modifica-${row.ordinamento}`}
            style={{ color: themeColors.primary }}
            onClick={() => history.push(`/area-riservata/amministrazione/gestione-utenti/${row.id}/modifica?trusted=${row.trusted}`)}
          />
          )}
      </div>

    ),
  },
];

const fieldsNoProfonditaStorica = [
  {
    dataField: 'ordinamento',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_cognome',
    text: 'Cognome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'identificativo',
    text: 'Identificativo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'trusted',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => (e ? 'Trusted' : 'Untrusted'),
  },
  {
    dataField: 'desc_tipo_connettore',
    text: 'Tipo connettore',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'data_registrazione',
    text: 'Data registrazione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e, row) => (

      <div>
        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'search']}
          title={`Visualizza l'utente ${row.identificativo}`}
          size="lg"
          id={`visualizza-${row.ordinamento}`}
          style={{ color: themeColors.primary }}
          className="me-2"
          onClick={() => history.push(`/area-riservata/amministrazione/gestione-utenti/${row.id}/dettaglio?trusted=${row.trusted}`)}
        />

        {controlla('X1') && row.trusted
          && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title={`Modifica l'utente ${row.identificativo}`}
            id={`modifica-${row.ordinamento}`}
            style={{ color: themeColors.primary }}
            onClick={() => history.push(`/area-riservata/amministrazione/gestione-utenti/${row.id}/modifica?trusted=${row.trusted}`)}
          />
          )}
      </div>

    ),
  },
];

export { fieldsProfonditaStorica, fieldsNoProfonditaStorica };
