import { formatoData } from '../../utils/formatData';

export const fieldsProfonditaStoricaIdentita = [
  {
    dataField: 'sequ_k_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_nome_tag_identita',
    text: 'TAG',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
  },
  {
    dataField: 'desc_tipo_identita',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
];

export const fieldsNoProfonditaStoricaIdentita = [
  {
    dataField: 'sequ_k_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_nome_tag_identita',
    text: 'TAG',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_tipo_identita',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
];

export const selectRows = (setIdentitaScelte, identitaScelte) => {
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...identitaScelte].map((el) => el.sequ_k_identita),
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setIdentitaScelte([...identitaScelte, row]);
      } else {
        setIdentitaScelte(identitaScelte.filter(
          (ruolo) => ruolo.sequ_k_identita !== row.sequ_k_identita,
        ));
      }
    },

  };
  return selectRow;
};
