import React from 'react';
import {
  Form, Row, Button, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FiltriRicercaFunzioni({ onChangeFiltriFunzione, getFunzioni, tagsFunzione }) {
  return (
    <Row id="form-cerca-utenti">
      <Row>
        {' '}
        <h2>Filtri ricerca</h2>
      </Row>
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="fk_sequ_tag_funzione">
            <Form.Label>TAG funzione</Form.Label>
            <Form.Select
              onChange={onChangeFiltriFunzione}
            >
              <option> </option>
              {tagsFunzione.map((tag) => (
                <option
                  key={tag.sequ_k_tag_funzione}
                  value={tag.sequ_k_tag_funzione}
                >
                  {tag.desc_nome_tag_funzione}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>

        <Button variant="primary" className="float-end" onClick={() => getFunzioni(0, 5, 1)} aria-label="Ricerca le informazioni secondo i filtri inseriti">
          <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} />
          {' '}
          CERCA
        </Button>
      </Form>
    </Row>
  );
}

export default FiltriRicercaFunzioni;
