import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  React, useContext, useEffect, useState,
} from 'react';
import {
  Button, Row,
} from 'react-bootstrap';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import { fields } from '../../../../components/Ruoli/fieldsTableFunzioni';
import FormInformazioni from '../../../../components/Ruoli/FormInformazioni';
import ModaleNuovoTagRuolo from '../../../../components/Ruoli/ModaleNuovoTagRuolo';
import ModaleRicercaFunzioni from '../../../../components/Ruoli/ModaleRicercaFunzioni';
import Table from '../../../../components/Shared/Table';
import funzioniService from '../../../../services/funzioniService';
import ruoliService from '../../../../services/ruoliService';
import tagFunzioniService from '../../../../services/tagFunzioniService';
import tagRuoliService from '../../../../services/tagRuoliService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

function FormNuovoRuolo() {
  const [context, setContext] = useContext(Context);
  const [tagsRuolo, setTagsRuolo] = useState([]);
  const [tagsFunzione, setTagsFunzione] = useState([]);
  const [showModalFunzioni, setShowModalFunzioni] = useState(false);
  const [showModalNewTagRuolo, setshowModalNewTagRuolo] = useState(false);
  const [funzioni, setFunzioni] = useState([]);
  const [filtriFunzione, setFiltriFunzione] = useState({
    flag_admin: false,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  const [nuovoRuolo, setNuovoRuolo] = useState({
    desc_nome_ruolo: '',
    desc_ruolo: '',
    desc_note_ruolo: '',
    flag_default: false,
    fk_sequ_tag_ruolo: 0,
    data_inizio_validita: new Date(),
  });
  const [funzioniScelte, setFunzioniScelte] = useState([]);
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function handleModaleFunzioniStatus() {
    setShowModalFunzioni(!showModalFunzioni);
  }

  function onChangeForm(e) {
    const { id, value } = e.target;
    switch (id) {
      case 'flag_default': {
        setNuovoRuolo((prevRuolo) => ({
          ...prevRuolo,
          [id]: value === 'true',
        }));
        break;
      }
      case 'fk_sequ_tag_ruolo': {
        setNuovoRuolo((prevRuolo) => ({
          ...prevRuolo,
          [id]: parseInt(value, 10),
        }));
        break;
      }
      default: {
        setNuovoRuolo((prevRuolo) => ({
          ...prevRuolo,
          [id]: value,
        }));
        break;
      }
    }
  }

  async function getTagsRuolo() {
    const { data } = await tagRuoliService.getTagRuoli({ flag_protetto: false });
    setTagsRuolo(data?.filter((el) => !el.data_fine_validita));
  }

  async function getFunzioni(offset, limit, page) {
    const response = await funzioniService.getFunzioni(filtriFunzione, offset, limit);
    setFunzioni(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getFunzioniDefault(offset, limit) {
    const response = await funzioniService.getFunzioni(
      { ...filtriFunzione, flag_default: true }, offset, limit,
    );
    setFunzioniScelte(response.data);
  }

  async function getTagFunzione() {
    setTagsFunzione(await tagFunzioniService.getTagFunzioni());
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getFunzioni(currentIndex, sizePerPage, page);
  };

  function verificaObbligatorietaCampi() {
    return nuovoRuolo.desc_nome_ruolo && nuovoRuolo.desc_ruolo
    && typeof nuovoRuolo.flag_default === 'boolean' && funzioniScelte.length && nuovoRuolo.fk_sequ_tag_ruolo;
  }

  async function postRuolo() {
    if (verificaObbligatorietaCampi()) {
      setNuovoRuolo({ ...nuovoRuolo, funzioni: funzioniScelte.map((el) => el.sequ_k_funzione) });
      ruoliService.nuovoRuolo({
        ...nuovoRuolo,
        funzioni: funzioniScelte.map((el) => el.sequ_k_funzione),
      })
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          history.push('/area-riservata/amministrazione/gestione-ruolo');
        })
        .catch((err) => {
          setOpenCloseModaleConferma();
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else {
      setOpenCloseModaleConferma();
      setContext(() => ({
        ...context, open: true, testoErrore: 'Alcuni campi non sono valorizzati', statusCode: 400,
      }));
    }
  }

  function onChangeFiltriFunzione(e) {
    const { id } = e.target;
    let { value } = e.target;
    value = parseInt(value, 10);
    setFiltriFunzione({
      ...filtriFunzione,
      [id]: value,
    });
  }

  function handleModaleNewTagRuolo() {
    setshowModalNewTagRuolo(!showModalNewTagRuolo);
    getTagsRuolo();
  }

  useEffect(() => {
    getTagsRuolo();
    getFunzioni(0, 5, 1);
    getFunzioniDefault(0, 1000);
    getTagFunzione();
  }, []);
  return (
    <>
      <TitlePage
        title="Inserimento nuovo ruolo"
        subtitle="È possibile inserire un nuovo ruolo."
        showIndietro
        functionIndietro={() => {
          history.push('/area-riservata/amministrazione/gestione-ruolo');
        }}
      />
      <FormInformazioni
        nuovoRuolo={nuovoRuolo}
        onChangeForm={onChangeForm}
        tagsRuolo={tagsRuolo}
        handleModaleNewTagRuolo={handleModaleNewTagRuolo}
      />
      <Row className="mb-3 border-top border-dark">
        <Table
          title="Elenco funzioni associate*"
          data={funzioniScelte}
          fields={fields}
          keyField="sequ_k_funzione"
          button={<Button onClick={handleModaleFunzioniStatus}>Modifica</Button>}
        />
      </Row>
      <br />
      <div className="d-flex justify-content-end">
        <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
          <FontAwesomeIcon aria-hidden="true" icon="save" />
          {' '}
          Salva
        </Button>
      </div>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione inserirà un nuovo ruolo'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={postRuolo}
      />
      <ModaleRicercaFunzioni
        show={showModalFunzioni}
        handleClose={handleModaleFunzioniStatus}
        funzioni={funzioni}
        getFunzioni={getFunzioni}
        funzioniScelte={funzioniScelte}
        setFunzioniScelte={setFunzioniScelte}
        onChangeFiltriFunzione={onChangeFiltriFunzione}
        tagsFunzione={tagsFunzione}
        handleTableChange={handleTableChange}
        pagination={pagination}
        setPagination={setPagination}
      />
      <ModaleNuovoTagRuolo
        show={showModalNewTagRuolo}
        handleClose={handleModaleNewTagRuolo}
      />
    </>
  );
}

export default FormNuovoRuolo;
