import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const newsUrl = process.env.REACT_APP_NEWS_FAQ_URL;

async function getListaNews(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${newsUrl}/news?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getNewsById(id) {
  return api.get(`${newsUrl}/news/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createNews(news) {
  const formData = new FormData();
  formData.append('bodyNews', JSON.stringify({
    titolo: news.titolo,
    contenuto: news.contenuto,
    inEvidenza: news.inEvidenza,
  }));
  formData.append('file', news.fileCaricato);
  return api.post(`${newsUrl}/news`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteNews(newsId) {
  return api.delete(`${newsUrl}/news/${newsId}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editNews(id, body) {
  const formData = new FormData();
  formData.append('bodyNews', JSON.stringify({
    titolo: body.titolo,
    contenuto: body.contenuto,
    inEvidenza: body.inEvidenza,
  }));
  formData.append('file', body.fileCaricato);
  return api.patch(`${newsUrl}/news/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const newsService = {
  getListaNews,
  getNewsById,
  createNews,
  deleteNews,
  editNews,
};

export default newsService;
