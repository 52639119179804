import { Editor } from '@tinymce/tinymce-react';
import React, { useContext, useEffect, useState } from 'react';
import {
  Card, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import TitlePage from '../../../../../../components/GestisciUtenti/TitoloPagina';
import FormImmagini from '../../../../../../components/Versioni/FormImmagini';
import FormTitolo from '../../../../../../components/Pagina/FormTitolo';
import ModalePreview from '../../../../../../components/Pagina/ModalePreview';
import TabsBar from '../../../../../../components/Pagina/TabsBar';
import galleriaService from '../../../../../../services/galleriaService';
import versioniService from '../../../../../../services/versioniService';
import { Context } from '../../../../../../utils/Context';
import history from '../../../../../../utils/history';
import { getItem } from '../../../../../../utils/storage';
import tiny from '../../../../../../utils/TinyMCEConfiguration';
import { controlla } from '../../../../../../utils/verificaPermessi';

const ModificaPagina = () => {
  const { idMenu } = useParams();
  const params = new URLSearchParams(window.location.search);
  const nomeMenu = params.get('nomeMenu');
  const { idVersione } = useParams();
  const [modifica, setModifica] = useState(0);
  const [showTextEditor, setShowTextEditor] = useState(true);
  const [context, setContext] = useContext(Context);
  // Pagina Main
  const [pagina, setPagina] = useState({
    desc_nome: '',
    fk_sequ_menu_disponibile: '',
    fk_sequ_versione: '',
    contenuti: {
      IT: {},
    },
  });
  // Lista lingue della versione corrente
  const [listaLingue, setListaLingue] = useState([]);
  const listaNomiLingue = {
    1: 'IT',
    2: 'EN',
    3: 'DE',
    4: 'FR',
    5: 'ES',
  };
  // Lingua corrente
  const [currentLingua, setCurrentLingua] = useState('IT');

  // Nuova Immagine da aggiungere nei contenuti
  const [immagine, setImmagine] = useState({
    nomeImmagine: '',
    fileCaricato: null,
    alt: '',
  });
  const [listaImmagini, setListaImmagini] = useState([]);
  const [openModalePreview, setopenModalePreview] = useState(false);

  function getLingueByStorage() {
    const arrayLingue = [];
    JSON.parse(getItem('lingue')).forEach((element) => {
      arrayLingue.push(listaNomiLingue[element.fk_codi_tipo_lingua || element]);
    });
    setListaLingue(arrayLingue);
  }

  function changeLingua(lingua) {
    if (lingua !== currentLingua) {
      if (!pagina.contenuti[lingua]) {
        setPagina({
          ...pagina,
          contenuti: {
            ...pagina.contenuti,
            [lingua]: {
              titolo: '',
            },
          },
        });
      }
      setShowTextEditor(false);
      setCurrentLingua(lingua);
      setTimeout(() => {
        setShowTextEditor(true);
      }, 150);
    }
  }

  async function getPagina() {
    try {
      const paginaById = await versioniService.getPagina(idVersione, idMenu);
      const imgDisponibili = await galleriaService.getGalleria();
      if (Object.keys(paginaById).length !== 0) {
        setPagina({
          desc_nome: nomeMenu,
          fk_sequ_menu_disponibile: paginaById.documentoMongo.fk_sequ_menu_disponibile,
          fk_sequ_versione: paginaById.documentoMongo.fk_sequ_versione,
          contenuti: paginaById.documentoMongo.contenuti,
        });
        setModifica(paginaById.sequ_k_pagina);
      } else {
        setPagina({
          desc_nome: nomeMenu,
          fk_sequ_menu_disponibile: parseFloat(idMenu),
          fk_sequ_versione: parseFloat(idVersione),
          contenuti: {
            IT: {
              titolo: '',
            },
          },
        });
      }
      setListaImmagini(imgDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  async function salvaPagina() {
    try {
      let response = '';
      if (modifica === 0) {
        response = await versioniService.creaPagina(
          idVersione,
          pagina,
        );
      } else {
        response = await versioniService.modificaPagina(
          idVersione,
          modifica,
          pagina,
        );
      }

      history.goBack();
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function uploadImmagine() {
    try {
      const response = await galleriaService.uploadImmagine(immagine);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      const imgDisponibili = await galleriaService.getGalleria();
      setListaImmagini(imgDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChange(e) {
    const { value } = e.target;
    setPagina({
      ...pagina,
      contenuti: {
        ...pagina.contenuti,
        [currentLingua]: {
          ...pagina.contenuti[currentLingua],
          titolo: value,
        },
      },
    });
  }

  function onChangeImmagine(immagineScelta) {
    setPagina({
      ...pagina,
      contenuti: {
        ...pagina.contenuti,
        [currentLingua]: {
          ...pagina.contenuti[currentLingua],
          fk_logo_mongo_db: immagineScelta._id,
          desc_filename_logo: immagineScelta.nomeFile,
          alt: immagineScelta.alt,
        },
      },
    });
  }

  const onUpload = (e) => {
    const { id, value, files } = e.target;
    setImmagine({
      ...immagine,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  const onChangeAltImmagine = (e) => {
    const { id, value } = e.target;
    setImmagine({
      ...immagine,
      [id]: value,
    });
  };

  function showPreview() {
    setopenModalePreview(!openModalePreview);
  }

  function onEditorChange(content) {
    setPagina({
      ...pagina,
      contenuti: {
        ...pagina.contenuti,
        [currentLingua]: {
          ...pagina.contenuti[currentLingua],
          paragrafo: content,
        },
      },
    });
  }

  useEffect(() => {
    getLingueByStorage();
    getPagina();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione pagina sito web"
        subtitle="Questo tipo di pagina permette di inserire un titolo, un testo ed un'immagine"
        showIndietro
        functionIndietro={() => history.goBack()}
        showSave={controlla('X99') === true}
        functionSave={salvaPagina}
        showPreview
        functionPreview={showPreview}
      />
      <p>
        Nome sezione:
        {' '}
        <strong>{pagina.desc_nome}</strong>
      </p>

      <TabsBar
        lingue={listaLingue}
        changeLingua={changeLingua}
        currentLingua={currentLingua}
      />

      <FormTitolo
        titolo={pagina.contenuti[currentLingua] ? pagina.contenuti[currentLingua].titolo : ''}
        onChange={onChange}
      />

      {showTextEditor ? (
        <Card className="mb-3">
          <Card.Body>
            <Form.Label htmlFor="content-html">Inserisci qui un paragrafo:</Form.Label>
            <Form>
              <Row className="mb-3">
                <Editor
                  id="content-html"
                  init={{
                    plugins: tiny.plugins,
                    toolbar: tiny.toolbar,
                  }}
                  apiKey={tiny.apiKey}
                  value={pagina.contenuti[currentLingua] ? pagina.contenuti[currentLingua].paragrafo : ''}
                  onEditorChange={onEditorChange}
                />
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <FormImmagini
        onChange={onChangeImmagine}
        listaImmagini={listaImmagini}
        versione={pagina.contenuti[currentLingua]}
        immagine={immagine}
        onUpload={onUpload}
        onChangeAltImmagine={onChangeAltImmagine}
        uploadImmagine={uploadImmagine}
      />

      <ModalePreview
        page={pagina.contenuti[currentLingua]
          ? pagina.contenuti[currentLingua] : []}
        contenuto={pagina.contenuti[currentLingua]
          ? pagina.contenuti[currentLingua].arrayContenuti : []}
        show={openModalePreview}
        handleClose={showPreview}
      />
    </>
  );
};

export default ModificaPagina;
