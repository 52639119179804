export const fieldsUtenti = [
  {
    dataField: 'codi_id_fiscale',
    text: 'Codice fiscale',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'desc_nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'desc_cognome',
    text: 'Cognome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '35%' }),
  },
];

export const selectRows = (onChangeAutorizzazioni, indexAutorizzazioneScelta) => {
  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    // selected: [...utentiScelti].map((el) => el.codi_matrix_user_id),
    // nonSelectable: [...utentiSceltiCopia].map((el) => el.codi_matrix_user_id),
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row) => {
      // eslint-disable-next-line no-unused-expressions
      const event = {
        target: {
          value: row.codi_id_fiscale,
          codi_listmonk_user_id: row.codi_listmonk_user_id,
        },
      };
      onChangeAutorizzazioni('codiceFiscale', event, indexAutorizzazioneScelta);
    },

  };
  return selectRow;
};
