import React from 'react';
import { Modal, Button } from 'react-bootstrap';

// labelBottone: scritta pulsante di destra ad esempio "salva", "elimina"
// coloreAzione: colore del pulsante ad esempio "primary", "danger", ecc.
const ModaleConferma = ({
  title, body, labelBottone, coloreAzione, show, handleClose, handleOk,
}) => (
  <>
    <Modal show={show} onHide={handleClose} centered role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
        <Button variant={coloreAzione} onClick={handleOk}>
          {labelBottone}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

export default ModaleConferma;
