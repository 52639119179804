import api from '../utils/api';

const authProviderUrl = process.env.REACT_APP_AUTH_PROVIDER_URL;
const spidUrl = process.env.REACT_APP_SPID_URL;

async function login(codiceFiscale) {
  return api.get(`${authProviderUrl}/hidden/auth-provider/login-sviluppo/${codiceFiscale}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoRefreshToken() {
  return api.get(`${authProviderUrl}/auth-provider/nuovo-refresh-token`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoCsrfToken() {
  return api.get(`${authProviderUrl}/auth-provider/nuovo-csrf-token`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response));
}

async function agidLogin(authorizationCode) {
  return api.get(`${authProviderUrl}/auth-provider/agid-login?authorization_code=${authorizationCode}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response));
}

async function spidLogin() {
  return api.get(`${spidUrl}/login`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response));
}

async function agidLoginOspite(authorizationCode) {
  return api.get(`${authProviderUrl}/auth-provider/agid-login/ospite?authorization_code=${authorizationCode}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response));
}

const authProviderService = {
  login,
  nuovoRefreshToken,
  nuovoCsrfToken,
  agidLogin,
  spidLogin,
  agidLoginOspite,
};

export default authProviderService;
