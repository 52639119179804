import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';

function TabsBar({
  lingue, changeLingua, currentLingua,
}) {
  const btnColor1 = 'link';
  const btnColor2 = 'white';

  return (
    <div>
      <Row className="justify-content-center">
        {lingue.map((el) => (
          <Col className="col-auto">
            <Button
              variant={currentLingua === el ? btnColor1 : btnColor2}
              onClick={() => {
                changeLingua(el);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon="list-alt" />
              {' '}
              {el}
            </Button>
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center">
        {lingue.forEach((el) => (
          <Col className="col-auto">
            <Button
              variant={currentLingua === el ? btnColor1 : btnColor2}
              onClick={() => {
                changeLingua(el);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon="list-alt" />
              {' '}
              {el}
            </Button>
          </Col>
        ))}
      </Row>

      <div className="separator border-1 border-bottom" />
    </div>
  );
}

export default TabsBar;
