/* eslint-disable no-underscore-dangle */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import repositoryService from '../../../../../services/repositoryService';
import { Context } from '../../../../../utils/Context';
import formatBytes from '../../../../../utils/formatBytes';
import { formatoDataEOra } from '../../../../../utils/formatData';
import history from '../../../../../utils/history';
import { controlla } from '../../../../../utils/verificaPermessi';

const repositoryUrl = process.env.REACT_APP_REPOSITORY_URL;

const VersioniFile = () => {
  const { idFile } = useParams();
  const [context, setContext] = useContext(Context);
  const [versioni, setVersioni] = useState([]);
  const [versione, setVersione] = useState({});
  const [showModaleConferma, setShowModaleConferma] = useState(false);

  const handleCloseModaleConferma = () => setShowModaleConferma(!showModaleConferma);

  async function getVersioni() {
    try {
      const listaVersioni = await repositoryService.getListaVersioni(idFile);
      setVersioni(listaVersioni);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  async function eliminaVersione() {
    await repositoryService.eliminaVersione(versione._id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleCloseModaleConferma();
      });
  }

  async function downloadVersione() {
    const a = document.createElement('A');
    a.href = `${repositoryUrl}/file/version/${versione._id}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => {
    getVersioni();
  }, []);
  return (
    <>
      <TitlePage
        title="Repository - lista versioni"
        subtitle="È possibile visualizzare, scaricare ed eliminare le versioni del file."
        showIndietro
        functionIndietro={() => {
          history.goBack();
        }}
      />
      {versioni.map((el, index) => (
        <Card className="shadow p-3 mb-5 bg-body rounded" key={el._id}>
          <Row>
            <Col>
              <strong>Data caricamento:</strong>
              {' '}
              {formatoDataEOra(el.dataCaricamento)}
              <br />
              <strong>Pubblicato da:</strong>
              {' '}
              {el.creatore.identificativo}
              <br />
              <strong>Nome:</strong>
              {' '}
              {el.nome}
              <br />
              <strong>Nome file:</strong>
              {' '}
              {el.nomeFile}
              <br />
              <strong>Dimensioni file:</strong>
              {' '}
              {formatBytes(el.dimensioni)}
            </Col>
            <Col>
              {controlla('X44') && (
              <Button variant="primary" className="m-3 float-end" onClick={() => { setVersione(el); downloadVersione(); }}>
                <FontAwesomeIcon aria-hidden="true" icon="download" />
                {' '}
                Scarica
              </Button>
              )}
              {controlla('X45') && index ? (
                <Button variant="danger" className="m-3 float-end" onClick={() => { setVersione(el); handleCloseModaleConferma(); }}>
                  <FontAwesomeIcon aria-hidden="true" icon="trash" />
                  {' '}
                  Elimina
                </Button>
              ) : null}
            </Col>
          </Row>
        </Card>
      ))}
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione cancellerà la versione."
        show={showModaleConferma}
        handleClose={handleCloseModaleConferma}
        handleOk={eliminaVersione}
      />
    </>
  );
};

export default VersioniFile;
