import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import Header from '../../../components/SceltaIdentita/Header';
import IdentitaCard from '../../../components/SceltaIdentita/IdentitaCard';
import identitaService from '../../../services/identitaService';
import permessoService from '../../../services/permessoService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import { getItem, setItem } from '../../../utils/storage';

function SceltaIdentita() {
  const [context, setContext] = useContext(Context);
  const [identita, setIdentita] = useState([]);

  async function getPermessiByIdentita(idIdentita, nomeIdentita) {
    const response = await permessoService.getPermessiByIdentita(idIdentita);
    setItem('permessi', JSON.stringify(response));
    setItem('identita', JSON.stringify({ idIdentita, nomeIdentita }));
    history.push('/area-riservata');
  }
  async function getListaIdentita() {
    const { data } = await identitaService.getListaIdentitaByUtente((JSON.parse(getItem('user'))).trusted);
    setIdentita(data);
    if (data.filter((el) => el.flag_default).length && !window.location.href.includes('from=area-riservata')) {
      const identitaDefault = data.filter((el) => el.flag_default)[0];
      getPermessiByIdentita(identitaDefault.sequ_k_identita, identitaDefault.desc_nome_identita);
    }
  }
  /** Gestisce la variazione dello stato */
  async function handleChange(e) {
    const { id, checked } = e.target;
    await identitaService.setIdentitaDefault(id, (JSON.parse(getItem('user'))).id, checked)
      .then(() => {
        setContext(() => ({
          ...context,
          openConferma: true,
          testo:
  <>
    <h5>{checked ? 'Identità di default selezionata correttamente.' : 'Identità di default deselezionata correttamente.'}</h5>
    <p>
      Al prossimo accesso la piattaforma
      {checked && 'non'}
      {' '}
      richiederà la selezione dell&apos;identità.
      {checked && 'È sempre possibile cambiarla attraverso la funzione Scegli identità'}
    </p>
  </>,
        }));
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
    await getListaIdentita();
  }

  useEffect(() => {
    getListaIdentita();
  }, []);
  return (
    <div>
      <Header />
      <Row xs={1} md={2} className="justify-content-center">
        {identita?.map((el) => (
          <IdentitaCard
            key={el.sequ_k_identita}
            id={el.sequ_k_identita}
            identita={el.desc_nome_identita}
            isDefault={el.flag_default}
            handleOnChange={handleChange}
            getPermessiByIdentita={getPermessiByIdentita}
          />
        ))}
      </Row>
    </div>
  );
}

export default SceltaIdentita;
