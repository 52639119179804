import React, { useContext, useState } from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import newsletterService from '../../../../services/newsletterService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

function HandleLista() {
  const [context, setContext] = useContext(Context);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [listInfo, setListInfo] = useState({
    name: '',
  });
  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }
  function handleChange(e) {
    const { value } = e.target;
    setListInfo({
      name: value,
    });
  }

  async function createNewListaDistribuzione() {
    await newsletterService.createNewListaDistribuzione(listInfo)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  return (
    <>
      <TitlePage
        title="Creazione nuova lista di distribuzione"
        subtitle="Sottotitolo nuova lista distribuzione"
        showIndietro
        showSave
        functionSave={() => { handleStateModaleConferma(); }}
        functionIndietro={() => history.push('/area-riservata/newsletter')}
      />
      <Row>
        <Form.Group as={Col} controlId="nome">
          <Form.Label>Nome</Form.Label>
          <Form.Control placeholder="Inserisci il nome della lista" value={listInfo.name} onChange={handleChange} />
        </Form.Group>
      </Row>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione aggiungerà la lista: ${listInfo.name}`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={createNewListaDistribuzione}
      />
    </>
  );
}

export default HandleLista;
