import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';
import { formatoData } from '../../../../utils/formatData';
import history from '../../../../utils/history';

const fields = (handleStateModaleConferma, setContenutoScelto) => [
  {
    dataField: 'contenuti.IT.titolo',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '60%' }),
  },
  {
    dataField: 'dataCreazione',
    text: 'Data',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => (formatoData(e)),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '30%' }),
    formatter: (e, row) => (
      <div>
        <FontAwesomeIcon
          icon={['fas', 'edit']}
          size="lg"
          title={`Modifica il contenuto ${row.contenuti.IT.titolo}`}
          id={`modifica-${row._id}`}
          style={{ color: themeColors.primary }}
          onClick={() => history.push(`/area-riservata/siti-web/contenuti-dinamici/by-id/${row._id}/modifica`)}
        />

        <FontAwesomeIcon
          icon={['fas', 'trash']}
          size="lg"
          title={`Elimina il contenuto ${row.contenuti.IT.titolo}`}
          id={`contenuti-dinamici-${row._id}`}
          style={{ color: themeColors.danger }}
          onClick={() => { setContenutoScelto(row); handleStateModaleConferma(); }}
        />
      </div>

    ),
  },
];

export default fields;
