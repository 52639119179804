import React, { useEffect } from 'react';
import {
  Card, Button, Row, Col,
} from 'react-bootstrap';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import history from '../../../utils/history';
import { getItem, removeItem } from '../../../utils/storage';

function Amministrazione() {
  const clearSession = () => {
    const filter = getItem('filtri');
    const page = getItem('page');
    if (filter) {
      removeItem('filtri');
    }
    if (page) {
      removeItem('page');
    }
  };
  useEffect(() => {
    clearSession();
  }, []);
  return (
    <div>
      <Title title="Amministrazione" subtitle="Scegli la voce da gestire" />
      <Row xs={1} md={2} lg={2} className="py-2">
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Title className="text-center m-3">
              <h3>Richiesta Associazione Utente-Identità</h3>
            </Card.Title>
            <Card.Body>
              In questa sezione è possibile gestire le richieste formulate dagli utenti,
              di poter operare sulla Piattaforma con una diversa Identità per
              lo svolgimento di attività specifiche
            </Card.Body>
            <Card.Footer className="text-center">
              <Button onClick={() => history.push('/area-riservata/amministrazione/gestione-richieste-identita')} aria-label="Vai alla gestione Richiesta Associazione Utente-Identità"> Vai alla gestione</Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Title className="text-center m-3">
              <h3>Utenti</h3>
            </Card.Title>
            <Card.Body>
              In questa sezione è possibile ricercare gli utenti registrati
              sulla Piattaforma per visualizzarne i dettagli e gestire le
              loro associazioni con le Identità
            </Card.Body>
            <Card.Footer className="text-center">
              <Button onClick={() => history.push('/area-riservata/amministrazione/gestione-utenti')} aria-label="Vai alla gestione utenti"> Vai alla gestione</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={2} className="pb-4">
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Title className="text-center m-3">
              <h3>Identità</h3>
            </Card.Title>
            <Card.Body>
              In questa sezione è possibile creare nuove Identità o
              ricercare quelle presenti nella Piattaforma per
              visualizzarne e/o modificarne i dettagli
              e gestire le loro associazioni con i
              Ruoli e con le Identità referenziate
            </Card.Body>
            <Card.Footer className="text-center">
              <Button onClick={() => history.push('/area-riservata/amministrazione/gestione-identita')} aria-label="Vai alla gestione identità"> Vai alla gestione</Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Title className="text-center m-3">
              <h3>Ruoli</h3>
            </Card.Title>
            <Card.Body>
              In questa sezione è possibile creare nuovi Ruoli
              o ricercare quelli presenti nella Piattaforma
              per visualizzarne e/o modificarne i dettagli
              e gestire le loro associazioni con le Funzioni
            </Card.Body>
            <Card.Footer className="text-center">
              <Button onClick={() => history.push('/area-riservata/amministrazione/gestione-ruolo')} aria-label="Vai alla gestione ruoli"> Vai alla gestione</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={2} className="py-4 border-top border-3 border-dark">
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Title className="text-center m-3">
              <h3>Tag ruolo</h3>
            </Card.Title>
            <Card.Body>
              In questa sezione è possibile creare nuovi
              Tag Ruolo o ricercare quelli presenti nella
              Piattaforma per visualizzarne i dettagli
            </Card.Body>
            <Card.Footer className="text-center">
              <Button onClick={() => history.push('/area-riservata/amministrazione/gestione-tag-ruolo')} aria-label="Vai alla gestione TAG ruolo"> Vai alla gestione</Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Title className="text-center m-3">
              <h3>Tag identità</h3>
            </Card.Title>
            <Card.Body>
              In questa sezione è possibile creare nuovi
              Tag Identità o ricercare quelli presenti nella Piattaforma
              per visualizzarne i dettagli
            </Card.Body>
            <Card.Footer className="text-center">
              <Button onClick={() => history.push('/area-riservata/amministrazione/gestione-tag-identita')} aria-label="Vai alla gestione TAG identita"> Vai alla gestione</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={2} className="py-4 border-top border-3 border-dark">
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Title className="text-center m-3">
              <h3>Gestione piattaforma</h3>
            </Card.Title>
            <Card.Body>
              In questa sezione è possibile modificare lo stato attuale della Piattaforma per poter
              gestire situazioni di fermo temporaneo e comunicare
              agli utenti l&apos;indisponibilità del sistema
            </Card.Body>
            <Card.Footer className="text-center">
              <Button onClick={() => history.push('/area-riservata/amministrazione/gestione-piattaforma')} aria-label="Vai alla gestione dello stato della piattaforma"> Vai alla gestione</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Amministrazione;
