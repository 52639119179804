import api from '../utils/api';

const sitiWebUrl = process.env.REACT_APP_SITI_URL;

async function getListaVersioni(flag) {
  return api.get(`${sitiWebUrl}/versione?trasparenza=${flag}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function creaVersione(versione) {
  const body = {
    ...versione,
    recapiti: versione.recapiti,
    lingue: versione.lingue,
  };
  return api.post(`${sitiWebUrl}/versione`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getVersione(id) {
  return api.get(`${sitiWebUrl}/versione/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getColoriDisponibili() {
  return api.get(`${sitiWebUrl}/colori-disponibili`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getLingueDisponibili() {
  return api.get(`${sitiWebUrl}/lingue-disponibili`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaVersione(id, versione, listaLingueRimosse) {
  const body = {
    ...versione,
    recapiti: versione.recapiti,
    lingue: versione.lingue,
    listaLingueRimosse,
  };
  return api.put(`${sitiWebUrl}/versione/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaVersione(id) {
  return api.delete(`${sitiWebUrl}/versione/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function clonaVersione(id) {
  return api.put(`${sitiWebUrl}/versione/${id}/clona-sito`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function richiestaPreview(id) {
  return api.put(`${sitiWebUrl}/versione/${id}/richiesta-preview`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function richiestaApprovazione(id, richiesta) {
  const body = {
    note: richiesta,
  };
  return api.patch(`${sitiWebUrl}/versione/${id}/richiesta-approvazione`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function pubblicaVersione(id) {
  return api.put(`${sitiWebUrl}/versione/${id}/pubblicazione`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}
async function approvazioneVersione(id) {
  return api.put(`${sitiWebUrl}/versione/${id}/approvazione`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function rifiutoApprovazione(id, note) {
  const body = {
    note,
  };
  return api.patch(`${sitiWebUrl}/versione/${id}/rifiuto-approvazione`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function revocaVersione(id) {
  return api.put(`${sitiWebUrl}/versione/${id}/revoca`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function creaPagina(idVersione, pagina) {
  const body = {
    ...pagina,
  };
  return api.post(`${sitiWebUrl}/versione/${idVersione}/pagina`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getPagina(idVersione, idMenu) {
  return api.get(`${sitiWebUrl}/versione/${idVersione}/pagina/get/${idMenu}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaPagina(idVersione, idPagina, pagina) {
  const body = {
    ...pagina,
  };
  return api.put(`${sitiWebUrl}/versione/${idVersione}/pagina/${idPagina}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaPagina(idVersione, idPagina) {
  return api.delete(`${sitiWebUrl}/versione/${idVersione}/pagina/${idPagina}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const versioniService = {
  getListaVersioni,
  creaVersione,
  getVersione,
  getColoriDisponibili,
  getLingueDisponibili,
  modificaVersione,
  eliminaVersione,
  clonaVersione,
  richiestaPreview,
  pubblicaVersione,
  richiestaApprovazione,
  approvazioneVersione,
  rifiutoApprovazione,
  revocaVersione,
  creaPagina,
  getPagina,
  modificaPagina,
  eliminaPagina,
};

export default versioniService;
