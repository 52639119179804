import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import repositoryService from '../../../../services/repositoryService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

const Upload = () => {
  const { path } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    nome: '',
    descrizione: '',
    file: '',
    path: '',
    metadati: [],
  });
  const [configurazione, setConfigurazione] = useState({});
  const [showModaleConferma, setShowModaleConferma] = useState(false);
  const [context, setContext] = useContext(Context);

  const handleCloseModaleConferma = () => setShowModaleConferma(!showModaleConferma);

  async function handleOkModaleConferma() {
    if (form.fileCaricato?.size > configurazione.dimensioneMassima * 1000000) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'File troppo grande', statusCode: 400,
      }));
    } else {
      setIsLoading(true);
      const func = history.location.search.includes('?id=')
        ? repositoryService.uploadVersione(history.location.search.split('?id=')[1], form)
        : repositoryService.uploadFile(form);
      setShowModaleConferma();
      func
        .then((res) => {
          if (res.status !== 'error') {
            setContext(() => ({
              ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
            }));
            history.goBack();
          } else {
            setContext(() => ({
              ...context, open: true, testoErrore: res.message, statusCode: 400,
            }));
          }
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const onChange = (e) => {
    const { value, id } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
  };

  const onChangeMetadata = (e) => {
    const { value, id } = e.target;
    setForm({
      ...form,
      metadati: { ...form.metadati, [id]: value },
    });
  };

  const onUpload = (e) => {
    const { id, value, files } = e.target;
    setForm({
      ...form,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  async function getConfigurazione() {
    await repositoryService.getConfigurazione()
      .then((res) => {
        setConfigurazione(res);
        const tokens = decodeURIComponent(path);
        setForm({
          ...form,
          path: tokens,
          nome: history.location.search.includes('?id=') ? tokens.split('/').at(-1) : '',
          metadati: res.metadati.reduce((o, m) => {
            // eslint-disable-next-line no-param-reassign
            o[m.chiave] = form.metadati[m.chiave] || '';
            return o;
          }, {}),
        });
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  useEffect(() => {
    getConfigurazione();
  }, []);

  return (
    <>
      <TitlePage
        title="Repository - carica documento"
        subtitle="Attraverso il form seguente puoi fare l'upload di un documento."
        showIndietro={!isLoading}
        functionIndietro={() => {
          history.goBack();
        }}
      />
      <Form>
        <Form.Group className="mb-3" controlId="path">
          <Form.Label>Percorso cartella</Form.Label>
          <Form.Control value={form.path} disabled />
        </Form.Group>
        <Form.Group className="mb-3" controlId="nome">
          <Form.Label>Nome documento</Form.Label>
          <Form.Control disabled={history.location.search.includes('?id=')} value={form.nome} placeholder="Inserisci il nome del documento" onChange={onChange} />
        </Form.Group>
        {configurazione?.metadati?.map((el) => (
          <Form.Group className="mb-3" controlId={el.chiave} key={el.chiave}>
            <Form.Label>{el.etichetta}</Form.Label>
            <Form.Control value={form.metadati[el.chiave]} placeholder="Inserisci il TAG" onChange={onChangeMetadata} />
          </Form.Group>
        ))}
        <Form.Group className="mb-3" controlId="file">
          <Form.Label>File</Form.Label>
          <Form.Control value={form.file} type="file" size="sm" onChange={onUpload} />
        </Form.Group>
      </Form>

      <Button
        variant="primary"
        className="mt-3 float-end"
        onClick={handleCloseModaleConferma}
        disabled={isLoading}
      >
        {isLoading
          ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : <FontAwesomeIcon aria-hidden="true" icon="save" />}
        {' '}
        SALVA
      </Button>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione aggiungerà un documento."
        show={showModaleConferma}
        handleClose={handleCloseModaleConferma}
        handleOk={handleOkModaleConferma}
      />
    </>
  );
};

export default Upload;
