/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row, Button, InputGroup,
} from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import appService from '../../../services/appService';
import ModaleConferma from '../../../components/modali/ModaleConferma/index.js';
import { Context } from '../../../utils/Context';
import ModaleRicercaUtenti from '../../../components/Repository/ModaleRicercaUtenti';
import canaliService from '../../../services/canaliService';
import AutorizzazioniForum from '../../../components/Forum/AutorizzazioniForum';

const urlIconaPrefix = process.env.REACT_APP_ESTERNE_URL_ICONA;

function ForumSezione() {
  const [context, setContext] = useContext(Context);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [newApp, setnewApp] = useState({
    visibilita: [],
    descrizione: '',
    nome: '',
    note: '',
    link: '',
    urlIcona: '',
    urlIconaSuffix: '',
  });
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [identitaScelta, setIdentitaScelta] = useState();
  const [listaIdentita, setListaIdentita] = useState([]);
  const [indexAutorizzazioneScelta, setIndexAutorizzazioneScelta] = useState();

  const onChangeAutorizzazioni = (prop, event, index, type) => {
    const old = newApp.visibilita[index];
    const updated = { ...old, [prop]: type === 'int' ? parseInt(event.target.value, 10) : event.target.value };
    const clone = [...newApp.visibilita];
    clone[index] = updated;
    setnewApp({
      ...newApp,
      visibilita: clone,
    });
  };

  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response);
  }

  const handleCloseModaleUtenti = () => setShowModaleUtenti(!showModaleUtenti);

  async function onChange(e, type) {
    const { id, value, checked } = e.target;
    setnewApp({
      ...newApp,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
    setnewApp({
      ...newApp,
      urlIcona: urlIconaPrefix + newApp.urlIconaSuffix,
    });
  }

  async function createNewApp() {
    await appService.createApp(newApp)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
        history.goBack();
      });
  }

  function aggiungiRow() {
    setnewApp({
      ...newApp,
      visibilita: [
        ...newApp.visibilita,
        {
          idIdentita: 0,
          codiceFiscale: null,
        },
      ],
    });
  }

  function eliminaRow(index) {
    setnewApp({
      ...newApp,
      visibilita: [
        ...newApp.visibilita.filter((el, indexEl) => indexEl !== index)],
    });
  }

  useEffect(() => {
    getListaIdentita();
  }, []);

  return (
    <div>
      <TitlePage
        title="Applicazioni esterne"
        subtitle="Creazione nuova applicazione esterna"
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <Form className="mt-3">

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="nome">
            <Form.Label>Nome *</Form.Label>
            <Form.Control placeholder="Inserire il nome per identificare l'Applicazione esterna" onChange={onChange} required value={newApp.nome} />
          </Form.Group>
          <Form.Group as={Col} lg controlId="descrizione">
            <Form.Label>Descrizione *</Form.Label>
            <Form.Control placeholder="Descrivere l'Applicazione esterna" onChange={onChange} required value={newApp.descrizione} />
          </Form.Group>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="link">
            <Form.Label>Link *</Form.Label>
            <Form.Control placeholder="Specificare l'indirizzo tramite cui è possibile raggiungere l'Applicazione esterna" onChange={onChange} required value={newApp.link} />
          </Form.Group>
        </Row>

        <Row className="my-3">
          <Form.Group as={Col} lg controlId="note">
            <Form.Label>Note</Form.Label>
            <Form.Control placeholder="Inserire delle note relative all'Applicazione esterna" as="textarea" onChange={onChange} value={newApp.note} />
          </Form.Group>
        </Row>

        <Form.Group as={Col} controlId="urlIconaSuffix">
          <Form.Label>url Icona *</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">{urlIconaPrefix}</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Specificare il nome del file icona"
              aria-describedby="inputGroupPrepend"
              onChange={onChange}
              required
              value={newApp.urlIconaSuffix}
            />
          </InputGroup>
        </Form.Group>
      </Form>
      <br />
      <div className="separator border-1 border-bottom" />
      <Row>
        <Col>
          <p className="h4" style={{ lineHeight: '1.6' }}>
            Regole di visibilità
          </p>
        </Col>
      </Row>
      <AutorizzazioniForum
        autorizzazioni={newApp.visibilita}
        listaIdentita={listaIdentita}
        aggiungiRow={aggiungiRow}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        handleCloseModaleUtenti={handleCloseModaleUtenti}
        setIndexAutorizzazioneScelta={setIndexAutorizzazioneScelta}
        eliminaRow={eliminaRow}
        setIdentitaScelta={setIdentitaScelta}
      />
      <Button
        variant="primary"
        className="text-uppercase float-end"
        onClick={handleStateModaleConferma}
        disabled={!(newApp.nome && newApp.descrizione && newApp.link && newApp.urlIconaSuffix)}
      >
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        {' '}
        Salva
      </Button>
      <br />

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione aggiungerà una nuova applicazione esterna: ${newApp.nome}`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={createNewApp}
      />
      {showModaleUtenti && (
      <ModaleRicercaUtenti
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        idIdentita={identitaScelta}
        listaIdentita={listaIdentita}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        indexAutorizzazioneScelta={indexAutorizzazioneScelta}
      />
      )}
    </div>
  );
}

export default ForumSezione;
