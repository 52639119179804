/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Card, Col, Row, Button,
} from 'react-bootstrap';
import { formatoDataEOra } from '../../utils/formatData';
import { controlla } from '../../utils/verificaPermessi';
import RenderHTML from '../Shared/RenderHTML';
import { getItem } from '../../utils/storage';

function CardGroupMessaggi({
  messaggi, title, functionDelete, sezione, button, boxNuovoMessaggio,
}) {
  return (

    <div className="mt-3">
      <Row>
        <Col>
          <h2>{title}</h2>
        </Col>
        <Col>
          {sezione.stato === 'aperta' && !sezione.dataFineValidita && controlla('X50') === true
            ? button
            : null }
        </Col>
      </Row>
      {boxNuovoMessaggio}
      <Row lg={1} xs={1} className="g-4 mb-2">
        {messaggi.map((el) => (
          <Col key={el._id}>
            <Card>
              <Card.Header>
                <Row>
                  <Col lg={11}>
                    <p className="h5" style={{ lineHeight: 1.6 }}>
                      {' '}
                      {el?.creatore?.nome}
                      {' '}
                      {el?.creatore?.cognome}
                    </p>
                    il
                    {' '}
                    <strong>{formatoDataEOra(el.dataCreazione)}</strong>
                  </Col>
                  {sezione.stato === 'aperta' && !sezione.dataFineValidita && !el.dataFineValidita
                    ? (
                      <Col lg={1}>
                        {(controlla('X51') && el?.creatore?.identificativo === (JSON.parse(getItem('user'))).identificativo)
                    || controlla('W2')
                          ? (
                            <Button
                              variant="danger"
                              className="float-end"
                              onClick={() => functionDelete(el._id)}
                              title={`Elimina il messaggio scritto da ${el?.creatore?.nome} ${el?.creatore?.cognome} il ${formatoDataEOra(el.dataCreazione)}`}
                            >
                              <FontAwesomeIcon aria-hidden="true" icon="trash" />
                            </Button>
                          ) : null}
                      </Col>
                    ) : null}
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <RenderHTML html={el.messaggio} />
                  <br />
                  <strong>Link: </strong>
                  <a
                    href={el.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {el.link}
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

  );
}

export default CardGroupMessaggi;
