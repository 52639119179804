/* eslint-disable jsx-a11y/label-has-associated-control */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import themeColors from '../../../utils/contants/theme-colors';
import styles from './index.module.scss';

const TextBox = ({ sendTextMessage, sendFile }) => {
  const [textValue, setTextValue] = useState();
  const [textBox, setTextBox] = useState(true);

  useEffect(() => setTextValue(''), []);

  function onChange(event) {
    setTextValue(event.target.value);
  }

  function onClick() {
    sendTextMessage(textValue);
    setTextValue('');
  }

  function onEnterPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onClick();
    }
  }

  function onChangeFile(e) {
    sendFile(e.target.files[0]);
    setTextBox(true);
  }

  return (
    <Card className={`d-flex flex-row p-3 mt-3 ${styles.textBoxCard}`}>
      {textBox
        ? (
          <>
            <label htmlFor="messaggio" style={{ display: 'none' }}>
              Inserisci il messaggio
            </label>
            <textarea onKeyDown={onEnterPress} id="messaggio" className={`w-100 border-0 ${styles.textarea}`} value={textValue} onChange={onChange} placeholder="Scrivi qualcosa..." />
            <button type="button" className={`border-0 ${styles.button}`} onClick={onClick} title="Invia messaggio" aria-label="Invia messaggio">
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'paper-plane']}
                size="sm"
                id="invia-messaggio-icon"
                style={{ color: themeColors.primary }}
              />
            </button>
            <button type="button" className={`border-0 ${styles.button}`} onClick={() => setTextBox(false)} title="Allega file" aria-label="Allega file">
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'paperclip']}
                size="sm"
                id="upload-file-icon"
                style={{ color: themeColors.primary }}
              />
            </button>
          </>
        )
        : (
          <>
            <Form.Group controlId="file" className="mb-3">
              <Form.Label>Allega file</Form.Label>
              <Form.Control type="file" size="sm" onChange={onChangeFile} />
            </Form.Group>
            <button type="button" className={`border-0 ${styles.button}`} onClick={() => setTextBox(true)} title="Scrivi messaggio" aria-label="Torna alla scrittura del messaggio">
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'times-circle']}
                size="sm"
                id="torna-messaggio-icon"
                className="float-right"
                style={{ color: themeColors.primary }}
              />
            </button>
          </>
        )}
    </Card>
  );
};

export default TextBox;
