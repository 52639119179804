/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';
import tiny from '../../utils/TinyMCEConfiguration';

export default function CardSezione({
  sezione, contenuto, currentLingua, arrayMove, onChangeSezione, addRigaSezione, onChangeRichText,
  onChangeProprieta, eliminaSezione, eliminaRiga,
}) {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <Card.Title>{sezione.titolo}</Card.Title>
          </Col>
          <Col md={{ span: 4, offset: 4 }}>
            {sezione.index > 0
              ? (
                <Button
                  aria-label="Sposta su"
                  className="float-end mx-1"
                  onClick={() => arrayMove(sezione.index, sezione.index - 1)}
                >
                  <FontAwesomeIcon icon="arrow-up" />
                </Button>
              )
              : null }
            {sezione.index < contenuto.contenuti[currentLingua].sezioni.length - 1
              ? (
                <Button
                  aria-label="Sposta giù"
                  className="float-end mx-1"
                  onClick={() => arrayMove(sezione.index, sezione.index + 1)}
                >
                  <FontAwesomeIcon icon="arrow-down" />
                </Button>
              )
              : null}
            <Button
              aria-label="Elimina sezione"
              className="float-end mx-1"
              variant="danger"
              onClick={() => eliminaSezione(sezione.index)}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          </Col>
        </Row>
        <Form>
          <Row>
            <Form.Group as={Col} lg controlId="titolo">
              <Form.Label>Titolo sezione</Form.Label>
              <Form.Control
                placeholder="Inserisci il titolo della sezione"
                onChange={(e) => onChangeSezione(sezione.index, e)}
                value={sezione.titolo}
              />
            </Form.Group>
          </Row>
        </Form>
        <Button className="my-3" onClick={() => addRigaSezione(sezione.index)}>
          <FontAwesomeIcon icon="plus" />
          Aggiungi riga
        </Button>
        {sezione.tabella?.map((el) => (
          <div key={'tabella-' + el.index} className="my-1">
            <Row>
              <Col md={3}>
                <Form.Group as={Col} lg controlId="proprieta">
                  <Form.Label>Proprietà*</Form.Label>
                  <Form.Control
                    placeholder="Inserisci il titolo della sezione"
                    onChange={(e) => onChangeProprieta(sezione.index, el.index, e)}
                    value={el.proprieta}
                  />
                </Form.Group>
              </Col>
              <Col md={8}>
                <label htmlFor={'content-html-' + sezione.index + '-' + el.index}>Inserisci il messaggio*</label>
                <Editor
                  id={'content-html-' + sezione.index + '-' + el.index}
                  init={{
                    height: 200,
                    plugins: tiny.plugins,
                    toolbar: tiny.toolbar,
                  }}
                  apiKey={tiny.apiKey}
                  value={el.descrizione}
                  onEditorChange={(text) => onChangeRichText(sezione.index, el.index, text)}
                />
              </Col>
              <Col md={1}>
                <Button
                  aria-label="Elimina riga"
                  className="float-end"
                  variant="danger"
                  onClick={() => eliminaRiga(sezione.index, el.index)}
                >
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </Col>
            </Row>
            <div className="separator border-1 border-bottom" />
          </div>
        ))}
      </Card.Body>
    </Card>
  );
}
