import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const templateUrl = process.env.REACT_APP_QUESTIONARI_URL;

async function getTemplates(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${templateUrl}/template?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createTemplate() {
  const body = {
    titolo: `Nuovo template ${Date.now()}`,
    descrizione: 'Descrizione template',
    totaleDomande: 0,
    sezioni: {},
    isEsterno: false,
    isTracciato: false,
  };
  return api.post(`${templateUrl}/template`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getTemplateById(idTemplate) {
  return api.get(`${templateUrl}/template/${idTemplate}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editTemplate(idTemplate, template) {
  const body = {
    titolo: template.titolo,
    descrizione: template.descrizione,
    visibilita: template.visibilita,
    isEsterno: template.isEsterno,
    isTracciato: template.isTracciato,
  };
  return api.put(`${templateUrl}/template/${idTemplate}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteTemplate(idTemplate) {
  return api.delete(`${templateUrl}/template/${idTemplate}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function publishTemplate(idTemplate) {
  return api.put(`${templateUrl}/template/${idTemplate}/pubblica`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function cloneTemplate(idTemplate) {
  return api.put(`${templateUrl}/template/${idTemplate}/clona`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function retireTemplate(idTemplate) {
  return api.put(`${templateUrl}/template/${idTemplate}/ritira`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function creaSezioneTemplate(idTemplate, body) {
  return api.post(`${templateUrl}/template/${idTemplate}/sezione`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaSezioneTemplate(idTemplate, titoloSezione, body) {
  return api.put(`${templateUrl}/template/${idTemplate}/sezione/${titoloSezione}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaSezioneTemplate(idTemplate, titoloSezione) {
  return api.delete(`${templateUrl}/template/${idTemplate}/sezione/${titoloSezione}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function clonaSezioneTemplate(idTemplate, titoloSezione) {
  return api.put(`${templateUrl}/template/${idTemplate}/sezione/${titoloSezione}/clona`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getStatisticaByTemplate(idTemplate) {
  return api.get(`${templateUrl}/template/${idTemplate}/statistica`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListaQuestionariByTemplate(idTemplate) {
  return api.get(`${templateUrl}/template/${idTemplate}/statistica/lista-questionari`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const templateService = {
  getTemplates,
  createTemplate,
  getTemplateById,
  editTemplate,
  deleteTemplate,
  publishTemplate,
  cloneTemplate,
  retireTemplate,
  creaSezioneTemplate,
  modificaSezioneTemplate,
  eliminaSezioneTemplate,
  clonaSezioneTemplate,
  getStatisticaByTemplate,
  getListaQuestionariByTemplate,
};

export default templateService;
