import React, { useContext, useEffect, useState } from 'react';
import appService from '../../../services/appService';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import { Context } from '../../../utils/Context';
import FiltriRicercaApp from '../../../components/AppEsterne/FiltriRicerca';
import CardGroupApp from '../../../components/AppEsterne/CardGroupApp';
import Pagination from '../../../components/Forum/Pagination';

function AppEsterne() {
  const [context, setContext] = useContext(Context);
  const [app, setApp] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    nome: '',
    descrizione: '',
  });

  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  async function getApp(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await appService.getListaApp(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setApp(response?.listaApp || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function visualizzaApp(id) {
    history.push(`/area-riservata/app-esterne/visualizza/${id}`);
  }

  useEffect(() => {
    getApp(0, 5);
  }, []);

  return (
    <div>
      <TitlePage
        title="Applicazioni esterne"
        subtitle="E' possibile ricercare Applicazioni esterne"
        showRefresh
        functionRefresh={() => {
          getApp(0, 5);
        }}
      />
      <FiltriRicercaApp
        filtri={filtri}
        getApp={getApp}
        onChange={onChangeFiltri}
      />
      <CardGroupApp
        app={app}
        showApp={visualizzaApp}
      />
      {pagination.totalSize !== 0 ? (
        <Pagination
          functionShow={getApp}
          pagination={pagination}
        />
      ) : <strong>Non sono presenti applicazioni</strong>}

    </div>
  );
}

export default AppEsterne;
