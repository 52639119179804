import { useEffect, useState } from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Title from '../../../../../components/GestisciUtenti/TitoloPagina';
import { fieldsProfonditaStoricaIdentita, fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Ruoli/fieldsTableIdentita';
import Table from '../../../../../components/Shared/Table';
import utenteService from '../../../../../services/utentiService';
import history from '../../../../../utils/history';
import useQuery from '../../../../../utils/useQuery';
import { fieldsProfonditaStoricaConnettori, fieldsNoProfonditaStoricaConnettori } from './fieldTableUtentiUntrusted';

function DettaglioUtentePerAmministratore() {
  const { idUtente } = useParams();
  const query = useQuery();
  const [utente, setUtente] = useState({});
  const [trusted, setTrusted] = useState(false);
  const [filtriUtente, setFiltriUtente] = useState({
    profondita_storica_connettori: 'false',
    profondita_storica_identita: 'false',
  });

  async function getUtente() {
    setTrusted(query.get('trusted'));
    setUtente(await utenteService.getUtenteById(idUtente,
      { trusted: query.get('trusted'), ...filtriUtente }));
  }

  function onChangeFiltriUtente(e) {
    const { id } = e.target;
    const { value } = e.target;
    setFiltriUtente({
      ...filtriUtente,
      [id]: value,
    });
  }
  useEffect(() => {
    getUtente();
  }, []);

  useEffect(() => {
    getUtente();
  }, [filtriUtente]);
  return (
    <>
      {trusted === 'true'
        ? (
          <>
            <Title
              title="Dettaglio utente"
              subtitle="È possibile visualizzare i dettagli dell'utente"
              showRefresh
              showIndietro
              functionIndietro={() => history.push('/area-riservata/amministrazione/gestione-utenti')}
            />
            <Form className="mt-3">
              <Row className="mb-3">
                <Form.Group as={Col} lg controlId="desc_nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control placeholder="Nome" value={utente.desc_nome} disabled />
                </Form.Group>

                <Form.Group as={Col} lg controlId="desc_cognome">
                  <Form.Label>Cognome</Form.Label>
                  <Form.Control placeholder="Cognome" value={utente.desc_cognome} disabled />
                </Form.Group>
                <Form.Group as={Col} lg controlId="codi_id_fiscale">
                  <Form.Label>Codice fiscale</Form.Label>
                  <Form.Control placeholder="Codice fiscale" value={utente.codi_id_fiscale} disabled />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg controlId="data_registrazione">
                  <Form.Label>Data registrazione</Form.Label>
                  <Form.Control placeholder="Data registrazione" value={utente.data_registrazione} disabled />
                </Form.Group>
                <Form.Group as={Col} lg controlId="desc_email">
                  <Form.Label>Indirizzo email</Form.Label>
                  <Form.Control placeholder="Indirizzo email" value={utente.desc_email} disabled />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg controlId="codi_area">
                  <Form.Label>Area</Form.Label>
                  <Form.Control placeholder="Area" value={utente.codi_area} disabled />
                </Form.Group>
                <Form.Group as={Col} lg controlId="codi_servizio">
                  <Form.Label>Servizio</Form.Label>
                  <Form.Control placeholder="Servizio" value={utente.codi_servizio} disabled />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {utente.identita_associate && (
                  <Table
                    title="Elenco identità associate"
                    data={utente.identita_associate}
                    fields={filtriUtente.profondita_storica_identita === 'true'
                      ? fieldsProfonditaStoricaIdentita : fieldsNoProfonditaStoricaIdentita}
                    keyField="sequ_k_identita"
                    profonditaStorica={(
                      <Form.Group controlId="profondita_storica_identita">
                        <Form.Label>Visualizza profondità storica</Form.Label>
                        <Form.Select
                          onChange={onChangeFiltriUtente}
                          value={filtriUtente.profondita_storica_identita}
                        >
                          <option value="true">Sì</option>
                          <option value="false">No</option>
                        </Form.Select>
                      </Form.Group>
                  )}
                  />
                )}
              </Row>
              <Row className="mb-3">
                {utente.utenti_untrusted_associati && (
                  <Table
                    title="Elenco utenti untrusted associati"
                    data={utente.utenti_untrusted_associati}
                    fields={filtriUtente.profondita_storica_connettori === 'true'
                      ? fieldsProfonditaStoricaConnettori : fieldsNoProfonditaStoricaConnettori}
                    keyField="sequ_k_utente_untrusted"
                    profonditaStorica={(
                      <Form.Group controlId="profondita_storica_connettori">
                        <Form.Label>Visualizza profondità storica</Form.Label>
                        <Form.Select
                          onChange={onChangeFiltriUtente}
                          value={filtriUtente.profondita_storica_connettori}
                        >
                          <option value="true">Sì</option>
                          <option value="false">No</option>
                        </Form.Select>
                      </Form.Group>
                  )}
                  />
                )}
              </Row>
            </Form>
          </>
        )
        : (
          <>
            <Title
              title="Dettaglio utente"
              subtitle="È possibile visualizzare i dettagli dell'utente"
              showRefresh
              showIndietro
              functionIndietro={() => history.push('/area-riservata/amministrazione/gestione-utenti')}
            />
            <Form className="mt-3">
              <Row className="mb-3">
                <Form.Group as={Col} lg controlId="desc_nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control placeholder="Nome" value={utente.desc_nome} disabled />
                </Form.Group>

                <Form.Group as={Col} lg controlId="desc_cognome">
                  <Form.Label>Cognome</Form.Label>
                  <Form.Control placeholder="Cognome" value={utente.desc_cognome} disabled />
                </Form.Group>
                <Form.Group as={Col} lg controlId="codi_id_connettore_utente">
                  <Form.Label>Identificativo</Form.Label>
                  <Form.Control placeholder="Identificativo" value={utente.codi_id_connettore_utente} disabled />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} lg controlId="data_registrazione">
                  <Form.Label>Data registrazione</Form.Label>
                  <Form.Control placeholder="Data registrazione" value={utente.data_registrazione} disabled />
                </Form.Group>
                <Form.Group as={Col} lg controlId="desc_tipo_connettore">
                  <Form.Label>Tipo connettore</Form.Label>
                  <Form.Control placeholder="Tipo connettore" value={utente.desc_tipo_connettore} disabled />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {utente.identita_associate && (
                <Table
                  title="Elenco identità associate"
                  data={utente.identita_associate}
                  fields={filtriUtente.profondita_storica_identita === 'true'
                    ? fieldsProfonditaStoricaIdentita : fieldsNoProfonditaStoricaIdentita}
                  keyField="sequ_k_identita"
                  profonditaStorica={(
                    <Form.Group controlId="profondita_storica_identita">
                      <Form.Label>Visualizza profondità storica</Form.Label>
                      <Form.Select
                        onChange={onChangeFiltriUtente}
                        value={filtriUtente.profondita_storica_identita}
                      >
                        <option value="true">Sì</option>
                        <option value="false">No</option>
                      </Form.Select>
                    </Form.Group>
                )}
                />
                )}
              </Row>

            </Form>
          </>
        )}
    </>
  );
}

export default DettaglioUtentePerAmministratore;
