import React, { useState } from 'react';
import {
  Modal, Button, Form, Col,
} from 'react-bootstrap';

const ModaleNuovoUntrusted = ({
  show, handleClose, setUser, user, listaTipiConnettore,
}) => {
  const [form, setForm] = useState({});
  async function onChangeForm(e) {
    let { value } = e.target;
    const { id } = e.target;
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(value) && value !== '') {
      value = parseInt(value, 10);
    }
    setForm({
      ...form,
      [id]: value,
    });
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} centered role="dialog">
        <Modal.Header closeButton closeLabel="Chiudi">
          <Modal.Title>Aggiungi collegamento utente untrusted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="my-3">
            <Form.Group>
              <Form.Label htmlFor="codi_id_connettore_utente">Identificativo</Form.Label>
              <Form.Control value={form.codi_id_connettore_utente} onChange={onChangeForm} id="codi_id_connettore_utente" />
            </Form.Group>
          </Form>
          <Form.Group as={Col} lg controlId="codi_k_tipo_connettore">
            <Form.Label>Tipo connettore</Form.Label>
            <Form.Select
              onChange={onChangeForm}
              value={form.codi_k_tipo_connettore}
            >
              <option> </option>
              {listaTipiConnettore.map((tag) => (
                <option
                  key={tag.codi_k_tipo_connettore}
                  value={tag.codi_k_tipo_connettore}
                >
                  {tag.desc_tipo_connettore}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="grey" onClick={handleClose}>
            Chiudi
          </Button>
          <Button
            variant="primary"
            disabled={!(!!form.codi_k_tipo_connettore && !!form.codi_id_connettore_utente)}
            onClick={() => {
              setUser({
                ...user,
                utenti_untrusted_associati: [
                  ...user.utenti_untrusted_associati,
                  {
                    ...form,
                    sequ_k_utente_untrusted: Math.floor(Math.random() * 10),
                    fk_codi_tipo_connettore: form.codi_k_tipo_connettore,
                    desc_tipo_connettore:
                  listaTipiConnettore
                    .filter((el) => el.codi_k_tipo_connettore === form.codi_k_tipo_connettore)[0]
                    .desc_tipo_connettore,
                    azione: 'aggiungi',
                  }],
              });
              handleClose();
            }}
          >
            Aggiungi
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModaleNuovoUntrusted;
