import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatoDataEOra } from '../../../utils/formatData';
import history from '../../../utils/history';
import { setItem } from '../../../utils/storage';
import { controlla } from '../../../utils/verificaPermessi';

function handleMod(row) {
  setItem('listaD', JSON.stringify(row));
  history.push(`/area-riservata/newsletter/lista/edit/${row.id}`);
}

function handleEditUserList(row) {
  history.push(`/area-riservata/newsletter/utenti/${row.id}`);
}

const fields = (handleStateModaleConfermaLista, setIdListaScelta) => [

  {
    dataField: 'name',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'subscriber_count',
    text: 'Iscritti',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'created_at',
    text: 'Data creazione',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => (formatoDataEOra(e)),
  },
  {
    dataField: 'updated_at',
    text: 'Data aggiornamento',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => (formatoDataEOra(e)),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '10%' }),
    // text: "Azioni"
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
      }}
      >
        <>

          {controlla('X73') && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            id={`Modifica-lista-${row.id}`}
            title={`Modifica il nome della lista ${row.name}`}
            style={{ color: '#0059b3' }}
            onClick={() => handleMod(row)}
          />
          )}

          {controlla('X74')
          && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'list']}
            size="lg"
            id={`Utenti-lista-${row.id}`}
            title={`Modifica l'elenco degli iscritti alla lista ${row.name}`}
            style={{ color: '#0059b3' }}
            onClick={() => handleEditUserList(row)}
          />
          )}

          {controlla('X75') && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash-alt']}
            size="lg"
            title={`Elimina la lista ${row.name}`}
            id={`Elimina-lista-${row.id}`}
            style={{ color: '#0059b3' }}
            onClick={() => { handleStateModaleConfermaLista(); setIdListaScelta(row.id); }}
          />
          )}

        </>
      </div>

    ),
  },
];

export default fields;
