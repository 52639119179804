/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { options } from '../../utils/tableUtils';

function Pagination({
  functionShow, pagination,
}) {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(pagination.sizePerPage);
  const [totalSize, setTotalSize] = useState();
  const [indexStart, setIndexStart] = useState(1);
  // buttons box[5|10|15|20|30]
  const buttons = [
    { text: '5', page: 5 },
    { text: '10', page: 10 },
    { text: '15', page: 15 },
    { text: '20', page: 20 },
    { text: '30', page: 30 },
  ];
  // buttons box[Prima|Prec|1|2|3|Succ|Ultima]
  const pages = [
    // button PrimaPagina
    { text: options.firstPageText, title: options.firstPageTitle },
    // button Pagina Precedente
    page !== 1 ? { text: options.prePageText, title: options.prePageTitle } : {},
    // button Pagina Precedente Numerica
    page !== 1 ? { text: page - 1 } : {},
    // button Pagina Attuale
    { text: page },
    // button Pagina Successiva Numerica
    (page < totalSize / sizePerPage) ? { text: page + 1 } : {},
    // button Pagina Successiva
    (page < totalSize / sizePerPage)
      ? { text: options.nextPageText, title: options.nextPageTitle } : {},
    // button UltimaPagina
    { text: options.lastPageText, title: options.lastPageTitle },
  ];

  // handle box1
  function onOptionChange(sizePage) {
    functionShow(0, sizePage);
    setSizePerPage(sizePage);
    setPage(1);
    setIndexStart(1);
  }

  // handle box2
  function onPageChange(nextPage) {
    // nextPage è un text se è stato premuto Prima/Prec/Succ/Ultima
    nextPage === 'Prima' ? nextPage = 1
      : nextPage === 'Prec.' ? nextPage = page - 1
        : nextPage === 'Succ.' ? nextPage = page + 1
          : nextPage === 'Ultima' ? nextPage = Math.floor(totalSize / sizePerPage) + ((totalSize / sizePerPage) - Math.trunc(totalSize / sizePerPage) === 0 ? 0 : 1) : null;
    functionShow((nextPage - 1) * sizePerPage, sizePerPage);
    setPage(nextPage);
    setIndexStart((nextPage - 1) * sizePerPage + 1);
  }

  useEffect(() => {
    setSizePerPage(pagination.sizePerPage);
    setTotalSize(pagination.totalSize);
  }, [functionShow]);

  return (
    <Row lg={1} className="g-4 mt-2 mb-2">
      <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <div className="btn-group" role="group">
          {options.sizePerPageRenderer({
            options: buttons,
            currSizePerPage: sizePerPage.toString(),
            onSizePerPageChange: onOptionChange,
          })}
        </div>
        <span className="react-bootstrap-table-pagination-total">
          { options.paginationTotalRenderer(
            indexStart,
            (indexStart + sizePerPage - 1) < totalSize ? (indexStart + sizePerPage - 1) : totalSize,
            totalSize,
          )}

        </span>
      </div>
      <div className="react-bootstr
      ap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6"
      >
        <ul className="pagination react-bootstrap-table-page-btns-ul">
          {pages
            .filter((el) => Object.keys(el).length)
            .map((el) => options.pageButtonRenderer({
              page: el.text,
              active: page == el.text,
              title: el.title,
              onPageChange,
            }))}
        </ul>
      </div>
    </Row>

  );
}

export default Pagination;
