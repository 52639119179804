import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import TitlePage from '../../../../../../components/GestisciUtenti/TitoloPagina';
import ElementModal from '../../../../../../components/Pagina/ElementModal';
import FormImmagini from '../../../../../../components/Pagina/FormImmagini';
import FormParagrafo from '../../../../../../components/Pagina/FormParagrafo';
import FormTitolo from '../../../../../../components/Pagina/FormTitolo';
import ModalePreview from '../../../../../../components/Pagina/ModalePreview';
import TabsBar from '../../../../../../components/Pagina/TabsBar';
import Table from '../../../../../../components/Shared/Table';
import galleriaService from '../../../../../../services/galleriaService';
import versioniService from '../../../../../../services/versioniService';
import { Context } from '../../../../../../utils/Context';
import history from '../../../../../../utils/history';
import { getItem } from '../../../../../../utils/storage';
import { controlla } from '../../../../../../utils/verificaPermessi';
import fieldsContent from './fieldsContent';

const ModificaPagina = () => {
  const { idMenu } = useParams();
  const params = new URLSearchParams(window.location.search);
  const nomeMenu = params.get('nomeMenu');
  const { idVersione } = useParams();
  const [modifica, setModifica] = useState(0);
  const [context, setContext] = useContext(Context);
  // Pagina Main
  const [pagina, setPagina] = useState({
    desc_nome: '',
    fk_sequ_menu_disponibile: '',
    fk_sequ_versione: '',
    contenuti: {},
  });
  // Lista lingue della versione corrente
  const [listaLingue, setListaLingue] = useState([]);
  const listaNomiLingue = {
    1: 'IT',
    2: 'EN',
    3: 'DE',
    4: 'FR',
    5: 'ES',
  };
  // Lingua corrente
  const [currentLingua, setCurrentLingua] = useState('IT');

  // Nuovo Paragrafo da aggiungere nei contenuti
  const [paragrafo, setParagrafo] = useState({
    tipo: 'paragrafo',
    nome: '',
    contenuto: '',
    dataCreazione: new Date(),
  });
  const [indexRow, setIndexRow] = useState(1);
  // Nuova Immagine da aggiungere nei contenuti
  const [immagine, setImmagine] = useState({
    tipo: 'immagine',
    dataCreazione: new Date(),
  });
  // Nuova immagine da inserire nella galleria immagini
  const [nuovaImmagine, setNuovaImmagine] = useState({
    nomeImmagine: '',
    fileCaricato: null,
    alt: '',
  });
  const [listaImmagini, setListaImmagini] = useState([]);
  const [openModaleModifica, setopenModaleModifica] = useState(false);
  const [openModalePreview, setopenModalePreview] = useState(false);

  function getLingueByStorage() {
    const arrayLingue = [];
    JSON.parse(getItem('lingue')).forEach((element) => {
      arrayLingue.push(listaNomiLingue[element.fk_codi_tipo_lingua || element]);
    });
    setListaLingue(arrayLingue);
  }

  function changeLingua(lingua) {
    if (lingua !== currentLingua) {
      if (!pagina.contenuti[lingua]) {
        setPagina({
          ...pagina,
          contenuti: {
            ...pagina.contenuti,
            [lingua]: {
              titolo: '',
              arrayContenuti: [],
            },
          },
        });
        setParagrafo({
          ...paragrafo,
          nome: '',
          contenuto: '',
        });
      } else {
        setParagrafo({
          ...paragrafo,
          nome: '',
          contenuto: '',
        });
      }
      setCurrentLingua(lingua);
    }
  }

  function handleStateNuovoParagrafo() {
    setPagina({
      ...pagina,
      contenuti: {
        ...pagina.contenuti,
        [currentLingua]: {
          ...pagina.contenuti[currentLingua],
          arrayContenuti: [
            ...pagina.contenuti[currentLingua].arrayContenuti,
            paragrafo,
          ],
        },
      },
    });

    setParagrafo({
      nome: '',
      contenuto: '',
      tipo: 'paragrafo',
      dataCreazione: new Date(),
    });
  }

  function handleStateNuovaImmagine() {
    setPagina({
      ...pagina,
      contenuti: {
        ...pagina.contenuti,
        [currentLingua]: {
          ...pagina.contenuti[currentLingua],
          arrayContenuti: [
            ...pagina.contenuti[currentLingua].arrayContenuti,
            immagine,
          ],
        },
      },
    });
  }

  function handleTableChange() {}

  async function getPagina() {
    try {
      const paginaById = await versioniService.getPagina(idVersione, idMenu);
      const imgDisponibili = await galleriaService.getGalleria();
      if (Object.keys(paginaById).length !== 0) {
        setPagina({
          desc_nome: nomeMenu,
          fk_sequ_menu_disponibile: paginaById.documentoMongo.fk_sequ_menu_disponibile,
          fk_sequ_versione: paginaById.documentoMongo.fk_sequ_versione,
          contenuti: paginaById.documentoMongo.contenuti,
        });
        setParagrafo({
          ...paragrafo,
        });
        setModifica(paginaById.sequ_k_pagina);
      } else {
        setPagina({
          desc_nome: nomeMenu,
          fk_sequ_menu_disponibile: parseFloat(idMenu),
          fk_sequ_versione: parseFloat(idVersione),
          contenuti: {
            IT: {
              titolo: '',
              arrayContenuti: [],
            },
          },
        });
      }
      setListaImmagini(imgDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  async function salvaPagina() {
    try {
      let response = '';
      if (modifica === 0) {
        response = await versioniService.creaPagina(
          idVersione,
          pagina,
        );
      } else {
        response = await versioniService.modificaPagina(
          idVersione,
          modifica,
          pagina,
        );
      }

      history.goBack();
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function uploadImmagine() {
    try {
      const response = await galleriaService.uploadImmagine(nuovaImmagine);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      const imgDisponibili = await galleriaService.getGalleria();
      setListaImmagini(imgDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function eliminaPagina() {
    try {
      const response = await versioniService.eliminaPagina(idVersione, modifica);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      history.goBack();
      const imgDisponibili = await galleriaService.getGalleria();
      setListaImmagini(imgDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChange(e) {
    const { value } = e.target;
    setPagina({
      ...pagina,
      contenuti: {
        ...pagina.contenuti,
        [currentLingua]: {
          ...pagina.contenuti[currentLingua],
          titolo: value,
        },
      },
    });
  }

  function onChangeImmagine(immagineScelta) {
    setImmagine({
      ...immagine,
      id: immagineScelta._id,
      nome: immagineScelta.nomeFile,
      alt: immagineScelta.alt,
    });
  }

  const onUpload = (e) => {
    const { id, value, files } = e.target;
    setNuovaImmagine({
      ...nuovaImmagine,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  const onChangeAltImmagine = (e) => {
    const { id, value } = e.target;
    setNuovaImmagine({
      ...nuovaImmagine,
      [id]: value,
    });
  };

  function handleStateModaleModifica(row, rowIndex) {
    if (row) {
      setParagrafo(row);
      setIndexRow(rowIndex);
    }
    setTimeout(() => {
      setopenModaleModifica(!openModaleModifica);
    }, 150);
  }

  function modificaElemento() {
    pagina.contenuti[currentLingua].arrayContenuti[indexRow] = paragrafo;

    setParagrafo({
      nome: '',
      contenuto: '',
      tipo: 'paragrafo',
    });

    setopenModaleModifica(!openModaleModifica);
  }

  function eliminaElemento(row) {
    setPagina({
      ...pagina,
      contenuti: {
        ...pagina.contenuti,
        [currentLingua]: {
          ...pagina.contenuti[currentLingua],
          arrayContenuti: pagina.contenuti[currentLingua].arrayContenuti
            .filter((el) => el !== row),
        },
      },
    });
  }

  function move(oldIndex, newIndex) {
    const arr = pagina.contenuti[currentLingua].arrayContenuti;
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    setPagina({
      ...pagina,
      contenuti: {
        ...pagina.contenuti,
        [currentLingua]: {
          ...pagina.contenuti[currentLingua],
          arrayContenuti: arr,
        },
      },
    });
  }

  function showPreview() {
    setopenModalePreview(!openModalePreview);
  }

  useEffect(() => {
    getLingueByStorage();
    getPagina();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione pagina sito web"
        subtitle="Questo tipo di pagina permette di inserire un titolo, uno o più testi ed eventualmente una o più immagini"
        showIndietro
        functionIndietro={() => history.goBack()}
        showSave={controlla('X99') === true}
        functionSave={salvaPagina}
        showPreview
        functionPreview={showPreview}
        showDelete={modifica !== 0}
        functionDelete={eliminaPagina}
      />
      <p>
        Nome sezione:
        {' '}
        <strong>{pagina.desc_nome}</strong>
      </p>

      <TabsBar
        lingue={listaLingue}
        changeLingua={changeLingua}
        currentLingua={currentLingua}
      />

      <FormTitolo
        titolo={pagina.contenuti[currentLingua] ? pagina.contenuti[currentLingua].titolo : ''}
        onChange={onChange}
      />

      <FormParagrafo
        paragrafo={paragrafo}
        setParagrafo={setParagrafo}
        handleStateNuovoParagrafo={handleStateNuovoParagrafo}
      />

      <FormImmagini
        onChange={onChangeImmagine}
        listaImmagini={listaImmagini}
        immagineSelected={immagine}
        nuovaImmagine={nuovaImmagine}
        onUpload={onUpload}
        onChangeAltImmagine={onChangeAltImmagine}
        uploadImmagine={uploadImmagine}
        handleStateNuovaImmagine={handleStateNuovaImmagine}
      />

      {pagina.contenuti[currentLingua]?.arrayContenuti
      && pagina.contenuti[currentLingua]?.arrayContenuti.length !== 0 ? (
        <Table
          title="Contenuti inseriti:"
          data={pagina.contenuti[currentLingua]
            ? pagina.contenuti[currentLingua].arrayContenuti : []}
          fields={fieldsContent(
            handleStateModaleModifica,
            eliminaElemento, move, pagina.contenuti[currentLingua]
              ? pagina.contenuti[currentLingua].arrayContenuti : [],
          )}
          onTableChange={handleTableChange}
          keyField="id"
        />
        ) : (
          <>
            <p className="fw-bold fs-3">
              Contenuti inseriti:
              {' '}
            </p>
            <div>La lista contenuti è vuota</div>

          </>
        )}

      {openModaleModifica && (
      <ElementModal
        setParagrafo={setParagrafo}
        paragrafo={paragrafo}
        show={openModaleModifica}
        handleClose={handleStateModaleModifica}
        handleSave={modificaElemento}
      />
      )}

      <ModalePreview
        page={pagina.contenuti[currentLingua]
          ? pagina.contenuti[currentLingua] : []}
        contenuto={pagina.contenuti[currentLingua]
          ? pagina.contenuti[currentLingua].arrayContenuti : []}
        show={openModalePreview}
        handleClose={showPreview}
      />
    </>
  );
};

export default ModificaPagina;
