import React, { useContext, useEffect, useState } from 'react';
import CardGroup from '../../components/News/CardGroup';
import newsService from '../../services/newsService';
import { Context } from '../../utils/Context';
import styles from './news/css/index.module.scss';

function Home() {
  const [newsInEvidenza, setNewsiInEvidenza] = useState([]);
  const [context, setContext] = useContext(Context);

  async function getListaNews(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await newsService.getListaNews(null, offset, limit);
      setNewsiInEvidenza(response?.newsInEvidenza || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getListaNews(0, 5);
  }, []);

  return (
    <>
      <div className="m-2" style={{ textAlign: 'center' }}>
        <h1>Benvenuto nella tua area riservata</h1>
        <p id="desc">Scegli una delle funzioni dal menu di lato.</p>
      </div>
      {newsInEvidenza.length
        ? (
          <CardGroup
            news={newsInEvidenza}
            title="Notizie in evidenza:"
            type="News"
            styles={styles}
          />
        ) : null}
    </>
  );
}

export default Home;
