import {
  React, useState, useEffect, useContext,
} from 'react';
import Title from '../../../../components/GestisciUtenti/TitoloPagina/index';
import Table from '../../../../components/Shared/Table';
import { fieldsProfonditaStorica, fieldsNoProfonditaStorica } from './fieldsTable';
import history from '../../../../utils/history';
import Filtri from './filtri';
import tagRuoliService from '../../../../services/tagRuoliService';
import { Context } from '../../../../utils/Context';
import { controlla } from '../../../../utils/verificaPermessi';

function GestioneTagRuoli() {
  const [context, setContext] = useContext(Context);
  const [tagRuoli, setTagRuoli] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    desc_nome_tag_ruolo: '',
    desc_tag_ruolo: '',
    profondita_storica: false,
  });

  async function getTagRuolo(offset, limit, page) {
    const response = await tagRuoliService.getTagRuoli(filtri, offset, limit);
    setTagRuoli(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getTagRuolo(currentIndex, sizePerPage, page);
  };

  const actions = {
    delete: async (idTagRuolo) => {
      tagRuoliService.cancellaTagRuolo(idTagRuolo)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          getTagRuolo(0, 5, 1);
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
      getTagRuolo(0, 5, 1);
    },
  };

  function onChangeFiltri(e) {
    const { id, value } = e.target;
    setFiltri((prevFiltri) => ({
      ...prevFiltri,
      [id]: value,
    }));
  }

  useEffect(() => {
    getTagRuolo(0, 5, 1);
  }, []);

  function refresh() {
    getTagRuolo(0, 5, 1);
  }

  return (
    <>
      <Title
        title="Gestione Tag ruolo"
        subtitle="È possibile ricercare, gestire e creare Tag ruoli"
        showRefresh
        showAdd={controlla('X11') === true}
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione')}
        functionRefresh={refresh}
        functionAdd={() => history.push('/area-riservata/amministrazione/gestione-tag-ruolo/aggiungi')}
      />

      <Filtri
        onChangeFiltri={onChangeFiltri}
        filtri={filtri}
        getTagRuolo={getTagRuolo}
      />
      <Table
        title="Elenco Tag ruolo"
        data={tagRuoli}
        fields={filtri.profondita_storica === 'true' ? fieldsProfonditaStorica(actions) : fieldsNoProfonditaStorica(actions)}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="sequ_k_tag_ruolo"
      />
    </>
  );
}

export default GestioneTagRuoli;
