/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styles from './CustomNode.module.css';

export default function CustomNode(props) {
  // eslint-disable-next-line no-unused-vars
  const [hover, setHover] = useState(false);

  const indent = props.depth * 24;

  // Apri/chiudi nodo
  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  return (
    <div
      className={`tree-node ${styles.root}`}
      style={{ paddingInlineStart: indent }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          props.isOpen ? styles.isOpen : ''
        }`}
      >
        {props.node.droppable && props.node.text !== 'Homepage' && (
          <FontAwesomeIcon
            title={`${props.isOpen ? 'Chiudi' : 'Apri'} la sezione ${props.node.text}`}
            onClick={handleToggle}
            icon="arrow-right"
            color="black"
          />
        )}
      </div>
      <div />
      <div className={styles.labelGridItem}>
        {props.node.flagDinamico
          ? (
            <p>
              {props.node.text}
              *
            </p>
          )
          : props.node.text === 'Homepage' ? (
            <Link to={`/area-riservata/siti-web/${props.idVersione}/albero/template-homepage/${props.node.sequ_k_menu_disponibile}?nomeMenu=${props.node.text}`}>
              {props.node.text}
            </Link>
          ) : (
            props.node.flagRappGrafica
              ? (
                <Link to={`/area-riservata/amm-trasparente/${props.idVersione}/albero/${props.node.id}/rappresentazione-grafica?nomeLivello=${props.node.text}`}>
                  {props.node.text}
                </Link>
              )
              : (
                <Link to={props.pathLink}>
                  {props.node.text}
                </Link>
              )
          )}
        {props.node.compilato
          && (
          <FontAwesomeIcon
            title="Sezione compilata"
            icon="check"
            color="green"
          />
          )}
      </div>
    </div>
  );
}
