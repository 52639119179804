/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import { fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Identita/fieldsTableIdentita';
import { fieldsNoProfonditaStorica } from '../../../../../components/Identita/fieldsTableRuoli';
import FormDettaglioIdentita from '../../../../../components/Identita/FormDettaglioIdentita';
import ModaleNuovoTagIdentita from '../../../../../components/Identita/ModaleNuovoTagIdentita';
import ModaleRicercaIdentita from '../../../../../components/Identita/ModaleRicercaIdentita';
import ModaleRicercaRuoli from '../../../../../components/Identita/ModaleRicercaRuoli';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import Table from '../../../../../components/Shared/Table';
import identitaService from '../../../../../services/identitaService';
import organizzazioniService from '../../../../../services/organizzazioniService';
import ruoliService from '../../../../../services/ruoliService';
import TagIdentitaService from '../../../../../services/tagIdentitaService';
import tagRuoliService from '../../../../../services/tagRuoliService';
import tipoIdentitaService from '../../../../../services/tipoIdentitaService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';

function AggiungIdentita() {
  const [context, setContext] = useContext(Context);
  const [listaTagIdentita, setListaTagIdentita] = useState([]);
  const [listaOrganizzazioni, setListaOrganizzazioni] = useState([]);
  const [listaTipoIdentita, setListaTipoIdentita] = useState([]);
  const [listaRuoli, setListaRuoli] = useState([]);
  const [listaIdentita, setListaIdentita] = useState([]);
  const [ruoliScelti, setRuoliScelti] = useState([]);
  const [identitaScelte, setIdentitaScelte] = useState([]);
  const [showModalRuoli, setShowModalRuoli] = useState(false);
  const [showModalIdentita, setShowModalIdentita] = useState(false);
  const [showModaleNewTagIdentita, setShowModaleNewTagIdentita] = useState(false);
  const [listaTagRuolo, setListaTagRuolo] = useState([]);
  const [nuovaIdentita, setNuovaIdentita] = useState({
    desc_nome_identita: '',
    desc_identita: '',
    desc_note_identita: '',
    fk_codi_tipo_identita: null,
    fk_sequ_tag_identita: null,
    // fk_sequ_organizzazione: 1,
    data_inizio_validita: new Date(),
  });
  const [paginationRuoli, setPaginationRuoli] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [paginationIdentita, setPaginationIdentita] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtriRuoli, setFiltriRuoli] = useState({
    flag_protetto: false,
    flag_admin: false,
    fk_sequ_tag_ruolo: null,
    profondita_storica: false,
  });
  const [filtriIdentita, setFiltriIdentita] = useState({
    flag_protetto: false,
    fk_sequ_tag_identita: null,
    desc_nome_identita: '',
    fk_codi_tipo_identita: null,
    profondita_storica: false,
  });
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function getListaIdentitaDominio() {
    const response = await identitaService.getIdentita(
      { fk_codi_tipo_identita: 5, profondita_storica: false }, 0, 10000,
    );
    return response.data;
  }

  async function onChangeForm(e) {
    const { id, value } = e.target;
    if (id === 'fk_codi_tipo_identita' && value === '5') {
      setIdentitaScelte(await getListaIdentitaDominio());
    } else if (id === 'fk_codi_tipo_identita' && value === '6') {
      setIdentitaScelte([]);
    }
    // if (value === 'true') {
    //   value = true;
    // } else if (value === 'false') {
    //   value = false;
    // // eslint-disable-next-line no-restricted-globals
    // } else if (!isNaN(value)) {
    //   value = parseInt(value, 10);
    // }
    // VEDI: Ho risolt oil problema dei Nan così, se vuoi usare un metodo alternativo va benissimo
    if (id === 'fk_codi_tipo_identita' || id === 'fk_sequ_tag_identita') {
      setNuovaIdentita((prevIdentita) => ({
        ...prevIdentita,
        [id]: parseInt(value, 10),
      }));
    } else {
      setNuovaIdentita((prevIdentita) => ({
        ...prevIdentita,
        [id]: value,
      }));
    }
  }

  async function getListaTagIdentita() {
    const response = await TagIdentitaService.getTagIdentita({ flag_protetto: false });
    setListaTagIdentita(response?.data?.filter((el) => !el.data_fine_validita));
  }

  async function getListaOrganizzazioni() {
    const response = await organizzazioniService.getOrganizzazioni();
    setListaOrganizzazioni(response);
  }

  async function getListaTipoIdentita() {
    const response = await tipoIdentitaService.getTipoIdentita({ flag_protetto: false });
    setListaTipoIdentita(response);
  }

  async function getListaRuoli(offset, limit, page) {
    const response = await ruoliService.getRuoli(filtriRuoli, offset, limit);
    setListaRuoli(response.data);
    setPaginationRuoli({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getListaRuoliDefault(offset, limit) {
    const response = await ruoliService.getRuoli(
      { ...filtriRuoli, flag_default: true }, offset, limit,
    );
    setRuoliScelti(response.data);
  }

  async function getListaIdentita(offset, limit, page) {
    const response = await identitaService.getIdentita(
      { flag_config: false, ...filtriIdentita }, offset, limit,
    );
    setListaIdentita(response.data);
    setPaginationIdentita({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getTagsRuolo() {
    const { data } = await tagRuoliService.getTagRuoli();
    setListaTagRuolo(data);
  }

  function onChangeFiltriRuolo(e) {
    let { value } = e.target;
    const { id } = e.target;
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(value)) {
      value = parseInt(value, 10);
    }
    setFiltriRuoli({
      ...filtriRuoli,
      [id]: value,
    });
  }

  function onChangeFiltriIdentita(e) {
    let { value } = e.target;
    const { id } = e.target;
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(value)) {
      value = parseInt(value, 10);
    }
    setFiltriIdentita({
      ...filtriIdentita,
      [id]: value,
    });
  }

  function handleModaleRuoliStatus() {
    setShowModalRuoli(!showModalRuoli);
  }

  function handleModaleIdentitaStatus() {
    setShowModalIdentita(!showModalIdentita);
  }

  const handleTableChangeRuoli = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getListaRuoli(currentIndex, sizePerPage, page);
  };

  const handleTableChangeIdentita = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getListaIdentita(currentIndex, sizePerPage, page);
  };

  function verificaObbligatorietaCampi() {
    return nuovaIdentita.desc_nome_identita && nuovaIdentita.desc_identita
     && nuovaIdentita.fk_sequ_tag_identita && nuovaIdentita.fk_codi_tipo_identita;
  }

  async function postIdentita() {
    if (verificaObbligatorietaCampi()) {
      const response = await identitaService.getIdentita(
        { fk_codi_tipo_identita: 5 }, 0, 10000,
      );

      let identitaEsterne = [];
      if (nuovaIdentita.fk_codi_tipo_identita === 6) {
        identitaEsterne = identitaScelte.filter(
          (el) => el.fk_codi_tipo_identita === 5 || el.fk_codi_tipo_identita === 6,
        ).map((el) => el.sequ_k_identita);
      } else if (nuovaIdentita.fk_codi_tipo_identita === 5) {
        identitaEsterne = identitaScelte.filter(
          (el) => el.fk_codi_tipo_identita === 6,
        ).map((el) => el.sequ_k_identita);
      }
      // ----- Inizio codice che capisce le identità escluse -----

      // Comparatore per capire se due entità sono uguali.
      const isSame = (a, b) => a.sequ_k_identita === b.sequ_k_identita;

      // Get elementi che appaiono solamente nell'array di sinistra,
      // utilizzando compareFunction per determinare l'uguaglianza
      const onlyInLeft = (left, right, compareFunction) => left.filter(
        (leftValue) => !right.some((rightValue) => compareFunction(leftValue, rightValue)),
      );

      const onlyInA = onlyInLeft(identitaScelte, response.data, isSame);
      const onlyInB = onlyInLeft(response.data, identitaScelte, isSame);

      const result = [...onlyInA, ...onlyInB];

      // ----- Fine codice che capisce le identità escluse -----
      identitaService.nuovaIdentita({
        ...nuovaIdentita,
        ruoli: ruoliScelti.map((el) => el.sequ_k_ruolo),
        collegate: identitaEsterne,
        escluse: result.filter(
          (el) => el.fk_codi_tipo_identita === 5,
        ).map(
          (el) => el.sequ_k_identita,
        ),
      })
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          history.goBack();
        })
        .catch((err) => {
          setOpenCloseModaleConferma();
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else {
      setOpenCloseModaleConferma();
      setContext(() => ({
        ...context, open: true, testoErrore: 'Alcuni campi non sono valorizzati', statusCode: 400,
      }));
    }
  }

  function handleModaleNewTagIdentita() {
    setShowModaleNewTagIdentita(!showModaleNewTagIdentita);
    getListaTagIdentita();
  }

  useEffect(() => {
    getListaTagIdentita();
    getListaOrganizzazioni();
    getListaTipoIdentita();
    getListaRuoli(0, 5, 1);
    getListaRuoliDefault(0, 1000);
    getTagsRuolo();
    getListaIdentita(0, 5, 1);
  }, []);

  return (
    <>
      <TitlePage
        title="Inserimento nuova identità"
        subtitle="È possibile inserire una nuova identità"
        showIndietro
        functionIndietro={() => {
          history.goBack();
        }}
      />
      <FormDettaglioIdentita
        listaTagIdentita={listaTagIdentita}
        listaOrganizzazioni={listaOrganizzazioni}
        listaTipoIdentita={listaTipoIdentita}
        nuovaIdentita={nuovaIdentita}
        onChangeForm={onChangeForm}
        handleModaleNewTagIdentita={handleModaleNewTagIdentita}
      />
      <Row className="mb-3 border-top border-dark">
        <Table
          title="Elenco ruoli associati"
          data={ruoliScelti}
          fields={fieldsNoProfonditaStorica}
          keyField="sequ_k_ruolo"
          button={<Button onClick={handleModaleRuoliStatus}>Modifica</Button>}
        />
      </Row>
      {nuovaIdentita.fk_codi_tipo_identita ? (
        <Row className="mb-3 border-top border-dark">
          <Table
            title="Lista identità referenziate da questa identità"
            data={identitaScelte}
            fields={fieldsNoProfonditaStoricaIdentita}
            keyField="sequ_k_identita"
            button={<Button onClick={handleModaleIdentitaStatus}>Modifica</Button>}
          />
        </Row>
      ) : null}
      <br />
      <div className="d-flex justify-content-end">
        <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
          <FontAwesomeIcon aria-hidden="true" icon="save" />
          {' '}
          Salva
        </Button>
      </div>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione inserirà una nuova identità'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={postIdentita}
      />
      <ModaleRicercaRuoli
        show={showModalRuoli}
        handleClose={handleModaleRuoliStatus}
        ruoli={listaRuoli}
        getListaRuoli={getListaRuoli}
        ruoliScelti={ruoliScelti}
        setRuoliScelti={setRuoliScelti}
        handleTableChangeRuoli={handleTableChangeRuoli}
        listaTagRuolo={listaTagRuolo}
        paginationRuoli={paginationRuoli}
        onChangeFiltriRuolo={onChangeFiltriRuolo}
      />
      <ModaleRicercaIdentita
        show={showModalIdentita}
        handleClose={handleModaleIdentitaStatus}
        identita={listaIdentita}
        getListaIdentita={getListaIdentita}
        identitaScelte={identitaScelte}
        setIdentitaScelte={setIdentitaScelte}
        handleTableChangeIdentita={handleTableChangeIdentita}
        listaTagIdentita={listaTagIdentita}
        listaTipoIdentita={listaTipoIdentita}
        paginationIdentita={paginationIdentita}
        setPaginationIdentita={setPaginationIdentita}
        onChangeFiltriIdentita={onChangeFiltriIdentita}
      />
      <ModaleNuovoTagIdentita
        show={showModaleNewTagIdentita}
        handleClose={handleModaleNewTagIdentita}
      />
    </>
  );
}

export default AggiungIdentita;
