/* eslint-disable prefer-template */
import React from 'react';
import {
  Form, Row, Button, Col, Accordion,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FiltriRicercaApp({
  filtri,
  onChange,
  getApp,
}) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <Col className="mb-3">
                <Form.Group as={Col} lg controlId="nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control placeholder="Inserisci il nome" onChange={onChange} value={filtri.nome} />
                </Form.Group>
              </Col>
              <Col className="mb-3">
                <Form.Group as={Col} lg controlId="descrizione">
                  <Form.Label>Descrizione</Form.Label>
                  <Form.Control placeholder="Inserisci la descrizione" onChange={onChange} value={filtri.descrizione} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Button variant="primary" onClick={() => { getApp(0, 5); }}>
            <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
            CERCA
          </Button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriRicercaApp;
