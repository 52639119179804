import React from 'react';
import {
  Form, Row, Button, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FiltriRicercaUtentiChat({
  onChangeFiltriUtenti, getListaUtenti, listaIdentitaCanali,
  notShowSelectIdentita, // campo usato da repository
}) {
  return (
    <Row id="form-cerca-utenti">
      <Row>
        {' '}
        <h2>Filtri ricerca</h2>
      </Row>
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="testo">
            <Form.Label>Ricerca per nome, cognome, codice fiscale</Form.Label>
            <Form.Control
              placeholder="Ricerca per nome, cognome, codice fiscale"
              onChange={onChangeFiltriUtenti}
            />
          </Form.Group>
          {!notShowSelectIdentita
            ? (
              <Form.Group as={Col} lg controlId="identita">
                <Form.Label>Lista identità</Form.Label>
                <Form.Select
                  onChange={onChangeFiltriUtenti}
                  value={listaIdentitaCanali.sequ_k_identita}
                >
                  <option> </option>
                  {listaIdentitaCanali.map((tipo) => (
                    <option
                      key={tipo.sequ_k_identita}
                      value={tipo.sequ_k_identita}
                    >
                      {tipo.desc_nome_identita}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : null }
        </Row>

        <Button variant="primary" className="float-end" onClick={() => getListaUtenti(0, 5, 1)} aria-label="Ricerca le informazioni secondo i filtri inseriti">
          <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} />
          {' '}
          CERCA
        </Button>
      </Form>
    </Row>
  );
}

export default FiltriRicercaUtentiChat;
