import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import logo from '../../images/logo-point.png';
import logoAgid from '../../images/logo-agid.svg';
import './index.css';

function FooterHtml() {
  return (
    <footer id="footer">
      <div className="container">
        <section className="footer-logo">
          <div className="row clearfix">
            <div className="col-sm-12 intestazione">
              <Row className="logoimg">
                <Col className="col-md-3">
                  <Link to="/" title="Vai alla pagina: Homepage">
                    <img id="img-point" src={logo} alt="Logo portale integrato" />
                  </Link>
                </Col>
                <Col className="col-md-3">
                  <a className="ms-3" href="https://www.agid.gov.it" title="Collegamento a sito esterno - AGID - si apre una nuova finestra">
                    <img src={logoAgid} id="img-agid" alt="Logo AGID" />
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* <section className="lista-sezioni">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6">
              <h1>
                <FontAwesomeIcon aria-hidden="true" className="icon" icon="newspaper" size="lg" />
                {' '}
                NOTIZIE
              </h1>
              <ul className="footer-list clearfix">
                <li>
                  <Link to="/notizie" title="Vai alla pagina: Notizie">
                    Notizie
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
        <section className="lista-linkutili">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 footer-ammtrasp">
              <a
                href="https://trasparenza.agid.gov.it/"
                target="_blank"
                title="Collegamento a sito esterno - Sito trasparenza AgID - si apre una nuova finestra"
                rel="noreferrer"
              >
                <h1>
                  AMMINISTRAZIONE TRASPARENTE
                </h1>
              </a>
              <p>
                I dati personali pubblicati sono riutilizzabili
                solo ai sensi dell&apos;articolo 7 del decreto legislativo 33/2013
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 footer-contatti">
              <h1>
                CONTATTI
              </h1>
              <p>
                <strong>AGID Piattaforma Integrata</strong>
                <br />
                <a
                  title="Collegamento a sito esterno - Google Maps - si apre una nuova finestra"
                  href="https://www.google.it/maps/place/Via+Liszt,+21,+00144+Roma+RM/@41.8336525,12.4653778,17z/data=!3m1!4b1!4m5!3m4!1s0x13258ae3d27bf449:0x5aa2ce4a30bafdda!8m2!3d41.8336485!4d12.4675665"
                  target="new"
                >
                  Via Liszt 21 - 00144 Roma

                </a>
                <br />
                <strong>Telefono: </strong>
                +39 06852641
                <br />
                <strong>Codice fiscale: </strong>
                97735020584
                <br />
                <strong>PEC: </strong>
                <a href="mailto:protocollo@pec.agid.gov.it">protocollo@pec.agid.gov.it</a>
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 footer-seguici">
              <h1> SEGUICI SU </h1>
              <ul className="list-inline text-start social">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/AgIDGovIT/"
                    target="_blank"
                    title="Collegamento a sito esterno - Facebook - si apre una nuova finestra"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'facebook']} size="lg" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://twitter.com/AgidGov"
                    target="_blank"
                    title="Collegamento a sito esterno - Twitter - si apre una nuova finestra"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'twitter']} size="lg" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UCxJmOWf__YcLgB19gy5dGQQ"
                    target="_blank"
                    title="Collegamento a sito esterno - Youtube - si apre una nuova finestra"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'youtube']} size="lg" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://medium.com/@AgidGov"
                    target="_blank"
                    title="Collegamento a sito esterno - Medium - si apre una nuova finestra"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'medium-m']} size="lg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div className="postFooter clearfix">
          <div className="link_piede_footer">
            <Link to="/privacy-policy" title="Vai alla pagina: Privacy policy" aria-label="Vai alla pagina: Privacy policy">
              Privacy policy
            </Link>
            |
            <Link to="/note-legali" title="Vai alla pagina: Note legali" aria-label="Vai alla pagina: Note legali">
              Note legali
            </Link>
            |
            <Link to="/dichiarazione-accessibilita" title="Vai alla pagina: Dichiarazione di accessibilità" aria-label="Vai alla pagina: Dichiarazione di accessibilità">
              Dichiarazione di accessibilità
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterHtml;
