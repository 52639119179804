import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Table from '../Shared/Table';
import { fields, selectRows } from './fieldsTableFunzioniModificaModale';
import FiltriRicercaFunzioni from './FiltriRicercaFunzioni';

const ModaleRicercaFunzioniModifica = ({
  show,
  handleClose,
  funzioni,
  funzioniScelte,
  setFunzioniScelte,
  onChangeFiltriFunzione,
  getFunzioni,
  tagsFunzione,
  funzioniRimosse,
  setFunzioniRimosse,
  funzioniAggiunte,
  setFunzioniAggiunte,
  handleTableChange,
  pagination,
}) => (
  <>
    <Modal show={show} onHide={handleClose} centered size="xl" role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Ricerca funzioni da associare</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FiltriRicercaFunzioni
          onChangeFiltriFunzione={onChangeFiltriFunzione}
          getFunzioni={getFunzioni}
          tagsFunzione={tagsFunzione}
        />
        <Table
          title="Ricerca funzioni"
          data={funzioni}
          fields={fields}
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="sequ_k_funzione"
          selectRow={selectRows(setFunzioniScelte, funzioniScelte, funzioniRimosse,
            setFunzioniRimosse,
            funzioniAggiunte,
            setFunzioniAggiunte)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

export default ModaleRicercaFunzioniModifica;
