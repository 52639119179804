/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {
  Breadcrumb, Button, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../components/Shared/Table';
import fields from './fieldsTable';
import ModaleNuovaCartella from '../../../components/Repository/ModaleNuovaCartella';
import repositoryService from '../../../services/repositoryService';
import history from '../../../utils/history';
import ModaleRinominaCartella from '../../../components/Repository/ModaleRinominaCartella';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import { Context } from '../../../utils/Context';
import ModaleSposta from '../../../components/Repository/ModaleSposta';
import FiltriRicercaRepository from '../../../components/Repository/FiltriRicerca';
import { controlla } from '../../../utils/verificaPermessi';
import controllaRepository from '../../../utils/verificaPermessiRepository';

const Repository = () => {
  const { path } = useParams();
  const [context, setContext] = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [modalRinominaShow, setModalRinominaShow] = useState(false);
  const [modalSpostaShow, setModalSpostaShow] = useState(false);
  const [modalEliminaFileShow, setModalEliminaFileShow] = useState(false);
  const [data, setData] = useState([]);
  const [pathRepo, setPathRepo] = useState('/main');
  const [cartellaAttuale, setCartellaAttuale] = useState({});
  const [breadcumbsItem, setBreadcumbsItem] = useState([]);
  const [cartellaOrFile, setCartellaOrFile] = useState('');
  const [configurazione, setConfigurazione] = useState({
    dimensioneMassima: 0,
    metadati: [{}],
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    profonditaStorica: false,
    nome: '',
    sottocartelle: false,
    metadati: {},
  });
  const [cartella, setCartella] = useState({});
  const [file, setFile] = useState({});
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);

  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function handleStateModaleEliminaFile() {
    setModalEliminaFileShow(!modalEliminaFileShow);
  }

  async function getElementiByPath(offset, limit, page) {
    try {
      const response = await repositoryService.getElementiPath(filtri, offset, limit,
        encodeURIComponent(pathRepo));
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.nCartelle + response.nFiles,
      }));
      setData();
      setTimeout(() => {
        setData([...response.cartelle, ...response.files]);
      }, 100);
      setCartellaAttuale(response.cartellaAttuale);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function getConfigurazione() {
    await repositoryService.getConfigurazione()
      .then((res) => {
        setConfigurazione(res);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function eliminaCartella() {
    await repositoryService.eliminaCartella(cartella._id)
      .then(() => {
        setContext((res) => ({
          ...context, openConferma: true, testo: res.message,
        }));
        getElementiByPath(0, 5, 1);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  async function eliminaFile() {
    await repositoryService.eliminaFile(file._id)
      .then(() => {
        setContext((res) => ({
          ...context, openConferma: true, testo: res.message,
        }));
        getElementiByPath(0, 5, 1);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleEliminaFile();
      });
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }

    getElementiByPath(currentIndex, sizePerPage, page);
  };

  useEffect(() => {
    setPathRepo(`/${decodeURIComponent(path)}`);
  }, [history.location.pathname]);

  function calcolaBreadcumbs() {
    const tokens = pathRepo.split('/').slice(2);
    const items = tokens.map((el) => ({ text: el }));

    let pathBase = 'main';
    for (let i = 0; i < items.length; i += 1) {
      pathBase += `/${tokens[i]}`;
      items[i].to = encodeURIComponent(pathBase);
    }
    setBreadcumbsItem(items);
  }

  useEffect(() => {
    getElementiByPath(0, 5, 1);
    calcolaBreadcumbs();
  }, [pathRepo]);

  useEffect(() => {
    getConfigurazione();
  }, []);

  return (
    <>
      <TitlePage
        title="Repository"
        subtitle="Questa sezione ti permette di gestire i file caricati."
        showRefresh
        functionRefresh={() => {
          getElementiByPath(0, 5, 1);
        }}
      />
      <FiltriRicercaRepository
        filtri={filtri}
        getElementiByPath={getElementiByPath}
        onChange={onChangeFiltri}
        configurazione={configurazione}
      />
      <Row className="justify-content-end">
        {(pathRepo === '/main' && controlla('W1')) || (controlla('X28') && controllaRepository('M', cartellaAttuale))
          ? (
            <Col className="col-auto">
              <Button variant="primary" onClick={() => setModalShow(true)}>
                <FontAwesomeIcon aria-hidden="true" icon="plus" />
                {' '}
                Nuova cartella
              </Button>
            </Col>
          )
          : null}
        {(pathRepo === '/main' && controlla('W1')) || (controlla('X28') && controllaRepository('M', cartellaAttuale))
          ? (
            <Col className="col-auto">
              <Button onClick={() => history.push(`/area-riservata/repository/upload/${encodeURIComponent(pathRepo)}`)}>
                <FontAwesomeIcon aria-hidden="true" icon="upload" />
                {' '}
                Nuovo documento
              </Button>
            </Col>
          ) : null}
        {controlla('X30') && (
        <Col className="col-auto">
          <Button variant="grey" onClick={() => history.push('/area-riservata/repository/configurazione')}>
            <FontAwesomeIcon aria-hidden="true" icon="cogs" />
            {' '}
            Configurazione
          </Button>
        </Col>
        )}
      </Row>
      <Row className="m-1">
        <Breadcrumb>
          <li className="breadcrumb-item">
            <Link to="/area-riservata/repository/main">
              Home
            </Link>
          </li>
          {breadcumbsItem.map((el, index) => (
            <li key={el.to} className={index + 1 === breadcumbsItem.length ? 'breadcrumb-item active' : 'breadcrumb-item'}>
              {index + 1 === breadcumbsItem.length
                ? el.text
                : (
                  <Link to={el.to}>
                    {el.text}
                  </Link>
                )}
            </li>
          ))}
        </Breadcrumb>
      </Row>
      {data && fields ? (
        <Table
          data={data}
          fields={fields(setModalRinominaShow, setCartella, handleStateModaleConferma,
            handleStateModaleEliminaFile, setFile, setModalSpostaShow,
            setCartellaOrFile, pathRepo, cartellaAttuale)}
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="_id"
          striped
          notBordered
        />

      ) : 'Caricamento...'}
      <ModaleNuovaCartella
        show={modalShow}
        setModalShow={setModalShow}
        pathRepo={pathRepo}
        getElementiByPath={getElementiByPath}
      />
      <ModaleRinominaCartella
        show={modalRinominaShow}
        setModalShow={setModalRinominaShow}
        pathRepo={pathRepo}
        cartella={cartella}
        setCartella={setCartella}
        getElementiByPath={getElementiByPath}
      />
      {modalSpostaShow && (
      <ModaleSposta
        show={modalSpostaShow}
        setModalShow={setModalSpostaShow}
        pathRepo={pathRepo}
        setPathRepo={setPathRepo}
        breadcumbsItem={breadcumbsItem}
        cartellaOrFile={cartellaOrFile}
        cartella={cartella}
        file={file}
      />
      )}
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà la cartella ${cartella.nome}`}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={eliminaCartella}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà il file ${file.nome}`}
        show={modalEliminaFileShow}
        handleClose={handleStateModaleEliminaFile}
        handleOk={eliminaFile}
      />
    </>
  );
};

export default Repository;
