import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getUtentiByCanali(filtri, offset, limit) {
  const filters = { ...filtri, offset, limit };
  const params = createQueryString(filters);
  return api.get(`${accreditamentoUrl}/gestione-canale/utenti-visibili?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getIdentitaByCanali() {
  return api.get(`${accreditamentoUrl}/gestione-canale/identita-visibili`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const canaliService = {
  getUtentiByCanali,
  getIdentitaByCanali,
};

export default canaliService;
