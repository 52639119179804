import React from 'react';

const HomePage = () => (
  <>
    <div className="m-5">
      <h1>PoInt</h1>
      <p className="text-muted h6" style={{ lineHeight: 2 }}>CHE COS&apos;È</p>
      <p className="text-justify">Ambitioni dedisse scripsisse iudicaretur. Cras mattis iudicium purus sit amet fermentum. Donec sed odio operae, eu vulputate felis rhoncus. Praeterea iter est quasdam res quas ex communi. At nos hinc posthac, sitientis piros Afros. Petierunt uti sibi concilium totius Galliae in diem certam indicere. Cras mattis iudicium purus sit amet fermentum.</p>
    </div>

  </>
);

export default HomePage;
