import {
  Card, Form,
} from 'react-bootstrap';

function FormEntita({
  entita, handleStateEntita, listaEntita,
}) {
  function savePage(e) {
    handleStateEntita(e.target.value);
  }

  return (
    <Card className="mb-3">
      <Card.Body>
        <p className="h6">Inserisci qui l&apos;entità</p>
        <Form.Group className="mb-3">
          <Form.Label>Scegli Entità</Form.Label>
          <Form.Select
            onChange={(e) => { savePage(e); }}
            value={entita}
          >
            <option value=""> </option>
            {listaEntita.map((element) => (
              <option value={element.sequ_k_entita} key={element.sequ_k_entita}>
                {element.desc_nome}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default FormEntita;
