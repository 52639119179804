import React from 'react';
import {
  Accordion,
  Form,
} from 'react-bootstrap';

const FormLingue = ({
  versione, onChange, listaLingue, disabled,
}) => (
  <Accordion className="mb-3">
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        Lingue del sito
      </Accordion.Header>
      <Accordion.Body>
        <strong>
          Si possono scegliere le lingue del sito.
        </strong>
        {listaLingue?.map((el) => (
          <Form.Check
            key={el.codi_k_tipo_lingua}
            label={el.desc_lingua}
            type="checkbox"
            name="lista-lingue"
            // eslint-disable-next-line prefer-template
            id={'lista-lingue-' + el.codi_k_tipo_lingua}
            value={el.codi_k_tipo_lingua}
            onChange={onChange}
            disabled={el.flag_obbligatorio || disabled}
            checked={!!versione.lingue
              .find((elem) => elem.fk_codi_tipo_lingua === el.codi_k_tipo_lingua
              || el.codi_k_tipo_lingua === elem)}
          />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
);

export default FormLingue;
