import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Col, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../../components/Shared/Table';
import contenutiDinamiciService from '../../../../services/contenutiDinamiciService';
import sitiWebService from '../../../../services/sitiWebService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';
import fields from './fieldsTableContenutiHome';

const GestioneContenutiDinamici = () => {
  const [context, setContext] = useContext(Context);
  const [treeData, setTreeData] = useState([]);
  const [listaContenutiHomepage, setListaContenutiHomepage] = useState([]);

  async function getAlbero() {
    try {
      const response = await sitiWebService.getAlbero();
      setTreeData(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function getContenutiHomepage() {
    try {
      const response = await contenutiDinamiciService.getContenutiHomePage();
      setListaContenutiHomepage(response.contenuti);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getAlbero();
    getContenutiHomepage();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione sezioni dinamiche"
        subtitle="È possibile gestire le sezioni dinamiche del sito web."
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <Row xs={1} md={5} className="g-4">
        {treeData
          .filter((el) => el.flagDinamico)
          .map((el) => (
            <Col key={el.id}>
              <Card>
                <Card.Body>
                  <Card.Title><Link to={`/area-riservata/siti-web/contenuti-dinamici/${el.text}`}>{el.text}</Link></Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
      <Table
        title="Elenco contenuti in homepage"
        data={listaContenutiHomepage}
        fields={fields()}
        keyField="_id"
      />
    </>
  );
};

export default GestioneContenutiDinamici;
