/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Card, Col, Row, Button, ButtonGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatoDataEOra } from '../../utils/formatData';
import { getItem } from '../../utils/storage';
import { controlla } from '../../utils/verificaPermessi';

function CardGroup({
  sections, title, functionDelete, functionEdit, type, functionClose,
}) {
  return (

    <div className="mt-3">
      <h2>{title}</h2>
      <Row lg={1} xs={1} className="g-4 mt-2 mb-2">
        {sections.map((el) => (
          <Col key={el._id}>
            <Card>
              <Card.Header>
                <Row>
                  <Col lg={10}>
                    <h3>
                      {type === 'sezione'
                        ? <Link to={`/area-riservata/forum/messaggi?id=${el._id}`}>{el?.titolo}</Link>
                        : el?.titolo }
                      {' '}
                      {el.stato === 'chiusa'
                        ? <FontAwesomeIcon aria-hidden="true" icon="lock" className="h6" />
                        : null}
                    </h3>
                    <div>
                      <strong>Descrizione: </strong>
                      {el.descrizione}
                      <br />
                      Creata da
                      <strong>
                        {' '}
                        {el?.creatore?.nome}
                        {' '}
                        {el?.creatore?.cognome}
                      </strong>
                      {' '}
                      il
                      {' '}
                      <strong>{formatoDataEOra(el.dataCreazione)}</strong>
                    </div>
                  </Col>
                  <Col lg={2}>
                    {functionEdit || functionDelete
                      ? (
                        <ButtonGroup className="float-end" aria-label={`Azioni disponibili per la sezione ${el.titolo}`}>
                          {/* Verifica se l'utente è il creatore
                          oppure se l'utente è responsabile */}
                          {(el.stato === 'aperta' && !el.dataFineValidita)
                          && ((controlla('X47') && el?.creatore?.identificativo === (JSON.parse(getItem('user'))).identificativo)
                          || controlla('W2'))
                            ? (
                              <Button
                                className="me-2"
                                onClick={() => functionEdit(el._id, el.titolo, el.descrizione)}
                                title={`Modifica la sezione ${el.titolo}`}
                              >
                                <FontAwesomeIcon aria-hidden="true" icon="edit" />
                              </Button>
                            ) : null}
                          {/* Essendo un'autorizzazione che ha
                             solo il responsabile si verifica solo il permesso del bottone */}
                          {(el.stato === 'aperta' && !el.dataFineValidita) && controlla('X49')
                            ? (
                              <Button
                                className="me-2"
                                onClick={() => functionClose(el._id, el.titolo, el.descrizione)}
                                title={`Chiudi la sezione ${el.titolo}`}
                              >
                                <FontAwesomeIcon aria-hidden="true" icon="lock" />
                              </Button>
                            ) : null}
                          {/* Verifica se l'utente è il creatore
                          oppure se l'utente è responsabile */}
                          {!el.dataFineValidita
                          && ((controlla('X48') && el?.creatore?.identificativo === (JSON.parse(getItem('user'))).identificativo)
                           || controlla('W2'))
                            ? (
                              <Button
                                variant="danger"
                                onClick={() => functionDelete(el._id)}
                                title={`Elimina la sezione ${el.titolo}`}
                              >
                                <FontAwesomeIcon aria-hidden="true" icon="trash" />
                              </Button>
                            ) : null}
                        </ButtonGroup>
                      )
                      : null }
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <strong>Risposte: </strong>
                  {el?.numeroMessaggi > 1 ? el.numeroMessaggi - 1 : 0}
                  <br />
                  <strong>Stato: </strong>
                  {el.stato}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

  );
}

export default CardGroup;
