import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getUtenti(filtri, offset, limit) {
  const filters = { ...filtri, offset, limit };
  const params = createQueryString(filters);
  return api.get(`${accreditamentoUrl}/gestione-utente?${params}`, { withCredentials: true })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getUtenteById(id, filters) {
  const params = createQueryString(filters);
  return api.get(`${accreditamentoUrl}/gestione-utente/${id}?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaUtente(utente) {
  return api.put(`${accreditamentoUrl}/gestione-utente/${utente.id}`, utente)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getUtenteByIdUtente() {
  return api.get(`${accreditamentoUrl}/gestione-utente/utente`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaUtentePerUtente(utente) {
  return api.put(`${accreditamentoUrl}/gestione-utente/utente`, utente)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const utenteService = {
  getUtenti,
  getUtenteById,
  modificaUtente,
  getUtenteByIdUtente,
  modificaUtentePerUtente,
};

export default utenteService;
