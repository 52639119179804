import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import { fieldsProfonditaStorica, fieldsNoProfonditaStorica } from '../../../../../components/Ruoli/fieldsTableFunzioniModifica';
import { fieldsProfonditaStoricaIdentita, fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Ruoli/fieldsTableIdentita';
import FormInformazioni from '../../../../../components/Ruoli/FormInformazioni';
import Table from '../../../../../components/Shared/Table';
import ruoliService from '../../../../../services/ruoliService';
import tagRuoliService from '../../../../../services/tagRuoliService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';

function FormDettaglioRuolo() {
  const { idRuolo } = useParams();
  const [context, setContext] = useContext(Context);
  const [tagsRuolo, setTagsRuolo] = useState([]);
  const [nuovoRuolo, setNuovoRuolo] = useState({
    sequ_k_ruolo_dett: null,
    fk_sequ_ruolo: null,
    desc_nome_ruolo: '',
    desc_ruolo: '',
    desc_note_ruolo: '',
    fk_sequ_tag_ruolo: 0,
    flag_protetto: false,
    flag_default: false,
    funzioni: [],
  });
  const [filtriRuolo, setFiltriRuolo] = useState({
    profondita_storica_funzioni: false,
    profondita_storica_identita: false,
  });
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);

  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function getTagsRuolo() {
    const { data } = await tagRuoliService.getTagRuoli();
    setTagsRuolo(data);
  }
  async function getRuolo() {
    const response = await ruoliService.getRuoloById(idRuolo, filtriRuolo);
    setNuovoRuolo(response);
  }

  async function eliminaRuolo() {
    ruoliService.eliminaRuolo(nuovoRuolo.sequ_k_ruolo)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-ruolo');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  function onChangeFiltriRuolo(e) {
    const { id } = e.target;
    const { value } = e.target;
    setFiltriRuolo({
      ...filtriRuolo,
      [id]: value,
    });
  }

  useEffect(() => {
    getTagsRuolo();
    getRuolo();
  }, []);

  useEffect(() => {
    getRuolo();
  }, [filtriRuolo.profondita_storica_identita, filtriRuolo.profondita_storica_funzioni]);

  return (
    <>
      <TitlePage
        title="Dettaglio ruolo"
        subtitle="È possibile visualizzare le informazioni del ruolo."
        showIndietro
        functionIndietro={() => {
          history.push('/area-riservata/amministrazione/gestione-ruolo');
        }}
      />
      <FormInformazioni
        nuovoRuolo={nuovoRuolo}
        disabled
        tagsRuolo={tagsRuolo}
      />
      <Row className="mb-3 border-top border-dark">
        {nuovoRuolo.funzioni && (
        <Table
          title="Elenco funzioni associate"
          data={nuovoRuolo.funzioni}
          fields={filtriRuolo.profondita_storica_funzioni === 'true' ? fieldsProfonditaStorica : fieldsNoProfonditaStorica}
          keyField="sequ_k_funzione"
          profonditaStorica={(
            <Form.Group controlId="profondita_storica_funzioni">
              <Form.Label>Visualizza profondità storica</Form.Label>
              <Form.Select
                onChange={onChangeFiltriRuolo}
                value={filtriRuolo.profondita_storica_funzioni}
              >
                <option value="true">Sì</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Group>
        )}
        />
        )}
      </Row>
      <Row className="mb-3 border-top border-dark">
        {nuovoRuolo.identita && (
          <Table
            title="Elenco identità associate"
            data={nuovoRuolo.identita}
            fields={filtriRuolo.profondita_storica_identita === 'true' ? fieldsProfonditaStoricaIdentita : fieldsNoProfonditaStoricaIdentita}
            keyField="sequ_k_identita"
            profonditaStorica={(
              <Form.Group controlId="profondita_storica_identita">
                <Form.Label>Visualizza profondità storica</Form.Label>
                <Form.Select
                  onChange={onChangeFiltriRuolo}
                  value={filtriRuolo.profondita_storica_identita}

                >
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
        )}
          />
        )}
      </Row>
      <br />
      {new URLSearchParams(window.location.search).get('elimina')
        ? (
          <>
            <div className="d-flex justify-content-end">
              <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
                <FontAwesomeIcon aria-hidden="true" icon="trash" />
                {' '}
                Elimina
              </Button>
            </div>
            <ModaleConferma
              labelBottone="Conferma"
              coloreAzione="primary"
              title="Sei sicuro di procedere?"
              body={'L\'operazione eliminerà il ruolo selezionato'}
              show={openCloseModaleConferma}
              handleClose={handleStateModaleConferma}
              handleOk={eliminaRuolo}
            />
          </>
        ) : null }
    </>
  );
}

export default FormDettaglioRuolo;
