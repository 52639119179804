import {
  React, useEffect, useState,
} from 'react';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import Table from '../../../components/Shared/Table';
import fields from './fieldsTable';
import history from '../../../utils/history';
import Filtri from './filtri';
import richiestIdentitaService from '../../../services/richiestaIdentitaService';
import checkStorage from '../../../utils/checkSessionStorage';
import { setItem } from '../../../utils/storage';
import { controlla } from '../../../utils/verificaPermessi';

function GestioneRichiesteIdentitaPerUtente() {
  const initialFilters = {
    codi_stato_richiesta: '',
    data_richiesta_from: '',
    data_richiesta_to: '',
  };
  const [filtri, setFiltri] = useState(initialFilters);
  const [richiesteIdentita, setRichiesteIdentita] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  async function getRichiesteIdentita(offset, limit, page, filters) {
    const filterSession = filters || filtri;
    const response = await richiestIdentitaService
      .getRichiesteIdentitaPerUtente(filterSession, offset, limit);
    setRichiesteIdentita(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getRichiesteIdentita(currentIndex, sizePerPage, page);
  };

  const actions = {

  };

  function refresh() {
    getRichiesteIdentita(0, 5, 1);
  }

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getRichiesteIdentita(offset, 5, storage.page, storage.filtri);
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  return (
    <>
      <Title
        title="Richiesta nuove identità"
        subtitle="È possibile gestire le richieste fatte all'amministratore per nuove identità"
        showRefresh
        functionRefresh={refresh}
        showAdd={controlla('X19') === true}
        functionAdd={() => history.push('/area-riservata/richiesta-identita/aggiungi')}
      />

      <Filtri
        filtri={filtri}
        onChangeFiltri={onChangeFiltri}
        getRichiesteIdentita={getRichiesteIdentita}
      />
      <Table
        title="Gestione richieste identità"
        data={richiesteIdentita}
        fields={fields(actions)}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="sequ_k_richiesta"
      />
    </>
  );
}

export default GestioneRichiesteIdentitaPerUtente;
