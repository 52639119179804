import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import {
  Row, Button,
} from 'react-bootstrap';
import Title from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import FormRichiestaIdentita from '../../../components/RichiesteIdentita/Form';
import richiestIdentitaService from '../../../services/richiestaIdentitaService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import { getItem } from '../../../utils/storage';

function NuovaRichiestaIdentitaPerUtente() {
  const [context, setContext] = useContext(Context);
  const [richiestaIdentita, setRichiestaIdentita] = useState({
    data_richiesta: new Date(),
    desc_nome: (JSON.parse(getItem('user'))).desc_nome,
    desc_cognome: (JSON.parse(getItem('user'))).desc_cognome,
  });
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function nuovaRichiesta() {
    if (richiestaIdentita.desc_msg_richiesta) {
      richiestIdentitaService.nuovaRichiesta(richiestaIdentita)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          history.push('/area-riservata/richiesta-identita');
        })
        .catch((err) => {
          setOpenCloseModaleConferma();
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else {
      setOpenCloseModaleConferma();
      setContext(() => ({
        ...context, open: true, testoErrore: 'Si prega di compilare il messaggio di richiesta', statusCode: 400,
      }));
    }
  }

  async function onChange(e) {
    const { value } = e.target;
    const { id } = e.target;
    setRichiestaIdentita({
      ...richiestaIdentita,
      [id]: value,
    });
  }

  return (
    <>
      <Row id="form-richiesta-identita">
        <Row>
          <Title
            title="Nuova richiesta identità"
            subtitle="È possibile inserire una nuova richiesta"
            showIndietro
            functionIndietro={() => history.push('/area-riservata/richiesta-identita')}
          />
        </Row>
        <FormRichiestaIdentita
          richiestaIdentita={richiestaIdentita}
          onChange={onChange}
          disabledRichiedente
        />
        <>
          <div className="d-flex justify-content-end">
            <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
              <FontAwesomeIcon aria-hidden="true" icon="save" />
              {' '}
              Salva
            </Button>
          </div>
          <ModaleConferma
            labelBottone="Conferma"
            coloreAzione="primary"
            title="Sei sicuro di procedere?"
            body={'L\'operazione invierà una richiesta all\'amministratore'}
            show={openCloseModaleConferma}
            handleClose={handleStateModaleConferma}
            handleOk={nuovaRichiesta}
          />
        </>
      </Row>
    </>
  );
}

export default NuovaRichiestaIdentitaPerUtente;
