import React, { useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Context } from '../../utils/Context';

const statusCode = (code, testo) => {
  if (code === 404) {
    return '404 - risultato non trovato';
  }
  if (code === 401) {
    return '401 - non autorizzato';
  }
  if (code === 400) {
    return testo;
  }
  return 'Errore non gestito';
};

const Alerts = () => {
  const [value, setValue] = useContext(Context);

  useEffect(() => {
    if (value.openConferma) {
      toast.success(value.testo,
        { theme: 'colored', autoClose: 10000, onClose: () => setValue((oldValue) => ({ ...oldValue, openConferma: false, testo: '' })) });
    }
  }, [value.openConferma]);

  useEffect(() => {
    if (value.open) {
      toast.error(statusCode(value.statusCode, value.testoErrore),
        { theme: 'colored', autoClose: false, onClose: () => setValue((oldValue) => ({ ...oldValue, open: false, testoErrore: '' })) });
    }
  }, [value.open]);

  return (
    <>
      <ToastContainer limit={5} role="alert" />
    </>
  );
};

export default Alerts;
