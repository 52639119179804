import React from 'react';
import Button from 'react-bootstrap/Button';
import history from '../../utils/history';

const PageNotFound = () => (
  <>
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
      <div className="inline-block align-middle">
        <h2 className="font-weight-normal lead" id="desc">La pagina richiesta non è stata trovata.</h2>
      </div>
      {' '}
      <Button variant="primary" onClick={() => history.push('/')}>Torna alla home</Button>
    </div>
  </>
);

export default PageNotFound;
