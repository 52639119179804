import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';

const OrdinamentoTabella = ({
  onChangeCampoSelect, listaCampiTabella, aggiungiColonna, ordinamentoTabella,
  eliminaColonna, arrayMove, campoSelect,
}) => (
  <Card className="mb-3" body>
    <p className="h6">Scelta elementi in tabella</p>
    <Row className="py-3 border-bottom">
      <Col md={4}>
        <Form.Group as={Col} lg controlId="lista-campi">
          <Form.Label>Campo</Form.Label>
          <Form.Select onChange={onChangeCampoSelect}>
            <option> </option>
            {listaCampiTabella.map((campo) => (
              <option
                key={campo.nume_posizione}
                value={campo.desc_colonna}
              >
                {campo.desc_colonna}

              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={2}>
        {campoSelect !== '' && (
        <Button onClick={aggiungiColonna}>
          <FontAwesomeIcon icon="plus" aria-hidden="true" />
          Aggiungi
        </Button>
        )}
      </Col>
    </Row>
    <Row className="py-3">
      {ordinamentoTabella.map((el, index) => (
        <Col className="border p-1" key={`tabella-${el.id}`}>
          <strong>{el.campo}</strong>
          <Button
            aria-label="Elimina colonna"
            className="float-end mx-1"
            variant="danger"
            onClick={() => eliminaColonna(el.id)}
          >
            <FontAwesomeIcon icon="trash-alt" aria-hidden="true" />
          </Button>
          {index < ordinamentoTabella.length - 1 ? (
            <Button
              aria-label="Sposta a destra"
              className="float-end mx-1"
              onClick={() => arrayMove(index, index + 1)}
            >
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
          ) : null}
          {index > 0 ? (
            <Button
              aria-label="Sposta a sinistra"
              className="float-end mx-1"
              onClick={() => arrayMove(index, index - 1)}
            >
              <FontAwesomeIcon icon="arrow-left" aria-hidden="true" />
            </Button>
          ) : null}
        </Col>
      ))}
    </Row>
  </Card>
);

export default OrdinamentoTabella;
