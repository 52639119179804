import { useContext, useEffect, useState } from 'react';
import Pagination from '../../../components/Forum/Pagination';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import CardGroup from '../../../components/News/CardGroup';
import FiltriRicercaNews from '../../../components/News/FiltriRicerca';
import newsService from '../../../services/newsService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';
import styles from './css/index.module.scss';

function ListaNews() {
  const [context, setContext] = useContext(Context);
  const [news, setNews] = useState([]);
  const [idNews, setIdNews] = useState();
  // const [newsInEvidenza, setNewsiInEvidenza] = useState([]);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    titolo: '',
    contenuto: '',
    profonditaStorica: false,
  });

  function handleStateModaleConferma(id) {
    setOpenModaleConferma(!openModaleConferma);
    setIdNews(id);
  }

  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  async function getListaNews(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await newsService.getListaNews(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setNews(response?.listaNews || []);
      // setNewsiInEvidenza(response?.newsInEvidenza || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function deleteNews() {
    await newsService.deleteNews(idNews)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getListaNews(0, 5);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  async function editNews(NewsId) {
    history.push(`/area-riservata/news/modifica/${NewsId}`);
  }

  useEffect(() => {
    getListaNews(0, 5);
  }, []);

  return (
    <div>
      <TitlePage
        title="Notizie"
        subtitle="In questa sezione è possibile gestire le notizie della piattaforma."
        showAdd={controlla('X64') === true}
        functionAdd={() => history.push('/area-riservata/news/aggiungi')}
      />
      {/* {newsInEvidenza.length
        ? (
          <>
            <CardGroup
              news={newsInEvidenza}
              title="Notizie in evidenza:"
              type="News"
              functionEdit={editNews}
              functionDelete={handleStateModaleConferma}
              styles={styles}
              showButtons
            />
            <div className="separator border-1 border-bottom mt-2 mb-2" />
          </>
        ) : null} */}
      <FiltriRicercaNews
        filtri={filtri}
        getListaNews={getListaNews}
        onChange={onChangeFiltri}
      />
      <CardGroup
        news={news}
        title=""
        type="News"
        functionEdit={editNews}
        functionDelete={handleStateModaleConferma}
        styles={styles}
        showButtons
      />
      {pagination.totalSize !== 0 ? (
        <Pagination
          functionShow={getListaNews}
          pagination={pagination}
        />
      ) : <p className="mt-4"><strong>Non sono presenti news</strong></p>}
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione eliminerà la news selezionata"
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => deleteNews()}
      />
    </div>
  );
}

export default ListaNews;
