import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';
import { formatoData } from '../../../utils/formatData';

const fields = (user, setUser) => {
  const campi = [
    {
      dataField: 'codi_id_connettore_utente',
      text: 'Identificativo connettore',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'desc_email',
      text: 'Mail',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'desc_tipo_connettore',
      text: 'Tipo connettore',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'data_inizio_validita',
      text: 'Data inizio validità',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e) => formatoData(e),
    },
    {
      dataField: 'data_fine_validita',
      text: 'Data fine validità',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e) => formatoData(e),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '20%' }),
      // text: "Azioni"
      formatter: (e, row) => (

        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
        }}
        >
          {row.azione !== 'elimina' && !row.data_fine_validita
            ? (
              <>
                <FontAwesomeIcon
                  title={`Elimina il collegamento ${row.codi_id_connettore_utente}`}
                  icon={['fas', 'trash-alt']}
                  size="lg"
                  id={`elimina-${row.sequ_k_utente_untrusted}`}
                  style={{ color: themeColors.primary }}
                  onClick={() => {
                    // eslint-disable-next-line no-param-reassign
                    row.azione = 'elimina';
                    setUser({
                      ...user,
                      utenti_untrusted_associati:
                  [...user.utenti_untrusted_associati
                    .filter((el) => el.sequ_k_utente_untrusted !== row.sequ_k_utente_untrusted)],
                    });
                    setTimeout(() => {
                      setUser({
                        ...user,
                        utenti_untrusted_associati:
                    [...user.utenti_untrusted_associati
                      .filter((el) => el.sequ_k_utente_untrusted !== row.sequ_k_utente_untrusted),
                    row],
                      });
                    }, 50);
                  }}
                />

              </>
            )
            : null }
        </div>

      ),
    },
  ];
  return campi;
};

export default fields;
