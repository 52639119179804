import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import FormNomeAlias from '../../../../components/Versioni/FormNomeAlias';
import versioniService from '../../../../services/versioniService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

const DettaglioVersioneAmmTrasparente = () => {
  const { idVersione } = useParams();
  const [context, setContext] = useContext(Context);
  const [versione, setVersione] = useState({
    desc_nome: '',
    desc_alias: '',
  });

  async function getEntita() {
    try {
      const versioneById = await versioniService.getVersione(idVersione);
      setVersione(
        versioneById,
      );
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err.status || 400,
      }));
    }
  }

  useEffect(() => {
    getEntita();
  }, []);

  return (
    <>
      <TitlePage
        title="Visualizzazione versione Amministrazione Trasparente"
        subtitle="È possibile visualizzarem la versione dell'Amministrazione Trasparente."
        showIndietro
        functionIndietro={() => history.goBack()}
        showAvanti
        functionAvanti={() => history.push(`/area-riservata/amm-trasparente/${idVersione}/albero`)}
      />
      <p className="h5">Sezione 1:</p>
      <div className="h6">
        In questa prima sezione, è obbligatorio inserire:
        <ol>
          <li>il nome della versione, ovvero un nome mnemonico che si vuole dare alla versione;</li>
          <li>l&apos;alias del sito, ovvero l&apos;indirizzo di pubblicazione del sito;</li>
        </ol>
      </div>
      <p className="h6"> I campi contrassegnati con * sono obbligatori </p>
      <FormNomeAlias
        versione={versione}
        disabled
      />
    </>
  );
};

export default DettaglioVersioneAmmTrasparente;
