import { getItem } from './storage';

/** Restituisce i filtri e la pagina acquisita dal session storage
 * se esistente. Altrimenti restituisce i filtri e la pgaina di default
 * @param filtri - i filtri di default relativi alla pagina
 * @param setFiltri - Il metodo setState dei filtri
 * @param setPagination - Il metodo set pagination
 * @returns (Object) {filtri: object, page: integer}
 */
const checkStorage = (filtri, setFiltri, setPagination) => {
  const filters = JSON.parse(getItem('filtri'));
  const pag = getItem('page');
  if (filters) {
    setFiltri((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  }
  if (pag) {
    setPagination((prevPag) => ({
      ...prevPag,
      page: pag,
    }));
  }
  return { filtri: filters || filtri, page: parseInt(pag, 10) || 1 };
};

export default checkStorage;
