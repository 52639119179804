import { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Badge, Button, Nav } from 'react-bootstrap';

import logo from '../../images/logo-point.png';

import './index.css';
import { getItem } from '../../utils/storage';
import { client } from '../../utils/matrix';

const agidLoginUrl = process.env.REACT_APP_AGID_LOGIN_URL;
const agidLoginUriPostLogout = process.env.REACT_APP_AGID_LOGIN_REDIRECT_URI_POST_LOGOUT;

export default function Header({ handleOpen, showSidebarButton, areaOspite }) {
  const [notifica, setNotifica] = useState(0);

  useEffect(() => {
    client?.on('event', () => {
      setNotifica(client?.getRooms().filter((el) => el.name === 'Notifiche')?.[0]?.notificationCounts.total);
    });
  }, []);
  return (
    <>
      <header className="it-header-wrapper">
        <div className="it-header-slim-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-slim-wrapper-content">
                  <a className="d-lg-block navbar-brand" href="https://www.agid.gov.it" target="_blank" rel="noreferrer">AGID</a>
                  <div className="header-slim-right-zone">
                    {client ? (
                      <Link
                        to="/area-riservata/notifiche"
                        className="btn btn-full"
                      >
                        <FontAwesomeIcon
                          aria-hidden="true"
                          icon="bell"
                          size="lg"
                        />
                      &nbsp;
                        <Badge bg="secondary">
                          {notifica}
                        </Badge>
                        <span className="sr-only">notifiche non lette</span>
                      </Link>
                    ) : null }

                    <a
                      href={`${agidLoginUrl}/session/end?post_logout_redirect_uri=${agidLoginUriPostLogout}`}
                      className="btn btn-primary btn-full"
                      onClick={() => client?.logout()}
                    >
                      <FontAwesomeIcon
                        aria-hidden="true"
                        icon="sign-out-alt"
                        size="lg"
                      />
                      <span style={{ paddingLeft: '2px' }}>Logout</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="it-header-center-wrapper" style={{ paddingLeft: '18px' }}>
          {showSidebarButton ? (
            <Button id="sidebarToggle" onClick={() => handleOpen()}>
              <FontAwesomeIcon aria-hidden="true" icon="bars" size="lg" />
              <span className="sr-only">Apertura sidebar</span>
            </Button>
          )
            : null}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-center-content-wrapper">
                  <div className="it-brand-wrapper">
                    <Link to="/area-riservata/scelta-identita?from=area-riservata" title="Home">
                      <img
                        alt="Logo AGID"
                        src={logo}
                        className="Logo-img"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {!areaOspite && (
      <Navbar bg="blue" collapseOnSelect expand="sm" variant="grey" role="banner" aria-label="Menu di navigazione">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FontAwesomeIcon aria-hidden="true" icon="ellipsis-v" size="lg" color="white" />
            <span className="sr-only">Apertura opzioni header</span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav" style={{ flexGrow: 0 }}>
            <Nav className="me-auto">
              <Link to="/area-riservata/scelta-identita?from=area-riservata" style={{ color: 'white', textDecoration: 'none' }} className="m-2">
                <FontAwesomeIcon aria-hidden="true" icon={['far', 'address-card']} size="lg" />
                {' '}
                {getItem('identita') ? (JSON.parse(getItem('identita')))?.nomeIdentita : 'Scelta identità'}
              </Link>
              <Link to="/area-riservata/account" style={{ color: 'white', textDecoration: 'none' }} className="m-2">
                <FontAwesomeIcon aria-hidden="true" icon="user-circle" size="lg" />
                {' '}
                Account
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      )}
    </>
  );
}
