import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Button, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ModalNotes from '../../../components/AmmTrasparente/ModalNotes';
import ModalRequest from '../../../components/AmmTrasparente/ModalRequest';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import versioniService from '../../../services/versioniService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import { options } from '../../../utils/tableUtils';
import { controlla } from '../../../utils/verificaPermessi';
import fields from '../siti-web/fieldsTable';

const GestioneVersioniAmmTrasparente = () => {
  const [context, setContext] = useContext(Context);
  const [versioni, setVersioni] = useState([]);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [showModalNote, setShowModalNote] = useState(false);
  const [showModalRequest, setShowModalRequest] = useState(false);
  const [versioneScelta, setVersioneScelta] = useState({});
  const [functionConferma, setFunctionConferma] = useState();
  const [titleModaleConferma, setTitleModaleConferma] = useState('');
  const [functionOk, setFunctionOk] = useState();
  const [titleModaleRequest, setTitleModaleRequest] = useState('');

  const note = useRef('');

  async function getVersioni() {
    try {
      const response = await versioniService.getListaVersioni(true);
      setVersioni(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function clonaVersione(id) {
    await versioniService.clonaVersione(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getVersioni();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setOpenModaleConferma(false);
      });
  }

  function handleClona(id) {
    setTitleModaleConferma('L operazione clonerà la versione selezionata');
    setFunctionConferma(() => () => clonaVersione(id));
    setOpenModaleConferma(true);
  }

  async function richiestaPreview(id) {
    await versioniService.richiestaPreview(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getVersioni();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setOpenModaleConferma(false);
      });
  }

  function handleRichiestaPreview(id) {
    setTitleModaleConferma('L operazione richiederà la preview della versione selezionata');
    setFunctionConferma(() => () => richiestaPreview(id));
    setOpenModaleConferma(true);
  }

  async function richiestaApprovazione(id) {
    await versioniService.richiestaApprovazione(id, note.current.value)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getVersioni();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setShowModalRequest(false);
      });
  }

  function handleStateModaleRichiestaApprovazione(id) {
    setShowModalRequest(true);
    setFunctionOk(() => () => richiestaApprovazione(id));
    setTitleModaleRequest('Richiesta approvazione versione');
  }

  async function accettaApprovazione(id) {
    await versioniService.approvazioneVersione(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getVersioni();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setOpenModaleConferma(false);
      });
  }

  function handleAccettaApprovazione(id) {
    setTitleModaleConferma('L operazione approverà la versione selezionata');
    setFunctionConferma(() => () => accettaApprovazione(id));
    setOpenModaleConferma(true);
  }

  async function rifiutoApprovazione(id) {
    await versioniService.rifiutoApprovazione(id, note.current.value)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getVersioni();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setShowModalRequest(false);
      });
  }

  function handleStateModaleRifiutoApprovazione(id) {
    setFunctionOk(() => () => rifiutoApprovazione(id));
    setTitleModaleRequest('Rifiuto approvazione versione');
    setShowModalRequest(true);
  }

  async function revocaVersione(id) {
    await versioniService.revocaVersione(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getVersioni();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setOpenModaleConferma(false);
      });
  }

  function handleRevoca(id) {
    setTitleModaleConferma('L operazione revocherà la versione selezionata');
    setFunctionConferma(() => () => revocaVersione(id));
    setOpenModaleConferma(true);
  }

  async function pubblicaVersione(id) {
    await versioniService.pubblicaVersione(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getVersioni();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setOpenModaleConferma(false);
      });
  }

  function handlePublish(id) {
    setTitleModaleConferma('L operazione pubblicherà la versione selezionata');
    setFunctionConferma(() => () => pubblicaVersione(id));
    setOpenModaleConferma(true);
  }

  async function deleteVersione(id) {
    await versioniService.eliminaVersione(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getVersioni();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        setOpenModaleConferma(false);
      });
  }

  function handleDelete(id) {
    setTitleModaleConferma(`L operazione eliminerà la versione con id ${id}`);
    setFunctionConferma(() => () => deleteVersione(id));
    setOpenModaleConferma(true);
  }

  function visualizzaVersione(id) {
    history.push(`/area-riservata/amm-trasparente/${id}/dettaglio`);
  }

  function modificaVersione(id) {
    history.push(`/area-riservata/amm-trasparente/${id}/modifica`);
  }

  useEffect(() => {
    getVersioni();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione versioni Amministrazione Trasparente"
        subtitle="In questa sezione puoi visualizzare, creare e gestire le versioni del'amministrazione trasparente della tua struttura."
        showAdd={controlla('X85') === true}
        showRefresh
        functionAdd={() => history.push('/area-riservata/amm-trasparente/crea')}
        functionRefresh={getVersioni}
      />
      <Col>
        <p className="fw-bold fs-3">
          Elenco versioni
          {' '}
          {controlla('X101') && (
            <Button onClick={() => history.push('/area-riservata/amm-trasparente/entita')}>
              Contenuti dinamici
            </Button>
          )}
        </p>
      </Col>
      <BootstrapTable
        columns={fields(setVersioneScelta, setShowModalNote, handleRichiestaPreview,
          handleDelete, visualizzaVersione, modificaVersione, handleClona,
          handleRevoca, handlePublish, handleStateModaleRichiestaApprovazione,
          handleAccettaApprovazione, handleStateModaleRifiutoApprovazione)}
        data={versioni}
        keyField="sequ_k_versione"
        pagination={paginationFactory(options)}
      />
      <ModalNotes
        showModalNote={showModalNote}
        setShowModalNote={setShowModalNote}
        versioneScelta={versioneScelta}
      />

      <ModalRequest
        showModalRequest={showModalRequest}
        setShowModalRequest={setShowModalRequest}
        request={note}
        titleModale={titleModaleRequest}
        functionInvia={functionOk}
      />

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={titleModaleConferma}
        show={openModaleConferma}
        handleClose={() => setOpenModaleConferma(false)}
        handleOk={functionConferma}
      />
    </>
  );
};

export default GestioneVersioniAmmTrasparente;
