/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { globalVariables } from './refreshToken';
import history from './history';
import { getItem } from './storage';
import permessoService from '../services/permessoService';

const authProviderUrl = process.env.REACT_APP_AUTH_PROVIDER_URL;

const api = axios.create({
  timeout: 10000,
  withCredentials: true,
});

// Interceptor Axios
api.interceptors.request.use((config) => {
// Azioni effettuate prima dell'invio della richiesta
  if (document) {
    document.getElementById('spinner').style.display = 'block';
  }
  return config;
},
(error) => {
  // Handler errore request
  if (document) {
    document.getElementById('spinner').style.display = 'none';
  }
  Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use((response) => {
  // Azioni effettuate dopo una risposta nel range 2xx
  if (document) {
    document.getElementById('spinner').style.display = 'none';
  }
  return response;
},
async (error) => {
  // Azioni effettuate dopo una risposta fuori dal range 2xx
  const originalRequest = error.config;
  const code = error.response ? parseInt(error.response.status, 10) : -1;
  try {
    if (code === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const response = await axios.post(`${`${authProviderUrl}/auth-provider/nuovi-token`}`, { refreshToken: globalVariables.refreshToken }, { withCredentials: true });
      globalVariables.refreshToken = response.data.refresh_token;
      await permessoService.getPermessiByIdentita((JSON.parse(getItem('identita')).idIdentita));
      return await axios(originalRequest, { withCredentials: true });
    }
    throw new Error();
  } catch (err) {
    if (code === 403 && originalRequest._retry) {
      history.push('/');
    }
    return Promise.reject(error);
  } finally {
    if (document) {
      document.getElementById('spinner').style.display = 'none';
    }
  }
});

export default api;
