import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';
import { formatoData } from '../../../../utils/formatData';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';

const fieldsProfonditaStorica = [
  {
    dataField: 'sequ_k_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_tipo_identita',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome_tag_identita',
    text: 'Tag',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    // text: "Azioni"
    formatter: (e, row) => (

      <div>
        <>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            title={`Visualizza l'identità ${row.desc_nome_identita}`}
            size="lg"
            id={`visualizza-${row.sequ_k_identita}`}
            style={{ color: themeColors.primary }}
            onClick={() => { history.push(`/area-riservata/amministrazione/gestione-identita/${row.sequ_k_identita}/dettaglio`); }}
          />

          {controlla('X3') && !row.data_fine_validita && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title={`Modifica l'identità ${row.desc_nome_identita}`}
            id={`modifica-${row.sequ_k_identita}`}
            style={{ color: themeColors.primary }}
            onClick={() => { history.push(`/area-riservata/amministrazione/gestione-identita/${row.sequ_k_identita}/modifica`); }}
          />
          )}

          {controlla('x4') && !row.flag_protetto && !row.data_fine_validita ? (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'trash-alt']}
              size="lg"
              title={`Elimina l'identità ${row.desc_nome_identita}`}
              id={`elimina-${row.sequ_k_identita}`}
              style={{ color: themeColors.primary }}
              onClick={() => { history.push(`/area-riservata/amministrazione/gestione-identita/${row.sequ_k_identita}/dettaglio?elimina=true`); }}
            />
          ) : null }
        </>
      </div>

    ),
  },
];

const fieldsNoProfonditaStorica = [
  {
    dataField: 'sequ_k_identita',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_tipo_identita',
    text: 'Tipo',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_nome_tag_identita',
    text: 'Tag',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    // text: "Azioni"
    formatter: (e, row) => (

      <div>
        <>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            title={`Visualizza l'identità ${row.desc_nome_identita}`}
            size="lg"
            id={`visualizza-${row.sequ_k_identita}`}
            style={{ color: themeColors.primary }}
            onClick={() => { history.push(`/area-riservata/amministrazione/gestione-identita/${row.sequ_k_identita}/dettaglio`); }}
          />

          {controlla('X3') && !row.data_fine_validita && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title={`Modifica l'identità ${row.desc_nome_identita}`}
            id={`modifica-${row.sequ_k_identita}`}
            style={{ color: themeColors.primary }}
            onClick={() => { history.push(`/area-riservata/amministrazione/gestione-identita/${row.sequ_k_identita}/modifica`); }}
          />
          )}

          {controlla('X4') && !row.flag_protetto && !row.data_fine_validita && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'trash-alt']}
              size="lg"
              title={`Elimina l'identità ${row.desc_nome_identita}`}
              id={`elimina-${row.sequ_k_identita}`}
              style={{ color: themeColors.primary }}
              onClick={() => { history.push(`/area-riservata/amministrazione/gestione-identita/${row.sequ_k_identita}/dettaglio?elimina=true`); }}
            />
          )}
        </>
      </div>

    ),
  },
];

export { fieldsProfonditaStorica, fieldsNoProfonditaStorica };
