/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useEffect, useState } from 'react';
import {
  Tree,
  getBackendOptions,
  MultiBackend,
} from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import styles from './App.module.css';
import CustomNode from '../../../../components/Versioni/CustomNode';
import sitiWebService from '../../../../services/sitiWebService';
import { Context } from '../../../../utils/Context';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';

export default function VisualizzazioneAlbero() {
  const [context, setContext] = useContext(Context);
  const [treeData, setTreeData] = useState([]);
  const { idVersione } = useParams();

  async function getAlbero() {
    try {
      const response = await sitiWebService.getAlbero(idVersione);
      setTreeData(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getAlbero();
  }, []);

  return (
    <>
      <TitlePage
        title="Gestione contenuti siti web"
        subtitle="È possibile gestire i contenuti della struttura del sito web."
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <p className="h5">Sezione 2:</p>
      <p className="h6">
        In questa seconda sezione, è possibile gestire i menu ed i sotto-menu
        del sito web selezionando la voce che si desidera editare
        <br />
        * Le voci dell&apos;albero non cliccabili non sono compilabili in questo momento.
        È possibile aggiungerle selezionando l&apos;icona
        <FontAwesomeIcon aria-hidden="true" icon="database" />
        <span className="sr-only">Contenuti dinamici</span>
        mediante la quale si accede alla gestione dei contenuti dinamici.
      </p>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Tree
          classes={{
            root: styles.treeRoot,
            draggingSource: styles.draggingSource,
          }}
          tree={treeData}
          rootId={0}
          // eslint-disable-next-line no-return-assign
          render={(node, options) => (
            <>
              <CustomNode
                pathLink={`/area-riservata/siti-web/${idVersione}/albero/template-statico/${node.sequ_k_menu_disponibile}?nomeMenu=${node.text}`}
                node={node}
                {...options}
                idVersione={idVersione}
              />
            </>
          )}
        />
      </DndProvider>
    </>
  );
}
