import React from 'react';
import TitlePage from '../components/GestisciUtenti/TitoloPagina';

const DichiarazioneAccessibilita = () => (
  <>
    <TitlePage title="Dichiarazione di accessibilità" subtitle="redatta il 23.09.2021" />
  </>
);

export default DichiarazioneAccessibilita;
