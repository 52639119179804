/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion, Button, ButtonGroup, Card, useAccordionButton,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FiltriRicercaFaq from '../../../components/Faq/FiltriRicerca';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import RenderHTML from '../../../components/Shared/RenderHTML';
import faqService from '../../../services/faqService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';
import faqs from './data';

function GestioneFaq() {
  const [argomenti, setArgomenti] = useState(null);
  const [idFaq, setIdFaq] = useState();
  const [listaFaq, setListaFaq] = useState(null);
  const [showModaleElimina, setShowModaleElimina] = useState(false);
  const [context, setContext] = useContext(Context);
  const [filtri, setFiltri] = useState({
    domanda: '',
    risposta: '',
    argomento: '',
    profonditaStorica: false,
  });

  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function handleCloseModaleElimina() { setShowModaleElimina(!showModaleElimina); }

  async function getListaFaq(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await faqService.getListaFaq(filtri, offset, limit);
      setListaFaq(response?.listaFaq || []);
      const argomentiTemp = [];
      response?.listaFaq.forEach((faq) => {
        if (!argomentiTemp.includes(faq.argomento)) {
          argomentiTemp.push(faq.argomento);
        }
      });
      setArgomenti(argomentiTemp);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function deleteFaq() {
    await faqService.deleteFaq(idFaq)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getListaFaq(0, 10);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleCloseModaleElimina();
      });
  }

  useEffect(() => {
    getListaFaq(0, 10);
  }, []);

  return (
    <>
      <Title
        title="Gestione FAQ"
        subtitle="In questa sezione puoi gestire le FAQ (Frequently Asked Question)."
        showRefresh
        showAdd={controlla('X68') === true}
        functionRefresh={() => {
          getListaFaq(0, 10);
        }}
        functionAdd={() => {
          history.push('/area-riservata/gestione-faq/aggiungi');
        }}
      />
      <FiltriRicercaFaq
        filtri={filtri}
        getListaFaq={getListaFaq}
        onChange={onChangeFiltri}
      />
      <br />
      <Accordion>
        {argomenti && argomenti.map((argomento) => (
          <Accordion.Item eventKey={argomento} key={argomento}>
            <Accordion.Header>{argomento}</Accordion.Header>
            <Accordion.Body>
              <Accordion>
                {
                  listaFaq && listaFaq.filter((el) => el.argomento === argomento).map((faq) => (
                    <Accordion.Item eventKey={faq.id} key={faq.id}>
                      <Accordion.Header>
                        {faq.domanda}
                        &nbsp;&nbsp;
                        <ButtonGroup className="float-end" aria-label={`Azioni disponibili per la FAQ ${faq.domanda}`}>
                          {controlla('X70') && !faq.dataFineValidita
                          && (
                          <Button
                            className="me-2"
                            onClick={() => history.push(`/area-riservata/gestione-faq/modifica/${faq._id}`)}
                            title={`Modifica la FAQ ${faq.domanda}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="edit" />
                          </Button>
                          )}
                          {controlla('X71') && !faq.dataFineValidita
                          && (
                          <Button
                            variant="danger"
                            onClick={() => { handleCloseModaleElimina(); setIdFaq(faq._id); }}
                            title={`Elimina la FAQ ${faq.domanda}`}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="trash" />
                          </Button>
                          )}
                        </ButtonGroup>
                      </Accordion.Header>
                      <Accordion.Body>
                        <RenderHTML html={faq.risposta} />
                      </Accordion.Body>
                    </Accordion.Item>

                  ))
                }
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="danger"
        title="Sei sicuro di procedere?"
        body="L'operazione eliminerà la FAQ da te scelta"
        show={showModaleElimina}
        handleClose={handleCloseModaleElimina}
        handleOk={deleteFaq}
      />
    </>
  );
}

export default GestioneFaq;
