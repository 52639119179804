/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CardSezione from '../../../../../components/ContenutiDinamici/CardSezione';
import InEvidenza from '../../../../../components/ContenutiDinamici/InEvidenza';
import InfoPagina from '../../../../../components/ContenutiDinamici/InfoPagina';
import ModaleAnteprima from '../../../../../components/ContenutiDinamici/ModaleAnteprima';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import TabsBar from '../../../../../components/Pagina/TabsBar';
import FormImmagini from '../../../../../components/Versioni/FormImmagini';
import contenutiDinamiciService from '../../../../../services/contenutiDinamiciService';
import galleriaService from '../../../../../services/galleriaService';
import versioniService from '../../../../../services/versioniService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';

const CreaContenutoDinamico = () => {
  const { nome } = useParams();
  const [context, setContext] = useContext(Context);
  const [contenuto, setContenuto] = useState({
    inHomepage: false,
    inEvidenza: false,
    entita: nome,
    contenuti: {
      IT: {
        titolo: '',
        sottotitolo: '',
        descrizione: '',
        sezioni: [],
      },
    },
  });
  const [listaImmagini, setListaImmagini] = useState([]);
  // Lista lingue
  const [listaLingue, setListaLingue] = useState([]);
  // Lingua corrente
  const [currentLingua, setCurrentLingua] = useState('IT');
  const [showTextEditor, setShowTextEditor] = useState(true);
  const [immagine, setImmagine] = useState({
    nomeImmagine: '',
    fileCaricato: null,
    alt: '',
  });
  const [showModaleAnteprima, setShowModaleAnteprima] = useState(false);

  function handleStateModaleAnteprima() {
    setShowModaleAnteprima(!showModaleAnteprima);
  }

  const onUpload = (e) => {
    const { id, value, files } = e.target;
    setImmagine({
      ...immagine,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  const onChangeAltImmagine = (e) => {
    const { id, value } = e.target;
    setImmagine({
      ...immagine,
      [id]: value,
    });
  };

  async function getGalleria() {
    try {
      const imgDisponibili = await galleriaService.getGalleria();
      setListaImmagini(imgDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function uploadImmagine() {
    try {
      const response = await galleriaService.uploadImmagine(immagine);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
      await getGalleria();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function addSezione() {
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          sezioni: [...contenuto.contenuti[currentLingua].sezioni, {
            titolo: `Sezione ${contenuto.contenuti[currentLingua].sezioni.length}`,
            index: contenuto.contenuti[currentLingua].sezioni.length,
            tabella: [],
          }],
        },
      },
    });
  }

  function addRigaSezione(indexSezione) {
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          sezioni: [
            ...contenuto.contenuti[currentLingua].sezioni.map((el) => {
              if (el.index === indexSezione) {
                return {
                  ...el,
                  tabella: [...el.tabella, {
                    proprieta: '',
                    descrizione: '',
                    index: contenuto.contenuti[currentLingua].sezioni[indexSezione].tabella.length,
                  }],
                };
              }
              return el;
            }),

          ],
        },
      },
    });
  }

  function arrayMove(oldIndex, newIndex) {
    const arr = contenuto.contenuti[currentLingua].sezioni;
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          sezioni: arr,
        },
      },
    });
  }

  async function getLingue() {
    try {
      const response = await versioniService.getLingueDisponibili();
      setListaLingue(response.map((el) => el.codi_lingua));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function aggiungiContenutoDinamico() {
    try {
      const response = await contenutiDinamiciService.aggiungiContenutoDinamico(contenuto);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChange(e) {
    const {
      id, value, checked, type,
    } = e.target;
    setContenuto({
      ...contenuto,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  function onChangeSezione(index, e) {
    const {
      id, value,
    } = e.target;

    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          sezioni: [
            ...contenuto.contenuti[currentLingua].sezioni.map((el) => {
              if (el.index === index) {
                return {
                  ...el,
                  [id]: value,
                };
              }
              return el;
            }),

          ],
        },
      },
    });
  }

  function onChangeRichText(indexSezione, indexRiga, content) {
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          sezioni: [
            ...contenuto.contenuti[currentLingua].sezioni.map((el) => {
              if (el.index === indexSezione) {
                return {
                  ...el,
                  tabella: el.tabella.map((riga) => {
                    if (riga.index === indexRiga) {
                      return {
                        ...riga,
                        descrizione: content,
                      };
                    }
                    return riga;
                  }),
                };
              }
              return el;
            }),

          ],
        },
      },
    });
  }

  function onChangeProprieta(indexSezione, indexRiga, e) {
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          sezioni: [
            ...contenuto.contenuti[currentLingua].sezioni.map((el) => {
              if (el.index === indexSezione) {
                return {
                  ...el,
                  tabella: el.tabella.map((riga) => {
                    if (riga.index === indexRiga) {
                      return {
                        ...riga,
                        proprieta: e.target.value,
                      };
                    }
                    return riga;
                  }),
                };
              }
              return el;
            }),

          ],
        },
      },
    });
  }

  function onChangePagina(e) {
    const {
      id, value,
    } = e.target;
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          [id]: value,
        },
      },
    });
  }

  function onChangeDescrizione(text) {
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          descrizione: text,
        },
      },
    });
  }

  function onChangeImmagine(immagineScelta) {
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          fk_logo_mongo_db: immagineScelta._id,
          desc_filename_logo: immagineScelta.nomeFile,
          alt: immagineScelta.alt,
        },
      },
    });
  }

  function eliminaSezione(index) {
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          sezioni: contenuto.contenuti[currentLingua].sezioni.filter((el) => el.index !== index),
        },
      },
    });
  }

  function eliminaRiga(indexSezione, indexRiga) {
    setContenuto({
      ...contenuto,
      contenuti: {
        ...contenuto.contenuti,
        [currentLingua]: {
          ...contenuto.contenuti[currentLingua],
          sezioni: [
            ...contenuto.contenuti[currentLingua].sezioni.map((el) => {
              if (el.index === indexSezione) {
                return {
                  ...el,
                  tabella: el.tabella.filter((riga) => riga.index !== indexRiga),
                };
              }
              return el;
            }),

          ],
        },
      },
    });
  }

  function changeLingua(lingua) {
    if (!contenuto.contenuti[lingua]) {
      setContenuto({
        ...contenuto,
        contenuti: {
          ...contenuto.contenuti,
          [lingua]: {
            titolo: '',
            sottotitolo: '',
            descrizione: '',
            sezioni: [],
          },
        },
      });
    }
    setCurrentLingua(lingua);
    setShowTextEditor(false);
    setTimeout(() => {
      setShowTextEditor(true);
    }, 150);
  }

  useEffect(() => {
    getLingue();
    getGalleria();
  }, []);

  return (
    <>
      <TitlePage
        title="Crea contenuto dinamico"
        subtitle="È possibile creare un contenuto dinamico per il sito web."
        showIndietro
        functionIndietro={() => history.goBack()}
        showSave
        functionSave={aggiungiContenutoDinamico}
      />
      <p>
        Nome sezione:
        {' '}
        <strong>{nome}</strong>
      </p>
      <InEvidenza
        onChange={onChange}
        contenuto={contenuto}
      />
      <Button className="mt-3" onClick={handleStateModaleAnteprima}>
        <FontAwesomeIcon icon="eye" />
        PREVIEW
      </Button>
      <TabsBar
        lingue={listaLingue}
        changeLingua={changeLingua}
        currentLingua={currentLingua}
      />
      <InfoPagina
        onChangePagina={onChangePagina}
        onChangeDescrizione={onChangeDescrizione}
        contenuto={contenuto}
        currentLingua={currentLingua}
        showTextEditor={showTextEditor}
      />
      <FormImmagini
        onChange={onChangeImmagine}
        listaImmagini={listaImmagini}
        versione={contenuto.contenuti[currentLingua]}
        immagine={immagine}
        onUpload={onUpload}
        onChangeAltImmagine={onChangeAltImmagine}
        uploadImmagine={uploadImmagine}
      />
      <Button className="my-3" onClick={addSezione}>
        <FontAwesomeIcon icon="plus" />
        Aggiungi sezione
      </Button>
      <Row xs={1} md={1} className="g-4">
        {contenuto.contenuti[currentLingua].sezioni
          .map((el) => (
            <CardSezione
              key={'sezione-' + el.index}
              sezione={el}
              arrayMove={arrayMove}
              contenuto={contenuto}
              currentLingua={currentLingua}
              onChangeSezione={onChangeSezione}
              addRigaSezione={addRigaSezione}
              onChangeRichText={onChangeRichText}
              onChangeProprieta={onChangeProprieta}
              eliminaRiga={eliminaRiga}
              eliminaSezione={eliminaSezione}
            />
          ))}
      </Row>
      {showModaleAnteprima && (
      <ModaleAnteprima
        contenuto={contenuto.contenuti[currentLingua]}
        show={showModaleAnteprima}
        handleClose={handleStateModaleAnteprima}
      />
      )}
    </>
  );
};

export default CreaContenutoDinamico;
