/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import {
  Button,
  Card, Col, Row, Table,
} from 'react-bootstrap';
import ModaleRisposte from './ModaleRisposte';

function CardGroupStatistiche({
  sezioni, statistiche, numQuestionari,
}) {
  const [showModale, SetShowModale] = useState(false);

  function showTextAnswers() {
    SetShowModale(true);
  }

  function handleClose() {
    SetShowModale(false);
  }

  return (

    <div className="mt-3">
      <Row xs={1} md={1} className="g-4">
        {sezioni ? Object.values(sezioni).map((sezione) => (
          <div>
            <Col key={sezione.titolo}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    <strong>{sezione.titolo}</strong>
                  </Card.Title>
                  <p>
                    <strong>Descrizione:</strong>
                    {' '}
                    {sezione.descrizione}
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>Domanda</th>
                  <th>Tipologia</th>
                  <th>Possibili risposte</th>
                  <th>Statistica risposte</th>
                </tr>
              </thead>
              <tbody>
                {statistiche
                  ? Object.values(sezione?.template?.pages?.[0]?.elements).map((domanda) => (
                    <tr>
                      <td>
                        {domanda.name}
                      </td>
                      <td>
                        {domanda.type}
                      </td>
                      <td>
                        {domanda.choices ? domanda.choices.map((opzione) => (
                          <div>
                            <p>
                              {opzione.text}
                              {' '}
                              (
                              {opzione.value}
                              )
                            </p>
                          </div>
                        )) : null}
                      </td>
                      <td>
                        {domanda.type === 'text' ? (
                          <div>
                            <Button variant="grey" onClick={showTextAnswers}>
                              {' '}
                              VISUALIZZA RISPOSTE
                              {' '}
                            </Button>
                            <ModaleRisposte
                              showModale={showModale}
                              handleClose={handleClose}
                              title={domanda.name}
                              body={statistiche?.[sezione.titolo]?.[domanda.name]}
                            />
                          </div>
                        ) : null}
                        {domanda.type === 'checkbox' || domanda.type === 'radiogroup' || domanda.type === 'dropdown' ? (
                          <div>
                            {domanda?.choices?.map((opzione) => (
                              statistiche?.[sezione.titolo]?.[domanda.name]?.[opzione.value]
                                ? (
                                  <div>
                                    <p>
                                      {statistiche?.[sezione.titolo]?.
                                        [domanda.name]?.[opzione.value]}
                                      /
                                      {numQuestionari}
                                      {' '}
                                      (
                                      {Number.parseFloat(
                                        statistiche?.[sezione.titolo]?.
                                          [domanda.name]?.[opzione.value]
                                       / numQuestionari * 100,
                                      ).toFixed(2)}
                                      {' '}
                                      %
                                      )
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p>
                                      0/
                                      {numQuestionari}
                                      {' '}
                                      (
                                      {' '}
                                      0
                                      {' '}
                                      %
                                      )
                                    </p>
                                  </div>
                                )))}
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  )) : null}
              </tbody>
            </Table>
          </div>
        )) : null}
      </Row>
    </div>

  );
}

export default CardGroupStatistiche;
