import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../../utils/contants/theme-colors';

function fields(handleShowModal, manageArrayAllegati, column) {
  const fieldsArray = [];
  Object.keys(column).map((el) => (el !== 'Allegati' ? fieldsArray.push(
    {
      dataField: el,
      text: el,
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
  ) : null));

  fieldsArray.push(
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '20%' }),
      formatter: (e, row, rowIndex) => (
        <div>
          {true && (
          <FontAwesomeIcon
            icon={['fas', 'chain']}
            title="Gestione allegati"
            size="lg"
            id="allegati"
            style={{ color: themeColors.primary }}
            className="me-2"
            onClick={() => {
              handleShowModal(rowIndex);
              manageArrayAllegati(row?.Allegati, rowIndex);
            }}
          />
          )}
        </div>

      ),
    },
  );

  return (fieldsArray);
}

export default fields;
