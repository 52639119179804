/* eslint-disable no-unused-vars */
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Row, Col } from 'react-bootstrap';
import { options } from '../../utils/tableUtils';

export default function Table({
  title,
  data,
  profonditaStorica,
  button,
  fields,
  pagination, onTableChange, campoSort, keyField, selectRow, cellEdit, striped, notBordered,
}) {
  return (

    <Row className="m-1">

      {title || button ? (
        <Col>
          <p className="fw-bold fs-3">
            {title}
            {' '}
            {button}
          </p>
        </Col>
      ) : null}
      {profonditaStorica ? (
        <Col className="mb-1 col-md-3">
          {profonditaStorica}
        </Col>
      ) : null}

      <BootstrapTable
        remote
        defaultSorted={[{
          dataField: campoSort || '',
          order: 'desc',
        }]}
        bordered={!notBordered}
        columns={fields}
        keyField={keyField}
        wrapperClasses="table-responsive-sm"
        data={data}
        pagination={pagination && paginationFactory({
          ...options,
          page: pagination.page,
          sizePerPage: pagination.sizePerPage,
          totalSize: pagination.totalSize,
        })}
        onTableChange={onTableChange}
        selectRow={selectRow}
        cellEdit={cellEdit}
        striped={!!striped}
      />
    </Row>
  );
}
